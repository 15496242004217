import React from "react";

import { useParams } from "react-router-dom";

import { Text, TranslationScope } from "@/components/hooks/useText";

import { AnalysisBody } from "../components/AnalysisDoc";

import { QueryStatusBadge } from "./components/QueryCard";
import { AnsweredQuery, useAnsweredQueryData } from "./api";

const QueryHeader = ({
  status,
  text,
  enqueued_date,
  answer: { name },
}: AnsweredQuery) => (
  <div className="flex flex-col gap-4 mx-10">
    <div className="flex">
      <QueryStatusBadge {...{ status }} />
    </div>
    <div className="flex flex-col gap-1">
      <h1 className="flex text-4xl font-semibold leading-tight text-text-bright">
        {name}
      </h1>
      <div className="text-sm leading-tight">
        <span className="before:content-['Q:'] before:text-text-default before:mr-1 text-text-default">
          {text}
        </span>
        <span className="text-text-muted"> • </span>
        <span className="text-text-default">
          <Text i18nKey="enqueued_date" values={{ enqueued_date }} />
        </span>
      </div>
    </div>
  </div>
);

export const AnsweredQueryDoc = () => {
  const answerId = useParams()["answerId"];
  const queryData = useAnsweredQueryData(answerId);
  return queryData ? (
    <TranslationScope ns="insights" keyPrefix="queries.query">
      <div className="flex gap-4 my-8 mx-auto items-start">
        <main className="flex flex-col w-full max-w-3xl pt-6 pb-10 bg-bg-l2 rounded-lg gap-6">
          <QueryHeader {...queryData} />
          <AnalysisBody {...{ content: queryData.answer.text }} />
        </main>
      </div>
    </TranslationScope>
  ) : null;
};

export default AnsweredQueryDoc;
