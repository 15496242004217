import { useMemo } from "react";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import { matchRoutes } from "react-router";

import { uniqueId } from "lodash";

import { ProductTourStepDef, StepContext } from "./types";
import steps from "./steps";

export interface ProductTourStep extends ProductTourStepDef {
  id: string;
}

interface ProductTourState {
  tourStatus: "paused" | "started";
  steps: ProductTourStep[];
  context: StepContext;
}

const stateDef: ProductTourState = {
  tourStatus: "paused",
  steps: steps.map(({ id, ...step }) => ({
    id: id ?? uniqueId("product-step-"),
    ...step,
  })),
  context: {},
};

export const matchRoute = (route: string, location: string) =>
  matchRoutes([{ path: route }], location) !== null;

export const useProductTourStore = create(
  // using `combine` here to avoid explicitly spelling out methods' and `set` & `get`'s types,
  // see https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md
  combine(stateDef, (set, _get) => ({
    advance: ({ context }: { context: StepContext }) =>
      set((state) => ({
        ...state,
        context,
      })),

    startTour: () => set((state) => ({ ...state, tourStatus: "started" })),
    pauseTour: () => set((state) => ({ ...state, tourStatus: "paused" })),
  }))
);

export const useRouteSteps = (route: string) => {
  const steps = useProductTourStore((state) => state.steps);
  return useMemo(
    () => steps.filter(({ route: r }) => !r || matchRoute(r, route)),
    [steps, route]
  );
};
