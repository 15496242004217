import { flatMap } from "lodash";
import { extractKeys } from "@/components/charts/data";
import { type MetricDatum } from "@/components/insights/types";

const toHeatmapData = (
  data: MetricDatum[],
  indexBy: string,
  valueKey: string,
  keyAlias?: string
) => data.map((d) => [d[indexBy], keyAlias ?? valueKey, d[valueKey]]);

export const cohortSizeDataset = (
  data: MetricDatum[],
  indexBy: string,
  keyAlias: string
) => {
  const valueKey = extractKeys(data, { indexBy })[0];
  return {
    source: valueKey ? toHeatmapData(data, indexBy, valueKey, keyAlias) : [],
  };
};

export const cohortDataset = (data: MetricDatum[], indexBy: string) => {
  const valueKeys = extractKeys(data, { indexBy });
  return {
    source: flatMap(valueKeys, (valueKey) =>
      toHeatmapData(data, indexBy, valueKey)
    ),
  };
};
