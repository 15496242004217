import React, { useState, PropsWithChildren } from "react";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as PlusSign } from "@/assets/icons/plus.svg";

import { EntityId } from "@/client/models-api";

import { ProductComponentJSON } from "@/pages/products/serialization";
import { useProductPlansetListAPI } from "@/pages/plans/models/api";

import ProductComponent from "./components/ProductComponent";

import { useProductComponentsAPI, useProductComponentsListAPI } from "./api";
import { useProductContext } from "./ProductPageLayout";

const ComponentsList = ({ children, ...props }: PropsWithChildren) => (
  <div className="flex flex-row" {...props}>
    <div
      id="product-component-list"
      className="grid grid-flow-row gap-1 auto-cols-[1fr]"
    >
      {children}
    </div>
  </div>
);

//@ts-ignore
const NewComponentPlaceholder = ({ draftsCount, onClick, className = "" }) => (
  <div
    id={
      draftsCount
        ? `new-component-placeholder-${draftsCount}`
        : "new-component-placeholder"
    }
    className={cx(
      "relative flex flex-row items-center rounded-md gap-5 p-4",
      "border-2 border-border-default group hover:border-border-bright",
      "hover:shadow-lg cursor-pointer select-none",
      className
    )}
    onClick={onClick}
  >
    <PlusSign className="w-10 text-text-muted group-hover:text-text-link" />
    <span className="text-text-muted font-medium group-hover:text-text-link ">
      Create a new component
    </span>
  </div>
);

const newComponent = (): ProductComponentJSON => ({
  id: EntityId.new(),
  name: "Untitled component",
  description: "",
  is_countable: false,
});

const Component = ({
  elementId,
  productId,
  component,
  discardDraft,
}: {
  elementId?: string;
  productId: string;
  component: ProductComponentJSON;
  discardDraft?: (productId: string) => void;
}) => {
  const { productComponent, saveProductComponent, deleteProductComponent } =
    useProductComponentsAPI({
      productId,
      productComponentId: component.id,
    });

  return (
    <ProductComponent
      elementId={elementId}
      component={productComponent || component}
      saveComponent={saveProductComponent}
      deleteComponent={deleteProductComponent}
      discardDraft={discardDraft}
    />
  );
};

const DraftComponents = ({ productId }: { productId: string }) => {
  const [drafts, setDrafts] = useState<ProductComponentJSON[]>([]);
  const discardDraft = (componentId: string) =>
    setDrafts(drafts.filter(({ id }) => id !== componentId));

  return (
    <>
      <NewComponentPlaceholder
        draftsCount={drafts.length}
        className="mb-4"
        onClick={() => setDrafts([newComponent(), ...drafts])}
      />
      {drafts.map((component, i) => (
        <Component
          elementId={`draft-component-${i + 1}`}
          key={component.id}
          productId={productId}
          component={component}
          discardDraft={discardDraft}
        />
      ))}
    </>
  );
};

const ProductComponents = () => {
  const { product } = useProductContext();
  const { components: productComponents } = useProductComponentsListAPI({
    productId: product.id,
  });

  const plans = useProductPlansetListAPI({ productId: product.id });

  return (
    <ComponentsList
      data-components-count={productComponents?.length ?? 0}
      data-plans-count={plans?.length}
    >
      <DraftComponents productId={product.id} />
      {(productComponents ?? []).map((component) => (
        <Component
          key={component.id}
          productId={product.id}
          component={component}
        />
      ))}
    </ComponentsList>
  );
};

export default ProductComponents;
