import { omit } from "lodash";

import { type MetricDatum } from "@/components/insights/types";

export const extractKeys = (
  data: MetricDatum[],
  { indexBy, comprehensive }: { indexBy: string; comprehensive?: boolean }
) =>
  comprehensive
    ? [...new Set(data.flatMap(Object.keys)).values()].filter(
        (k) => k !== indexBy
      )
    : Object.keys(omit(data[0], [indexBy]));
