import React from "react";

import cx from "clsx";

// @ts-ignore
import { ReactComponent as UndoIcon } from "@/assets/icons/undo.svg";

import Tooltip from "@/components/Tooltip";
import { type CoreButtonProps } from "@/components/CoreButtons";

import { useText } from "@/components/hooks/useText";

const UndoButton = ({ className, disabled, ...props }: CoreButtonProps) => {
  const t = useText();
  return (
    <Tooltip content={disabled ? "" : t("tooltip.undo")}>
      <button
        type="button"
        className={cx(
          "button-base bg-bg-l3 p-2 rounded-md text-text-default hover:text-text-error",
          "focus:border-border-focus active:text-text-error",
          className
        )}
        {...{ disabled, ...props }}
      >
        <UndoIcon className="w-5" />
      </button>
    </Tooltip>
  );
};

export default UndoButton;
