import { createTheme } from "@mui/material/styles";

export const colors = {
  primary: {
    black: "#000000", // Background
    brand: "#7605e8", // Purple - Highlighted components
    textMonoBase: "#A7AAB1",
  },
  secondary: {
    bgDark: "#070B0D",
    bgBase: "#121416",
    bgLightish: "#16181D",
    bgLight: "#1B1C21",
    bgLighter: "#292A35",
    strokeBase: "#2B2C35",
    strokeLight: "#47495B",
    strokeFocus: "#6F27B7",
    white: "#FFFFFF",
    textMonoLight: "#DDDDE2",
    textMonoDark: "#828790",
    red: "#E74C3C",
    amber: "#ffc400",
  },
};

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: colors.primary.brand,
    },
    secondary: {
      main: colors.secondary.red,
    },
    background: {
      default: colors.secondary.bgBase,
      paper: colors.secondary.bgBase,
    },
    text: {
      main: colors.primary.textMonoBase,
      disabled: colors.secondary.textMonoDark,
    },
    info: {
      main: colors.primary.textMonoBase,
    },
    divider: colors.secondary.strokeBase,
    warning: {
      main: colors.secondary.amber,
    },
    error: {
      main: colors.secondary.red,
    },
    shape: {
      borderRadius: 5,
    },
    unstable_sxConfig: {
      borderColor: colors.secondary.strokeBase,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "var(--ui-font-family)",
    },
  },
});
