import React from "react";

// @ts-ignore
import { ReactComponent as QCIcon } from "@/assets/icons/quality-control-lg.svg";

import { Text, TranslationScope } from "@/components/hooks/useText";

const Header = ({ description }: { description?: string }) => (
  <TranslationScope ns="data-quality">
    <div className="flex items-center gap-5">
      <QCIcon className="min-h-[48px] h-[48px] text-base-teal-300" />
      <div className="flex flex-col gap-1">
        <h1 className="text-3xl font-semibold text-text-bright">
          <Text i18nKey="title" />
        </h1>
        {description && <div className="text-text-default">{description}</div>}
      </div>
    </div>
  </TranslationScope>
);

export default Header;
