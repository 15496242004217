import React, { useCallback, forwardRef, ForwardedRef } from "react";

import { Trans } from "react-i18next";

import {
  FieldValues,
  useFieldArray,
  Control,
  FieldPathByValue,
  ArrayPath,
} from "react-hook-form";

import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { pick } from "lodash";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as UploadIcon } from "@/assets/icons/upload.svg";
// @ts-ignore
import { ReactComponent as InfoIcon } from "@/assets/icons/circle-info.svg";
// @ts-ignore
// @ts-ignore
import { ReactComponent as FileIcon } from "@/assets/icons/document.svg";
// @ts-ignore
import { ReactComponent as CheckIcon } from "@/assets/icons/circle-check-filled.svg";
// @ts-ignore
import { ReactComponent as XIcon } from "@/assets/icons/small-x.svg";

import useText from "@/components/hooks/useText";
import Tooltip from "@/components/Tooltip";
import Spinner from "@/components/Spinner";

import InputField, { InputFieldProps } from "@/components/easy-form/InputField";

import { uploadFile } from "@/pages/onboarding/api";
import { FileJSON } from "@/pages/onboarding/v2/schema";
import { EntityId } from "@/client/models-api";

type FileUploadMode = "single" | "multiple";

const FieldTooltip = () => {
  const t = useText();
  return (
    <div className="text-text-bright text-sm leading-tight p-4 pb-3">
      <Trans
        i18nKey="tooltip"
        t={t}
        components={{ em: <span className="font-medium" /> }}
      />
    </div>
  );
};

const UploadPlaceholder = ({
  isDragActive,
  hasFiles,
}: {
  isDragActive: boolean;
  hasFiles: boolean;
}) => {
  const { t } = useTranslation("common", {
    keyPrefix: "components.file_upload",
  });

  const pt = useText();
  const hasTooltip = Boolean(pt("tooltip", { defaultValue: "" }));

  return (
    <li
      className={cx(
        "flex items-center justify-between gap-5 rounded-lg",
        hasFiles ? "py-3 pl-6 pr-3" : "py-5 pl-6 pr-3"
      )}
    >
      <UploadIcon
        className={cx(
          "w-8 aspect-square text-text-link transition-transform state-transition",
          isDragActive ? "scale-[1.1]" : "group-hover:scale-[1.1]"
        )}
      />
      <div className="flex flex-col flex-grow leading-tight gap-y-0.5">
        <div className="text-text-bright font-medium group-hover:text-text-link state-transition-colors">
          {pt(`upload_label.${hasFiles ? "other" : "first"}`)}
        </div>
        <div
          className={cx(
            "text-sm state-transition-colors",
            isDragActive ? "text-text-link animate-pulse" : "text-text-default"
          )}
        >
          {t(isDragActive ? "active_prompt" : "passive_prompt")}
        </div>
      </div>
      {hasTooltip && (
        <Tooltip
          content={<FieldTooltip />}
          delayDuration={200}
          align="end"
          className="z-50 bg-[#202127] fill-bg-[#202127] max-w-[360px] rounded-lg drop-shadow-lg"
        >
          <InfoIcon
            className={cx(
              "w-9 p-2 aspect-square text-text-muted/60 group-hover:text-text-muted group-hover:hover:text-text-bright",
              "state-transition-colors transition-all",
              isDragActive && "opacity-0"
            )}
          />
        </Tooltip>
      )}
    </li>
  );
};

const FileStatus = ({
  file,
  remove,
}: {
  file: FileJSON;
  remove: VoidFunction;
}) => {
  switch (file.uploadStatus) {
    case "uploading":
      return (
        <div className="w-7 mr-1 aspect-square">
          <Spinner />
        </div>
      );

    case "uploaded":
      return (
        <div className="p-1">
          <CheckIcon className="w-6 aspect-square shrink-0 text-text-bright fill-brand-purple-l3/40" />
        </div>
      );

    case "failed":
      return (
        <button
          className="p-2 text-text-error/80 hover:text-text-error"
          onClick={(event) => {
            event.stopPropagation();
            remove();
          }}
        >
          <XIcon className="w-4" />
        </button>
      );
  }

  return null;
};

const FileItem = ({
  file,
  className,
  remove,
}: {
  file: FileJSON;
  className?: string;
  remove: VoidFunction;
}) => {
  const { t } = useTranslation("common", {
    keyPrefix: "components.file_upload",
  });

  return (
    <li
      className={cx(
        "group  first-of-type:rounded-t-lg last-of-type:rounded-b-lg",
        "mx-[2px] first-of-type:mt-[2px] last-of-type:mb-[2px]",
        "transition-opacity state-transition",
        file.uploadStatus === "uploaded" ? "bg-brand-purple" : "bg-bg-l4",
        className
      )}
    >
      <div
        className={cx(
          "flex items-center justify-between gap-5 py-4 pl-6 pr-3",
          "-mx-[2px] group-first-of-type:-mt-[2px] group-last-of-type:-mb-[2px]"
        )}
      >
        <FileIcon
          className={cx(
            "w-8 aspect-square shrink-0",
            file.uploadStatus === "failed" && "opacity-50"
          )}
        />
        <div
          className={cx(
            "flex flex-col flex-grow leading-tight",
            file.uploadStatus === "failed" && "opacity-50"
          )}
        >
          <div
            className={
              file.uploadStatus === "failed"
                ? "text-text-error"
                : "text-text-bright"
            }
          >
            {file.name}
          </div>
          <div className="text-sm text-text-default">
            {t(
              file.lastModified
                ? "local_file_description"
                : "uploaded_file_description",
              file
            )}
          </div>
        </div>
        <Tooltip
          content={t(`status.${file.uploadStatus}.tooltip`, {
            defaultValue: "",
          })}
          className="z-50 bg-[#202127] fill-bg-[#202127] rounded-lg drop-shadow-lg"
        >
          <FileStatus {...{ file, remove }} />
        </Tooltip>
      </div>
    </li>
  );
};

const Dropzone = forwardRef(
  <
    Values extends FieldValues,
    Name extends FieldPathByValue<Values, FileJSON[]>
  >(
    {
      name,
      control,
      mode,
    }: {
      name: Name;
      control: Control<Values>;
      mode: FileUploadMode;
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { fields, append, update, remove } = useFieldArray({
      control,
      name: name as ArrayPath<Values>,
      keyName: "key",
    });

    const files = fields as unknown as FileJSON[];

    const onDrop = useCallback(
      async <T extends File>(acceptedFiles: T[]) => {
        const baseIndex = files.length;

        await Promise.all([
          acceptedFiles.map(async (file, i) => {
            const fileRecord = {
              id: EntityId.new(),
              ...pick(file, ["name", "size", "lastModified"]),
              mime_type: file.type,
              category: name,
              uploadStatus: "uploading",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;

            append(fileRecord);

            const uploaded = await uploadFile(file, name);
            update(
              baseIndex + i,
              uploaded ?? { ...fileRecord, uploadStatus: "failed" }
            );
          }),
        ]);
      },
      [append, name, update, files]
    );

    const disabled = mode !== "multiple" && files.length > 0;

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple: mode === "multiple",
      disabled,
    });

    return (
      <div
        {...getRootProps()}
        className={cx(
          "group flex flex-col rounded-lg",
          "focus-ring-rounded-lg state-transition-colors select-none",
          isDragActive
            ? "border-dashed-8-4-text-link animate-flowing-border bg-bg-l4/60"
            : !disabled &&
                "hover:bg-bg-l4/60 focus-visible:bg-bg-l4/60 border-dashed-8-4-border-bright cursor-pointer"
        )}
      >
        <input ref={ref} {...getInputProps()} data-testid="upload-file" />
        <ul className={cx("flex flex-col gap-[1px]")}>
          {files.map((file, i) => (
            <FileItem
              key={`${file.name}-${i}`}
              file={file}
              className={isDragActive ? "opacity-20" : ""}
              remove={() => remove(i)}
            />
          ))}
          {(mode === "multiple" || !files.length) && (
            <UploadPlaceholder
              isDragActive={isDragActive}
              hasFiles={Boolean(files.length)}
            />
          )}
        </ul>
      </div>
    );
  }
);

const FileUpload = forwardRef(
  <
    Values extends FieldValues,
    Name extends FieldPathByValue<Values, FileJSON[]>
  >(
    {
      mode = "single",
      ...props
    }: InputFieldProps<Values, Name> & { mode?: FileUploadMode },
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <InputField ref={ref} {...props}>
      {(props) => <Dropzone {...props} mode={mode} />}
    </InputField>
  )
);

export default FileUpload;
