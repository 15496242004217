import React, { Fragment, useMemo, useState, useEffect } from "react";

import {
  UNSAFE_LocationContext as UnsafeLocationContext,
  RouterProvider,
  Navigate,
} from "react-router";

import { createHashRouter, useLocation } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import { useSessionStorage } from "usehooks-ts";

import { useAuth } from "@/components/Auth";
import { Overlay } from "@/components/Modal";

import { useOnboardingStatusAPI } from "@/pages/onboarding/api";

import { calcCurrentLink } from "./links";

import WelcomePanel from "./Welcome";
import BusinessDetailsPanel from "./BusinessDetails";
import PricingPanel from "./Pricing";
import StripeSetup from "./StripeCheckout";
import SalesforceSetup from "./integrations/SalesforceSetup";

import StepsLayout from "./StepsLayout";
import SlackSetup from "./integrations/SlackSetup";
import CrmIntegration from "./CrmIntegration";
import DataWarehouseIntegration from "./DataWarehouseIntegration";
import BillingIntegration from "./BillingIntegration";

const cpqStepRoutes = [
  { path: "company/business-details", element: <BusinessDetailsPanel /> },
  { path: "company/pricing", element: <PricingPanel /> },
  { path: "apps/salesforce", element: <SalesforceSetup /> },
  { path: "apps/billing", element: <BillingIntegration /> },
  { path: "apps/slack", element: <SlackSetup /> },
];

const insightsOnlyStepRoutes = [
  { path: "company/business-details", element: <BusinessDetailsPanel /> },
  { path: "company/pricing", element: <PricingPanel /> },
  { path: "apps/slack", element: <SlackSetup /> },
  { path: "apps/crm", element: <CrmIntegration /> },
  { path: "apps/billing", element: <BillingIntegration /> },
  { path: "apps/data-warehouse", element: <DataWarehouseIntegration /> },
];

interface StepRoute {
  path: string;
  element: JSX.Element;
}

const NavigateIfNeeded = ({ to }: { to: string }) => {
  const location = useLocation();
  return location.pathname !== to ? <Navigate to={to} /> : null;
};

const useStepRoutes = () => {
  const { session } = useAuth();
  return session.organization?.product_offering === "cpq"
    ? cpqStepRoutes
    : insightsOnlyStepRoutes;
};

export const NavigateToCurrentStep = ({
  basePath,
  fallback,
}: {
  basePath: string;
  fallback?: string;
}) => {
  const status = useOnboardingStatusAPI();
  const stepRoutes = useStepRoutes();

  if (!status) return null;

  if (status.completedNonPaymentRequired)
    return <NavigateIfNeeded to={`${basePath}/checkout`} />;

  const stepPath =
    status.percentageComplete === 0
      ? "welcome"
      : calcCurrentLink(
          stepRoutes.map((route) => route.path),
          status.steps
        );

  return (
    <>
      {Boolean(stepPath || fallback) && (
        <NavigateIfNeeded to={`${basePath}/${stepPath ?? fallback}`} />
      )}
    </>
  );
};

const NavigateToCheckoutWhenReady = ({ basePath }: { basePath: string }) => {
  const status = useOnboardingStatusAPI();

  if (!status) return null;

  if (status.completedNonPaymentRequired)
    return <NavigateIfNeeded to={`${basePath}/checkout`} />;

  return null;
};

const createOnboardingRouter = ({
  basePath,
  basename,
  stepRoutes,
}: {
  basePath: string;
  basename?: string;
  stepRoutes: StepRoute[];
}) =>
  createHashRouter(
    [
      {
        path: "/",
        element: (
          <NavigateToCurrentStep basePath={basePath} fallback="welcome" />
        ),
      },
      {
        path: `${basePath}/welcome`,
        element: (
          <>
            <NavigateToCurrentStep basePath={basePath} />
            <WelcomePanel next={`${basePath}/${stepRoutes[0]?.path}`} />
          </>
        ),
      },
      {
        element: (
          <>
            <StepsLayout routes={stepRoutes} sections={["company", "apps"]} />
            <NavigateToCheckoutWhenReady basePath={basePath} />
          </>
        ),
        path: basePath,
        children: stepRoutes,
      },
      {
        path: `${basePath}/checkout`,
        element: <StripeSetup />,
      },
    ],
    { basename }
  );

const Router = () => {
  const stepRoutes = useStepRoutes();
  const onboardingRouter = useMemo(
    () =>
      createOnboardingRouter({
        basePath: "/onboarding",
        stepRoutes: stepRoutes,
      }),
    [stepRoutes]
  );

  return (
    // see https://github.com/remix-run/react-router/issues/7375#issuecomment-975431736
    //@ts-ignore
    <UnsafeLocationContext.Provider value={null}>
      <RouterProvider router={onboardingRouter} />
    </UnsafeLocationContext.Provider>
  );
};

const secretSkipCode = "goosehonk";

const SetupGuide = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: VoidFunction;
}) => {
  const { session } = useAuth();
  const [skipOnboarding, setSkipOnboarding] = useSessionStorage(
    `skip-onboarding-${session.organization?.id}`,
    false
  );
  const [skipCode, setSkipCode] = useState("");
  const secretSkipCodeHandler = (e: KeyboardEvent) => {
    // If onboarding is already skipped, then ignore everything.
    if (skipOnboarding) return;

    // If event has a defined target (usually HTML input), then don't capture its value.
    if ((e.target as HTMLInputElement)?.type) return;

    setSkipCode((prev) => {
      if (e.key.length !== 1) return prev;

      const next = prev + e.key;
      const comparator = secretSkipCode.substring(0, next.length);
      // Update the state if user is on track to type the secret code,
      // reset to empty otherwise.
      return next === comparator ? next : "";
    });
  };

  useEffect(() => {
    if (skipCode === secretSkipCode) {
      console.log("Found a goose!");
      setSkipOnboarding(true);
    }
  }, [skipCode, setSkipOnboarding]);

  useEffect(() => {
    document.addEventListener("keydown", secretSkipCodeHandler);
    return () => document.removeEventListener("keydown", secretSkipCodeHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- we only want this effect to happen on mount

  return skipOnboarding ? (
    <></>
  ) : (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Overlay />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95 translate-y-48"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 scale-95 translate-y-48"
            >
              <Dialog.Panel className="transform overflow-hidden rounded-xl shadow-xl transition-all">
                <Router />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SetupGuide;
