import { every } from "lodash";
import z, { Infer } from "myzod";

export const pricingSchema = z.object({
  id: z.string().optional(),
  pricing_structure: z.string(),
  updated_at: z.date().optional(),
  created_at: z.date().optional(),
});

export const fileSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  size: z.number(),
  mime_type: z.string(),
  category: z.string().optional(),
  created_at: z.date().optional(),
  lastModified: z.number().optional(),
  uploadStatus: z.enum(["uploading", "uploaded", "failed"]).optional(),
});

export const fileListSchema = z
  .array(fileSchema)
  .min(1, "required")
  .withPredicate(
    (files) => every(files, ({ uploadStatus }) => uploadStatus !== "uploading"),
    "uploading"
  )
  .withPredicate(
    (files) => every(files, ({ uploadStatus }) => uploadStatus !== "failed"),
    "failed"
  );

export const uploadsSchema = z.object({
  pricing_matrix: fileListSchema,
});

export const crmUploadsSchema = z.object({
  crm_export: fileListSchema,
});

export const dataWarehouseUploadsSchema = z.object({
  data_warehouse_export: fileListSchema,
});

export const billingUploadsSchema = z.object({
  invoices: fileListSchema,
});

export const oauthInstallationSchema = z
  .object({
    connected: z.boolean(),
  })
  .withPredicate((installation) => installation.connected, "not connected");

export type FileJSON = Infer<typeof fileSchema>;
export type PricingJSON = Infer<typeof pricingSchema>;
export type FileUploadsValues = Infer<typeof uploadsSchema>;
export type CrmUploadsValues = Infer<typeof crmUploadsSchema>;
export type DataWarehouseUploadsValues = Infer<
  typeof dataWarehouseUploadsSchema
>;
export type BillingUploadsValues = Infer<typeof billingUploadsSchema>;
