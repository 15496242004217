import { useTranslationScope } from "@/components/hooks/useText";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";

export const SettingsHeader = ({
  title,
  description,
}: {
  title: string;
  description?: string | null;
}) => (
  <hgroup>
    <h1 className="text-2xl">{title}</h1>
    {description && <p className="text-text-default mt-2">{description}</p>}
  </hgroup>
);

export const SettingsActions = ({ actions }: { actions: React.ReactNode }) => {
  return <div>{actions}</div>;
};

const SettingsPanel = ({
  children,
  keyPrefix,
  actions,
}: PropsWithChildren<{ keyPrefix: string; actions?: React.ReactNode }>) => {
  const { t, TranslationScope } = useTranslationScope("settings", {
    keyPrefix: `panels.${keyPrefix}`,
  });

  return (
    <TranslationScope>
      <div className="flex flex-col gap-4">
        <div className={clsx("flex flex-row gap-4")}>
          <div className={clsx("grow")}>
            <SettingsHeader title={t("title")} description={t("description")} />
          </div>
          <div>
            <SettingsActions actions={actions} />
          </div>
        </div>
        {children}
      </div>
    </TranslationScope>
  );
};

export default SettingsPanel;
