import { sortBy, uniqBy, map, max } from "lodash";

import { EntityId } from "@/client/models-api";

import {
  ProductPlanSetJSON,
  ProductPlanDetailsJSON,
  ProductPlanComponentJSON,
  ProductPlanPricingJSON,
  PricingType,
  BillingPeriod,
  PricingFormula,
} from "./serialization";

import {
  hydratePricing,
  normalizePricing,
  summaryPrice,
} from "@/pages/plans/models/pricing-plan";

export const normalizeComponents = (
  planComponents: ProductPlanComponentJSON[]
) => sortBy(planComponents, ["order", "name"]);

export const defaultPlanPricing = (
  plan: ProductPlanDetailsJSON
): ProductPlanPricingJSON[] =>
  [BillingPeriod.MONTHLY, BillingPeriod.ANNUALLY].map((period) => ({
    id: EntityId.new(),
    pricing_type: PricingType.UNIT,
    pricing_formula: PricingFormula.SINGLE_RATE,
    billing_period: period,
    default_amount: plan.free_plan ? 0 : undefined,
  }));

export const normalizePlanPricing = (
  plan: ProductPlanDetailsJSON,
  pricing: ProductPlanPricingJSON[]
) => {
  // Create empty plan prices as fallback if the price objects are incomplete.
  const prices = uniqBy(
    [...pricing, ...defaultPlanPricing(plan)].map(hydratePricing),
    "billing_period"
  );

  return sortBy(prices, "billing_period");
};

export type EditablePlan = ProductPlanSetJSON;

export const normalizeBeforeSave = ({
  components,
  prices,
  ...plan
}: EditablePlan) => ({
  ...plan,
  prices: prices.map(normalizePricing),
  components: components.map((component, index) => ({
    ...component,
    order: index,
  })),
});

export const productPlansSortOrder = (
  left: ProductPlanSetJSON,
  right: ProductPlanSetJSON
) => {
  // Free plans should always come first.
  if (left.free_plan && right.free_plan) return 0;
  if (left.free_plan) return -1;
  if (right.free_plan) return 1;

  // If the plan is *not* free AND plan prices are *none*, assume
  // it is enterprise "contact sales" plan. In real world scenario,
  // this is unlikely as internally they should have some number.
  const leftMaxPrice = max(map(left.prices, summaryPrice));
  const rightMaxPrice = max(map(right.prices, summaryPrice));

  if (leftMaxPrice === undefined && rightMaxPrice === undefined) return 0;
  if (leftMaxPrice === undefined) return 1;
  if (rightMaxPrice === undefined) return -1;

  // In the event that both plans have proper pricing tiers, sort by
  // price.
  return Number(leftMaxPrice) - Number(rightMaxPrice);
};
