import React, { useState, useCallback, useMemo, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { every } from "lodash";

import { inflateRect, Rectangle } from "@/lib/geometry";

import {
  getClientRect,
  getAbsoluteClientRect,
  elementsBoundingRect,
  queryForElement,
  getDataset,
  useVizObserver,
  useWaitForElement,
  useMutationObserver,
} from "./dom";

import {
  useProductTourStore,
  useRouteSteps,
  ProductTourStep,
  matchRoute,
} from "./store";
import { StepContext, StepPrecondition, StepSpotlight } from "./types";
import { StepBeacon } from "./Beacon";
import { StepPopover } from "./Popover";
import { ProductTourOverlay } from "./Overlay";

const getTargetElement = (
  { target }: ProductTourStep,
  context: StepContext
): Element | null => {
  const t = typeof target === "function" ? target(context) : target;
  // console.log("target", t);
  return t instanceof Element ? t : t ? queryForElement(t) : null;
};

interface ActiveStep {
  step: ProductTourStep;
  element: Element;
}

const checkPrecondition = (precondition: StepPrecondition) =>
  Boolean(
    typeof precondition === "string"
      ? queryForElement(precondition)
      : !queryForElement(precondition.not)
  );

const checkPreconditions = (preconditions: StepPrecondition[]) =>
  every(preconditions, checkPrecondition);

const useActiveStep = () => {
  const { pathname } = useLocation();
  const routeSteps = useRouteSteps(pathname);
  const context = useProductTourStore((state) => state.context);
  const [activeStep, setActiveStep] = useState<ActiveStep | null>(null);
  const [resetting, setResetting] = useState(false);

  useEffect(() => {
    if (!routeSteps.length) setActiveStep(null);
  }, [routeSteps.length]);

  // console.log("route steps", routeSteps);

  const firstActiveStep = useCallback(() => {
    // console.log("running firstActiveStep callback for", routeSteps);
    for (const step of routeSteps) {
      const element = getTargetElement(step, context);
      const disableIfSelector = step.advanceOn?.selector;
      // console.log("element for step:", { element, step, disableIfSelector });
      if (
        element &&
        (!step.advanceOn?.route ||
          !matchRoute(step.advanceOn.route, pathname)) &&
        (!disableIfSelector || !queryForElement(disableIfSelector)) &&
        (!step.preconditions || checkPreconditions(step.preconditions))
      ) {
        // console.log("returning first active step", { step, element });
        return { step, element };
      }
    }

    // console.log("!! no active step found, returning null");
    setActiveStep(null);
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeSteps, context, setActiveStep, resetting]);

  useWaitForElement(
    firstActiveStep,
    useCallback(
      (activeStep) => {
        setResetting(false);
        setActiveStep(activeStep);
      },
      [setResetting, setActiveStep]
    )
  );

  const reset = useCallback(() => {
    if (!resetting) {
      setResetting(true);
    }
  }, [resetting]);

  return activeStep
    ? {
        ...activeStep,
        reset,
      }
    : null;
};

const calcSpotlightRect = (
  clientRect: Rectangle,
  spotlight?: StepSpotlight
) => {
  const calculatedRect = elementsBoundingRect(
    (spotlight?.elements ?? []).map(queryForElement)
  );

  return inflateRect(calculatedRect ?? clientRect, spotlight?.padding ?? 32);
};

const TourStep = ({
  step,
  element,
  reset,
}: {
  step: ProductTourStep;
  element: Element;
  reset: () => void;
}) => {
  const { tourStatus, startTour, pauseTour, advance } = useProductTourStore(
    ({ tourStatus, startTour, pauseTour, advance }) => ({
      tourStatus,
      startTour,
      pauseTour,
      advance,
    })
  );

  // use `fixed` positioning for the modal overlay/spotlight to simplify
  // implementation/avoid tripping Safari
  const [clientRect, setClientRect] = useState(getClientRect(element));

  // use `absolute` positioning for the beacon for tighter position tracking
  // when scrolling
  const [absoluteclientRect, setAbsoluteClientRect] = useState(
    getAbsoluteClientRect(element)
  );

  useVizObserver(
    element,
    useCallback(() => {
      if (document.body.contains(element)) {
        // console.log("element visibility changed", element);
        setClientRect(getClientRect(element));
        setAbsoluteClientRect(getAbsoluteClientRect(element));
      } else {
        // console.log("element removed from DOM, resetting", element);
        reset();
      }
    }, [element, setClientRect, setAbsoluteClientRect, reset])
  );

  const enforcePreconditions = useCallback(
    (_: MutationRecord[], observer: MutationObserver) => {
      if (step.preconditions && !checkPreconditions(step.preconditions)) {
        // console.log("step precondition no longer holds, resetting");
        reset();
        observer.disconnect();
        return true;
      }

      return false;
    },
    [step.preconditions, reset]
  );

  useMutationObserver(
    document.body,
    { attributes: true, childList: true, subtree: true },
    step.preconditions ? enforcePreconditions : undefined
  );

  useWaitForElement<Element>(
    step.advanceOn?.selector,
    useCallback(
      (element) => {
        // console.log(
        //   "!!! found advanceOn element",
        //   element,
        //   getDataset(element)
        // );

        advance({ context: getDataset(element) });
      },
      [advance]
    )
  );

  const spotlightRect = useMemo(
    () => calcSpotlightRect(clientRect, step.spotlight),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(clientRect), step.spotlight]
  );

  return tourStatus === "started" ? (
    <ProductTourOverlay {...{ step, spotlightRect }} onClick={pauseTour}>
      {({ popoverProps }) => (
        <StepPopover {...popoverProps}>
          {typeof step.content === "function"
            ? step.content(getDataset(element))
            : step.content}
        </StepPopover>
      )}
    </ProductTourOverlay>
  ) : step.beacon === false ? (
    <></>
  ) : (
    <StepBeacon
      elementRect={absoluteclientRect}
      {...step.beacon}
      onClick={startTour}
    />
  );
};

const ProductTour = () => {
  const activeStep = useActiveStep();
  if (!activeStep) {
    return <></>;
  }

  // console.log("active step", activeStep);
  return <TourStep {...activeStep} />;
};

export default ProductTour;
