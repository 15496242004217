import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { slug } from "@/lib/string";
import mailToUrl from "@/lib/mailto";
import api, { extractError } from "@/client/api";

// @ts-ignore
import { ReactComponent as BorkenLogo } from "@/assets/icons/borken-logo.svg";
// @ts-ignore
import { ReactComponent as TimerExpiredIcon } from "@/assets/icons/timer-expired.svg";
// @ts-ignore
import { ReactComponent as BanIcon } from "@/assets/icons/ban.svg";

import { useAuth } from "@/components/Auth";
import Spinner from "@/components/Spinner";
import { Text, TranslationScope } from "@/components/hooks/useText";

interface EmailConfirmationStatus {
  status: "confirming" | "confirmed" | "failed";
  error?: string;
}

const ConfirmingSpinner = () => (
  <div className="flex flex-col items-center gap-6 pb-10">
    <div className="w-20 aspect-square">
      <Spinner className="border-4" />
    </div>
    <div className="text-xl font-semibold text-text-bright">Confirming...</div>
  </div>
);

const BannerFooter = ({ link }: { link: string }) => (
  <div className="text-text-default text-center">
    <Text
      i18nKey="footer"
      components={[
        <Link
          to={link}
          className="text-text-link font-semibold hover:underline outline-none focus:text-text-link"
        />,
      ]}
    />
  </div>
);

const HelpFooter = () => (
  <div className="text-text-default text-center">
    <Text
      i18nKey="footer"
      values={{ contactEmail }}
      components={[
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={mailToUrl({
            to: contactEmail,
            subject: "Email confirmation issue",
          })}
          className="underline hover:text-text-link outline-none focus:text-text-link"
        />,
      ]}
    />
  </div>
);

const ConfirmedBanner = ({ email }: { email: string }) => (
  <TranslationScope keyPrefix="email_confirmed">
    <div className="flex flex-col items-center gap-6">
      <div className="[font-size:80px] leading-none">🎉</div>
      <div className="text-xl text-text-bright font-semibold text-center">
        <Text
          i18nKey="message"
          values={{ email }}
          components={[
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={mailToUrl({ to: email })}
              className="underline text-text-link"
            />,
          ]}
        />
      </div>
      <BannerFooter link="/settings/user/profile" />
    </div>
  </TranslationScope>
);

const contactEmail = "help@maca.io";

const InternalErrorBanner = () => (
  <TranslationScope keyPrefix="error.internal_error">
    <div className="flex flex-col items-center gap-6">
      <BorkenLogo className="w-36 text-base-white" />
      <div className="text-text-bright text-xl font-semibold leading-tight text-center">
        <Text i18nKey="message" />
      </div>
      <HelpFooter />
    </div>
  </TranslationScope>
);

const ExpiredTokenBanner = () => (
  <TranslationScope keyPrefix="error.expired_token">
    <div className="flex flex-col items-center gap-6">
      <TimerExpiredIcon className="w-20 text-base-orange-400" />
      <div className="text-text-bright text-xl font-semibold leading-tight text-center">
        <Text i18nKey="message" />
      </div>
      <BannerFooter link="/settings/user/profile" />
    </div>
  </TranslationScope>
);

const DuplicateEmailBanner = ({ email }: { email: string }) => (
  <TranslationScope keyPrefix="error.duplicate_email">
    <div className="flex flex-col items-center gap-6">
      <BanIcon className="w-20 text-base-orange-400" />
      <div className="text-text-bright text-xl font-semibold leading-tight text-center">
        <Text
          i18nKey="message"
          values={{ email }}
          components={[
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={mailToUrl({ to: email })}
              className="underline text-text-link"
            />,
          ]}
        />
      </div>
      <HelpFooter />
    </div>
  </TranslationScope>
);

const VerifyEmail = () => {
  const [{ status, error }, setStatus] = useState<EmailConfirmationStatus>({
    status: "confirming",
  });

  const { refreshSession } = useAuth();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token") ?? "";
  const email = searchParams.get("email") ?? "";

  useEffect(() => {
    const confirmEmail = async () => {
      const res = await api.post("user/verify-email/", {
        json: { token, email },
        throwHttpErrors: false,
      });

      const errorState = await extractError(res);
      setStatus(() =>
        res.ok ? { status: "confirmed" } : { status: "failed", ...errorState }
      );

      if (res.ok) {
        refreshSession();
      }
    };

    confirmEmail();
  }, [token, email, refreshSession]);

  return (
    <TranslationScope ns="user" keyPrefix="verify_email">
      <div className="flex flex-col grow items-center justify-center">
        <div className="flex flex-col items-center gap-4 text-text-muted select-none mt-5 mb-10">
          {status === "confirming" ? (
            <ConfirmingSpinner />
          ) : status === "confirmed" ? (
            <ConfirmedBanner {...{ email }} />
          ) : (
            <Text
              i18nKey={`error.${slug(error ?? "")}.root`}
              components={{
                internal_error: <InternalErrorBanner />,
                expired_token: <ExpiredTokenBanner />,
                duplicate_email: <DuplicateEmailBanner {...{ email }} />,
              }}
            />
          )}
        </div>
      </div>
    </TranslationScope>
  );
};

export default VerifyEmail;
