import React, { ReactNode, PropsWithChildren } from "react";
import cx from "clsx";

import LazyRender from "@/components/LazyRender";
import { Text } from "@/components/hooks/useText";

import { ChartDataFetcher } from "@/components/insights/ChartData";

type IdOrTitle =
  | {
      id: string;
      title?: undefined;
      subtitle?: undefined;
    }
  | {
      id?: undefined;
      title: ReactNode;
      subtitle?: ReactNode;
    };

type ChartContentProps =
  | {
      children: ReactNode;
      dataKey?: undefined;
    }
  | {
      dataKey: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      children: (data: any) => ReactNode;
    };

type ChartWidgetProps = IdOrTitle &
  ChartContentProps & {
    className?: string;
  };

export const Em = ({ children }: PropsWithChildren) => (
  <span className="bg-bg-l3 rounded-md px-1.5 py-0.5">{children}</span>
);

export const Token = ({ children }: PropsWithChildren) => (
  <span className="bg-bg-l3 font-medium rounded-md px-1.5 py-0.5">
    {children}
  </span>
);

export const ChartSubtitle = ({
  id,
  values,
}: {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: Record<string, any>;
}) => (
  // the space in the `defaults` prop value below forces `react-i18next` to return the
  // default when the key is missing; "" is interpreted as "no default" and is
  // effectively ignored
  <Text
    i18nKey={`charts.${id}.subtitle`}
    defaults=" "
    values={values}
    components={{ em: <Em />, token: <Token /> }}
  />
);

const ChartWidget = ({
  id,
  title,
  subtitle,
  dataKey,
  children,
  className,
}: ChartWidgetProps) => {
  const chartTitle = id ? <Text i18nKey={`charts.${id}.title`} /> : title;
  const chartSubtitle = id ? <ChartSubtitle {...{ id }} /> : subtitle;

  return (
    <div
      id={id}
      className={cx(
        "group flex flex-col min-h-[400px] rounded-md bg-bg-l2 p-4 gap-y-3 wg-highlight-scroll-to",
        className
      )}
    >
      <div className="flex flex-col pl-1.5 mt-0.5 gap-1 select-none">
        <h3 className="text-lg font-semibold text-text-bright leading-tight wg-title-highlight-scroll-to">
          {chartTitle}
        </h3>
        {chartSubtitle && (
          <p className="text-sm text-text-default leading-normal">
            {chartSubtitle}
          </p>
        )}
      </div>
      <LazyRender fadeIn={true}>
        {({ inView }) =>
          dataKey === undefined ? (
            children
          ) : (
            <ChartDataFetcher {...{ dataKey, inView, children }} />
          )
        }
      </LazyRender>
    </div>
  );
};

export default ChartWidget;
