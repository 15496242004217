import React, { ForwardedRef, forwardRef } from "react";
import {
  FieldValues,
  Controller,
  FieldPath,
  PathValue,
  Path,
} from "react-hook-form";

import { Choice } from "@/components/forms";
import { Select as SelectControl } from "@/components/input/EntitySelect";

import InputField, { InputFieldProps } from "./InputField";

interface SelectInputProps<
  Values extends FieldValues,
  Name extends FieldPath<Values>
> extends InputFieldProps<Values, Name> {
  options: Choice<string>[];
}

const Select = forwardRef(
  <Values extends FieldValues, Name extends FieldPath<Values>>(
    { options, ...props }: SelectInputProps<Values, Name>,
    _: ForwardedRef<HTMLInputElement>
  ) => (
    <InputField {...props}>
      {({ control, name, id, required, placeholder }) => (
        <Controller
          name={name}
          defaultValue={"" as PathValue<Values, Path<Values>>}
          control={control}
          rules={{ required }}
          render={({
            field: { onChange, value, ...props },
            fieldState: { error },
          }) => (
            <SelectControl
              options={options}
              value={options.find((option) => option.key === value)}
              searchKeys={["value"]}
              optionKey={(option) => option?.key}
              displayValue={(option) => option?.value ?? ""}
              onChange={(option) => onChange(option?.key)}
              invalid={Boolean(error)}
              placeholder={placeholder}
              id={id}
              {...props}
            />
          )}
        />
      )}
    </InputField>
  )
);

export default Select;
