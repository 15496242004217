import {
  useRef,
  RefObject,
  ForwardedRef,
  RefCallback,
  useCallback,
} from "react";
import { isFunction } from "lodash";

// Roughly based on https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
export const useInnerRef = <T>(
  ref: ForwardedRef<T>
): [RefObject<T>, RefCallback<T>] => {
  const innerRef = useRef<T | null>(null);
  const setInnerRef: RefCallback<T> = useCallback(
    (r) => {
      if (isFunction(ref)) {
        ref(r);
      } else if (ref) {
        ref.current = r;
      }
      innerRef.current = r;
    },
    [ref]
  );

  return [innerRef, setInnerRef];
};

export const useRefCallback = <T>(
  ref: ForwardedRef<T>,
  callback: RefCallback<T>
): [ForwardedRef<T>, RefCallback<T>] => {
  const setRef: RefCallback<T> = useCallback(
    (r) => {
      if (isFunction(ref)) {
        ref(r);
      } else if (ref) {
        ref.current = r;
      }
      callback(r);
    },
    [ref, callback]
  );

  return [ref, setRef];
};
