import React from "react";
import { useNavigate } from "react-router";
import { Dialog } from "@headlessui/react";

import useText, { useTranslationScope } from "@/components/hooks/useText";

import { PrimaryButton } from "@/components/CoreButtons";
import VideoPlayer from "@/components/VideoPlayer";

const WelcomeTitle = () => {
  const t = useText();
  return (
    <Dialog.Title
      as="h1"
      className="text-3xl font-semibold text-left leading-tight text-text-bright"
    >
      <span className="inline-block pr-3 origin-[70%_70%] animate-hand-wave group-hover:animate-none">
        👋
      </span>
      {t("title")}
    </Dialog.Title>
  );
};

const WelcomeVideo = () => (
  <VideoPlayer
    playbackId="kCNIPazyhrwCt4zi00k6SexL02loa3r01p6Dqjnx7UzU800"
    metadata={{
      video_id: "onboarding-welcome",
      video_title: "Welcome to Maca",
    }}
    prologuePause={750}
    className="w-full aspect-video bg-gradient-to-b from-[#3D00E5] to-[#492873]"
  >
    <WelcomeTitle />
  </VideoPlayer>
);

const WelcomePanel = ({ next }: { next?: string }) => {
  const { t, TranslationScope } = useTranslationScope("onboarding", {
    keyPrefix: "welcome",
  });

  const navigate = useNavigate();

  return (
    <TranslationScope>
      <div className="w-full max-w-xl bg-bg-l3">
        <WelcomeVideo />
        <div className="flex flex-col px-8 pt-6 pb-8 gap-6">
          <div className="flex flex-col text-text-bright text-left gap-5">
            <p className="text-lg font-medium leading-tight">{t("subtitle")}</p>
            <p className="whitespace-pre-line">{t("text")}</p>
          </div>

          <PrimaryButton onClick={() => next && navigate(next)}>
            {t("cta")}
          </PrimaryButton>
        </div>
      </div>
    </TranslationScope>
  );
};

export default WelcomePanel;
