import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useSearchParams,
} from "react-router-dom";

import RootProviders from "@/components/RootProviders";
import { AuthProvider, RequireAuth, useAuth } from "@/components/Auth";
import {
  NavPageLayout,
  ExternalPageLayout,
  PageLayout,
} from "@/components/PageLayout";

import NotFound from "@/pages/NotFound";
import OnboardingCheck from "@/pages/onboarding/v2/OnboardingCheck";

import userRoutes from "@/pages/user/routes";
import orgRoutes from "@/pages/organization/routes";
import productRoutes from "@/pages/products/routes";
import priceRulesRoutes from "@/pages/priceRules/routes";
import settingsRoutes from "@/pages/settings/routes";
import insightsRoutes from "@/pages/insights/routes";
import dataQualityRoutes from "@/pages/data-quality/routes";
import invitationRoutes from "@/pages/invitation/routes";

import "@/App.css";
import Bypass from "./pages/bypasses/Bypasses";
import reportsRoutes from "@/pages/analysis/routes";
import experimentsRoutes from "@/pages/experiments/routes";

const RootRedirect = ({ to }: { to: string }) => {
  // redirect while moving search params to state
  const [search] = useSearchParams();
  return (
    <Navigate to={to} state={{ rootQuery: Array.from(search.entries()) }} />
  );
};

const RootPage = () => {
  const {
    session: { organization },
  } = useAuth();

  if (organization?.product_offering === "cpq" || !organization) {
    return <RootRedirect to="products" />;
  } else {
    return <RootRedirect to="insights" />;
  }
};

const router = createBrowserRouter([
  {
    element: (
      <RequireAuth>
        <NavPageLayout />
      </RequireAuth>
    ),
    children: [...orgRoutes, ...settingsRoutes({ path: "settings" })],
  },
  {
    element: (
      <RequireAuth>
        <OnboardingCheck />
        <NavPageLayout />
      </RequireAuth>
    ),
    children: [
      { path: "", element: <RootPage /> },
      { path: "/bypasses", element: <Bypass /> },
      ...productRoutes,
      ...priceRulesRoutes,
      ...insightsRoutes,
      ...dataQualityRoutes,
      ...reportsRoutes,
      ...experimentsRoutes,
    ],
  },
  {
    element: <ExternalPageLayout />,
    children: [...userRoutes, ...invitationRoutes],
  },
  {
    element: <PageLayout />,
    children: [{ path: "*", element: <NotFound /> }],
  },
]);

function App() {
  return (
    <RootProviders>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </RootProviders>
  );
}

export default App;
