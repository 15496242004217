import React from "react";
import { Outlet } from "react-router-dom";

import { PageTabs } from "@/components/Navigation";
import { TranslationScope } from "@/components/hooks/useText";

import Header from "./components/Header";

interface PageLayoutProps {
  routes: string[];
  description?: string;
}

const PageLayout = ({ routes, description }: PageLayoutProps) => {
  return (
    <div className="flex flex-col w-full mb-8 mx-auto gap-8">
      <div className="flex flex-col bg-bg-l1 border-b border-border-default pt-8 px-8 gap-8">
        <Header description={description} />
        <PageTabs pageName="data-quality" links={routes} />
      </div>
      <main className="flex flex-col px-8 gap-4">
        <TranslationScope ns="data-quality">
          <Outlet />
        </TranslationScope>
      </main>
    </div>
  );
};

export default PageLayout;
