import React, { useState, useCallback } from "react";
import cx from "clsx";

import { useAPI } from "@/client/api";

import { TranslationScope, Text } from "@/components/hooks/useText";

import { CoreButton } from "@/components/CoreButtons";

import { useChartDataContext } from "@/components/insights/ChartData";

import { AccuracyMeter } from "@/components/dataflow/accuracy";
import { FieldName } from "@/components/dataflow/FieldSlot";

import { ChartDataflowModal } from "./ChartDataflow";
import { TableViewLink } from "./TableView";
import { FieldStatsNote } from "./common";

export type ChartAccuracyData = {
  accuracy: number;
  excluded_count: number;
  inputs: ChartInput[];
};

export type ChartInput = {
  id: string;
  name: string;
  accuracy: number;
  missing_values_count: number;
  unexpected_values_count: number;
};

export const useChartAccuracyData = ({ chartId }: { chartId: string }) => {
  const { baseUrl } = useChartDataContext();
  const { data } = useAPI<ChartAccuracyData>(
    baseUrl ? `${baseUrl}/${chartId}/accuracy/` : null,
    {
      keepPreviousData: true,
    }
  );

  return data;
};

const SectionTitle = ({ i18nKey }: { i18nKey: string }) => (
  <div className="text-sm font-semibold text-text-bright leading-tight">
    <Text {...{ i18nKey }} />
  </div>
);

const SummaryNote = ({
  excluded_count,
}: Pick<ChartAccuracyData, "excluded_count">) => (
  <div className="group text-text-default text-xs leading-tight cursor-pointer">
    <Text
      i18nKey={excluded_count ? "note.exlcuded" : "note.imputed"}
      values={{ count: excluded_count }}
      components={[
        <span
          className={cx(
            "underline decoration-text-muted group-hover:text-text-link",
            "group-hover:decoration-text-link"
          )}
        />,
      ]}
    />
  </div>
);

const Accuracy = ({
  accuracy,
  excluded_count,
}: Pick<ChartAccuracyData, "accuracy" | "excluded_count">) => (
  <div className="flex flex-col gap-2">
    <div className="flex gap-2">
      <SectionTitle i18nKey="section.accuracy" />
      <AccuracyMeter value={accuracy} />
    </div>
    {accuracy < 1 ? <SummaryNote {...{ excluded_count }} /> : null}
  </div>
);

const InputNote = ({
  stats,
  onClick,
}: {
  stats: Record<string, number>;
  onClick: () => void;
}) => (
  <div
    {...{ onClick }}
    className={
      "text-xs text-text-default leading-tight hover:text-text-link hover:underline cursor-pointer"
    }
  >
    <FieldStatsNote {...{ stats }} />
  </div>
);

const Inputs = ({ inputs }: Pick<ChartAccuracyData, "inputs">) => (
  <div className="flex flex-col gap-2">
    <SectionTitle i18nKey="section.inputs" />
    <div className="flex flex-col gap-2 justify-center">
      {inputs.map(({ id, name, accuracy, ...input }) => (
        <div key={id} className="flex flex-col gap-1">
          <div className="flex gap-2 items-center">
            <FieldName name={name} />
            <AccuracyMeter value={accuracy} size="sm" />
          </div>
          {accuracy < 1 ? (
            <TableViewLink fieldId={id}>
              {({ onClick }) => <InputNote {...{ onClick, stats: input }} />}
            </TableViewLink>
          ) : null}
        </div>
      ))}
    </div>
  </div>
);

const Button = ({
  i18nKey,
  onClick,
}: {
  i18nKey: string;
  onClick: () => void;
}) => (
  <CoreButton
    type="button"
    className={cx(
      "text-sm text-text-bright font-medium bg-bg-l4 hover:bg-brand-purple",
      "focus:text-text-link"
    )}
    {...{ onClick }}
  >
    <Text {...{ i18nKey }} />
  </CoreButton>
);

const ViewDataflowButton = ({ chartId }: { chartId: string }) => {
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <Button i18nKey="action.view_dataflow" onClick={() => setOpen(true)} />
      <ChartDataflowModal {...{ chartId, open, onClose }} />
    </>
  );
};

export const ChartAccuracyPane = ({
  chartId,
  data,
}: {
  chartId: string;
  data: ChartAccuracyData;
}) => (
  <TranslationScope ns="data-quality" keyPrefix="chart_accuracy">
    <div className="max-w-[328px] flex flex-col gap-4">
      <Accuracy {...data} />
      <Inputs {...data} />
      <div className="flex flex-col gap-2">
        <ViewDataflowButton {...{ chartId }} />
        <Button i18nKey="action.improve_accuracy" onClick={() => {}} />
      </div>
    </div>
  </TranslationScope>
);

const ChartAccuracy = ({ chartId }: { chartId: string }) => {
  const data = useChartAccuracyData({ chartId });
  return data && <ChartAccuracyPane {...{ chartId, data }} />;
};

export default ChartAccuracy;
