import React, { ForwardedRef, forwardRef } from "react";

import { FieldValues, FieldPathByValue, useController } from "react-hook-form";

import { includes } from "lodash";

import { OptionItem } from "./Choice";
import InputField, { InputFieldProps } from "./InputField";

const toggleOption = (options: string[], op: string) => {
  const filtered = options.filter((o) => o !== op);
  return filtered.length === options.length ? [...options, op] : filtered;
};

const ToggleGroup = <
  Values extends FieldValues,
  Name extends FieldPathByValue<Values, string[]>
>({
  options,
  id,
  name,
  control,
}: InputFieldProps<Values, Name> & {
  options: string[];
  id: string;
}) => {
  const {
    field: { onChange, value: selected, ...props },
  } = useController({
    control,
    name,
  });

  return (
    <fieldset className="flex flex-col gap-2" id={id} {...props}>
      {options.map((op) => (
        <OptionItem
          key={op}
          option={op}
          role="checkbox"
          {...{ name, checked: includes(selected, op) }}
          onClick={() => onChange(toggleOption(selected ?? [], op))}
        />
      ))}
    </fieldset>
  );
};

interface MultiChoiceProps<
  Values extends FieldValues,
  Name extends FieldPathByValue<Values, string[]>
> extends InputFieldProps<Values, Name> {
  options: string[];
}

export const MultiChoice = forwardRef(
  <Values extends FieldValues, Name extends FieldPathByValue<Values, string[]>>(
    { options, ...props }: MultiChoiceProps<Values, Name>,
    _: ForwardedRef<HTMLInputElement>
  ) => (
    <InputField {...props}>
      {(props) => <ToggleGroup options={options} {...props} />}
    </InputField>
  )
);

export default MultiChoice;
