import React from "react";
import cx from "clsx";
import { isEmpty } from "lodash";

// @ts-ignore
import { ReactComponent as ChevronRight } from "@/assets/icons/ctrl-right.svg";
// @ts-ignore
import { ReactComponent as CircleCheck } from "@/assets/icons/circle-check.svg";
// @ts-ignore
import { ReactComponent as SlackLogo } from "@/assets/logos/slack.svg";

import useText, { Text } from "@/components/hooks/useText";
import ErrorMessage from "@/components/input/ErrorMessage";

import {
  useSlackInstallAPI,
  SlackConfig,
  SlackInstallLink,
} from "@/pages/settings/panels/integrations/Slack";

import { useStepContext } from "../StepsLayout";
import { Infer } from "myzod";
import useEasyForm from "@/components/easy-form";
import { oauthInstallationSchema } from "../schema";

const WorkspaceSummary = ({ workspace, url }: SlackConfig) => {
  const t = useText();
  return (
    <div className="flex flex-col gap-2">
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={cx(
          "group flex items-center justify-between text-text-bright text-xl font-medium bg-bg-l4/60 pl-5 pr-3 py-3 border border-transparent rounded-md",
          "hover:text-text-link hover:border-text-link hover:bg-bg-l4/80 active:bg-bg-l4/100 state-transition-colors select-none",
          "focus-ring-rounded-md"
        )}
        draggable={false}
      >
        <span>{workspace}</span>
        <ChevronRight
          className={cx(
            "w-6 shrink-0 grow-0 group-hover:translate-x-[2px] group-hover:scale-[1.1]",
            "transition-transform state-transition"
          )}
        />
      </a>
      <span className="flex text-text-success gap-2 px-1 py-1">
        <CircleCheck className="w-5" />
        {t("status.connected")}
      </span>
    </div>
  );
};

type SlackInstallationStatus = Infer<typeof oauthInstallationSchema>;

const SlackSetup = () => {
  const { Panel } = useStepContext();

  const config = useSlackInstallAPI();
  const connected = Boolean(config.workspace);
  const { handleSubmit, errors } = useEasyForm<SlackInstallationStatus>({
    values: { connected },
    schema: oauthInstallationSchema,
  });

  return (
    <Panel
      handleSubmit={(event, { next }) =>
        handleSubmit(async () => {
          next();
        })(event)
      }
    >
      <div className="flex flex-col gap-4">
        {connected ? (
          <WorkspaceSummary {...config} />
        ) : (
          <>
            <div className="flex rounded-md gap-4">
              <SlackLogo className="w-14 p-1 self-start" />
              <div className="flex flex-row gap-2 items-center grow">
                <div className="flex-initial">
                  <SlackInstallLink
                    url={config.url}
                    className="button-base button-primary px-4 py-2"
                  />
                </div>
              </div>
            </div>
            {!isEmpty(errors) && (
              <ErrorMessage>
                <Text i18nKey="error.not_installed" />
              </ErrorMessage>
            )}
          </>
        )}
      </div>
    </Panel>
  );
};

export default SlackSetup;
