import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as BlockIcon } from "@/assets/icons/block.svg";
// @ts-ignore
import { ReactComponent as BlocksIcon } from "@/assets/icons/blocks.svg";
// @ts-ignore
import { ReactComponent as BlocksOverrideIcon } from "@/assets/icons/blocks-override.svg";

import { FadeInFadeOutTransition } from "@/components/Transitions";

import {
  EditableCard,
  EditingButtons,
  CardMenu,
} from "@/components/EditableCard";

import TextInput from "@/components/input/TextInput";
import Toggle from "@/components/input/Toggle";

import { SaveOptions } from "@/client/models-api";

import { ProductComponentJSON } from "@/pages/products/serialization";

export const ComponentIcon = ({
  isCountable,
  override,
  size = "w-12",
  className,
}: {
  isCountable: boolean;
  override?: boolean;
  size?: string;
  className?: string;
}) => {
  const Icon = isCountable
    ? override
      ? BlocksOverrideIcon
      : BlocksIcon
    : BlockIcon;

  return <Icon className={cx("text-text-default shrink-0", size, className)} />;
};

export const UnitsLabel = ({
  units,
  className = "text-xs px-1.5 py-0.5 max-w-[10rem]",
}: {
  units: string;
  className?: string;
}) => (
  <div
    className={cx(
      "group border border-text-muted text-text-default rounded-lg",
      className
    )}
  >
    <div className="font-semibold uppercase min-w-0 break-words">{units}</div>
  </div>
);

//@ts-ignore
const ComponentMenu = ({ onEdit, onDelete }) => (
  <CardMenu
    className="absolute right-2 top-2"
    items={[
      {
        item: "Edit component",
        action: () => onEdit(),
      },
      {
        item: "Delete component",
        action: onDelete && (() => onDelete()),
        danger: true,
      },
    ]}
  />
);

//@ts-ignore
const CountableOptions = ({ register, registerController, control }) => {
  const isCountable = useWatch({ control, name: "is_countable" });
  return (
    <div className="flex gap-4 items-center">
      <Controller
        {...registerController("is_countable")}
        render={({ field }) => (
          <Toggle {...field} className="h-7" label={"Countable"} />
        )}
      />
      <div
        className={isCountable ? "text-text-default" : "text-border-default"}
      >
        in
      </div>
      <TextInput
        {...register("quantity_unit_of_measure")}
        maxLength={32}
        placeholder="Unit of measure"
        className="leading-tight p-2 grow"
        disabled={!isCountable}
      />
    </div>
  );
};

//@ts-ignore
const EditingComponentIcon = ({ control }) => {
  const isCountable = useWatch({ control, name: "is_countable" });
  return (
    <div className="grid self-start">
      <FadeInFadeOutTransition
        show={isCountable}
        className="col-start-1 row-start-1"
      >
        <ComponentIcon isCountable={true} />
      </FadeInFadeOutTransition>
      <FadeInFadeOutTransition
        show={!isCountable}
        className="col-start-1 row-start-1"
      >
        <ComponentIcon isCountable={false} />
      </FadeInFadeOutTransition>
    </div>
  );
};

const DecoratedComponentName = ({
  component,
}: {
  component: ProductComponentJSON;
}) => {
  const units = component.is_countable && component.quantity_unit_of_measure;
  return (
    <div className="flex items-center gap-2.5 pr-10">
      <div className="text-text-bright text-lg font-medium leading-tight min-w-0 break-words">
        {component.name}
      </div>
      {units && <UnitsLabel units={units} />}
    </div>
  );
};

export type SaveComponentFunction = (
  component: ProductComponentJSON,
  options?: SaveOptions
) => Promise<void>;

const ProductComponent = ({
  elementId,
  component,
  saveComponent,
  deleteComponent,
  discardDraft,
}: {
  elementId?: string;
  component: ProductComponentJSON;
  saveComponent: SaveComponentFunction;
  deleteComponent?: () => Promise<void>;
  discardDraft?: (productId: string) => void;
}) => {
  const { t } = useTranslation("common", { keyPrefix: "components" });
  const isDraft = Boolean(discardDraft);

  return (
    <EditableCard
      {...{ elementId, discardDraft }}
      className={(editing) =>
        cx(
          "relative bg-bg-l2 py-4 px-4 rounded-md max-w-xl",
          editing && "shadow-sm"
        )
      }
      entity={component}
      onSave={async (entity) =>
        await saveComponent(entity, { forceSync: true })
      }
    >
      {({
        editing,
        setEditing,
        control,
        register,
        registerController,
        cancelEditing,
        isSubmitting,
      }) => (
        <>
          {!editing && (
            <ComponentMenu
              onEdit={() => setEditing(true)}
              onDelete={deleteComponent}
            />
          )}
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-5">
              {editing ? (
                <EditingComponentIcon control={control} />
              ) : (
                <ComponentIcon
                  isCountable={component.is_countable}
                  className="self-start"
                />
              )}
              <div
                className={cx(
                  "flex flex-col min-w-0 select-none",
                  editing ? "gap-2" : "gap-1"
                )}
              >
                {editing ? (
                  <TextInput
                    {...register("name")}
                    maxLength={128}
                    placeholder="Component name"
                    className="text-lg font-medium leading-tight p-2 -ml-2 grow overflow-y-hidden"
                    autoSelect
                  />
                ) : (
                  <DecoratedComponentName component={component} />
                )}
                {editing ? (
                  <TextInput
                    {...register("description")}
                    maxLength={512}
                    minRows={component.description ? undefined : 2}
                    placeholder="Component description"
                    className="leading-tight p-2 -ml-2 grow"
                  />
                ) : (
                  <div className="text-text-default leading-tight min-w-0 break-words pr-10">
                    {component.description}
                  </div>
                )}
                {editing && (
                  <CountableOptions
                    register={register}
                    registerController={registerController}
                    control={control}
                  />
                )}
              </div>
            </div>
            {editing && (
              <div className="justify-end w-full">
                <EditingButtons
                  control={control}
                  cancelEditing={cancelEditing}
                  isSubmitting={isSubmitting}
                  isDraft={isDraft}
                  required={["name"]}
                  t={t}
                />
              </div>
            )}
          </div>
        </>
      )}
    </EditableCard>
  );
};

export default ProductComponent;
