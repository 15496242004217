import stringHash from "@sindresorhus/string-hash";

import tw from "@/styles/tailwind";
import { values, flatMap, shuffle, take } from "lodash";

const pickColorPalette = (
  colors: Record<string, Record<string, string>>,
  shuffleKeys: (keys: string[]) => string[] = shuffle
): string[] => {
  const keys = shuffleKeys(Object.keys(colors));
  return flatMap(keys, (key) => values(colors[key]));
};

export const primaryInsightsColors = (): string[] =>
  pickColorPalette(tw.theme.colors.insights);

export const extendedInsightsColors = (): string[] =>
  pickColorPalette(tw.theme.colors.insightsExt).reverse();

export const allColors = (
  shuffleColors: (colors: string[]) => string[] = shuffle
) => shuffleColors(flatMap(tw.theme.colors.insights, (color) => values(color)));

export const takeColors = (n: number) => {
  return take(allColors(), n);
};

export const insightsColors = tw.theme.colors.insightsExt;

export const chartColors = (chartId: string, n?: number) => {
  const hash = stringHash(chartId);
  const shuffle = (items: string[]) => {
    const n = hash % items.length;
    return [...items.slice(n), ...items.slice(0, n)];
  };

  return n
    ? take(allColors(shuffle), n)
    : pickColorPalette(tw.theme.colors.insights, shuffle);
};
