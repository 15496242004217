import React, { useMemo, useRef } from "react";
import { Pie, PieSvgProps } from "@nivo/pie";
import { useTheme } from "@nivo/core";

import tw from "@/styles/tailwind";

import { chartColors } from "./colorPicker";

import useSize from "@/components/hooks/useSize";

import type { MetricDatum, ChartPresentationProps, ChartProps } from "./types";
import { metricFormatter } from "./format";
import { toPieChartData } from "./PieChart2Data";
import Legend from "./Legend";

export type PieChart2Props = ChartProps<ChartPresentationProps, MetricDatum[]>;

const presentationProps: Partial<PieSvgProps<MetricDatum>> = {
  innerRadius: 0.5,
  padAngle: 0.7,
  cornerRadius: 3,
  activeOuterRadiusOffset: 8,
  borderWidth: 1,
  borderColor: {
    from: "color",
    modifiers: [["darker", 0.2]],
  },
  arcLinkLabelsSkipAngle: 10,
  arcLinkLabelsTextColor: tw.theme?.colors.text.bright,
  arcLinkLabelsThickness: 2,
  arcLinkLabelsColor: { from: "color" },
  arcLabelsSkipAngle: 20,
  arcLabelsTextColor: {
    from: "color",
    modifiers: [["darker", 2]],
  },
  margin: { top: 40, right: 80, bottom: 40, left: 80 },
};

const PieChart2 = ({ id, presentation, data }: PieChart2Props) => {
  const theme = useTheme();
  const target = useRef(null);
  const size = useSize(target);

  const { indexBy, metricType }: ChartPresentationProps = {
    metricType: "currency",
    ...presentation,
  };

  const valueFormat = metricFormatter(metricType, true);
  const colors = useMemo(() => chartColors(id, data.length), [id, data.length]);
  const pieChartData = useMemo(
    () => toPieChartData(data, indexBy),
    [data, indexBy]
  );

  const keys = useMemo(
    () => pieChartData.map(({ id }) => `${id}`),
    [pieChartData]
  );

  return (
    <div ref={target} className="@container/chart relative h-full w-full">
      {size && (
        <Pie
          {...{
            theme,
            data: pieChartData,
            valueFormat,
            colors,
            ...presentationProps,
            ...size,
          }}
        />
      )}
      <div className="absolute left-0 bottom-0">
        <Legend {...{ keys, colors, theme }} />
      </div>
    </div>
  );
};

export default PieChart2;
