import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { isNil, forEach } from "lodash";

import format from "@/lib/format";

export const initI18n = ({
  loadResources,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadResources?: (ns: string[], supportedLngs: string[]) => any;
} = {}) => {
  const ns = [
    "common",
    "invitation",
    "onboarding",
    "price-rules",
    "user",
    "settings",
    "insights",
    "data-quality",
  ];

  const supportedLngs = ["en-US"];
  const resources = loadResources?.(ns, supportedLngs);
  const debug = process.env["NODE_ENV"] !== "production";
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      ns,
      defaultNS: ns[0],
      fallbackLng: supportedLngs[0],
      load: "currentOnly",
      interpolation: {
        escapeValue: false,
      },
      react: {
        transSupportBasicHtmlNodes: true,
      },
      resources,
      supportedLngs,
      returnNull: false,
      parseMissingKeyHandler: (key: string, defaultValue?: string) => {
        // custom missing key diagnostics, see the comment below
        if (debug && isNil(defaultValue)) {
          console.warn(`Missing translation for key: ${key}`);
        }

        return isNil(defaultValue) ? key : defaultValue;
      },
      // disable built-in debug logging as overly verbose; specifically, when
      // `debug` is true, missing keys with explicitly provided default values
      // will always trigger a log entry, even when `parseMissingKeyHandler`
      // and/or `missingKeyHandler` are present
      debug: false,
    });

  forEach(format, (formatter, key) =>
    i18n.services.formatter?.add(key, formatter)
  );
};

export default i18n;
