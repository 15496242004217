import { toQueryString } from "./queryParams";

const mailToUrl = ({
  to,
  ...props
}: {
  to?: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
}) => {
  const baseUrl = `mailto:${to ?? ""}`;
  return `${baseUrl}${toQueryString(props)}`;
};

export default mailToUrl;
