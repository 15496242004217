import { visit } from "unist-util-visit";
import { visitParents } from "unist-util-visit-parents";
import { type Root } from "mdast";

import { parseUrl } from "@/lib/url";
import { interpolate } from "@/lib/string";

import { blake2s } from "@/lib/string";

const parseEmbedDataAttr = (dataAttr: string, defaultDataDir?: string) => {
  const [category, id] = dataAttr.split("/");
  return id === undefined
    ? { id: category, category: defaultDataDir ?? "" }
    : { id, category };
};

export const embedDirective =
  (embeds: string[], defaultDataDir?: string) => () => (tree: Root) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visit(tree, (node: any) => {
      if (node.type === "leafDirective" && embeds.includes(node.name)) {
        const { data: dataAttr, ...attrs } = node.attributes ?? {};
        const nodeData = node.data ?? (node.data = {});
        if (!dataAttr) {
          console.error(
            `Missing 'data' attribute in ${node.name} directive in line ${node.position?.start?.line}`
          );
          return;
        }

        nodeData.hName = `${node.name}-embed`;
        nodeData.hProperties = {
          ...parseEmbedDataAttr(dataAttr, defaultDataDir),
          ...attrs,
        };
      }
    });
  };

export type ObjectLinks = Record<string, string>;

export const expandObjectLinks = (urls?: ObjectLinks) => () => (tree: Root) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visit(tree, (node: any) => {
    if (node.type === "link") {
      const { protocol, pathname: id } = parseUrl(node.url) ?? {};
      const linkTemplate = urls?.[protocol ?? ""];
      if (linkTemplate) {
        node.url = interpolate(linkTemplate, { id });
      }
    }
  });
};

type ListItem = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  node: any;
  content: string;
  sublist: string[];
};

const formatContent = ({ content, sublist }: ListItem) =>
  [content]
    .concat(
      sublist.length > 1 ? sublist.map((s, i) => `(${i + 1}) ${s}`) : sublist
    )
    .join(" ")
    .trim();

const contentId = (str: string) => blake2s(str).slice(0, 8);

// calculate content IDs for each list item in the document using the same
// algorithm used by chart_search when indexing the items -- see
// https://github.com/maca-io/maca/blob/main/chart_search/chart_search/lib/parse_report.py
export const addContentIds = () => {
  const stack: ListItem[] = [];

  return (tree: Root) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visitParents(tree, (node: any, ancestors: any[]) => {
      const parent = stack[stack.length - 1];

      // pop the stack when we leave a list item
      if (parent && !ancestors.includes(parent?.node)) {
        // if this is a top-level list item, calculate and assign it a content ID
        if (stack.length === 1 && !parent.node.properties.id) {
          parent.node.properties = {
            ...parent.node.properties,
            id: contentId(formatContent(parent)),
          };
        } else if (stack.length > 1) {
          // ...otherwise, lift up the list item's content to its parent
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const grandParent = stack[stack.length - 2]!;
          grandParent.sublist.push(formatContent(parent));
        }

        stack.pop();
      }

      // add text nodes' content to the current parent
      if (node.type === "text" && parent && node.value !== "\n") {
        parent.content += node.value;
      }

      // push a new list item onto the stack
      if (node.tagName === "li") {
        stack.push({ node, content: "", sublist: [] });
      }
    });
  };
};
