import React, { useMemo, type ReactNode } from "react";
import { isNil, uniq } from "lodash";
import cx from "clsx";

import { valueFormatter } from "./format";

import type { MetricDatum, ValueType } from "./types";

type ColumnDef = {
  label?: string;
  type: ValueType;
};

export type TablePresentionProps = {
  columns?: Record<string, ColumnDef>;
  highlightedRows?: number[];
  highlightedColumns?: string[];
  stickyHeader?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableCellFormatter = (value: any, lang?: string) => ReactNode;

export type TableProps = {
  id: string;
  presentation: TablePresentionProps;
  data: MetricDatum[];
  className?: string;
  highlightBgClassName?: string;
  hoverBgClassName?: string;
  formatter?: (col: {
    columnName: string;
    columnType: ColumnDef["type"];
  }) => TableCellFormatter;
};

const defaultFormatter: TableProps["formatter"] = ({ columnType }) =>
  valueFormatter(columnType, false);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNa = (value: any, type?: ColumnDef["type"]) => {
  switch (type) {
    case "string":
      return isNil(value);

    default:
      return isNil(value) || `${value}`.trim() === "";
  }
};

const NA = ({ hightlighted }: { hightlighted?: boolean }) => (
  <span
    className={cx(
      "text-xs rounded-md px-1 py-0.5 whitespace-nowrap",
      hightlighted
        ? "bg-base-orange-300 text-bg-default"
        : "bg-bg-l3 group-hover/row:bg-bg-l4"
    )}
  >
    N/A
  </span>
);

const Table = ({
  id,
  presentation,
  data,
  className,
  highlightBgClassName,
  hoverBgClassName,
  formatter = defaultFormatter,
}: TableProps) => {
  const {
    columns: columnDefs,
    highlightedRows,
    highlightedColumns,
    stickyHeader,
  } = presentation;

  const columns: string[] = useMemo(
    () =>
      uniq([...Object.keys(columnDefs ?? {}), ...Object.keys(data[0] ?? {})]),
    [columnDefs, data]
  );

  const columnTypes = useMemo(
    () => columns.map((col) => columnDefs?.[col]?.type ?? "string"),
    [columns, columnDefs]
  );

  const formatters = useMemo(
    () =>
      columns.reduce(
        (res, columnName, i) => ({
          ...res,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          [columnName]: formatter({ columnName, columnType: columnTypes[i]! }),
        }),
        {} as Record<string, TableCellFormatter>
      ),
    [columns, columnTypes, formatter]
  );

  const renderCell = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    col: string,
    i: number,
    hightlighted?: boolean
  ) => (
    <td
      key={col}
      className={cx(
        "px-3 py-2",
        hightlighted &&
          cx(
            "font-medium text-base-orange-200 hover:text-text-bright",
            highlightBgClassName ?? "bg-bg-l250",
            hoverBgClassName ?? "group-hover/row:bg-bg-l3"
          ),
        columnTypes[i] !== "string" && "tabular-nums text-right"
      )}
    >
      {isNa(value, columnTypes[i]) ? (
        <NA {...{ hightlighted }} />
      ) : (
        formatters[col]?.(value)
      )}
    </td>
  );

  return (
    <table {...{ id }} className={cx("table-auto border-collapse", className)}>
      <colgroup>
        {columns.map((column) => (
          <col key={column} />
        ))}
      </colgroup>
      <thead className={cx(stickyHeader && "sticky top-0", stickyHeader)}>
        <tr>
          {columns.map((column, i) => (
            <th
              key={column}
              className={cx(
                "relative text-sm leading-tight px-3 py-2",
                highlightedColumns?.includes(column)
                  ? cx(
                      "font-semibold text-base-orange-200 hover:text-text-bright",
                      highlightBgClassName ?? "bg-bg-l250"
                    )
                  : "font-medium text-text-default hover:text-text-bright",
                columnTypes[i] === "string" ? "text-left" : "text-right"
              )}
            >
              {columnDefs?.[column]?.label ?? column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((row, i) => (
          <tr
            key={`row-${i}`}
            className={cx(
              "group/row leading-tight border-t border-b border-border-default",
              hoverBgClassName ?? "hover:bg-bg-l3",
              highlightedRows?.includes(i)
                ? cx(
                    "font-medium text-base-orange-200 hover:text-text-bright",
                    highlightBgClassName ?? "bg-bg-l250"
                  )
                : "text-text-default hover:text-text-bright"
            )}
          >
            {columns?.map((col, i) =>
              renderCell(row[col], col, i, highlightedColumns?.includes(col))
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
