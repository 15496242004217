import React from "react";

import OrganizationNew from "./New";
import OrganizationSelect from "./Select";

const orgRoutes = [
  { path: "organization/new", element: <OrganizationNew /> },
  { path: "organization/select", element: <OrganizationSelect /> },
];

export default orgRoutes;
