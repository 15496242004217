import React, { Children, PropsWithChildren } from "react";
import { range } from "lodash";

export type ListType = "conjunction" | "disjunction";
export type ListStyle = "long" | "short" | "narrow";

const FormattedList = ({
  listType,
  children,
  listStyle,
}: PropsWithChildren<{ listType?: ListType; listStyle?: ListStyle }>) => {
  const items = Children.toArray(children);
  const parts = new Intl.ListFormat(undefined, {
    style: listStyle ?? "long",
    type: listType ?? "conjunction",
  }).formatToParts(range(items.length).map((i) => `${i}`));

  return (
    <>
      {parts.map(({ type, value }, i) =>
        type === "element"
          ? items[parseInt(value)]
          : type === "literal" && <span key={i}>{value}</span>
      )}
    </>
  );
};

export default FormattedList;
