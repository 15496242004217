import React, {
  PropsWithChildren,
  FormEvent,
  forwardRef,
  ForwardedRef,
  ComponentPropsWithoutRef,
} from "react";

interface FormProps extends ComponentPropsWithoutRef<"form"> {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  disabled?: boolean;
  submitOnEnter?: boolean;
  className?: string;
}

const Form = forwardRef(
  (
    {
      onSubmit,
      disabled,
      submitOnEnter,
      className,
      children,
      ...rest
    }: PropsWithChildren<FormProps>,
    ref: ForwardedRef<HTMLFormElement>
  ) => (
    <form {...{ onSubmit, className, ref }} {...rest}>
      {!submitOnEnter && (
        <input
          type="submit"
          onClick={(e) => e.preventDefault()}
          className="invisible absolute pointer-events-none w-0 h-0"
          tabIndex={-1}
          aria-hidden="true"
        />
      )}
      <fieldset disabled={disabled} className="group/fieldset contents">
        {children}
      </fieldset>
    </form>
  )
);

export default Form;
