import React, { PropsWithChildren } from "react";
import { Trans } from "react-i18next";
import z, { Infer } from "myzod";
import { isEmpty } from "lodash";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as ChevronRight } from "@/assets/icons/ctrl-right.svg";
// @ts-ignore
import { ReactComponent as CircleCheck } from "@/assets/icons/circle-check.svg";
// @ts-ignore
import { ReactComponent as MacaLogoMark } from "@/assets/maca-logo-mark.svg";

import Tooltip from "@/components/Tooltip";
import useText from "@/components/hooks/useText";
import ErrorMessage from "@/components/input/ErrorMessage";

import useEasyForm from "@/components/easy-form";

import { useStepContext } from "../StepsLayout";

// @ts-ignore
import salesforceAppLauncherMacaGif from "../assets/salesforce-app-launcher-maca.gif";
// @ts-ignore
import returnToMacaGif from "../assets/return-to-maca.gif";

import { useSalesforcePackageInstallationAPI } from "../../api";

const InstallQuoteToolButton = ({ installed }: { installed: boolean }) => {
  const t = useText({ keyPrefix: "install_quote_tool" });
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={
        installed
          ? undefined
          : "https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000JMeP4UAL"
      }
      target="_blank"
      rel="noreferrer"
      className={cx(
        "group flex items-center gap-5 font-medium bg-bg-l4/60 pl-5 pr-2 py-3 border border-transparent rounded-md",
        "state-transition-colors select-none focus-ring-rounded-md",
        installed
          ? "cursor-default text-text-muted"
          : "text-text-bright hover:text-text-link hover:border-text-link hover:bg-bg-l4/80 active:bg-bg-l4/100"
      )}
      draggable={false}
    >
      <MacaLogoMark
        className={cx(
          "w-8 shrink-0",
          installed ? "text-text-default" : "text-base-white"
        )}
      />
      <span>{t("label")}</span>
      {installed ? (
        <CircleCheck className="w-6 aspect-square shrink-0 text-text-success mr-2" />
      ) : (
        <ChevronRight
          className={cx(
            "w-8 shrink-0 grow-0 group-hover:translate-x-[2px] group-hover:scale-[1.1]",
            "transition-transform state-transition"
          )}
        />
      )}
    </a>
  );
};

const ImageTooltip = ({
  src,
  className,
  children,
}: PropsWithChildren<{
  src: string;
  className?: string;
}>) => (
  <Tooltip
    content={
      <div className="p-2">
        <img src={src} className={cx("rounded-md", className)} alt="" />
      </div>
    }
    delayDuration={100}
    ignoreClick={true}
    side="bottom"
    className="z-50 bg-base-white fill-base-white rounded-lg drop-shadow-lg"
  >
    <span className="underline decoration-text-muted/60 hover:text-text-bright cursor-pointer">
      {children}
    </span>
  </Tooltip>
);

const InstallationInstructions = ({ showError }: { showError: boolean }) => {
  const t = useText();
  return (
    <>
      {showError && <ErrorMessage message={t("error.not_installed")} />}
      <div
        className={cx(
          "text-sm cursor-default border-l",
          showError
            ? "border-text-error text-text-default pl-2"
            : "text-text-muted border-transparent"
        )}
      >
        <Trans
          i18nKey="instructions"
          t={t}
          components={[
            <ImageTooltip
              src={salesforceAppLauncherMacaGif}
              className="w-64"
            />,
            <ImageTooltip src={returnToMacaGif} className="w-72" />,
          ]}
        />
      </div>
    </>
  );
};

const InstalledOn = ({ date }: { date: Date }) => {
  const t = useText();
  return (
    <div className="text-sm text-text-muted">
      {t("installed_on", {
        date,
        formatParams: {
          date: { dateStyle: "medium", timeStyle: "long" },
        },
      })}
    </div>
  );
};

export const packageStatusSchema = z
  .object({
    installed: z.boolean(),
  })
  .withPredicate((status) => status.installed, "not installed");

type PackageStatus = Infer<typeof packageStatusSchema>;

const SalesforceSetup = () => {
  const { Panel } = useStepContext();
  const { data } = useSalesforcePackageInstallationAPI();

  const installed = Boolean(data?.salesforce_organization_id);

  const { errors, handleSubmit } = useEasyForm<PackageStatus>({
    values: { installed },
    schema: packageStatusSchema,
  });

  return (
    <Panel
      handleSubmit={(event, { next }) =>
        handleSubmit(async () => next())(event)
      }
    >
      <div className="flex flex-col gap-2">
        <InstallQuoteToolButton {...{ installed }} />
        {installed ? (
          <InstalledOn date={new Date(data?.updated_at ?? "")} />
        ) : (
          <InstallationInstructions showError={!isEmpty(errors)} />
        )}
      </div>
    </Panel>
  );
};

export default SalesforceSetup;
