import { sumBy, invert, omit } from "lodash";

import { WithId } from "@/client/types";
import { isNotNil, KeysByValueType } from "@/lib/functional";

import {
  IPriceRule,
  PriceRule,
  PriceRuleType,
  PriceRuleSubject,
  PriceRuleBlockType,
  RuleBlock,
} from "./price-rules";

export interface PriceRuleOperatorsJSON {
  gt?: number;
  gte?: number;
  lt?: number;
  lte?: number;
}

export interface PriceRuleBlockJSON extends PriceRuleOperatorsJSON {
  order?: number;
  approvers: string[];
  notifications: string[];
}

export type PriceRuleBlockOperator = KeysByValueType<
  PriceRuleOperatorsJSON,
  number | undefined
>;

export interface PriceRuleJSON extends WithId {
  preset:
    | "amount_or_discount_range"
    | "line_item_amount_range"
    | "unit_measurement_range"
    | "price_percentage_rate_range";
  name: string;
  object_type: PriceRuleSubject["type"];
  object_field: PriceRuleSubject["field"];
  plan_id?: string;
  product_component_id?: string;
  ranges: PriceRuleBlockJSON[];
}

export const serializeRuleBlock = ({
  type,
  values,
  approvers,
  notifications,
}: RuleBlock) => {
  const operators =
    type === "between"
      ? { gte: values[0], lt: values[1] }
      : { [type]: values[0] };

  return { ...operators, approvers, notifications };
};

const priceRuleTypeToPreset: Record<PriceRuleType, PriceRuleJSON["preset"]> = {
  [PriceRuleType.GtLt]: "amount_or_discount_range",
  [PriceRuleType.Range]: "amount_or_discount_range",
  [PriceRuleType.AvgDiscount]: "amount_or_discount_range",
  [PriceRuleType.LineItem]: "line_item_amount_range",
  [PriceRuleType.UnitMeasurement]: "unit_measurement_range",
  [PriceRuleType.PricePercentageRate]: "price_percentage_rate_range",
};

const presetToRuleType: Partial<
  Record<PriceRuleJSON["preset"], PriceRuleType>
> = omit(invert(priceRuleTypeToPreset), "amount_or_discount_range");

export const serializePriceRule = (rule: IPriceRule): PriceRuleJSON => {
  if (!rule.name || !rule.subject) {
    throw Error(
      `Attempt to serialize incomplete rule: ${JSON.stringify(rule)}`
    );
  }

  return {
    preset: priceRuleTypeToPreset[rule.type],
    id: rule.id,
    name: rule.name,
    object_type: rule.subject?.type,
    object_field: rule.subject?.field,
    plan_id: rule.plan?.id,
    product_component_id: rule.planComponent?.id,
    ranges: (rule.rules || []).map(serializeRuleBlock),
  };
};

const rangeOperators: PriceRuleBlockOperator[] = ["gt", "gte", "lt", "lte"];

export const rangeOperatorsCount = (range?: PriceRuleBlockJSON) =>
  sumBy(rangeOperators, (op) => (range?.[op] ? 1 : 0));

export const rangeOperatorsList = (range: PriceRuleOperatorsJSON) =>
  rangeOperators.filter((op) => op in range);

export const toPriceRuleType = (
  { preset, object_field }: Partial<PriceRuleJSON>,
  ranges: PriceRuleBlockJSON[]
) => {
  const ruleType = preset && presetToRuleType[preset];
  if (ruleType) return ruleType;

  return ranges.length === 1 && rangeOperatorsCount(ranges[0]) === 1
    ? object_field === "Average Discount" && Boolean(ranges[0]?.gt)
      ? PriceRuleType.AvgDiscount
      : PriceRuleType.GtLt
    : PriceRuleType.Range;
};

const toRuleBlockType = (range: PriceRuleBlockJSON): PriceRuleBlockType => {
  const operators = rangeOperatorsList(range);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return operators.length === 1 ? operators[0]! : "between";
};

const toPriceRuleBlocks = (ranges: PriceRuleBlockJSON[]) =>
  ranges.map((range) => ({
    type: toRuleBlockType(range),
    values: rangeOperators.map((op) => range?.[op]).filter(isNotNil),
    approvers: range.approvers,
    notifications: range.notifications,
  }));

export const toPriceRule = ({ id, name, ranges, ...props }: PriceRuleJSON) =>
  new PriceRule({
    id: id!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
    name: name,
    type: toPriceRuleType(props, ranges),
    subject: {
      type: props.object_type,
      field: props.object_field,
    } as PriceRule["subject"],
    ...(isNotNil(props.plan_id) ? { plan: { id: props.plan_id } } : {}),
    ...(isNotNil(props.product_component_id)
      ? {
          planComponent: { id: props.product_component_id },
        }
      : {}),
    rules: toPriceRuleBlocks(ranges),
  });
