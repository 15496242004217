import React, { PropsWithChildren } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PriceRule, PriceRuleType } from "./models/price-rules";
import { usePriceRulesAPI } from "./models/api";

import PriceRuleEditor from "./rules/PriceRuleEditor";

const PageContainer = ({ children }: PropsWithChildren) => (
  <div className="flex flex-col w-full max-w-3xl mt-9 mb-8 mx-auto px-8">
    {children}
  </div>
);

export const NewRulePage = ({ ruleType }: { ruleType: PriceRuleType }) => {
  const { saveRule } = usePriceRulesAPI({});
  const navigate = useNavigate();

  return (
    <PageContainer>
      <PriceRuleEditor
        {...{
          rule: PriceRule.newRule(ruleType),
          saveRule,
          onCancel: () => navigate("/price-rules/new"),
          onSubmit: () => navigate("/price-rules"),
        }}
      />
    </PageContainer>
  );
};

export const EditPriceRulePage = () => {
  const ruleId = useParams()["ruleId"];
  const { priceRule, saveRule } = usePriceRulesAPI({ ruleId });
  const navigate = useNavigate();

  if (!priceRule) return null;

  return (
    <PageContainer>
      <PriceRuleEditor
        {...{
          rule: priceRule,
          saveRule,
          onCancel: () => navigate("/price-rules"),
          onSubmit: () => navigate("/price-rules"),
        }}
      />
    </PageContainer>
  );
};
