import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  DependencyList,
} from "react";
import {
  OnBeforeCaptureResponder,
  OnBeforeDragStartResponder,
  OnDragStartResponder,
  OnDragUpdateResponder,
  OnDragEndResponder,
} from "@hello-pangea/dnd";

import { remove } from "lodash";

export interface DndRespondersContextType {
  onBeforeCapture?: OnBeforeCaptureResponder[];
  onBeforeDragStart?: OnBeforeDragStartResponder[];
  onDragStart?: OnDragStartResponder[];
  onDragUpdate?: OnDragUpdateResponder[];
  onDragEnd: OnDragEndResponder[];
}

export const DndRespondersContext = createContext<DndRespondersContextType>({
  onDragEnd: [],
});

export const useDragEndResponder = (
  onDragEnd: OnDragEndResponder,
  deps: DependencyList
) => {
  const ctx = useContext(DndRespondersContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(onDragEnd, deps);

  useEffect(() => {
    ctx.onDragEnd.push(callback);
    return () => {
      remove(ctx.onDragEnd, (cb) => cb === callback);
    };
  }, [ctx.onDragEnd, callback]);
};
