import React, { useEffect, Fragment } from "react";
import { useController, FieldValues, FieldPathByValue } from "react-hook-form";

import { RadioGroup } from "@headlessui/react";
import cx from "clsx";

import { CustomInputProps } from "@/components/input/props";

import useText from "@/components/hooks/useText";

const OptionsSwitch = <
  Entity extends FieldValues,
  ValueType extends string,
  Name extends FieldPathByValue<Entity, ValueType | undefined>
>({
  options,
  name,
  control,
  keyPrefix,
  className,
}: CustomInputProps<Entity, Name> & {
  options: ValueType[];
  keyPrefix?: string;
  className?: string;
}) => {
  const t = useText({ keyPrefix });
  const {
    field: { onChange, value },
  } = useController({ control, name });

  useEffect(() => {
    // normalize current value to the first option if it's not in the list
    if (!options.includes(value)) {
      // `setTimeout` decouples the `onChange` update from `useController` initialization
      setTimeout(() => onChange(options[0]), 0);
    }
  }, [value, onChange, options]);

  return (
    <RadioGroup
      className={cx("flex rounded-md", className ?? "bg-bg-d1")}
      value={value ?? null}
      onChange={(value) => onChange(value)}
    >
      {options.map((op) => (
        <RadioGroup.Option key={op} value={op} as={Fragment}>
          {({ checked }) => (
            <button
              type="button"
              className={cx(
                checked
                  ? "text-text-bright bg-bg-l3 ui-focus-visible:border-border-focus"
                  : "text-text-muted",
                "grow font-semibold leading-none whitespace-nowrap px-4 py-2.5 rounded-md",
                "outline-none border border-transparent hover:text-text-link",
                "group-disabled/fieldset:text-text-muted"
              )}
            >
              {t(op)}
            </button>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default OptionsSwitch;
