import React, { ReactNode } from "react";

import { Menu } from "@headlessui/react";

import cx from "clsx";

// @ts-ignore
import { ReactComponent as MenuIcon } from "@/assets/icons/three-dots-menu.svg";

export interface MenuItem {
  item: ReactNode;
  action?: VoidFunction;
  danger?: boolean;
}

interface MenuDropdownPros {
  items: MenuItem[];
}

export const MenuThreeDotsButton = ({ open }: { open: boolean }) => (
  <Menu.Button
    className={cx(
      "text-text-muted group-hover:text-text-default p-2 rounded-md outline-none border border-transparent",
      "focus:bg-bg-l3 hover:bg-bg-l3 hover:text-text-bright",
      open && "bg-bg-l3"
    )}
  >
    <MenuIcon className="w-4" />
  </Menu.Button>
);

export const MenuDropdown = ({ items }: MenuDropdownPros) => (
  <Menu.Items
    as="ul"
    className={cx(
      "absolute z-50 flex flex-col bg-bg-l3 rounded-md top-[100%] right-0 mt-1 py-1 shadow-xl",
      "outline-none"
    )}
  >
    {items.map(({ item, action, danger }, i) => (
      <Menu.Item
        as="li"
        key={`menu-item-${i}`}
        className="group"
        disabled={!action}
      >
        {({ active }) => (
          <div
            className={cx(
              "px-3 py-1 font-medium whitespace-nowrap border-t-2 border-b-2 border-bg-l3 select-none cursor-pointer",
              "group-aria-disabled:text-text-muted group-aria-disabled:cursor-default",
              danger
                ? active
                  ? "bg-border-error text-base-white"
                  : "text-text-error"
                : active
                ? "bg-bg-l4 text-text-link"
                : "text-text-bright"
            )}
            onClick={(event) => {
              event.preventDefault();
              action?.();
            }}
          >
            {item}
          </div>
        )}
      </Menu.Item>
    ))}
  </Menu.Items>
);

export default Menu;
