import React, { useCallback } from "react";

import { useForm, Control, useWatch } from "react-hook-form";
import isValidHostname from "is-valid-hostname";

import { useAPI } from "@/client/api";
import apiClient from "@/client";

// @ts-ignore
import { ReactComponent as SalesforceLogo } from "@/assets/logos/salesforce.svg";
// @ts-ignore

import { useText, TranslationScope } from "@/components/hooks/useText";

import Form from "@/components/input/Form";
import UndoButton from "@/components/UndoButton";

import DomainInput, { stripDomainSuffix } from "./components/DomainInput";
import ConnectButton, {
  type ConnectButtonProps,
} from "./components/ConnectButton";

import OpenExternalLink from "./components/OpenExternalLink";

import SettingsCard from "./SettingsCard";

const salesforcePrimaryDomainSuffix = ".lightning.force.com";
const salesforceAPIDomainSuffix = ".my.salesforce.com";
const salesforceDomainSuffixes = [
  salesforcePrimaryDomainSuffix,
  salesforceAPIDomainSuffix,
];

const salesforceAPIDomain = (subdomain: string) =>
  `${subdomain}${salesforceAPIDomainSuffix}`;

const salesforcePrimaryDomain = (subdomain: string) =>
  `${subdomain}${salesforcePrimaryDomainSuffix}`;

interface SalesforceInstanceConfigJSON {
  tenant_domain: string;
  updated_at: string;
}

interface SalesforceInstanceConfig {
  tenantDomain: string;
  updatedAt?: Date;
}

export const useSalesForceConfigAPI = () => {
  const { data } = useAPI<SalesforceInstanceConfigJSON>("oauth2/salesforce/");
  if (!data) return { tenantDomain: "" };

  const { tenant_domain, updated_at } = data;
  return {
    tenantDomain: stripDomainSuffix(tenant_domain, salesforceDomainSuffixes),
    updatedAt: new Date(updated_at),
  };
};

export const OpenSalesforceLink = ({
  control,
}: {
  control: Control<SalesforceInstanceConfig>;
}) => {
  const domain = useWatch({ control, name: "tenantDomain" });
  const disabled = !domain || !isValidHostname(salesforcePrimaryDomain(domain));

  return (
    <OpenExternalLink
      href={`https://${salesforcePrimaryDomain(domain)}`}
      disabled={disabled}
    />
  );
};

export const useSalesforceSettingsForm = () => {
  const t = useText();
  const config = useSalesForceConfigAPI();

  const {
    register,
    control,
    resetField,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SalesforceInstanceConfig>({
    values: config,
  });

  const onSubmit = useCallback(async (data: SalesforceInstanceConfig) => {
    try {
      const { oauth2_url } = await apiClient.salesforceSetupStart(
        salesforceAPIDomain(data.tenantDomain),
        window.location.protocol + "//" + window.location.host,
        window.location.pathname
      );

      window.location.href = oauth2_url;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const undoEdits = useCallback(() => resetField("tenantDomain"), [resetField]);

  const domain = useWatch({ control, name: "tenantDomain" });
  const connected =
    Boolean(config.tenantDomain) && domain === config.tenantDomain;
  const unsavedChanges = Boolean(config.tenantDomain) && !connected;
  const disabled =
    !domain || !isValidHostname(salesforcePrimaryDomain(domain)) || connected;

  return {
    unsavedChanges,
    connected,
    getFormProps: () => ({
      onSubmit: handleSubmit(onSubmit),
      disabled: isSubmitting,
    }),
    getInputProps: () => ({
      ...register("tenantDomain"),
      domainSuffix: salesforceDomainSuffixes,
      placeholder: "acme-corp",
    }),
    getConnectButtonProps: (): ConnectButtonProps => ({
      status: connected ? "connected" : "ready",
      disabled,
    }),
    getUndoButtonProps: () => ({ onClick: undoEdits }),
    getOpenSFLinkProps: () => ({ control }),
    footerText:
      config.updatedAt && connected
        ? t("footer.configured", {
            ...config,
            formatParams: {
              updatedAt: { dateStyle: "medium", timeStyle: "long" },
            },
          })
        : t("footer.setting_up"),
  };
};

const SalesforceSettings = () => {
  const {
    unsavedChanges,
    getFormProps,
    getInputProps,
    getConnectButtonProps,
    getUndoButtonProps,
    getOpenSFLinkProps,
    footerText,
  } = useSalesforceSettingsForm();

  return (
    <SettingsCard unsavedChanges={unsavedChanges}>
      <Form {...getFormProps()} className="flex flex-col gap-1">
        <div className="flex items-center justify-between gap-2">
          <DomainInput {...getInputProps()}>
            <OpenSalesforceLink {...getOpenSFLinkProps()} />
          </DomainInput>
          {unsavedChanges && <UndoButton {...getUndoButtonProps()} />}
          <ConnectButton
            {...getConnectButtonProps()}
            className="button-base button-primary px-4 py-1.5"
          />
        </div>
        <div className="text-sm text-text-muted mx-1 cursor-default">
          {footerText}
        </div>
      </Form>
    </SettingsCard>
  );
};

const Salesforce = () => (
  <TranslationScope keyPrefix="salesforce">
    <div className="flex bg-bg-l2 rounded-md p-4 gap-4">
      <SalesforceLogo className="w-14 self-start" />
      <SalesforceSettings />
    </div>
  </TranslationScope>
);

export default Salesforce;
