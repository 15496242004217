import React from "react";

import cx from "clsx";

import { Text } from "@/components/hooks/useText";
import { CardMenu } from "@/components/EditableCard";
import { MenuItem } from "@/components/Menu";

import { AnalysisQuery } from "@/pages/analysis/queries/api";

const statusColor: Record<AnalysisQuery["status"], string> = {
  enqueued: "bg-base-teal-300 text-bg-l2",
  processing: "bg-base-indigo-300 text-bg-l2",
  ready: "bg-bg-l4 text-text-bright",
  cancelled: "bg-base-orange-300 text-bg-l2",
};

export const QueryStatusBadge = ({
  status,
}: {
  status: AnalysisQuery["status"];
}) => (
  <div
    className={cx(
      "text-2xs uppercase rounded-[4px] px-1.5 py-0.25 pb-[0.5px] select-none",
      statusColor[status]
    )}
  >
    <Text i18nKey={`status.${status}`} />
  </div>
);

export const EnqueuedQuerySummary = ({
  status,
  text,
  enqueued_by,
  enqueued_date,
  menu,
}: AnalysisQuery & { menu?: MenuItem[] }) => (
  <>
    <div className="flex">
      <QueryStatusBadge {...{ status }} />
    </div>
    <div className="flex text-2xl font-semibold leading-tight">
      <div className="before:content-['Q:'] before:text-text-default before:mr-2 text-text-bright">
        {text}
      </div>
    </div>
    <div className="relative flex">
      <div className="text-text-default text-sm leading-tight mr-8">
        <Text i18nKey="enqueued_by" values={{ enqueued_by, enqueued_date }} />
      </div>
      {menu && (
        <CardMenu className="absolute -right-3 -bottom-2" items={menu} />
      )}
    </div>
  </>
);
