import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Button, Alert, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { ThemeProvider } from "@mui/material/styles";

import { useAuth } from "@/components/Auth";

import { darkTheme } from "../../themes/theme";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../styles/ToggleButtons";

export default function OrganizationSelect() {
  const { refreshSession } = useAuth();

  const initChoices: { [key: string]: string } = {};
  const [choices, setChoices] = useState(initChoices);
  const [selected, setSelected] = useState("");
  const [alertBox, setAlertBox] = useState(<></>);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env["REACT_APP_API_HOST"] + "/organization/session/", {
      credentials: "include",
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          if (Object.keys(data.options).length === 0) {
            setAlertBox(
              <Alert data-testid="alert-box" severity="info">
                No organizations found. Create one to get started.
              </Alert>
            );
            return;
          }
          setChoices(data.options);
          if (data.current !== "") {
            setSelected(data.current);
          }
        });
      } else {
        response.text().then((data) => console.log(data));
      }
    });
  }, []);

  const renderedChoices = Object.keys(choices).map((id) => {
    return (
      <StyledToggleButton key={id} value={id} data-testid={"choice-" + id}>
        {choices[id]}
      </StyledToggleButton>
    );
  });

  async function selectChoice(_event: React.MouseEvent, newChoice: string) {
    setSelected(newChoice);
  }

  function showError(message: string) {
    setAlertBox(
      <Alert data-testid="alert-box" severity="error">
        {message}
      </Alert>
    );
  }

  async function submitChoice(event: React.SyntheticEvent) {
    event.preventDefault();
    if (!selected) {
      showError("At least one option must be selected.");
      return;
    }

    fetch(process.env["REACT_APP_API_HOST"] + "/organization/session/", {
      method: "PUT",
      body: JSON.stringify({
        organization_id: selected,
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    })
      .then(async (response) => {
        if (response.ok) {
          await refreshSession();
          navigate("/", { replace: true });
        } else {
          response.text().then((data) => {
            console.log(data);
            showError("Unable to set active organization. Please try again.");
          });
        }
      })
      .catch((err) => showError(err));
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        m="auto"
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Typography variant="h4">Organizations</Typography>
        <Typography paragraph={true} style={{ paddingBottom: "20px" }}>
          Choose an active organization to work on:
        </Typography>
        <form onSubmit={submitChoice}>
          <StyledToggleButtonGroup
            data-testid="styled-toggle-button-group"
            orientation="vertical"
            value={selected}
            onChange={selectChoice}
            exclusive
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1em",
            }}
          >
            {renderedChoices}
          </StyledToggleButtonGroup>
          <br />
          <Box
            alignItems="center"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.5em",
            }}
          >
            <Button
              data-testid="submit-button"
              color="primary"
              variant="contained"
              type="submit"
              disabled={selected === ""}
              fullWidth
            >
              Set Active
            </Button>
            <Button
              data-testid="new-button"
              component={RouterLink}
              to="/organization/new"
              fullWidth
              variant="outlined"
            >
              Create New
            </Button>
          </Box>
          <br />
          {alertBox}
        </form>
      </Box>
    </ThemeProvider>
  );
}
