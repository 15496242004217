import React from "react";

import cx from "clsx";

// @ts-ignore
import { ReactComponent as OpenExternal } from "@/assets/icons/open-external.svg";

const OpenExternalLink = ({
  href,
  disabled,
}: {
  href: string;
  disabled?: boolean;
}) => (
  <a
    href={href}
    className={cx(
      "px-2.5 py-2.5 rounded-r-md state-transition-colors outline-none",
      disabled
        ? "text-border-bright pointer-events-none"
        : "text-text-muted hover:text-text-link hover:bg-bg-l3"
    )}
    aria-disabled={disabled}
    target="_blank"
    rel="noreferrer"
    tabIndex={-1}
  >
    <OpenExternal className="w-5" />
  </a>
);

export default OpenExternalLink;
