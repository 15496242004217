import React from "react";
import { useStepContext } from "./StepsLayout";
import HubSpotSetup from "./integrations/HubSpotSetup";
import SalesforceSetupOauth from "./integrations/SalesforceSetupOAuth";
import Select from "@/components/easy-form/Select";

import z, { Infer } from "myzod";
import useEasyForm, { useSubforms } from "@/components/easy-form";
import { useWatch } from "react-hook-form";
import CrmExport from "./integrations/CrmExport";
import { useAPI } from "@/client/api";
import { type IntegrationComponent } from "./types";

const schema = z.object({
  crm_setup: z.string(),
});

type CrmSetupJSON = Infer<typeof schema>;

const useCrmSetupAPI = () => useAPI<CrmSetupJSON>("onboarding/crm-setup/");

type CrmIntegrationId = "hubspot" | "salesforce" | "other";

const integration: Record<CrmIntegrationId, IntegrationComponent> = {
  hubspot: HubSpotSetup,
  salesforce: SalesforceSetupOauth,
  other: CrmExport,
};

const CrmIntegrations = () => {
  const { Panel } = useStepContext();

  const { data: values } = useCrmSetupAPI();

  const { control, register, handleSubmit } = useEasyForm<CrmSetupJSON>({
    values,
    schema,
  });

  const crm_setup = useWatch({
    control,
    name: "crm_setup",
  });

  const { handleSubmitAll, registerSubmitHandler, registerNoopSubmitHandler } =
    useSubforms(handleSubmit);
  const IntegrationSetup = integration[crm_setup as CrmIntegrationId];

  return (
    <Panel
      handleSubmit={(event, { next }) =>
        handleSubmitAll(async () => {
          next();
        })(event)
      }
    >
      <div className="flex flex-col flex-grow gap-9">
        <Select
          {...register("crm_setup")}
          options={[
            { key: "hubspot", value: "HubSpot" },
            { key: "salesforce", value: "Salesforce" },
            { key: "other", value: "Other" },
          ]}
        />
        {IntegrationSetup && (
          <IntegrationSetup
            {...{ registerSubmitHandler, registerNoopSubmitHandler }}
          />
        )}
      </div>
    </Panel>
  );
};

export default CrmIntegrations;
