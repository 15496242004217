import format from "@/lib/format";

import type { MetricValueType, ValueType } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Formatter = (value: any, lang?: string) => string;

const formatPercentage = (value: number, lang?: string) =>
  format.percentage(value, lang, { fractional: true });

const formatPercentageCompact = (value: number, lang?: string) =>
  format.percentage(value, lang, { fractional: true, maxFractionDigits: 0 });

export const metricFormatter = (
  metricType: MetricValueType | undefined,
  compact: boolean
): Formatter =>
  metricType === "currency"
    ? compact
      ? format.currencyLargeCompact
      : format.currencyLarge
    : metricType === "price"
    ? format.currencyPrice
    : metricType === "percentage"
    ? compact
      ? formatPercentageCompact
      : formatPercentage
    : format.decimal;

const noop = (value: string) => value;

export const valueFormatter = (
  valueType: ValueType,
  compact: boolean
): Formatter =>
  valueType === "string"
    ? noop
    : valueType === "date"
    ? format.date
    : metricFormatter(valueType, compact);
