import { useAPI } from "@/client/api";

export interface InsightDashboardInfo {
  id: string;
  type: "generic" | "custom" | "data_driven";
  status: "live" | "draft";
  custom_key: string;
  routes: string[];
}

export const useDashboardInfoAPI = () => {
  const { data } = useAPI<InsightDashboardInfo>("insight/dashboard/");
  return data;
};

export type ChartIndexEntry = {
  id: string;
  title: string;
  subtitle?: string;
  category: string;
};

export const useDashboardChartIndexAPI = () => {
  const { data } = useAPI<ChartIndexEntry[]>("insight/dashboard/chart_index/");
  return data;
};

export type ChartWidth = "1/2" | "1/3" | "2/3" | "full";
export type ChartHeight = "md" | "ml" | "lg" | "xl" | "2xl";

export type RouteChartIndexEntry = [
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: { width: ChartWidth; height?: ChartHeight; [key: string]: any }
];

export const useRouteChartIndexAPI = (key?: string) => {
  const { data } = useAPI<RouteChartIndexEntry[]>(
    key ? `insight/dashboard/${key}/` : undefined
  );

  return data;
};
