import React, {
  forwardRef,
  type ForwardedRef,
  type ReactElement,
  useEffect,
  useMemo,
  memo,
  PropsWithChildren,
} from "react";

import Markdown from "react-markdown";
import remarkFrontmatter from "remark-frontmatter";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import remarkHeadingId from "remark-heading-id";
import remarkDirective from "remark-directive";
import remarkFlexibleContainers from "remark-flexible-containers";
import rehypeSlug from "rehype-slug";
import rehypeKatex from "rehype-katex";
import rehypeExternalLinks from "rehype-external-links";
import { type PluggableList } from "unified";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as WarningIcon } from "@/assets/icons/circle-warning.svg";
// @ts-ignore
import { ReactComponent as InfoIcon } from "@/assets/icons/circle-info.svg";

import { Text, TranslationScope } from "@/components/hooks/useText";
import { useScrollToState } from "@/components/hooks/useScrollIntoView";

import { ChartAnimationProvider } from "@/components/insights/animation";
import { ChartDataProvider } from "@/components/insights/ChartData";
import ChartWidget2, {
  type ChartTitleProps,
} from "@/components/insights/ChartWidget2";

import {
  addContentIds,
  expandObjectLinks,
  embedDirective,
  type ObjectLinks,
} from "./markdown";

import "katex/dist/katex.min.css";

const EmbeddedChartTitle = ({ id, className }: ChartTitleProps) => (
  <div
    className={cx(className, "flex flex-col px-1.5 pt-0.5 gap-1 select-none")}
  >
    <figcaption className="text-sm font-medium text-text-bright leading-tight wg-title-highlight-scroll-to">
      <Text i18nKey={`charts.${id}.title`} />
    </figcaption>
  </div>
);

const aspectMap: Record<string, string> = {
  "2/1": "aspect-[2/1]",
  "3/2": "aspect-[3/2]",
  "4/3": "aspect-[4/3]",
};

const ChartEmbed = memo(
  ({
    id,
    category,
    aspect,
  }: {
    id: string;
    category: string;
    aspect?: string;
  }) => (
    <TranslationScope ns="insights">
      <ChartDataProvider routeKey={category}>
        <ChartWidget2
          {...{ id, renderTitle: EmbeddedChartTitle, controlSize: "sm" }}
          className={cx(
            "not-prose w-full rounded-md bg-bg-l1 py-3 px-3",
            aspectMap[aspect ?? "2/1"]
          )}
        />
      </ChartDataProvider>
    </TranslationScope>
  )
);

const TableEmbed = memo(
  ({ id, category }: { id: string; category: string }) => (
    <TranslationScope ns="insights">
      <ChartDataProvider routeKey={category}>
        <ChartWidget2
          {...{ id, renderTitle: () => <></>, controlSize: "sm" }}
          className="not-prose -mx-2 py-2"
        />
      </ChartDataProvider>
    </TranslationScope>
  )
);

const ALERT_COMPONENT_NAME = "alert-box";

const remarkPlugins: PluggableList = [
  [remarkFrontmatter, ["yaml", "toml"]],
  [remarkMath, { singleDollarTextMath: false }],
  [remarkGfm],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [remarkHeadingId as any],
  [remarkDirective],
  [
    remarkFlexibleContainers,
    { containerTagName: ALERT_COMPONENT_NAME, containerClassName: "" },
  ],
];

const rehypePlugins: PluggableList = [
  [rehypeSlug],
  [addContentIds],
  [rehypeKatex, { fleqn: true }],
  [rehypeExternalLinks, { target: "_blank" }],
];

const remarkRehypeOptions = {
  footnoteLabelProperties: {},
  clobberPrefix: "",
};

const alertIcons: Record<string, ReactElement> = {
  info: (
    <InfoIcon className="w-6 aspect-square shrink-0 text-base-indigo-200" />
  ),
  warning: (
    <WarningIcon className="w-6 aspect-square shrink-0 text-base-orange-300" />
  ),
};

const AlertBox = ({
  children,
  className,
}: PropsWithChildren<{ className: string }>) => (
  <div className="alert flex gap-4 bg-bg-l250 pl-4 pr-6 py-3 mb-5 rounded-md">
    {alertIcons[className]}
    {children}
  </div>
);

const embeds = ["chart", "table"];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: any = {
  "chart-embed": ChartEmbed,
  "table-embed": TableEmbed,
  [ALERT_COMPONENT_NAME]: AlertBox,
};

const Body = memo(
  ({
    content,
    urls,
    embedsDataDir,
  }: {
    content: string;
    urls?: ObjectLinks;
    embedsDataDir?: string;
  }) => (
    <ChartAnimationProvider {...{ animate: true, lazyRender: false }}>
      <Markdown
        {...{
          remarkPlugins: useMemo(
            () => [
              ...remarkPlugins,
              expandObjectLinks(urls),
              embedDirective(embeds, embedsDataDir),
            ],
            [urls, embedsDataDir]
          ),
          rehypePlugins,
          remarkRehypeOptions,
          components,
        }}
      >
        {content}
      </Markdown>
    </ChartAnimationProvider>
  )
);

// cannot be easily unified with the `useScrollIntoView` we use for charts:
// the former uses refs to avoid fighting with React for the state of the DOM,
// but here our targets are DOM elements rendered through `react-markdown`.
const useScrollIntoView = (scrollTo?: string) =>
  useEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        element.dataset["highlighted"] = "true";
        setTimeout(() => {
          delete element.dataset["highlighted"];
        }, 2500);
      }
    }
  }, [scrollTo]);

export const AnalysisBody = forwardRef(
  (
    {
      content,
      urls,
      embedsDataDir,
      className,
    }: {
      content: string;
      urls?: ObjectLinks;
      embedsDataDir?: string;
      className?: string;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const scrollTo = useScrollToState();
    useScrollIntoView(scrollTo);

    return (
      <div
        {...{ ref }}
        className={cx(
          "prose prose-invert max-w-none mx-10 leading-snug",
          className
        )}
      >
        <Body {...{ content, urls, embedsDataDir }} />
      </div>
    );
  }
);
