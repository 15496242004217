import React from "react";
import { useStepContext } from "./StepsLayout";
import SnowflakeSetup from "./integrations/SnowflakeSetup";
import Select from "@/components/easy-form/Select";

import z, { Infer } from "myzod";
import useEasyForm, { useSubforms } from "@/components/easy-form";
import { useWatch } from "react-hook-form";
import DataWarehouseExport from "./integrations/DataWarehouseExport";
import { useAPI } from "@/client/api";

import { type IntegrationComponent } from "./types";

const schema = z.object({
  data_warehouse_setup: z.string(),
});

type DataWarehouseSetupJSON = Infer<typeof schema>;
const useCrmSetupAPI = () =>
  useAPI<DataWarehouseSetupJSON>("onboarding/data-warehouse-setup/");

type DataWarehouseIntegrationId = "snowflake" | "other";

const integration: Record<DataWarehouseIntegrationId, IntegrationComponent> = {
  snowflake: SnowflakeSetup,
  other: DataWarehouseExport,
};

const DataWarehouseIntegration = () => {
  const { Panel } = useStepContext();

  const { data: values } = useCrmSetupAPI();

  const { control, register, handleSubmit } =
    useEasyForm<DataWarehouseSetupJSON>({
      values,
      schema,
    });

  const data_warehouse_setup = useWatch({
    control,
    name: "data_warehouse_setup",
  });

  const { handleSubmitAll, registerSubmitHandler, registerNoopSubmitHandler } =
    useSubforms(handleSubmit);
  const IntegrationSetup =
    integration[data_warehouse_setup as DataWarehouseIntegrationId];

  return (
    <Panel
      handleSubmit={(event, { next }) =>
        handleSubmitAll(async () => {
          next();
        })(event)
      }
    >
      <div className="flex flex-col flex-grow gap-9">
        <Select
          {...register("data_warehouse_setup")}
          options={[
            { key: "snowflake", value: "Snowflake" },
            { key: "other", value: "Other" },
          ]}
        />
        {IntegrationSetup && (
          <IntegrationSetup
            {...{ registerSubmitHandler, registerNoopSubmitHandler }}
          />
        )}
      </div>
    </Panel>
  );
};

export default DataWarehouseIntegration;
