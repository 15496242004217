import React from "react";
import { type HeatmapSeriesOption } from "echarts/charts";
import { type EChartsOption } from "echarts/types/dist/shared";
import { max } from "lodash";

import useText from "@/components/hooks/useText";
import renderToElement from "@/components/render-to-element";

import { type MetricSentiment } from "@/components/insights/types";
import { metricFormatter } from "@/components/insights/format";
import {
  type MetricDatum,
  type ChartPresentationProps,
  type ChartProps,
} from "@/components/insights/types";

import { Chart } from "./Chart";
import { cohortDataset, cohortSizeDataset } from "./CohortChartData";
import { THEME_COLOR_RANGE } from "./theme";

type CohortChartPresentationProps = ChartPresentationProps & {
  metricSentiment?: MetricSentiment;
};

export type CohortChartProps = ChartProps<
  CohortChartPresentationProps,
  [MetricDatum[], MetricDatum[]]
>;

type ChartOptions = Omit<HeatmapSeriesOption, "id"> & EChartsOption;

const colorRangeBySentiment: Record<MetricSentiment, string[]> = {
  positive: THEME_COLOR_RANGE["green"],
  negative: THEME_COLOR_RANGE["red"],
  neutral: THEME_COLOR_RANGE["indigo"],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const maxCohortSize = (data: any[], dimension: number) =>
  (max(data.map((d) => Number(d[dimension]))) || 0) * 1.25;

const useCohorChartProps = ({
  id,
  presentation,
  data,
}: CohortChartProps): ChartOptions => {
  const { metricType, metricSentiment, indexBy }: CohortChartPresentationProps =
    {
      metricType: "percentage",
      metricSentiment: "neutral",
      ...presentation,
    };

  const t = useText({ keyPrefix: `charts.${id}` });
  const valueFormat = metricType
    ? metricFormatter(metricType, true)
    : undefined;

  const cohortSizeData = cohortSizeDataset(
    data[0],
    indexBy,
    t("cohortSizeLabel")
  );

  return {
    dataset: [cohortSizeData, cohortDataset(data[1], indexBy)],
    grid: [
      {
        id: "cohortSize",
        top: 0,
        left: 0,
        width: 120,
        bottom: 50,
        containLabel: true,
      },
      {
        id: "cohortMatrix",
        top: 0,
        left: 120,
        right: 0,
        bottom: 50,
        containLabel: true,
      },
    ],
    xAxis: [
      {
        gridIndex: 0,
        type: "category",
        position: "top",
        dimension: 1,
        axisTick: {
          show: false,
        },
        axisLabel: {
          width: 40,
          overflow: "break",
        },
      },
      {
        gridIndex: 1,
        type: "category",
        position: "top",
        name: t("keyLabel"),
        splitArea: {
          show: true,
        },
        axisTick: {
          alignWithLabel: false,
        },
      },
    ],
    yAxis: [
      {
        gridIndex: 0,
        type: "category",
        name: t("indexLabel"),
        inverse: true,
        splitArea: {
          show: true,
        },
        axisTick: {
          alignWithLabel: false,
        },
      },
      {
        gridIndex: 1,
        type: "category",
        inverse: true,
        padding: 0,
        splitArea: {
          show: true,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series: [
      {
        datasetIndex: 0,
        type: "heatmap",
        encode: {
          y: 0,
        },
        label: {
          show: true,
        },
      },
      {
        datasetIndex: 1,
        xAxisIndex: 1,
        yAxisIndex: 1,
        type: "heatmap",
        encode: {
          y: 0,
        },
        label: {
          show: true,
          formatter: valueFormat
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ({ data }: any) => valueFormat(data[2])
            : undefined,
        },
      },
    ],
    visualMap: [
      {
        seriesIndex: 0,
        show: false,
        min: 0,
        max: maxCohortSize(cohortSizeData.source, 2),
        inRange: {
          color: THEME_COLOR_RANGE["gray"],
        },
      },
      {
        seriesIndex: 1,
        orient: "horizontal",
        min: 0,
        max: 1,
        calculable: true,
        left: "center",
        itemHeight: 300,
        itemWidth: 18,
        padding: 0,
        textGap: 1,
        formatter: valueFormat,
        inRange: {
          color: colorRangeBySentiment[metricSentiment],
        },
      },
    ],
    tooltip: {
      trigger: "item",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formatter: ({ seriesIndex, marker, value }: any) =>
        renderToElement(
          <div className="flex flex-col gap-1">
            <span>
              {t(`series${seriesIndex}.tooltipLabel`, {
                cohort: value[0],
                index: value[1],
              })}
            </span>
            <span>
              <span dangerouslySetInnerHTML={{ __html: marker }} />
              <span className="font-bold">
                {t(`series${seriesIndex}.tooltipValue`, {
                  value: value[2],
                })}
              </span>
            </span>
          </div>
        ),
      axisPointer: {
        type: "shadow",
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any as ChartOptions;
};

const CohortChart = (props: CohortChartProps) => {
  const chartProps = useCohorChartProps(props);
  return <Chart id={props.id} className="w-full h-full" {...chartProps} />;
};

export default CohortChart;
