import React, { useMemo } from "react";
import { useController, FieldValues, FieldPathByValue } from "react-hook-form";
import { keyBy, pick, uniqBy } from "lodash";

import { CustomInputProps } from "@/components/input/props";
import { Select } from "@/components/input/EntitySelect";

import { useProductPlansetListAPI } from "@/pages/plans/models/api";
import { useProductsAPI } from "@/pages/products/api";

import { PriceRulePlanComponent } from "../models/price-rules";

const ProductPlanComponentSelect = <
  Entity extends FieldValues,
  Name extends FieldPathByValue<Entity, PriceRulePlanComponent | undefined>
>({
  planId,
  productId,
  control,
  name,
  required,
  ...props
}: CustomInputProps<Entity, Name> & {
  planId?: string;
  productId?: string;
  placeholder?: string;
  className?: string;
}) => {
  const plans = useProductPlansetListAPI({ productId: productId });
  const plansets = keyBy(plans, "id");
  const planset = planId ? plansets?.[planId] : undefined;

  const { product } = useProductsAPI({ productId });

  const productComponentsById = useMemo(
    () => keyBy(product?.components, "id"),
    [product?.components]
  );

  const planComponents = useMemo(() => {
    let basePlanComponents = planset?.components ?? [];
    let basePlanId = planset?.base_plan_id;
    while (basePlanId) {
      const basePlanset = plansets?.[basePlanId];
      basePlanComponents = [
        ...basePlanComponents,
        ...(basePlanset?.components ?? []),
      ];
      basePlanId = basePlanset?.base_plan_id;
    }
    return basePlanComponents;
  }, [planset, plansets]);

  const options = useMemo(
    () =>
      uniqBy(planComponents, "product_component_id")
        .map((c) => ({
          ...c,
          ...productComponentsById[c.product_component_id],
        }))
        .filter((c) => c.is_countable),
    [planComponents, productComponentsById]
  );

  const componentsById = useMemo(() => keyBy(options, "id"), [options]);

  const {
    field: { ref, onChange, onBlur, value: partialComponent },
    fieldState: { invalid },
  } = useController({ control, name, rules: { required } });

  return (
    <Select
      options={options ?? []}
      searchKeys={["name"]}
      optionKey={(component) => component?.id ?? ""}
      displayValue={(component) => component?.name ?? ""}
      value={componentsById[partialComponent?.id] ?? null}
      onChange={(component) =>
        onChange(
          component
            ? pick(component, ["id", "name", "quantity_unit_of_measure"])
            : undefined
        )
      }
      onBlur={onBlur}
      ref={ref}
      invalid={invalid}
      {...props}
    />
  );
};

export default ProductPlanComponentSelect;
