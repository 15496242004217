import { useAPI } from "@/client/api";

export interface ReportMetadata {
  id: string;
  name: string;
  publish_date: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stats: Record<string, any>;
  charts?: string[];
}

export type ReportIndexEntryDTO = Omit<ReportMetadata, "publish_date"> & {
  publish_date: string;
};

export const fromDTO = <T extends ReportIndexEntryDTO>({
  publish_date,
  ...props
}: T) => ({
  publish_date: new Date(publish_date),
  ...props,
});

const reportsBaseURL = "insight/analysis/reports/";

export const useReportIndex = () => {
  const { data } = useAPI<ReportIndexEntryDTO[]>(reportsBaseURL);
  return data?.map(fromDTO);
};

export interface ReportData extends ReportMetadata {
  content: string;
}

type ReportDataDTO = Omit<ReportData, "publish_date"> & {
  publish_date: string;
};

export const useReportData = (reportId?: string) => {
  const { data } = useAPI<ReportDataDTO>(
    reportId && `${reportsBaseURL}${reportId}`,
    {
      keepPreviousData: true,
    }
  );

  return data ? fromDTO(data) : undefined;
};
