import React, { forwardRef, Fragment, type ForwardedRef } from "react";
import { Tab } from "@headlessui/react";
import { useInView } from "react-intersection-observer";

import { isArray } from "lodash";
import cx from "clsx";

//@ts-ignore

import { Text } from "@/components/hooks/useText";
import useScrollIntoView from "@/components/hooks/useScrollIntoView";

import { ChartLazyRender } from "@/components/insights/animation";
import { useChartData } from "@/components/insights/ChartData";
import { ChartSubtitle } from "@/components/insights/ChartWidget";

import BarChart, { type BarChartProps } from "@/components/charts/BarChart";

import LineChart2, {
  type LineChart2Props,
} from "@/components/insights/LineChart2";

import PieChart2, {
  type PieChart2Props,
} from "@/components/insights/PieChart2";

import Heatmap2, { type Heatmap2Props } from "@/components/insights/Heatmap2";
import CohortChart, {
  type CohortChartProps,
} from "@/components/charts/CohortChart";

import Table, { type TableProps } from "@/components/insights/Table";

import QCBadge from "./quality-control/QCBadge";

export type ChartTitleProps = {
  id: string;
  selected?: string;
  className?: string;
};

type ControlSize = "sm" | "md" | "lg";

export type ChartWidget2Props = {
  id: string;
  controlSize?: ControlSize;
  className?: string;
  scrollIntoView?: boolean;
  renderTitle?: (props: ChartTitleProps) => JSX.Element;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ChartData<Data = any> = {
  presentation: {
    chartType: "bar" | "line" | "pie" | "heatmap" | "table" | "cohort";
    showAxisLabels?: boolean;
    showTabs?: boolean;
  };

  data: Data;
  sources?: Record<string, string[]>;
};

const renderChart = (id: string, { presentation, data }: ChartData) => {
  switch (presentation?.chartType) {
    case "bar":
      return (
        <BarChart
          {...({ id, presentation, data } as unknown as BarChartProps)}
        />
      );

    case "line":
      return (
        <LineChart2
          {...({ id, presentation, data } as unknown as LineChart2Props)}
        />
      );

    case "pie":
      return (
        <PieChart2
          {...({ id, presentation, data } as unknown as PieChart2Props)}
        />
      );

    case "heatmap":
      return (
        <Heatmap2
          {...({ id, presentation, data } as unknown as Heatmap2Props)}
        />
      );

    case "cohort":
      return (
        <CohortChart
          {...({ id, presentation, data } as unknown as CohortChartProps)}
        />
      );

    case "table":
      return (
        <Table
          {...({ id, presentation, data } as unknown as TableProps)}
          className="overflow-hidden w-full"
        />
      );
  }
};

const ChartTitle = ({ id, selected, className }: ChartTitleProps) => (
  <div className={cx(className, "flex flex-col px-2 pt-1.5 gap-1 select-none")}>
    <figcaption className="text-lg font-semibold text-text-bright leading-tight wg-title-highlight-scroll-to">
      <Text i18nKey={`charts.${id}.title`} />
    </figcaption>
    <p className="text-sm text-text-default leading-normal">
      <ChartSubtitle {...{ id, values: { selected } }} />
    </p>
  </div>
);

const SingleChartWidget = forwardRef(
  (
    {
      id,
      data,
      className,
      renderTitle,
    }: ChartWidget2Props & { data?: ChartData },
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <figure
      {...{ id, ref }}
      className={cx(
        "relative group flex flex-col gap-y-3 wg-highlight-scroll-to",
        className
      )}
    >
      {renderTitle?.({ id })}
      <div className="flex h-full mx-1 mb-1">
        <ChartLazyRender>
          {() => <>{data && renderChart(id, data)}</>}
        </ChartLazyRender>
      </div>
      {data?.sources ? <QCBadge chartId={id} /> : null}
    </figure>
  )
);

const tabClasses: Record<ControlSize, string> = {
  lg: "px-4 py-3",
  md: "text-sm px-2.5 py-2",
  sm: "text-sm px-2 py-1.5",
};

const TabList = ({
  tabs,
  controlSize,
}: {
  tabs: string[];
  controlSize?: ControlSize;
}) => (
  <div className="flex flex-col">
    <Tab.List className="flex bg-bg-l1 rounded-md">
      {tabs.map((key) => (
        <Tab
          key={key}
          className={({ selected }) =>
            cx(
              tabClasses[controlSize ?? "md"],
              selected
                ? "text-text-bright bg-bg-l3 rounded-md"
                : "text-text-muted",
              "font-semibold leading-none whitespace-nowrap",
              "outline-none border border-transparent focus:border-border-bright hover:text-text-link"
            )
          }
        >
          {key}
        </Tab>
      ))}
    </Tab.List>
  </div>
);

const ChartGroupWidget = forwardRef(
  (
    {
      id,
      data: chartData,
      controlSize,
      className,
      renderTitle,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ChartWidget2Props & { data?: ChartData<Record<string, any>> },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { presentation, data } = chartData ?? {};
    const tabs = Object.keys(data ?? {});

    return (
      <Tab.Group defaultIndex={tabs.length - 1}>
        <figure
          {...{ id, ref }}
          className={cx(
            "relative group grid grid-flow-col grid-cols-[minmax(0,1fr),minmax(0,min-content)]",
            "grid-rows-[minmax(0,min-content),minmax(0,1fr)]",
            "gap-3 wg-highlight-scroll-to",
            className
          )}
        >
          <div className="col-start-2 flex justify-end">
            {presentation?.showTabs && <TabList {...{ tabs, controlSize }} />}
          </div>

          <Tab.Panels as={Fragment}>
            {({ selectedIndex }) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const selectedKey: string = tabs[selectedIndex]!;
              return (
                <>
                  {renderTitle?.({
                    id,
                    className: "col-start-1",
                    selected: selectedKey,
                  })}

                  <div className="col-start-1 col-span-2 row-start-2 px-1 pb-1">
                    <div className="flex h-full">
                      <ChartLazyRender>
                        {() => (
                          <>
                            {data &&
                              presentation &&
                              selectedKey &&
                              renderChart(id, {
                                presentation,
                                data: data[selectedKey],
                              })}
                          </>
                        )}
                      </ChartLazyRender>
                    </div>
                  </div>
                </>
              );
            }}
          </Tab.Panels>
          {chartData?.sources ? <QCBadge chartId={id} /> : null}
        </figure>
      </Tab.Group>
    );
  }
);

const mergePresentationProps = (
  chartData: ChartData,
  presentation: ChartData["presentation"]
) =>
  chartData
    ? {
        ...chartData,
        presentation: {
          showTabs: true,
          ...chartData.presentation,
          ...(presentation as ChartData["presentation"]),
        },
      }
    : undefined;

const ChartWidget2 = ({
  id,
  className,
  controlSize,
  scrollIntoView,
  presentation,
  renderTitle,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
ChartWidget2Props & { presentation?: any }) => {
  const { ref, inView } = useInView({ trackVisibility: false });
  const { scrollClass, scrollRef } = useScrollIntoView(ref, scrollIntoView);

  const chartData = useChartData({ dataKey: id, inView }) as ChartData;
  const Widget =
    chartData && !isArray(chartData.data)
      ? ChartGroupWidget
      : SingleChartWidget;

  return (
    <Widget
      {...{
        id,
        data: mergePresentationProps(chartData, presentation),
        className: cx(className, scrollClass),
        controlSize,
        renderTitle: renderTitle ?? ChartTitle,
        ref: scrollRef,
      }}
    />
  );
};

export default ChartWidget2;
