import { WithId } from "@/client/types";

export type BypassId = string;

export interface BypassJSON extends WithId {
  id: BypassId;
  name: string;
  start_date: string;
  end_date: string;
  is_active: boolean;
}

export interface BypassData extends WithId {
  id: BypassId;
  name: string;
  start_date: number; // Technically Date.parse return value
  end_date: number; // Technically Date.parse return value
  is_active: boolean;
}

export const bypassParseJSON = (json: BypassJSON): BypassData => {
  const { start_date, end_date, ...rest } = json;
  return {
    ...rest,
    start_date: Date.parse(start_date),
    end_date: Date.parse(end_date),
  };
};
