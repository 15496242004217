import React from "react";
import { Navigate } from "react-router-dom";

import ResetPassword from "./ResetPassword";
import ResetPasswordToken from "./ResetPasswordToken";
import Login from "./Login";
import Register from "./Register";
import VerifyEmail from "./VerifyEmail";

const userRoutes = [
  { path: "user/login", element: <Login /> },
  { path: "user/register", element: <Register /> },
  { path: "user/reset-password", element: <ResetPassword /> },
  { path: "user/reset-password-token", element: <ResetPasswordToken /> },
  { path: "user/verify-email", element: <VerifyEmail /> },
  { path: "user", element: <Navigate replace to="/user/login" /> },
  { path: "login", element: <Navigate replace to="/user/login" /> },
];

export default userRoutes;
