import React, { useState, useMemo, ReactNode, forwardRef, Ref } from "react";
import { useCombobox } from "downshift";
import { matchSorter } from "match-sorter";
import cx from "clsx";

import { replacePunctuation } from "@/lib/string";
import { WithId } from "@/client/types";
// @ts-ignore
import { ReactComponent as AddIcon } from "@/assets/icons/circle-plus.svg";
// @ts-ignore
import { ReactComponent as XIcon } from "@/assets/icons/small-x.svg";

import { ProductComponentJSON } from "@/pages/products/serialization";

import {
  ComponentIcon,
  UnitsLabel,
} from "@/pages/products/components/ProductComponent";

import {
  toPlanComponent,
  ProductPlanComponentJSON,
} from "../models/serialization";

export interface ProductComponentOption extends ProductComponentJSON {
  override?: boolean;
}

export type PlanComponent = ProductPlanComponentJSON;

export const filterComponents = (
  components: ProductComponentJSON[],
  input: string
) => {
  if (!input) return components;

  const lowerCasedInput = input.toLowerCase();
  return matchSorter(components, lowerCasedInput, {
    keys: [
      "name",
      "description",
      ({ name }) => replacePunctuation(name),
      ({ description }) => replacePunctuation(description ?? ""),
    ],
    threshold: matchSorter.rankings.WORD_STARTS_WITH,
  });
};

interface PlanComponentsSelectProps {
  options: ProductComponentOption[];
  onAdd: (component: PlanComponent) => void;
  renderNoMatches?: (input: string) => ReactNode;
  placeholder?: string;
  className?: string;
}

interface SelectedItemProps<T extends WithId> {
  item: T;
  onRemove: (item: T) => void;
  children: ReactNode;
  className?: string;
}

export const SelectedItem = forwardRef(
  <T extends WithId>(
    { item, onRemove, children, className, ...props }: SelectedItemProps<T>,
    ref: Ref<HTMLSpanElement>
  ) => (
    <span
      className={cx(
        "flex items-center bg-bg-l3 rounded-full gap-x-0.5 pr-1 group",
        "capitalize outline-none focus:bg-brand-purple-l1 cursor-pointer",
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
      <span
        className={cx(
          "inline-flex p-2 rounded-full cursor-pointer text-text-muted hover:text-text-link",
          "group-focus:text-text-link group-focus:hover:text-text-bright"
        )}
        onClick={(e) => {
          e.stopPropagation();
          onRemove(item);
        }}
      >
        <XIcon className="inline-block w-3" />
      </span>
    </span>
  )
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ToggleButton = forwardRef((props: any, ref: Ref<HTMLButtonElement>) => (
  <button
    type="button"
    aria-label="toggle menu"
    className="py-1 px-1.5 text-text-default hover:text-text-link rounded-r-md bg-transparent"
    ref={ref}
    {...props}
  >
    <AddIcon className="w-6" />
  </button>
));

interface MenuProps {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
}

const Menu = forwardRef(
  (
    { isOpen, children, className, ...props }: MenuProps,
    ref: Ref<HTMLUListElement>
  ) => (
    <ul
      className={cx(
        "absolute z-50 w-full overflow-auto p-0",
        "shadow-md rounded-md bg-bg-l3 border border-bg-l2",
        !isOpen && "hidden",
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
    </ul>
  )
);

interface MenuItemProps {
  isHighlighted?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

const MenuItem = forwardRef(
  (
    {
      isHighlighted,
      isSelected: _,
      disabled,
      children,
      ...props
    }: MenuItemProps,
    ref: Ref<HTMLLIElement>
  ) => (
    <li
      className={cx(
        isHighlighted ? "bg-bg-l4 text-text-link" : "text-text-bright",
        disabled ? "cursor-default" : "cursor-pointer",
        "py-2 px-2 flex flex-col"
      )}
      ref={ref}
      {...props}
    >
      {children}
    </li>
  )
);

export const ComponentMenuItem = ({
  item,
}: {
  item: ProductComponentOption;
}) => (
  <span className="flex flex-row items-center gap-x-1.5 pr-2">
    <ComponentIcon
      isCountable={item.is_countable}
      override={item.override}
      size="w-5"
      className="self-start pt-[1px]"
    />
    <span className="flex flex-col min-w-0 gap-1">
      <span className="inline-flex items-center gap-1.5">
        <span className="leading-tight capitalize min-w-0 break-words">
          {item.name}
        </span>
        {item.quantity_unit_of_measure && (
          <UnitsLabel
            units={item.quantity_unit_of_measure}
            className="text-2xs px-1 py-0.5 max-w-[5rem]"
          />
        )}
      </span>
      {item.description && (
        <span className="text-text-default text-sm leading-tight text-gray-700 break-words">
          {item.description}
        </span>
      )}
    </span>
  </span>
);

const AutoCompleteInput = forwardRef(
  (
    {
      placeholder,
      value,
      ...props
    }: {
      placeholder: string;
      value: string;
    },
    ref: Ref<HTMLInputElement>
  ) => (
    <input
      type="search"
      placeholder={placeholder}
      maxLength={256}
      className="w-full text-text-muted focus:text-text-bright bg-transparent pl-2 outline-none"
      ref={ref}
      value={value}
      tabIndex={0}
      {...props}
    />
  )
);

const PlanComponentsSelect = ({
  options,
  onAdd,
  placeholder,
  className,
  renderNoMatches,
}: PlanComponentsSelectProps) => {
  const [inputValue, setInputValue] = useState("");
  const items = useMemo(
    () => filterComponents(options, inputValue || ""),
    [options, inputValue]
  );

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items,
    itemToString: (item) => item?.name || "",
    inputValue,
    selectedItem: null,

    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputFocus:
        case useCombobox.stateChangeTypes.InputChange:
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          return {
            ...changes,
            ...(changes.isOpen && { highlightedIndex: 0 }),
          };

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: 0,
          };

        default:
          return changes;
      }
    },

    onStateChange: ({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          if (newSelectedItem) onAdd(toPlanComponent(newSelectedItem, null));
          setInputValue("");
          break;
        }

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue || "");
          break;

        default:
          break;
      }
    },
  });

  return (
    <div
      onBlur={(event) => {
        // reset incomplete input, if any, if focus is leaving the component
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setInputValue("");
        }
      }}
      className={cx(
        "relative flex gap-2 items-center flex-wrap p-1.5 bg-bg-l2 rounded-lg z-10",
        "border border-border-default hover:border-border-bright focus-within:border-border-focus",
        className
      )}
    >
      <div className="flex gap-0.5 grow">
        <AutoCompleteInput placeholder={placeholder} {...getInputProps()} />
        <ToggleButton {...getToggleButtonProps()} />
        <Menu
          isOpen={isOpen}
          {...getMenuProps()}
          className="bottom-12 left-0 max-h-[20rem]"
        >
          {isOpen && items.length ? (
            items.map((item, index) => (
              <MenuItem
                key={`${item.id}${index}`}
                isHighlighted={highlightedIndex === index}
                isSelected={selectedItem === item}
                {...getItemProps({ item, index })}
              >
                <ComponentMenuItem item={item} />
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <span className="text-text-muted select-none">
                {inputValue
                  ? renderNoMatches?.(inputValue) || (
                      <span>
                        No component matches for "
                        <span className="font-semibold break-all">
                          {inputValue}
                        </span>
                        " 🤔
                      </span>
                    )
                  : "Nothing to select 🤷"}
              </span>
            </MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default PlanComponentsSelect;
