import React from "react";
import cx from "clsx";

import FormattedList from "@/components/FormattedList";
import { Text } from "@/components/hooks/useText";

const KeyStats = ({
  stats,
  className,
}: {
  stats: Record<string, number>;
  className?: string;
}) => (
  <div className={cx("text-text-default leading-tight", className)}>
    <FormattedList listType="conjunction">
      {Object.entries(stats).map(([key, count]) => (
        <span {...{ key }} className="font-medium">
          <Text i18nKey={key} values={{ count }} />
        </span>
      ))}
    </FormattedList>
  </div>
);

export default KeyStats;
