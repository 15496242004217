import React, { useId, ReactNode, ForwardedRef, forwardRef } from "react";
import {
  UseFormRegisterReturn,
  FieldValues,
  FieldError,
  Control,
  FieldPath,
} from "react-hook-form";

import { useTranslation } from "react-i18next";

import cx from "clsx";

import { slug } from "@/lib/string";

import ErrorMessage from "@/components/input/ErrorMessage";
import useText, { Text, TranslationScope } from "@/components/hooks/useText";

const OptionalBadge = () => {
  const { t } = useTranslation("common");
  return (
    <div
      className={cx(
        "text-text-default text-2xs font-medium leading-tight uppercase",
        "rounded-[4px] bg-bg-l4 px-1.5 py-1 select-none"
      )}
    >
      {t("labels.optional")}
    </div>
  );
};

export type InputFieldProps<
  Values extends FieldValues,
  Name extends FieldPath<Values>
> = Omit<UseFormRegisterReturn, "ref"> & {
  name: Name;
  control: Control<Values>;
  error?: FieldError;
  className?: string;
};

export type InputFieldRenderProps<
  Values extends FieldValues,
  Name extends FieldPath<Values>,
  Element extends HTMLElement
> = InputFieldProps<Values, Name> & {
  id: string;
  ref: ForwardedRef<Element>;
  placeholder: string;
  "aria-invalid": boolean;
};

const InputField = forwardRef(
  <
    Values extends FieldValues,
    Name extends FieldPath<Values>,
    Element extends HTMLElement
  >(
    {
      name,
      required,
      error,
      children,
      className,
      ...props
    }: InputFieldProps<Values, Name> & {
      children: (
        props: InputFieldRenderProps<Values, Name, Element>
      ) => ReactNode;
    },
    ref: ForwardedRef<Element>
  ) => {
    const keyPrefix = `fields.${name}`;
    const t = useText({ keyPrefix });

    const label = t("label", { defaultValue: "" });
    const description = t("description", { defaultValue: "" });
    const errorKeys = error
      ? [
          `error.${error.message ? slug(error.message) : error.type}`,
          `error.${error.type}`,
        ]
      : null;

    const id = useId();
    return (
      <TranslationScope keyPrefix={keyPrefix}>
        <div className={cx("flex flex-col gap-1.5", className)}>
          <div className="flex flex-col">
            {label && (
              <div className="flex">
                <label
                  className="flex items-center gap-2 pb-2.5 cursor-pointer select-none"
                  htmlFor={id}
                >
                  <div className="text-text-bright font-medium leading-tight">
                    {label}
                  </div>
                  {!required && <OptionalBadge />}
                </label>
              </div>
            )}
            {children({
              name,
              id,
              ref,
              error,
              placeholder: t("placeholder", { defaultValue: "" }),
              "aria-invalid": Boolean(error),
              ...props,
            })}
          </div>
          {description && (
            <div className="text-text-muted text-sm select-none">
              {description}
            </div>
          )}
          {errorKeys && (
            <ErrorMessage>
              <Text
                i18nKey={errorKeys}
                components={{ em: <span className="font-semibold" /> }}
              />
            </ErrorMessage>
          )}
        </div>
      </TranslationScope>
    );
  }
);

export default InputField;
