import React from "react";

import { type DatumValue } from "@nivo/core";
import { Line } from "@nivo/line";
import { isNumber } from "lodash";

import type { MetricDatum, ChartPresentationProps, ChartProps } from "./types";
import { toLineSeries } from "./LineChart2Data";
import XYChart from "./XYChart";

export type LineChart2Props = ChartProps<ChartPresentationProps, MetricDatum[]>;

export const LineChart2 = ({ id, presentation, data }: LineChart2Props) => (
  <XYChart {...{ id, presentation, data }}>
    {({ size, data, indexBy, valueFormat, margin, axisBottom, ...props }) => (
      <Line
        {...{
          data: toLineSeries(data, indexBy),
          yFormat: (value: DatumValue) =>
            isNumber(value) ? valueFormat(value) : `${value}`,
          enableGridX: false,
          enableGridY: false,
          xScale: { type: "point" },
          yScale: {
            type: "linear",
            min: "auto",
            max: "auto",
          },
          pointSize: 4,
          pointLabelYOffset: -12,
          useMesh: true,
          margin: {
            ...margin,
            right: axisBottom.tickRotation ? 5 : 40,
          },
          axisBottom,
          ...size,
          ...props,
        }}
      />
    )}
  </XYChart>
);

export default LineChart2;
