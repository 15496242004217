import React from "react";
import cx from "clsx";

const Spinner = ({ className = "border-2" }: { className?: string }) => (
  <div className="w-full aspect-square shrink-0">
    <div className="relative">
      <div
        className={cx(
          "absolute w-full aspect-square rounded-full border-solid border-base-white/20",
          className
        )}
      />
      <div
        className={cx(
          "absolute w-full aspect-square rounded-full animate-spin border-solid border-brand-purple-l1 border-t-transparent",
          className
        )}
      />
    </div>
  </div>
);

export default Spinner;
