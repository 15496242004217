import React, { useState, ReactNode, PropsWithChildren } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";
import { map, max } from "lodash";
import cx from "clsx";

import { isNotNil } from "@/lib/functional";

import { Control, useWatch } from "@/components/EditableCard";
import { Drawer } from "@/components/Modal";

// @ts-ignore
import { ReactComponent as ChevronRight } from "@/assets/icons/ctrl-right.svg";

import {
  ProductPlanPricingJSON,
  PricingType,
} from "@/pages/plans/models/serialization";

import {
  isTiered,
  defaultPrice,
  defaultPercentRate,
  isNilPrice,
  DEFAULT_PRICING_UNIT,
  summaryPrice,
  isPercentageRate,
} from "@/pages/plans/models/pricing-plan";

import { EditablePlan } from "@/pages/plans/models/planset";

import PricingPlanSummary from "./PricingPlanSummary";
import PlanPricePanel, { NestedEditorProps } from "./PlanPricePanel";

interface PlanPricingProps {
  price: ProductPlanPricingJSON;
  t: TFunction;
}

const PriceAmount = ({ price, t }: PlanPricingProps) => (
  <Trans
    i18nKey={`pricing.amount.${
      isPercentageRate(price)
        ? "percent_rate"
        : isTiered(price)
        ? "tiered"
        : "base"
    }`}
    values={{ price: defaultPrice(price), percent: defaultPercentRate(price) }}
    t={t}
    components={[
      <span className="text-base text-text-default leading-tight" />,
      <span className="text-4xl font-semibold leading-none text-right" />,
    ]}
  />
);

const PricePlaceholder = ({ price, t }: PlanPricingProps) => (
  <span className="text-4xl leading-none">
    <Trans
      i18nKey={`pricing.placeholder.${
        isPercentageRate(price) ? "percent" : "amount"
      }`}
      t={t}
      components={[
        <span className="text-text-muted font-semibold group-hover:text-text-link" />,
        <span className="inline-block w-[0.75em] -mb-1 border-b border-b-text-muted" />,
      ]}
    />
  </span>
);

const PriceTerms = ({
  price,
  pricingUnit,
  t,
}: PlanPricingProps & { pricingUnit?: string }) => (
  <span className="flex flex-col">
    <span className="text-base text-text-default leading-tight">
      {t(
        [
          `pricing.terms.${price.pricing_type}_${price.pricing_formula}`,
          `pricing.terms.${price.pricing_type}`,
        ],
        {
          unit: pricingUnit || DEFAULT_PRICING_UNIT,
          metric: price.usage_metric_name,
        }
      )}
    </span>
    <span className="text-text-muted text-sm leading-tight">
      {t("pricing.billing", { period: price.billing_period })}
    </span>
  </span>
);

const PlanPrice = ({
  price,
  t,
  pricingUnit,
  onClick,
  className,
  children,
}: PlanPricingProps & {
  pricingUnit?: string;
  onClick?: VoidFunction;
  className?: string;
  children?: ReactNode;
}) => {
  const amount = summaryPrice(price);
  return (
    <div
      key={price.id}
      onClick={() => onClick?.()}
      className={cx(
        "flex gap-2 items-center select-none px-3 py-2 rounded-md",
        className
      )}
      data-price-amount={amount}
    >
      {isNilPrice(amount) ? (
        <PricePlaceholder {...{ price, t }} />
      ) : (
        <PriceAmount {...{ price, t }} />
      )}
      <PriceTerms {...{ price, pricingUnit, t }} />
      {children}
    </div>
  );
};

const PriceDrawerTitle = ({
  control,
  price,
  t,
}: PlanPricingProps & { control: Control<EditablePlan> }) => {
  const planName = useWatch({ control, name: "name" });
  return (
    <Drawer.Title className="text-xl text-text-bright font-semibold leading-tight select-none">
      <Trans
        i18nKey="pricing.editing_title"
        t={t}
        values={{
          planName,
          billingPeriod: t(
            `pricing.billing_period_plan_name.${price.billing_period}`
          ),
        }}
      />
    </Drawer.Title>
  );
};

const PlanPriceDrawer = ({
  open,
  setOpen,
  control,
  price,
  t,
  children,
}: PropsWithChildren<
  PlanPricingProps & {
    open: boolean;
    setOpen: (value: boolean) => void;
    control: Control<EditablePlan>;
  }
>) => (
  <Drawer {...{ open, setOpen, onClose: () => setOpen(false) }}>
    <div
      id={open ? "plan-price-drawer" : "plan-price-drawer-closed"}
      className="flex flex-col h-full pl-7 pr-5 pt-5 pb-6 gap-6 overflow-y-auto"
    >
      <PriceDrawerTitle {...{ price, t, control }} />
      {children}
    </div>
  </Drawer>
);

const EditablePlanPrice = ({
  index,
  control,
  ...props
}: {
  index: number;
  control: Control<EditablePlan>;
  t: TFunction;
  onClick?: VoidFunction;
  className?: string;
  children?: ReactNode;
}) => {
  const price = useWatch({ control, name: `prices.${index}` });
  const pricingUnit = useWatch({
    control,
    name: `pricing_unit`,
    disabled: price.pricing_type !== PricingType.UNIT,
  });

  return <PlanPrice {...{ price, pricingUnit, ...props }} />;
};

const LivePricingPlanSummary = ({
  index,
  control,
}: {
  index: number;
  control: Control<EditablePlan>;
}) => {
  const planName = useWatch({ control, name: "name" });
  const pricingUnit =
    useWatch({ control, name: "pricing_unit" }) || DEFAULT_PRICING_UNIT;
  const pricing = useWatch({ control, name: `prices.${index}` });
  return (
    <div>
      <PricingPlanSummary {...{ planName, pricingUnit, pricing }} />
    </div>
  );
};

const PlanPriceEditor = ({
  index,
  control,
  register,
  setValue,
  price,
  t,
}: PlanPricingProps & NestedEditorProps<EditablePlan>) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col items-center">
      <EditablePlanPrice
        className="text-text-link cursor-pointer hover:bg-bg-l3 group"
        onClick={() => setOpen(true)}
        {...{ index, control, t }}
      >
        <ChevronRight className="w-9 -ml-2 -mr-3 text-bg-l4 group-hover:text-text-link" />
      </EditablePlanPrice>
      <PlanPriceDrawer {...{ open, setOpen, control, price, t }}>
        <PlanPricePanel {...{ index, control, register, setValue }} />
        <LivePricingPlanSummary {...{ index, control }} />
      </PlanPriceDrawer>
    </div>
  );
};

const PlanPricing = ({
  control,
  register,
  setValue,
  pricing,
  pricingUnit,
  editing,
  t,
}: {
  control: Control<EditablePlan>;
  register: UseFormRegister<EditablePlan>;
  setValue: UseFormSetValue<EditablePlan>;
  pricing: ProductPlanPricingJSON[];
  pricingUnit?: string;
  editing: boolean;
  t: TFunction;
}) => {
  if (!editing) {
    const maxPrice = max(map(pricing, summaryPrice));
    if (!maxPrice) {
      return (
        <div className="flex flex-col items-center justify-center min-h-[106px]">
          <span className="text-4xl text-text-bright font-semibold leading-none text-center select-none">
            {t(maxPrice === 0 ? "pricing.free" : "pricing.custom")}
          </span>
        </div>
      );
    }
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-[106px]">
      {editing
        ? pricing.map((price, i) => (
            <PlanPriceEditor
              key={`${price.id ?? price.billing_period}-editor`}
              index={i}
              {...{ control, register, setValue, price, t }}
            />
          ))
        : pricing
            .filter((price) => isNotNil(summaryPrice(price)))
            .map((price) => (
              <PlanPrice
                className="text-text-bright"
                key={price.id}
                {...{ price, pricingUnit, t }}
              />
            ))}
    </div>
  );
};

export default PlanPricing;
