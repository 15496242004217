import { useCallback } from "react";
import api, { useAPI, useAPIConfig } from "@/client/api";

export type QueryAnswer = {
  id: string;
  name: string;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stats: Record<string, any>;
  charts?: string[];
};

type BaseQuery = {
  id: string;
  text: string;
  enqueued_date: Date;
  enqueued_by: string;
};

export type AnsweredQuery = BaseQuery & {
  status: "ready";
  answer: QueryAnswer;
};

export type AnalysisQuery =
  | (BaseQuery & {
      status: "enqueued" | "processing" | "cancelled";
    })
  | AnsweredQuery;

export type AnalysisQueryDTO = Omit<AnalysisQuery, "enqueued_date"> & {
  enqueued_date: string;
};

export const fromDTO = <T extends AnalysisQueryDTO>({
  enqueued_date,
  ...props
}: T) =>
  ({
    enqueued_date: new Date(enqueued_date),
    ...props,
  } as AnalysisQuery);

const queriesBaseURL = "insight/analysis/queries/";

export const useAnalysisQueryIndex = () => {
  const { data } = useAPI<AnalysisQueryDTO[]>(queriesBaseURL);
  return data?.map(fromDTO);
};

export const useQueryAPI = () => {
  const { mutate: mutateKey } = useAPIConfig();
  const enqueue = useCallback(
    async (query: string) => {
      await api.post("insight/ask/enqueue/", { json: { query } });
      await mutateKey(queriesBaseURL, null);
    },
    [mutateKey]
  );

  const cancel = useCallback(
    async (queryId: string) => {
      await api.delete(`${queriesBaseURL}${queryId}`);
      await mutateKey(queriesBaseURL, null);
    },
    [mutateKey]
  );

  return { enqueue, cancel };
};

export type AnsweredQueryDTO = Omit<AnsweredQuery, "enqueued_date"> & {
  enqueued_date: string;
};

export const useAnsweredQueryData = (answerId?: string) => {
  const { data } = useAPI<AnsweredQueryDTO>(
    answerId && `${queriesBaseURL}a/${answerId}`,
    {
      keepPreviousData: true,
    }
  );

  return data ? (fromDTO(data) as AnsweredQuery) : undefined;
};
