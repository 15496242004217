import React from "react";

import AnsweredQuery from "./AnsweredQuery";

const answeredQueryBaseRoute = "queries/a";

export const answeredQueryLink = (answerId: string) =>
  `${answeredQueryBaseRoute}/${answerId}`;

const routes = [
  {
    path: answeredQueryBaseRoute,
    children: [
      {
        path: ":answerId",
        element: <AnsweredQuery />,
      },
    ],
  },
];

export default routes;
