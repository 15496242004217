import { every, values, pick } from "lodash";

import api, { useAPI, useAPIConfig } from "@/client/api";
import type { WithId } from "@/client/types";
import { baseClient } from "@/client/base";

import { useAuth } from "@/components/Auth";

import { FileJSON } from "./v2/schema";

type FileJSONRawDates = FileJSON & {
  file: string;
  created_at: string;
};

const toFileJSON = ({ file: _, created_at, ...json }: FileJSONRawDates) => ({
  ...json,
  created_at: new Date(created_at),
  uploadStatus: "uploaded",
});

export const uploadFile = async (file: File, category: string) => {
  const formData = new FormData();
  formData.append("name", file.name);
  formData.append("size", file.size.toString());
  formData.append("mime_type", file.type);
  formData.append("file", file as Blob, file.name);
  formData.append("category", category);

  try {
    // await new Promise<void>((resolve) => setTimeout(() => resolve(), 100000));
    const response = await baseClient(`/onboarding/uploads/`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      return toFileJSON(await response.json());
    }

    const body = await response.json();
    const message = `Upload of file ${file.name} failed with ${response.status}`;
    console.error(message, response.statusText, body);
  } catch (e) {
    console.error(e);
  }

  return null;
};

export const useUploadsListAPI = () => {
  const { data } = useAPI<FileJSONRawDates[]>("onboarding/uploads");
  return (data ?? []).map(toFileJSON);
};

export const useOnboardingStepAPI = <Entity extends WithId>(url: string) => {
  const fullUrl = `onboarding/${url}`;
  const { mutate: mutateKey } = useAPIConfig();
  const { data, mutate, ...other } = useAPI<Entity[]>(fullUrl);

  const createOrUpdate = async (entity: Entity) => {
    await mutate(
      async () => {
        const updatedEntity = await api.createOrUpdate(fullUrl, entity);
        mutateKey("onboarding/");
        return [updatedEntity];
      },
      {
        optimisticData: [entity],
        revalidate: false,
      }
    );
  };

  return {
    data,
    values: data?.[0],
    createOrUpdate,
    ...other,
  };
};

export interface OnboardingSteps {
  welcome: boolean;
  business_details: boolean;
  pricing: boolean;
  salesforce: boolean;
  slack: boolean;
  checkout: boolean;
  hubspot: boolean;
}

const cpqRequiredSteps: (keyof OnboardingSteps)[] = [
  // "welcome",
  "business_details",
  "pricing",
  "salesforce",
  "slack",
];

const insightsOnlyRequiredSteps: (keyof OnboardingSteps)[] = [
  // "welcome",
  "business_details",
  "pricing",
  "hubspot",
];

const useRequiredSteps = () => {
  const { session } = useAuth();
  return session.organization?.product_offering === "cpq"
    ? {
        nonPaymentRequiredSteps: cpqRequiredSteps,
        requiredSteps: cpqRequiredSteps,
      }
    : {
        nonPaymentRequiredSteps: insightsOnlyRequiredSteps,
        requiredSteps: [...insightsOnlyRequiredSteps, "checkout"],
      };
};

export interface OnboardingStatus {
  steps: OnboardingSteps;
  percentageComplete: number;
  completed: boolean;
  completedNonPaymentRequired: boolean;
  completedRequired: boolean;
}

export const useOnboardingStatusAPI = (
  localState?: OnboardingStatus | null
): OnboardingStatus | null => {
  const { nonPaymentRequiredSteps, requiredSteps } = useRequiredSteps();
  const steps = useAPI<OnboardingSteps>(
    localState?.completed ? null : "onboarding/"
  ).data;

  if (!steps) return null;

  const stepStatuses = values(steps);

  return {
    steps,
    percentageComplete: Math.round(
      (stepStatuses.filter(Boolean).length / stepStatuses.length) * 100
    ),
    completed: every(stepStatuses, Boolean),
    completedNonPaymentRequired: every(
      values(pick(steps, nonPaymentRequiredSteps)),
      Boolean
    ),
    completedRequired: every(values(pick(steps, requiredSteps)), Boolean),
  };
};

export interface SalesforcePackageInstallationJSON {
  service_user: string;
  salesforce_organization_id?: string;
  updated_at?: string;
}

const sfPackageInstallationAPIEndpoint = "salesforce/package-installation";

export const confirmSalesforcePackageInstallation = async ({
  serviceUser,
}: {
  serviceUser: string;
}) => {
  await api
    .post(sfPackageInstallationAPIEndpoint, {
      json: { service_user: serviceUser },
    })
    .json();
};

export const useSalesforcePackageInstallationAPI = () =>
  useAPI<SalesforcePackageInstallationJSON>(sfPackageInstallationAPIEndpoint);
