import React, { useState, useMemo, ReactNode, forwardRef, Ref } from "react";
import { useMultipleSelection, useCombobox } from "downshift";
import { matchSorter } from "match-sorter";
import { map } from "lodash";
import cx from "clsx";

import Avatar, { AvatarSize } from "@/components/Avatar";
import { WithId } from "@/client/types";
// @ts-ignore
import { ReactComponent as AddIcon } from "@/assets/icons/circle-plus.svg";
// @ts-ignore
import { ReactComponent as XIcon } from "@/assets/icons/small-x.svg";

export interface SlackUser extends WithId {
  id: string;
  recipient_id: string;
  email: string;
  display_name: string;
  metadata: {
    image?: string;
    title?: string;
  };
  organization: string;
}

export const filterUsers = (
  allUsers: SlackUser[],
  selectedUsers: SlackUser[],
  input: string
) => {
  const selectedIds = map(selectedUsers, "id");
  const users = allUsers.filter(({ id }) => !selectedIds.includes(id));
  if (!input) return users;

  const lowerCasedInput = input.toLowerCase();

  return matchSorter(users, lowerCasedInput, {
    keys: ["display_name", "email", "metadata.title"],
    threshold: matchSorter.rankings.WORD_STARTS_WITH,
  });
};

interface SlackUserSelectProps {
  options: SlackUser[];
  selection: SlackUser[];
  setSelection: (selection: SlackUser[]) => void;
  placeholder?: string;
  inputRef?: Ref<HTMLInputElement>;
  onBlur?: () => void;
  className?: string;
}

interface SelectedItemProps<T extends WithId> {
  item: T;
  onRemove: (item: T) => void;
  children: ReactNode;
  className?: string;
}

export const SelectedItem = forwardRef(
  <T extends WithId>(
    { item, onRemove, children, className, ...props }: SelectedItemProps<T>,
    ref: Ref<HTMLSpanElement>
  ) => (
    <span
      className={cx(
        "flex items-center bg-bg-l3 rounded-full gap-x-0.5 pr-1 group",
        "capitalize outline-none focus:bg-brand-purple-l1 cursor-pointer",
        "group-disabled/fieldset:focus:bg-bg-l3 group-disabled/fieldset:cursor-default",
        "state-transition-colors",
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
      <span
        className={cx(
          "inline-flex p-2 rounded-full cursor-pointer text-text-muted hover:text-text-link",
          "group-focus:text-text-link group-focus:hover:text-text-bright",
          "group-disabled/fieldset:text-border-bright group-disabled/fieldset:cursor-default",
          "hover:group-disabled/fieldset:text-border-bright state-transition-colors"
        )}
        onClick={(e) => {
          e.stopPropagation();
          onRemove(item);
        }}
      >
        <XIcon className="inline-block w-3" />
      </span>
    </span>
  )
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ToggleButton = forwardRef((props: any, ref: Ref<HTMLButtonElement>) => (
  <button
    type="button"
    aria-label="toggle menu"
    className={cx(
      "py-1 px-1.5 text-text-muted rounded-r-md bg-transparent",
      "hover:text-text-link group-disabled/fieldset:text-border-default",
      "state-transition-colors"
    )}
    ref={ref}
    {...props}
  >
    <AddIcon className="w-6" />
  </button>
));

interface MenuProps {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
}

const Menu = forwardRef(
  (
    { isOpen, children, className, ...props }: MenuProps,
    ref: Ref<HTMLUListElement>
  ) => (
    <ul
      className={cx(
        "absolute z-50 w-inherit overflow-auto p-0",
        "shadow-lg rounded-md bg-bg-l2 border border-border-default",
        !isOpen && "hidden",
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
    </ul>
  )
);

interface MenuItemProps {
  isHighlighted?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

const MenuItem = forwardRef(
  (
    {
      isHighlighted,
      isSelected: _,
      disabled,
      children,
      ...props
    }: MenuItemProps,
    ref: Ref<HTMLLIElement>
  ) => (
    <li
      className={cx(
        isHighlighted && "bg-bg-l3",
        disabled ? "cursor-default" : "cursor-pointer",
        "py-2 px-3 flex flex-col"
      )}
      ref={ref}
      {...props}
    >
      {children}
    </li>
  )
);

const SlackUserAvatar = ({
  user,
  size,
}: {
  user: SlackUser;
  size: AvatarSize;
}) => (
  <Avatar
    name={user.display_name || user.email}
    src={user.metadata.image}
    size={size}
  />
);

const SlackSelectedItem = ({ item }: { item: SlackUser }) => (
  <span className="inline-flex flex-row items-center pl-1 py-1 gap-1.5 select-none">
    <SlackUserAvatar user={item} size="xs" />
    <span
      className={cx(
        "text-text-bright group-focus:text-base-white",
        "group-disabled/fieldset:text-text-default state-transition-colors"
      )}
    >
      {item.display_name || item.email}
    </span>
  </span>
);

const SlackMenuItem = ({ item }: { item: SlackUser }) => (
  <span className="flex flex-row items-center gap-x-3 pr-2">
    <SlackUserAvatar user={item} size="base" />
    <span className="flex flex-col">
      <span className="text-text-bright leading-tight capitalize">
        {item.display_name || item.email}
      </span>
      <span className="text-text-default text-sm leading-tight text-gray-700">
        {item.metadata.title || item.email}
      </span>
    </span>
  </span>
);

const AutoCompleteInput = forwardRef(
  (
    {
      placeholder,
      value,
      ...props
    }: {
      placeholder: string;
      value: string;
    },
    ref: Ref<HTMLInputElement>
  ) => (
    <input
      placeholder={placeholder}
      maxLength={256}
      className={cx(
        "w-full text-text-muted focus:text-text-bright bg-transparent pl-2",
        "outline-none placeholder:text-text-muted disabled:placeholder:text-border-default",
        "state-transition-colors"
      )}
      ref={ref}
      value={value}
      tabIndex={0}
      {...props}
    />
  )
);

const SlackUserSelect = ({
  options,
  selection = [],
  setSelection,
  placeholder,
  inputRef,
  onBlur,
  className,
}: SlackUserSelectProps) => {
  const [inputValue, setInputValue] = useState("");
  const items = useMemo(
    () => filterUsers(options, selection, inputValue || ""),
    [options, selection, inputValue]
  );

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems: selection,
      onStateChange: ({ selectedItems: newSelectedItems, type }) => {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            setSelection(newSelectedItems || []);
            break;

          default:
            break;
        }
      },
    });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items,
    itemToString(item) {
      return item?.display_name || item?.email || "";
    },
    inputValue,
    selectedItem: null,

    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputFocus:
        case useCombobox.stateChangeTypes.InputChange:
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          return {
            ...changes,
            ...(changes.isOpen && { highlightedIndex: 0 }),
          };

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: 0,
          };

        default:
          return changes;
      }
    },

    onStateChange: ({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          const added = newSelectedItem ? [newSelectedItem] : [];
          setSelection([...selection, ...added]);
          setInputValue("");
          break;
        }

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue || "");
          break;

        default:
          break;
      }
    },
  });

  return (
    <div
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          // reset incomplete input, if any, if focus is leaving the component
          setInputValue("");
          onBlur?.();
        }
      }}
      className={cx(
        "flex gap-2 items-center flex-wrap p-1.5 bg-transparent input-border rounded-lg",
        className
      )}
    >
      {selection.map((item, index) => (
        <SelectedItem
          {...getSelectedItemProps({
            selectedItem: item,
            index,
          })}
          key={`selected-item-${index}`}
          item={item}
          onRemove={removeSelectedItem}
        >
          <SlackSelectedItem item={item} />
        </SelectedItem>
      ))}
      <div className="flex gap-0.5 grow">
        <AutoCompleteInput
          placeholder={placeholder}
          {...getInputProps({
            ...getDropdownProps({ ref: inputRef, preventKeyAction: false }),
          })}
        />
        <ToggleButton {...getToggleButtonProps()} />
        <Menu isOpen={isOpen} {...getMenuProps()} className="mt-12 max-h-84">
          {isOpen && items.length ? (
            items.map((item, index) => (
              <MenuItem
                key={`${item.id}${index}`}
                isHighlighted={highlightedIndex === index}
                isSelected={selectedItem === item}
                {...getItemProps({ item, index })}
              >
                <SlackMenuItem item={item} />
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <span className="text-text-muted select-none">
                {inputValue ? (
                  <span>
                    No matches for "
                    <span className="font-semibold break-all">
                      {inputValue}
                    </span>
                    " 🤔
                  </span>
                ) : (
                  "Nothing to select 🤷"
                )}
              </span>
            </MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default SlackUserSelect;
