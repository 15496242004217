import React from "react";
import { Navigate, RouteObject } from "react-router-dom";

import ProductsPage from "./ProductsPage";
import ProductPageLayout from "./ProductPageLayout";
import ProductComponents from "./ProductComponents";

import ProductPlans from "@/pages/plans/ProductPlans";

const routes = [
  { path: "plans", element: <ProductPlans /> },
  { path: "components", element: <ProductComponents /> },
];

const productRoutes = [
  {
    path: "products",
    children: [
      { index: true, element: <ProductsPage /> },
      {
        path: ":productId",
        element: <ProductPageLayout routes={routes} />,
        children: [
          {
            index: true,
            element: <Navigate to={routes[0]?.path || ""} replace />,
          } as RouteObject,
        ].concat(routes),
      },
    ],
  },
];

export default productRoutes;
