import React, {
  createContext,
  useContext,
  PropsWithChildren,
  ReactNode,
} from "react";

import LazyRender from "@/components/LazyRender";

interface ChartAnimationContextType {
  animate: boolean;
  lazyRender: boolean;
}

const ChartAnimationContext = createContext<ChartAnimationContextType>({
  animate: true,
  lazyRender: true,
});

export const ChartAnimationProvider = ({
  animate,
  lazyRender,
  children,
}: PropsWithChildren<ChartAnimationContextType>) => (
  <ChartAnimationContext.Provider value={{ animate, lazyRender }}>
    {children}
  </ChartAnimationContext.Provider>
);

export const useChartAnimationContext = () => useContext(ChartAnimationContext);

export const ChartLazyRender = ({
  children,
}: {
  children: () => ReactNode;
}) => {
  const { lazyRender } = useChartAnimationContext();
  return lazyRender ? (
    <LazyRender fadeIn={true}>{children}</LazyRender>
  ) : (
    <>{children()}</>
  );
};
