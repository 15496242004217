import blake from "blakejs";

export const slug = (str: string, { sep = "_" } = {}) =>
  str.replace(/\W+/g, sep)?.toLocaleLowerCase();

export const stripSuffix = (str: string, suffix: string) =>
  suffix && str.endsWith(suffix) ? str.slice(0, -suffix.length) : str;

export const replacePunctuation = (str: string, replacement = " ") =>
  str.replace(/[^\w]/g, replacement);

export const blake2s = (str: string) => blake.blake2sHex(str);

// interpolates a string template with basic Handlebars-like syntax
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const interpolate = (str: string, args: Record<string, any>) =>
  Object.entries(args).reduce(
    (result, [key, val]) => result.replace(`{{${key}}}`, `${val}`),
    str
  );
