import React from "react";

import z, { Infer } from "myzod";

import { useAPI } from "@/client/api";

import { Choice } from "@/components/forms";

import { useOnboardingStepAPI } from "@/pages/onboarding/api";

import Select from "@/components/easy-form/Select";
import MultiChoice from "@/components/easy-form/MultiChoice";

import useEasyForm from "@/components/easy-form";

import { useStepContext } from "./StepsLayout";

const schema = z.object({
  id: z.string().optional(),
  company_size: z.string(),
  current_yearly_revenue: z.string(),
  priorities: z.array(z.string()).min(1),
});

type BusinessDetailsJSON = Infer<typeof schema>;

interface BusinessDetailsChoices {
  industry_choices: Choice<string>[];
  size_choices: Choice<string>[];
  revenue_choices: Choice<string>[];
}

const BusinessDetails = () => {
  const { Panel } = useStepContext();

  const { values, createOrUpdate } =
    useOnboardingStepAPI<BusinessDetailsJSON>("business-details/");

  const { data: choices } = useAPI<BusinessDetailsChoices>(
    "onboarding/business-details/choices/"
  );

  const { register, handleSubmit } = useEasyForm<BusinessDetailsJSON>({
    values,
    schema,
  });

  return (
    <Panel
      handleSubmit={(event, { next }) =>
        handleSubmit(async (data) => {
          await createOrUpdate(data);
          next();
        })(event)
      }
    >
      <div className="flex flex-col flex-grow gap-9">
        <Select
          {...register("company_size")}
          options={choices?.size_choices || []}
        />

        <Select
          {...register("current_yearly_revenue")}
          options={choices?.revenue_choices || []}
        />

        <MultiChoice
          {...register("priorities")}
          options={[
            "CLOSE DEALS",
            "INCREASE MARGINS",
            "RELIABLE SOURCE",
            "ALL ABOVE",
            "OTHER",
          ]}
        />
      </div>
    </Panel>
  );
};

export default BusinessDetails;
