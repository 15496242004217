import React from "react";

import FormattedList, { ListType } from "@/components/FormattedList";

export interface RuleBlockUser {
  id: string;
  name: string;
  title: string;
}

const User = ({ name, title }: RuleBlockUser) => (
  <>
    <span className="capitalize font-semibold text-text-bright">{name}</span>
    {title && (
      <>
        {" "}
        (<span className="text-text-default">{title}</span>)
      </>
    )}
  </>
);

export const UserList = ({
  users,
  listType,
}: {
  users: RuleBlockUser[];
  listType: ListType;
}) => (
  <FormattedList listType={listType}>
    {users.map((user) => (
      <User key={user.id} {...user} />
    ))}
  </FormattedList>
);

export type { ListType } from "@/components/FormattedList";
