import React, { useCallback } from "react";
import {
  useFieldArray,
  useWatch,
  Control,
  UseFormRegister,
  UseFormSetValue,
  FieldValues,
  FieldPathByValue,
} from "react-hook-form";

import cx from "clsx";

// @ts-ignore

import Label from "@/components/input/Label";
import OptionsSwitch from "@/components/input/OptionsSwitch";
import TextInput from "@/components/input/TextInput";
import NumericInput from "@/components/input/NumericInput";
import { DeleteItemButton } from "@/components/input/ActionButtons";

import useText, { Text, TranslationScope } from "@/components/hooks/useText";

import { EditablePlan } from "@/pages/plans/models/planset";
import {
  PricingType,
  PricingFormula,
} from "@/pages/plans/models/serialization";

import {
  PRICING_TYPES,
  UNIT_PRICING_FORMULAS,
  USAGE_PRICING_FORMULAS,
  DEFAULT_PRICING_UNIT,
  DEFAULT_USAGE_METRIC,
} from "../models/pricing-plan";

export interface NestedEditorProps<Values extends FieldValues> {
  index: number;
  register: UseFormRegister<Values>;
  control: Control<Values>;
  setValue: UseFormSetValue<Values>;
}

const PriceInput = <
  Name extends FieldPathByValue<EditablePlan, number | null | undefined>
>({
  control,
  name,
  perUnit,
  className,
}: {
  control: Control<EditablePlan>;
  name: Name;
  perUnit?: boolean;
  className?: string;
}) => {
  const unit =
    useWatch({
      control,
      name: `pricing_unit`,
      disabled: !perUnit,
    }) || DEFAULT_PRICING_UNIT;

  return (
    <NumericInput
      {...{ name, control, className }}
      required={true}
      options={{
        valueType: "currency",
        asPrice: perUnit ? { perUnit: unit } : true,
      }}
    />
  );
};

const UsageTierPriceInput = <
  Name extends FieldPathByValue<EditablePlan, number | null | undefined>
>({
  index,
  control,
  name,
  className,
}: {
  index: number;
  control: Control<EditablePlan>;
  name: Name;
  className?: string;
}) => {
  const usageMetric =
    useWatch({
      control,
      name: `prices.${index}.usage_metric_name`,
    }) || DEFAULT_USAGE_METRIC;
  return (
    <NumericInput
      {...{ name, control, className }}
      required={true}
      options={{
        valueType: "currency",
        asPrice: { perUnit: usageMetric },
      }}
    />
  );
};

const PriceTier = ({
  index,
  tierIndex,
  control,
  boundEditable,
  onBoundChange,
  stairstep,
  usage,
}: {
  index: number;
  tierIndex: number;
  control: Control<EditablePlan>;
  boundEditable: boolean;
  onBoundChange?: (value: number | null, tierIndex: number) => void;
  stairstep?: boolean;
  usage?: boolean;
}) => {
  const t = useText();
  const lowerBound = useWatch({
    name: `prices.${index}.tiers.${tierIndex}.lower_bound`,
    control,
  });

  const handleUpperBoundBlur = useCallback(
    (value: number | null) => {
      if ((value ?? 0) >= lowerBound) onBoundChange?.(value, tierIndex);
    },
    [onBoundChange, tierIndex, lowerBound]
  );

  return (
    <>
      <td>
        <NumericInput
          name={`prices.${index}.tiers.${tierIndex}.lower_bound`}
          control={control}
          disabled={true}
          className="ui-disabled:bg-bg-l3"
          inputClassName="disabled:!text-text-default"
        />
      </td>
      <td>
        <NumericInput
          name={`prices.${index}.tiers.${tierIndex}.upper_bound`}
          control={control}
          placeholder={t("upper_bound.placeholder")}
          options={{ valueType: "decimal", decimalScale: 0 }}
          disabled={!boundEditable}
          onBlur={handleUpperBoundBlur}
          className="ui-disabled:bg-bg-l3"
          inputClassName="disabled:!text-text-default"
        />
      </td>
      <td>
        {usage ? (
          <UsageTierPriceInput
            {...{
              index,
              control,
              name: `prices.${index}.tiers.${tierIndex}.tier_price`,
            }}
          />
        ) : (
          <PriceInput
            {...{
              control,
              name: `prices.${index}.tiers.${tierIndex}.tier_price`,
              perUnit: !stairstep,
            }}
          />
        )}
      </td>
    </>
  );
};

const TierTableHeader = ({ stairstep }: { stairstep?: boolean }) => (
  <thead>
    <tr>
      {[
        ["lower_bound", "w-[26%]"],
        ["upper_bound", "w-[26%]"],
        [stairstep ? "price_per_step" : "price_per_unit", "w-[46%]"],
      ].map(([key, width]) => (
        <th key={key} scope="col" className={cx("align-top", width)}>
          <div className="font-medium text-left text-text-bright">
            <Text i18nKey={`${key}.title`} />
          </div>
          <div className="font-normal text-sm text-left text-text-default leading-tight">
            <Text i18nKey={`${key}.subtitle`} />
          </div>
        </th>
      ))}
      <th className="w-[2%]">
        {/* Spacer to keep the column width consistent regardless of the presence of 
          the delete row button */}
        <div className="w-10" />
      </th>
    </tr>
  </thead>
);

const TieredPricingEditor = ({
  index,
  control,
  setValue,
  stairstep,
  usage,
}: {
  index: number;
  control: Control<EditablePlan>;
  setValue: UseFormSetValue<EditablePlan>;
  stairstep?: boolean;
  usage?: boolean;
}) => {
  const {
    fields: tiers,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `prices.${index}.tiers`,
  });

  const onBoundChange = useCallback(
    (value: number | null, tierIndex: number) => {
      if (value === null) return;
      if (tierIndex === tiers.length - 1) {
        append({
          lower_bound: value + 1,
          // Here and below, `NaN`s should be `null`s, but a `null` doesn't reset the `NumericInput`'s value;
          // `NaN` does
          upper_bound: NaN,
          tier_price: NaN,
        });
      }
    },
    [append, tiers.length]
  );

  const handleDelete = (tierIndex: number) => {
    remove(tierIndex);
    setValue(`prices.${index}.tiers.${tierIndex - 1}.upper_bound`, NaN);
  };

  return (
    <div>
      <TranslationScope keyPrefix="pricing_tiers.columns">
        <table className="border-separate border-spacing-x-3 border-spacing-y-2 -ml-1 -mr-3 -mt-2">
          <TierTableHeader {...{ stairstep }} />
          <tbody>
            {tiers.map((tier, tierIndex) => (
              <tr key={tier.id}>
                <PriceTier
                  {...{
                    index,
                    tierIndex,
                    control,
                    stairstep,
                    boundEditable: tierIndex === tiers.length - 1,
                    onBoundChange,
                    usage,
                  }}
                />
                <td>
                  {tierIndex !== 0 && tierIndex === tiers.length - 1 && (
                    <DeleteItemButton
                      onClick={() => handleDelete(tierIndex)}
                      className="-ml-2"
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TranslationScope>
    </div>
  );
};

const SingleRatePricingEditor = ({
  index,
  control,
}: {
  index: number;
  control: Control<EditablePlan>;
}) => (
  <div className="flex flex-col items-start gap-2">
    <Label i18nKey="single_tier.label" />
    <PriceInput
      {...{
        control,
        name: `prices.${index}.default_amount`,
        perUnit: true,
        className: "max-w-[16rem]",
      }}
    />
  </div>
);

const PerUnitPricingFormula = ({
  index,
  control,
  ...props
}: NestedEditorProps<EditablePlan>) => {
  const pricingFormula = useWatch({
    control,
    name: `prices.${index}.pricing_formula`,
  });

  switch (pricingFormula) {
    case PricingFormula.SINGLE_RATE:
      return <SingleRatePricingEditor {...{ index, control }} />;

    case PricingFormula.TIERED:
    case PricingFormula.VOLUME:
      return <TieredPricingEditor {...{ index, control, ...props }} />;

    case PricingFormula.STAIRSTEP:
      return (
        <TieredPricingEditor
          {...{ index, control, ...props, stairstep: true }}
        />
      );
  }

  return null;
};

const UnitNameDescription = ({
  control,
}: {
  control: Control<EditablePlan>;
}) => {
  const prices = useWatch({ control, name: `prices` }) ?? [];
  const unitBasedPrices = prices.filter(
    ({ pricing_type }) => pricing_type === PricingType.UNIT
  );

  return unitBasedPrices.length > 1 ? (
    <div className="text-text-muted text-sm select-none">
      <Text i18nKey="unit.description" />
    </div>
  ) : null;
};

const PerUnitPricingEditor = ({
  index,
  register,
  control,
  ...props
}: NestedEditorProps<EditablePlan>) => {
  const t = useText();

  return (
    <>
      <div className="flex flex-col items-start gap-2">
        <Label i18nKey="unit.label" />
        <div className="flex flex-col gap-1.5">
          <TextInput
            {...register(`pricing_unit`)}
            maxLength={32}
            placeholder={t("unit.placeholder")}
            className="leading-tight p-2 grow"
          />
          <UnitNameDescription {...{ control }} />
        </div>
      </div>

      <div className="flex flex-col items-start gap-2">
        <Label i18nKey="pricing_formula.label" />
        <OptionsSwitch
          name={`prices.${index}.pricing_formula`}
          options={UNIT_PRICING_FORMULAS}
          control={control}
          keyPrefix="pricing_formula.options"
          className="bg-bg-default"
        />
      </div>

      <PerUnitPricingFormula {...{ index, register, control, ...props }} />
    </>
  );
};

const FlatRatePricingEditor = ({
  index,
  control,
}: {
  index: number;
  control: Control<EditablePlan>;
}) => (
  <div className="flex flex-col items-start gap-2">
    <Label i18nKey="flatrate.label" />
    <PriceInput
      {...{
        control,
        name: `prices.${index}.default_amount`,
        className: "max-w-[16rem]",
      }}
    />
  </div>
);

const PricingModelEditor = ({
  index,
  control,
  ...props
}: NestedEditorProps<EditablePlan>) => {
  const pricingType = useWatch({
    control,
    name: `prices.${index}.pricing_type`,
  });

  switch (pricingType) {
    case PricingType.UNIT:
      return <PerUnitPricingEditor {...{ index, control, ...props }} />;

    case PricingType.FLAT_RATE:
      return <FlatRatePricingEditor {...{ index, control }} />;

    case PricingType.USAGE:
      return <UsagePricingEditor {...{ index, control, ...props }} />;
  }
};

const PlanPricePanel = ({
  index,
  control,
  ...props
}: NestedEditorProps<EditablePlan>) => (
  <TranslationScope ns="common" keyPrefix="plans.pricing">
    <div className="flex flex-col grow gap-6">
      <div className="flex flex-col items-start gap-2">
        <Label i18nKey="pricing_type.label" />
        <OptionsSwitch
          name={`prices.${index}.pricing_type`}
          options={PRICING_TYPES}
          control={control}
          keyPrefix="pricing_type.options"
          className="bg-bg-default"
        />
      </div>
      <PricingModelEditor {...{ index, control, ...props }} />
    </div>
  </TranslationScope>
);

const UsageBasedPricingFormula = ({
  index,
  control,
  ...props
}: NestedEditorProps<EditablePlan>) => {
  const pricingFormula = useWatch({
    control,
    name: `prices.${index}.pricing_formula`,
  });

  switch (pricingFormula) {
    case PricingFormula.PERCENTAGE:
      return <PercentRatePricingEditor {...{ index, control }} />;

    case PricingFormula.VOLUME:
      return (
        <>
          <Label i18nKey="usage_volume_tier.label" />
          <TieredPricingEditor {...{ index, control, usage: true, ...props }} />
        </>
      );
  }

  return null;
};

const PercentRatePricingEditor = ({
  index,
  control,
}: {
  index: number;
  control: Control<EditablePlan>;
}) => (
  <div className="flex flex-col items-start gap-2">
    <Label i18nKey="usage_percentage_tier.label" />
    <NumericInput
      {...{
        control,
        name: `prices.${index}.default_percent_rate`,
        options: { valueType: "percent" },
        className: "max-w-[16rem]",
      }}
    />
  </div>
);

const UsagePricingEditor = ({
  index,
  register,
  control,
  ...props
}: NestedEditorProps<EditablePlan>) => {
  const t = useText();

  return (
    <>
      <div className="flex flex-col items-start gap-2">
        <Label i18nKey="usage.label" />
        <div className="flex flex-col gap-1.5">
          <TextInput
            {...register(`prices.${index}.usage_metric_name`)}
            maxLength={32}
            placeholder={t("usage.placeholder")}
            className="leading-tight p-2 grow"
          />
          <div className="text-text-muted text-sm select-none">
            <Text i18nKey="usage.description" />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start gap-2">
        <Label i18nKey="usage_formula.label" />
        <OptionsSwitch
          name={`prices.${index}.pricing_formula`}
          options={USAGE_PRICING_FORMULAS}
          control={control}
          keyPrefix="usage_formula.options"
          className="bg-bg-default"
        />
      </div>

      <UsageBasedPricingFormula {...{ index, register, control, ...props }} />
    </>
  );
};

export default PlanPricePanel;
