import React, { useMemo } from "react";
import {
  useForm,
  useFieldArray,
  useWatch,
  Control,
  FieldPath,
} from "react-hook-form";

import { last, keyBy, zipObject, some, isNil } from "lodash";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as PlusSign } from "@/assets/icons/plus.svg";

import Label from "@/components/input/Label";
import TextInput from "@/components/input/TextInput";
import Form from "@/components/input/Form";
import { DeleteItemButton } from "@/components/input/ActionButtons";
import { EditingButtons } from "@/components/EditableCard";

import useText, { useTranslationScope } from "@/components/hooks/useText";

import PriceRuleSubjectSelect from "../components/PriceRuleSubjectSelect";
import ProductPlanSelect from "../components/ProductPlanSelect";
import ProductPlanComponentSelect from "../components/ProductPlanComponentSelect";
import ConditionBlock from "../components/ConditionBlock";
import ApprovalsBlock from "../components/ApprovalsBlock";

import { useProductPlansListAPI } from "@/pages/plans/models/api";

import {
  IPriceRule,
  PriceRule,
  PriceRuleBlockType,
  PriceRuleSubject,
  PriceRuleEditingOptions,
  priceRuleEditingOptions,
} from "../models/price-rules";

import { SaveRuleCallback } from "../models/api";
import { usePriceRuleRefs, hydrateRule } from "../models/hydration";

export interface PriceRuleEditorProps {
  rule: PriceRule;
  saveRule: SaveRuleCallback;
  onCancel?: () => void;
  onSubmit?: () => void;
}

const AddBlockButtons = ({
  blockTypes,
  appendBlock,
}: {
  blockTypes: PriceRuleBlockType[];
  appendBlock: (type: PriceRuleBlockType) => void;
}) => {
  const t = useText();
  return (
    <div className="flex gap-2">
      {blockTypes.map((type) => (
        <button
          type="button"
          className={cx(
            "group flex items-center gap-2 py-2 px-3 rounded-md text-text-default",
            "border border-border-default hover:text-text-link hover:border-border-bright"
          )}
          key={type}
          onClick={() => appendBlock(type)}
        >
          <PlusSign className="w-4 h-4 text-text-mute group-hover:text-text-link" />
          <span>{t(`action.add_block.${type}`)}</span>
        </button>
      ))}
    </div>
  );
};

const PriceRuleBlocks = ({
  control,
  ruleType,
  subjectFields,
  multiBlock,
  operatorList,
}: PriceRuleEditingOptions & {
  control: Control<IPriceRule>;
}) => {
  const t = useText();
  const {
    fields: blocks,
    append,
    remove,
  } = useFieldArray({ control, name: "rules" });

  const subjectValues = useWatch({ control, name: subjectFields });
  const subject = zipObject(subjectFields, subjectValues);

  const lastBlock = useWatch({ control, name: `rules.${blocks.length - 1}` });
  const appendBlock = (type: PriceRuleBlockType) => {
    const lastBlockValue = last(lastBlock?.values);
    const fromValue = isNil(lastBlockValue) ? undefined : lastBlockValue;
    append(PriceRule.newRuleBlock(type, [fromValue]), {
      focusName: `rules.${blocks.length}.values.0`,
    });
  };

  const allowedBlockTypes: PriceRuleBlockType[] = !lastBlock
    ? ["lt", "between"]
    : lastBlock?.type !== "gte"
    ? ["between", "gte"]
    : [];

  if (some(subjectValues, isNil)) return null;

  return (
    <div className="flex flex-col gap-2">
      <Label>
        {t(!multiBlock && !operatorList ? "if.fixed" : "if.variable", {
          subject: t([`if.subject_${ruleType}`, "if.subject"], subject),
          relation: t(`block_type.${blocks[0]?.type}`),
        })}
      </Label>
      <ul className="flex flex-col gap-2">
        {blocks.map((block, index) => (
          <li
            key={block.id}
            className={cx(
              "flex flex-col gap-4",
              multiBlock && "p-4 bg-bg-l2 rounded-lg"
            )}
          >
            <div className="flex justify-between gap-3">
              <ConditionBlock
                index={index}
                inputOptions={PriceRule.inputOptions({
                  type: ruleType,
                  ...subject,
                })}
                operatorList={operatorList}
                operatorLabel={multiBlock}
                control={control}
              />
              {multiBlock && <DeleteItemButton onClick={() => remove(index)} />}
            </div>
            <ApprovalsBlock index={index} control={control} />
          </li>
        ))}
      </ul>
      {multiBlock && (
        <AddBlockButtons
          blockTypes={allowedBlockTypes}
          appendBlock={appendBlock}
        />
      )}
    </div>
  );
};

const PlanSelect = ({ control }: { control: Control<IPriceRule> }) => {
  const plans = useProductPlansListAPI();
  const t = useText();
  return (
    <div className="flex flex-col gap-2">
      <Label>{t("plan.label")}</Label>
      <div className="flex gap-2">
        <ProductPlanSelect
          plans={plans}
          name="plan"
          control={control}
          placeholder={t("plan.placeholder")}
          required={true}
          className="grow"
        />
      </div>
    </div>
  );
};

const PlanComponentSelect = ({ control }: { control: Control<IPriceRule> }) => {
  const plans = useProductPlansListAPI();
  const t = useText();
  const plansById = useMemo(() => keyBy(plans, "id"), [plans]);
  const plan = useWatch({ control, name: "plan" });

  return (
    <>
      <div className="flex flex-col gap-2">
        <Label>{t("plan_component.label")}</Label>
        <div className="flex gap-2">
          <ProductPlanSelect
            plans={plans}
            name="plan"
            control={control}
            placeholder={t("plan_component.plan_placeholder")}
            required={true}
          />
          <ProductPlanComponentSelect
            planId={plan?.id}
            productId={plansById[plan?.id ?? ""]?.product_id}
            name="planComponent"
            placeholder={t("plan_component.component_placeholder")}
            control={control}
            disabled={!plan?.id}
            className="grow"
          />
        </div>
      </div>
    </>
  );
};

const SubjectSelect = ({
  options,
  control,
  subjectFields,
}: {
  options: PriceRuleSubject[];
  control: Control<IPriceRule>;
  subjectFields: FieldPath<IPriceRule>[];
}) => {
  const t = useText();
  return (
    <>
      {subjectFields.includes("subject") && (
        <div className="flex flex-col gap-2">
          <Label>{t("subject.label")}</Label>
          <PriceRuleSubjectSelect
            options={options}
            name="subject"
            control={control}
            placeholder={t("subject.placeholder")}
            required={true}
          />
        </div>
      )}
      {subjectFields.includes("plan") &&
        (subjectFields.includes("planComponent") ? (
          <PlanComponentSelect control={control} />
        ) : (
          <PlanSelect control={control} />
        ))}
    </>
  );
};

export const PriceRuleEditor = ({
  rule,
  saveRule,
  onCancel,
  onSubmit,
}: PriceRuleEditorProps) => {
  const { t, TranslationScope } = useTranslationScope("price-rules", {
    keyPrefix: "rule",
  });

  const refs = usePriceRuleRefs();

  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({ values: hydrateRule(rule, refs) });

  const saveAndSubmit = async (data: IPriceRule) => {
    await saveRule(data, { forceSync: true });
    onSubmit?.();
  };

  const editingOptions = priceRuleEditingOptions(rule.type);
  return (
    <TranslationScope>
      <Form
        onSubmit={handleSubmit(saveAndSubmit)}
        disabled={isSubmitting}
        className="flex flex-col gap-5"
      >
        <TextInput
          {...register("name", { required: true })}
          maxLength={128}
          placeholder={t("name.placeholder")}
          className={cx(
            "text-3xl font-semibold leading-tight py-2 px-3",
            errors.name && "!border-border-error"
          )}
          autoSelect
        />

        <SubjectSelect
          options={rule.subjectOptions}
          control={control}
          {...editingOptions}
        />
        <PriceRuleBlocks control={control} {...editingOptions} />

        <EditingButtons
          control={control}
          cancelEditing={() => onCancel?.()}
          isDraft={rule.isNew()}
          isSubmitting={isSubmitting}
          required={["name", ...editingOptions.subjectFields]}
          t={t}
        />
      </Form>
    </TranslationScope>
  );
};

export default PriceRuleEditor;
