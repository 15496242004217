import React, { useMemo } from "react";
import { useTheme } from "@nivo/core";
import { AnchoredContinuousColorsLegendProps } from "@nivo/legends";
import { ContinuousColorScaleConfig } from "@nivo/colors";
import { HeatMap } from "@nivo/heatmap";
import cx from "clsx";

import { MetricDatum, ChartPresentationProps, ChartProps } from "./types";
import { metricFormatter } from "./format";
import { useChartAnimationContext } from "./animation";
import { useAxes, useAxisTransitions, YAxisLabel, XAxisLabel } from "./XYChart";
import { toHeatmapData } from "./Heatmap2Data";

export type Heatmap2Props = ChartProps<ChartPresentationProps, MetricDatum[]>;

type LegendProps = Omit<
  AnchoredContinuousColorsLegendProps,
  "scale" | "containerWidth" | "containerHeight"
>;

const legendsProps: LegendProps = {
  anchor: "bottom",
  translateX: 0,
  translateY: 20,
  thickness: 8,
  direction: "row",
  tickPosition: "after",
  tickSize: 3,
  tickSpacing: 4,
  tickOverlap: false,
  titleAlign: "start",
  titleOffset: 4,
};

export const Heatmap2 = ({ id, presentation, data }: Heatmap2Props) => {
  const { indexBy, metricType, orientation }: ChartPresentationProps = {
    metricType: "currency",
    orientation: "horizontal",
    ...presentation,
  };

  const {
    target,
    size,
    yAxis: axisLeft,
    xAxis: axisTop,
    yAxisLabel,
    xAxisLabel,
    margin,
  } = useAxes({
    id,
    data,
    indexBy,
    orientation,
  });

  const valueFormat = metricFormatter(metricType, false);
  const tickFormat = metricFormatter(metricType, true);
  const colors = useMemo<ContinuousColorScaleConfig>(
    () => ({
      type: "sequential",
      scheme: "blue_green",
      ...(metricType === "percentage" ? { minValue: 0, maxValue: 1 } : {}),
    }),
    [metricType]
  );

  const theme = useTheme();
  const heatMapData = useMemo(
    () => toHeatmapData(data, indexBy),
    [data, indexBy]
  );

  const chartMargin = useMemo(
    () => ({ left: margin.x, top: margin.y, bottom: 40 }),
    [margin.x, margin.y]
  );

  const legends = useMemo<LegendProps[]>(
    () => [
      {
        length: Math.min((size?.width ?? 0) / 2, 400),
        tickFormat,
        ...legendsProps,
      },
    ],
    [tickFormat, size?.width]
  );

  const axisTransitions = useAxisTransitions({ size });
  const { animate } = useChartAnimationContext();

  return (
    <div
      className={cx(
        "@container/chart relative grid [grid-template-columns:auto_1fr] [grid-template-rows:auto_1fr]",
        "gap-x-3 gap-y-2 h-full w-full"
      )}
    >
      <div></div>
      <XAxisLabel
        {...{ theme, marginLeft: margin.x, className: axisTransitions }}
      >
        {xAxisLabel}
      </XAxisLabel>
      <YAxisLabel
        {...{ theme, marginTop: margin.y, className: axisTransitions }}
      >
        {yAxisLabel}
      </YAxisLabel>
      <div ref={target} className="relative w-full h-full">
        <div className="absolute svg-child-overflow-visible">
          {size ? (
            <HeatMap
              {...{
                theme,
                data: heatMapData,
                valueFormat,
                colors,
                axisLeft,
                axisTop,
                margin: chartMargin,
                legends,
                ...size,
                animate,
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Heatmap2;
