import * as echarts from "echarts/core";
import { transparentize } from "color2k";

import tw from "@/styles/tailwind";

export const THEME_NAME = "maca";

const colors = tw.theme?.colors;

const themeColorRange = (baseKey: string, intensity = 9) =>
  ["50", "100", "200", "300", "400", "500", "600", "700", "800", "900"]
    .slice(0, intensity)
    .map((key) => colors?.base[baseKey][key]);

export const THEME_COLOR_RANGE = {
  red: themeColorRange("red", 6),
  orange: themeColorRange("orange", 6),
  green: themeColorRange("green", 6),
  teal: themeColorRange("teal", 6),
  indigo: themeColorRange("indigo", 7),
  pink: themeColorRange("pink", 6),
  gray: [
    colors.base.white,
    colors.text.bright,
    colors.text.default,
    colors.text.muted,
  ],
};

// can't use CSS variables here, echart always uses canvas' `measureText` to calculate text width
// regardless of the specified renderer
const fontFamily = "Inter, system-ui, sans-serif";

const axisTheme = {
  nameLocation: "middle",
  nameLayout: "auto",
  nameGap: 16,
  nameTextStyle: {
    fontFamily,
    fontSize: 12,
    color: colors?.text.default,
  },
  axisLine: {
    show: true,
    lineStyle: {
      color: colors?.border.bright,
    },
  },
  axisTick: {
    show: true,
    alignWithLabel: true,
    lineStyle: {
      color: colors?.text.default,
    },
  },
  axisLabel: {
    show: true,
    fontFamily,
    fontSize: 11,
    color: colors?.text.bright,
  },
  splitLine: {
    show: false,
    lineStyle: {
      color: [colors?.text.default],
    },
  },
  splitArea: {
    show: false,
    areaStyle: {
      color: [
        transparentize(colors?.bg.l250, 0.7),
        transparentize(colors?.bg.l2, 0.8),
      ],
    },
  },
};

echarts.registerTheme(THEME_NAME, {
  backgroundColor: colors?.transparent,
  textStyle: {
    fontFamily,
  },
  grid: {
    left: 0,
    top: 10,
    right: 10,
    bottom: 0,
    containLabel: true,
  },
  categoryAxis: axisTheme,
  valueAxis: axisTheme,
  logAxis: axisTheme,
  timeAxis: axisTheme,
  legend: {
    inactiveColor: colors?.text.muted,
    textStyle: {
      fontFamily,
      overflow: "break",
      color: colors?.text.default,
    },
  },
  tooltip: {
    backgroundColor: transparentize(colors?.bg.d1, 0.15),
    borderColor: colors?.bg.d1,
    textStyle: {
      fontFamily,
      color: colors?.text.bright,
      fontSize: 12,
    },
    axisPointer: {
      shadowStyle: {
        color: transparentize(colors?.text.bright, 0.95),
      },
      lineStyle: {
        color: colors?.border.bright,
        width: 1,
      },
      crossStyle: {
        color: colors?.border.bright,
        width: 1,
      },
    },
  },
  visualMap: {
    handleSize: "110%",
    textStyle: {
      fontFamily,
      color: colors?.text.bright,
      fontSize: 12,
      lineHeight: 16,
    },
    inRange: {
      color: THEME_COLOR_RANGE["indigo"],
    },
  },
  dataZoom: {
    backgroundColor: "rgba(255,255,255,0)",
    dataBackgroundColor: "rgba(114,204,255,1)",
    fillerColor: "rgba(114,204,255,0.2)",
    handleColor: "#72ccff",
    handleSize: "100%",
    textStyle: {
      color: "#333333",
    },
  },
  markPoint: {
    label: {
      color: "#293441",
    },
    emphasis: {
      label: {
        color: "#293441",
      },
    },
  },
});
