import React, { useCallback, useState, useMemo, useEffect } from "react";

//@ts-ignore
import { ReactComponent as SparksIcon } from "../icons/sparks.svg";
//@ts-ignore
import { ReactComponent as ChatIcon } from "../icons/chat-bubble.svg";
//@ts-ignore
import { ReactComponent as CircleCheck } from "@/assets/icons/circle-check.svg";

import format from "@/lib/format";

import api from "@/client/api";

import { Text, TranslationScope } from "@/components/hooks/useText";
import { ActionButton } from "@/components/CoreButtons";

import { ChartDataProvider } from "@/components/insights/ChartData";
import ChartWidget2 from "@/components/insights/ChartWidget2";

import { useQueryAPI } from "@/pages/analysis/queries/api";

type EmptyAnswerJSON = {
  id: undefined;
  query: string;
  timestamp: Date;
};

type AnswerJSON = {
  id: string;
  query: string;
  timestamp: Date;
  recommendation: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>;
  charts?: string[];
};

type QueryAnswerJSON = EmptyAnswerJSON | AnswerJSON;
type QueryAnswerDTO = Omit<QueryAnswerJSON, "timestamp"> & {
  timestamp: string;
};

export const useAskMaca = (query: string) => {
  const [answer, setAnswer] = useState<null | QueryAnswerJSON>(null);
  const askMaca = useCallback(async (query: string) => {
    try {
      const { timestamp, ...answer } = await api
        .post("insight/ask/", { json: { query } })
        .json<QueryAnswerDTO>();

      setAnswer({
        ...answer,
        timestamp: new Date(timestamp),
      } as QueryAnswerJSON);
    } catch (x) {
      console.error(x);
    }
  }, []);

  useEffect(() => {
    if (answer && query && answer.query !== query) {
      setAnswer(null);
    }
  }, [query, answer, setAnswer]);

  const answers = useMemo(
    () =>
      answer?.id !== undefined ? (
        <Answer {...answer} key="answers" />
      ) : answer ? (
        <EnqueuePrompt {...answer} key="enqueue_prompt" />
      ) : null,
    [answer]
  );

  return { askMaca, answers };
};

const AnswerTitle = ({
  query,
  timestamp,
}: {
  query: string;
  timestamp: Date;
}) => (
  <header className="flex gap-3">
    <SparksIcon className="w-7 aspect-square shrink-0 text-text-link" />
    <div className="flex flex-col flex-grow leading-tight">
      <div className="flex gap-3 justify-between">
        <div className="text-text-bright font-medium">
          <Text i18nKey="title" />
        </div>
        <div className="text-text-default text-sm">
          {format.timestamp(timestamp)}
        </div>
      </div>
      <div className="text-text-default text-sm">
        <Text
          i18nKey="subtitle"
          values={{ query }}
          components={[<span className="font-medium" />]}
        />
      </div>
    </div>
  </header>
);

const ChartEmbed = ({ id }: { id: string }) => (
  <TranslationScope ns="insights">
    <ChartDataProvider routeKey={"__answers__"}>
      <ChartWidget2
        {...{ id }}
        className="h-[400px] w-full bg-bg-l2 p-4 rounded-lg"
      />
    </ChartDataProvider>
  </TranslationScope>
);

export const Answer = ({
  id,
  query,
  timestamp,
  values,
  charts,
}: AnswerJSON) => (
  <TranslationScope keyPrefix={id}>
    <section className="flex flex-col gap-2 bg-bg-l3 rounded-lg px-4 pt-3 pb-4">
      <AnswerTitle {...{ query, timestamp }} />
      <div className="flex flex-col gap-4">
        <div className="text-text-bright">
          <Text
            i18nKey="body"
            {...{ values }}
            components={[<span className="font-medium" />]}
          />
        </div>
        {charts?.map((id) => (
          <ChartEmbed {...{ id }} key={id} />
        ))}
      </div>
    </section>
  </TranslationScope>
);

export const EnqueuePrompt = ({ query }: EmptyAnswerJSON) => {
  const [enqueued, setEnqueued] = useState(false);
  const { enqueue } = useQueryAPI();
  const handleEnqueue = useCallback(async () => {
    try {
      await enqueue(query);
      setEnqueued(true);
    } catch (x) {
      console.error(x);
    }
  }, [enqueue, query]);

  return (
    <section className="flex gap-4 items-start bg-bg-l3 rounded-lg px-4 pt-3 pb-4">
      <ChatIcon className="w-7 aspect-square shrink-0 text-brand-purple-l3 mt-1.5" />
      <div className="flex flex-col gap-2 flex-grow leading-tight">
        <div className="flex justify-between">
          <div className="text-text-bright max-w-2xl leading-tight">
            <Text i18nKey={enqueued ? "enqueued" : "prompt"} />
          </div>
          <ActionButton
            onClick={handleEnqueue}
            disabled={enqueued}
            className="disabled:!bg-bg-l4"
          >
            {enqueued ? (
              <div className="flex items-center text-text-success gap-2">
                <CircleCheck className="w-6 shrink-0 -ml-1" />
                <Text i18nKey="enqueued_badge" />
              </div>
            ) : (
              <Text i18nKey="cta" />
            )}
          </ActionButton>
        </div>
        <div className="text-text-default text-sm leading-tight">
          <Text
            i18nKey="subtitle"
            values={{ query }}
            components={[<span className="font-medium" />]}
          />
        </div>
      </div>
    </section>
  );
};
