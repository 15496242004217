import React, { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

import NotFound from "@/pages/NotFound";

import { useDashboardInfoAPI } from "./api";
import InsightsPending from "./InsightsPending";

const InsightsDynamicRoutes = () => {
  const dashboardInfo = useDashboardInfoAPI();
  if (!dashboardInfo) {
    return null;
  }

  const { status, type, custom_key, routes } = dashboardInfo;
  if (status === "draft") {
    return <InsightsPending />;
  }

  const InsightsRoot = lazy(() =>
    type === "custom"
      ? import(`./custom/${custom_key}/routes`)
      : type === "data_driven"
      ? import("./dashboard/routes")
      : import("./demo/routes")
  );

  return (
    <ErrorBoundary fallback={<NotFound />}>
      <Suspense>
        <InsightsRoot {...{ routes }} />
      </Suspense>
    </ErrorBoundary>
  );
};

const routes = [{ path: "insights/*", element: <InsightsDynamicRoutes /> }];

export default routes;
