import React, { useMemo } from "react";
import { useController, FieldValues, FieldPathByValue } from "react-hook-form";
import { keyBy, sortBy } from "lodash";

import { CustomInputProps } from "@/components/input/props";
import { Select } from "@/components/input/EntitySelect";

import { ProductPlanSetJSON } from "@/pages/plans/models/serialization";

import { PriceRulePlan } from "../models/price-rules";

const planName = (plan: ProductPlanSetJSON) =>
  plan.name.startsWith(plan.product_name ?? "")
    ? plan.name
    : `${plan.product_name} ${plan.name}`;

const ProductPlanSelect = <
  Entity extends FieldValues,
  Name extends FieldPathByValue<Entity, PriceRulePlan | undefined>
>({
  plans,
  control,
  name,
  required,
  ...props
}: CustomInputProps<Entity, Name> & {
  plans?: ProductPlanSetJSON[];
  placeholder?: string;
  className?: string;
}) => {
  const plansById = useMemo(() => keyBy(plans, "id"), [plans]);
  const options = useMemo(() => sortBy(plans, ["product_name"]), [plans]);

  const {
    field: { ref, onChange, onBlur, value: partialPlan },
    fieldState: { invalid },
  } = useController({ control, name, rules: { required } });

  return (
    <Select
      options={options ?? []}
      searchKeys={["name"]}
      optionKey={(plan) => plan?.id ?? ""}
      displayValue={(plan) => (plan ? planName(plan) : "")}
      value={plansById[partialPlan?.id] ?? null}
      onChange={(plan) =>
        onChange(plan ? { id: plan.id, name: planName(plan) } : undefined)
      }
      onBlur={onBlur}
      ref={ref}
      invalid={invalid}
      {...props}
    />
  );
};

export default ProductPlanSelect;
