export const colors = {
  primary: {
    black: "#080B0D", // Background
    purple: "#962EFF", // Highlighted components
    textMonoBase: "#A7AAB1",
  },
  secondary: {
    darkGray: "#121416", // Drawer (side nav) background
    lightGray: "#585A5D", // Borders
    romanSilver: "#8E929D", // Gray text
    ghostWhite: "#E8E8EF", // White text
  },
};
