import React, { useRef, useMemo, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as ArrowIcon } from "@/assets/icons/arrow-right.svg";

import { Text, TranslationScope } from "@/components/hooks/useText";
import useScrollIntoView, {
  useScrollToState,
} from "@/components/hooks/useScrollIntoView";

import { QueryStatusBadge, EnqueuedQuerySummary } from "./components/QueryCard";
import {
  type AnalysisQuery,
  type AnsweredQuery,
  useAnalysisQueryIndex,
  useQueryAPI,
} from "./api";

import KeyStats from "../components/KeyStats";
import KeyCharts from "../components/KeyCharts";

import { answeredQueryLink } from "./routes";

const AnsweredQuerySummary = ({
  status,
  text: query,
  enqueued_date,
  answer: { name, text, charts, stats },
}: AnsweredQuery) => (
  <>
    <div className="flex">
      <QueryStatusBadge {...{ status }} />
    </div>
    <div className="flex flex-col gap-1">
      <div className="flex text-2xl font-semibold leading-tight text-text-bright group-hover:text-text-link">
        {name}
      </div>
      <div className="text-sm leading-tight">
        <span className="before:content-['Q:'] before:text-text-default before:mr-1 text-text-default">
          {query}
        </span>
        <span className="text-text-muted"> • </span>
        <span className="text-text-default">
          <Text i18nKey="enqueued_date" values={{ enqueued_date }} />
        </span>
      </div>
    </div>
    <div
      className={cx(
        "relative text-text-bright line-clamp-3",
        "after:absolute after:inset-0 after:bg-gradient-to-t after:from-bg-l2"
      )}
    >
      {text}
    </div>
    {charts && <KeyCharts {...{ charts }} />}
    <div className="flex items-center justify-between">
      <KeyStats {...{ stats }} />
      <ArrowIcon
        className={cx(
          "h-6 text-text-muted translate-x-1 group-hover:text-text-link",
          "state-transition transition-all origin-left group-hover:scale-[1.1]"
        )}
      />
    </div>
  </>
);

const LinkIfAny = ({ to, children }: PropsWithChildren<{ to?: string }>) =>
  to ? <Link to={to}>{children}</Link> : <>{children}</>;

const useQueryMenu = (query: AnalysisQuery) => {
  const { cancel } = useQueryAPI();
  return useMemo(
    () => [
      {
        item: "Cancel processing",
        danger: true,
        action: () => cancel(query.id),
      },
    ],
    [query.id, cancel]
  );
};

const QueryCard = ({
  query,
  scrollIntoView,
}: {
  query: AnalysisQuery;
  scrollIntoView?: boolean;
}) => {
  const ref = useRef(null);
  const { scrollClass, scrollRef } = useScrollIntoView(ref, scrollIntoView, {
    highlightDurationMs: 1000,
  });

  const answered = query.status === "ready";
  const menu = useQueryMenu(query);

  return (
    <TranslationScope ns="insights" keyPrefix="queries.query">
      <LinkIfAny to={answered ? answeredQueryLink(query.answer.id) : undefined}>
        <div
          {...{ ref: scrollRef, id: query.id }}
          className={cx(
            "group flex flex-col justify-between h-full gap-4 rounded-md px-7 pt-6 pb-5 select-none",
            "border border-transparent state-transition-colors",
            scrollClass,
            answered ? "bg-bg-l2" : "bg-stripes"
          )}
        >
          {answered ? (
            <AnsweredQuerySummary {...query} />
          ) : (
            <EnqueuedQuerySummary {...{ menu, ...query }} />
          )}
        </div>
      </LinkIfAny>
    </TranslationScope>
  );
};

const QueriesIndex = () => {
  const queries = useAnalysisQueryIndex();
  const scrollTo = useScrollToState();

  return queries?.length > 0 ? (
    <TranslationScope ns="insights" keyPrefix="queries">
      <div className="flex flex-col gap-4">
        <h2 className="text-text-bright text-2xl font-semibold">
          <Text i18nKey="title" />
        </h2>
        <ul className="flex flex-col gap-4">
          {queries?.map((query) => (
            <li key={query.id}>
              <QueryCard
                {...{ query, scrollIntoView: query.id === scrollTo }}
              />
            </li>
          ))}
        </ul>
      </div>
    </TranslationScope>
  ) : null;
};

export default QueriesIndex;
