import React from "react";

import { entries } from "@/lib/functional";

import { PriceRuleType } from "./models/price-rules";
import PriceRulesPage from "./PriceRulesPage";
import CreatePriceRulePage from "./CreatePriceRulePage";
import { EditPriceRulePage, NewRulePage } from "./EditPriceRulePage";

const ruleTypePaths: Record<PriceRuleType, string> = {
  [PriceRuleType.GtLt]: "greater-than-less-than",
  [PriceRuleType.Range]: "within-range",
  [PriceRuleType.AvgDiscount]: "average-discount",
  [PriceRuleType.LineItem]: "line-item",
  [PriceRuleType.UnitMeasurement]: "unit-measurement",
  [PriceRuleType.PricePercentageRate]: "price-percentage-rate",
};

const routes = [
  {
    path: "price-rules",
    children: [
      { index: true, element: <PriceRulesPage /> },
      { path: ":ruleId", element: <EditPriceRulePage /> },
      {
        path: "new",
        children: [
          {
            index: true,
            element: <CreatePriceRulePage ruleTypePaths={ruleTypePaths} />,
          },
          ...entries(ruleTypePaths).map(([ruleType, path]) => ({
            path,
            element: <NewRulePage ruleType={ruleType} />,
          })),
        ],
      },
    ],
  },
];

export default routes;
