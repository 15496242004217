import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import type { User } from "@/client/user";

export const initAnalytics = () => {
  const key = process.env["REACT_APP_ANALYTICS_KEY"] as string | undefined;
  if (key) {
    LogRocket.init(key);
    setupLogRocketReact(LogRocket);
  }
};

export const identify = ({ id, ...data }: User) => {
  LogRocket.identify(id, data);
};
