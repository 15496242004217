import React, { useState, useLayoutEffect, PropsWithChildren } from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useOnSelectionChange,
} from "@xyflow/react";

import "@xyflow/react/dist/base.css";

import { TranslationScope } from "@/components/hooks/useText";

import { type FieldGroupDef, type Dependency } from "./types";

import FieldSlot from "./FieldSlot";
import FieldGroup from "./FieldGroup";
import FieldConnector from "./FieldConnector";

import dataflowLayout, {
  type DataflowNode,
  type DataflowEdge,
} from "./dataflowLayout";

import { useEventHandlers } from "./eventHandlers";

import "./dataflow.css";

const proOptions = { hideAttribution: true };
const nodeTypes = {
  fieldslot: FieldSlot,
  fieldgroup: FieldGroup,
};

const edgeTypes = {
  fielddependency: FieldConnector,
};

const reactFlowOptions = {
  nodeTypes,
  edgeTypes,
  proOptions,
  nodesConnectable: false,
  nodesDraggable: false,
  edgesFocusable: false,
  selectNodesOnDrag: false,
  preventScrolling: false,
  panOnDrag: false,
  zoomOnDoubleClick: false,
};

const DataflowLayout = ({
  nodes: groupNodes,
  deps,
}: {
  nodes: FieldGroupDef[];
  deps: Dependency[];
}) => {
  const [layoutedNodes, setLayoutedNodes, onNodesChange] =
    useNodesState<DataflowNode>([]);

  const [layoutedEdges, setLayoutedEdges, onEdgesChange] =
    useEdgesState<DataflowEdge>([]);

  const [size, setSize] = useState({ width: 0, height: 0 });
  const eventHandlers = useEventHandlers();

  useLayoutEffect(() => {
    dataflowLayout(groupNodes, deps).then(({ nodes, edges, size }) => {
      setLayoutedNodes(nodes);
      setLayoutedEdges(edges);
      setSize(size);
    });
  }, [groupNodes, deps, setLayoutedNodes, setLayoutedEdges]);

  return (
    <div style={{ ...size }} className="overflow-hidden">
      <ReactFlow
        {...{
          nodes: layoutedNodes,
          edges: layoutedEdges,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onNodesChange: onNodesChange as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onEdgesChange: onEdgesChange as any,
        }}
        {...eventHandlers}
        {...reactFlowOptions}
      />
    </div>
  );
};

export const DataflowProvider = ({ children }: PropsWithChildren) => (
  <ReactFlowProvider>{children}</ReactFlowProvider>
);

export const useSelectedNodes = () => {
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  useOnSelectionChange({
    onChange: ({ nodes }) => {
      setSelectedNodes(nodes.map((node) => node.id));
    },
  });

  return selectedNodes;
};

const DataflowDiagram = ({
  nodes,
  deps,
}: {
  nodes: FieldGroupDef[];
  deps: Dependency[];
}) => (
  <TranslationScope ns="data-quality">
    <DataflowLayout {...{ nodes, deps }} />
  </TranslationScope>
);

export default DataflowDiagram;
