import React from "react";

import Report from "./Report";

const reportBaseRoute = "report";

export const reportLink = (reportId: string) =>
  `${reportBaseRoute}/${reportId}`;

const routes = [
  {
    path: reportBaseRoute,
    children: [
      {
        path: ":reportId",
        element: <Report />,
      },
    ],
  },
];

export default routes;
