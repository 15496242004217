import React, { useState, useEffect, PropsWithChildren } from "react";
import { useTranslation, Trans } from "react-i18next";
import { TFunction } from "i18next";
import { Grid, Box, Button, Typography, Alert, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../../themes/theme";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import z, { Infer } from "myzod";

const FooterLink = ({ children, to }: PropsWithChildren<{ to: string }>) => (
  <a
    href={to}
    target="_blank"
    style={{
      textDecoration: "none",
      color: "#A7AAB1",
    }}
    rel="noreferrer"
  >
    {children}
  </a>
);

const UsageDisclaimer = ({ t }: { t: TFunction }) => (
  <Typography variant="body2">
    <Trans
      i18nKey="usage_disclaimer"
      t={t}
      components={{
        tou: <FooterLink to="https://www.maca.io/terms-of-use" />,
        pp: <FooterLink to="https://www.maca.io/privacy-policy" />,
      }}
    />
  </Typography>
);

const schema = z.object({
  name: z.string(),
  phone: z.string(),
  email: z.string(),
});

type UserInfo = Infer<typeof schema>;

const Register: React.FC = () => {
  const { t } = useTranslation("user");
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
    },
    resetOptions: { keepDirtyValues: true, keepErrors: true },
  });
  const [alertBox, setAlertBox] = useState(<></>);

  useEffect(() => {
    if (Cookies.get("csrftoken") === undefined) {
      fetch(process.env["REACT_APP_API_HOST"] + "/user/session", {
        credentials: "include",
      });
    }
  }, []);

  const sendForm = async (data: UserInfo) => {
    try {
      clearErrors();
      const response = await fetch(
        process.env["REACT_APP_API_HOST"] + "/user/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(data),
        }
      );

      if (response.ok) return;

      const res = await response.json();
      setError("root", { type: "server", message: res.message });
      console.log(res.message);
    } catch (error) {
      setError("root", { type: "server", message: `${error}` });
      console.log(error);
    }

    setAlertBox(
      <Alert severity="warning" style={{ marginBottom: "10px" }}>
        We are unable to create a new account with the given email. <br />
        Make sure you have received an invitation before trying again.
      </Alert>
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        m="auto"
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Typography variant="h4" style={{ paddingBottom: "20px" }}>
          Create your account
        </Typography>
        {isSubmitSuccessful ? (
          <div className="text-lg text-text-bright">
            🎉 Nice work! Check your{" "}
            <span className="font-semibold">{getValues("email")}</span> email
            for a link to setup your login password.
          </div>
        ) : (
          <form onSubmit={handleSubmit(sendForm)}>
            <Grid
              alignItems="center"
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "1em",
              }}
            >
              <TextField
                {...register("name")}
                data-testid="name"
                placeholder="Name, e.g. Jane Doe"
                required
                fullWidth
              />
              <TextField
                {...register("phone")}
                data-testid="phone"
                placeholder="Phone, e.g. (555) 234-5678"
                required
                fullWidth
              />
              <TextField
                {...register("email")}
                data-testid="email"
                placeholder="Email"
                type="email"
                required
                fullWidth
              />
              <Button
                data-testid="submit-button"
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                Create Account
              </Button>
              {alertBox}
              <Typography variant="body2">
                Already have an account?
                <Button variant="text" href="/user/login" color="info">
                  Login
                </Button>
                <Button
                  variant="text"
                  href="/user/reset-password-token"
                  color="info"
                >
                  Forgot password
                </Button>
              </Typography>
              <UsageDisclaimer t={t} />
            </Grid>
          </form>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default Register;
