import { omit } from "lodash";

import { isNotNil } from "@/lib/functional";

import type { MetricDatum } from "./types";

export const toPieChartData = (data: MetricDatum[], indexBy: string) => {
  const valueKey = Object.keys(omit(data[0], [indexBy]))[0] ?? "value";

  return data
    .filter((row) => isNotNil(row[indexBy]))
    .map((row) => ({
      id: `${row[indexBy]}`,
      label: `${row[indexBy]}`,
      value: Number(row[valueKey]),
    }));
};
