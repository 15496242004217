import React from "react";

// @ts-ignore
import { ReactComponent as CircleCheck } from "@/assets/icons/circle-check.svg";

import Spinner from "@/components/Spinner";
import { useText } from "@/components/hooks/useText";

export interface ConnectButtonProps {
  status: "ready" | "connecting" | "connected";
  disabled: boolean;
  className?: string;
}

const ConnectButton = ({ status, disabled, className }: ConnectButtonProps) => {
  const t = useText();
  return (
    <button className={className} disabled={disabled}>
      {status === "connected" ? (
        <span className="flex text-text-success gap-2">
          <CircleCheck className="w-5" />
          {t("status.connected")}
        </span>
      ) : status === "connecting" ? (
        <span className="flex items-center text-text-default gap-2">
          <div className="w-6 aspect-square">
            <Spinner />
          </div>
          {t("status.connecting")}
        </span>
      ) : (
        t("action.connect")
      )}
    </button>
  );
};

export default ConnectButton;
