import { isNil, uniqueId } from "lodash";

import { isNotNil } from "@/lib/functional";

import api, { useAPI, useAPIConfig } from "@/client/api";

const tempIdPrefix = "temp-";

export class EntityId {
  static new() {
    return uniqueId(tempIdPrefix);
  }

  static isNew(id?: string | null) {
    return isNil(id) || id.startsWith(tempIdPrefix);
  }
}

interface ModelAPIOptions {
  on_demand: boolean;
}

export type WithOptions<T> = T & {
  _options?: ModelAPIOptions;
};

export interface SaveOptions {
  forceSync?: boolean;
}

const _saveEntity = async <JSON>(
  endpointURL: string,
  entity: JSON,
  entityId: string | undefined,
  saveOptions?: SaveOptions
) => {
  const json: WithOptions<JSON> = {
    ...(saveOptions
      ? { _options: { on_demand: Boolean(saveOptions.forceSync) } }
      : {}),
    ...entity,
  };

  return await api.createOrUpdateExt<JSON>(endpointURL, json, entityId, {
    isNew: EntityId.isNew(entityId),
  });
};

const _patchEntity = async <JSON>(
  endpointURL: string,
  entity: Partial<JSON>,
  entityId: string,
  saveOptions?: SaveOptions
) => {
  const json: WithOptions<Partial<JSON>> = {
    ...(Object.keys(entity).length > 0 && saveOptions
      ? { _options: { on_demand: Boolean(saveOptions.forceSync) } }
      : {}),
    ...entity,
  };

  return await api.partialUpdateExt<JSON>(endpointURL, json, entityId);
};

export const _deleteEntity = async ({
  endpointURL,
  entityId,
  mutate,
}: {
  endpointURL: string;
  entityId: string;
  mutate: () => void;
}) => {
  await api.delete(`${endpointURL}${entityId}/`);
  mutate();
};

export const useModelAPI = <JSON>({
  entityId,
  endpointURL,
  existing,
  options,
}: {
  entityId?: string;
  endpointURL: string;
  existing?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
}) => {
  existing = existing ?? !EntityId.isNew(entityId);

  const { mutate: mutateKey } = useAPIConfig();
  const { data, mutate } = useAPI<JSON>(
    existing && `${endpointURL}${entityId}/`,
    options
  );

  const updateCache = async (data: JSON) =>
    existing
      ? await mutate(data, {
          revalidate: false,
        })
      : await mutateKey(endpointURL, null, {
          populateCache: (_, entities) =>
            (entities || []).concat({ ...data, _previousId: entityId }),
          revalidate: false,
        });

  const saveEntity = async (json: JSON, options?: SaveOptions) => {
    try {
      await updateCache(
        await _saveEntity<JSON>(endpointURL, json, entityId, options)
      );
    } catch (x) {
      console.error(x, { json });
    }
  };

  const patchEntity = async (json: Partial<JSON>, options?: SaveOptions) => {
    try {
      if (!entityId) {
        throw new Error(`patchEntity is missing entityId: ${json}`);
      }
      await updateCache(
        await _patchEntity<JSON>(endpointURL, json, entityId, options)
      );
    } catch (x) {
      console.error(x, { json });
    }
  };

  const deleteEntity = async (entityId: string) => {
    try {
      await _deleteEntity({
        endpointURL,
        entityId,
        mutate: () =>
          mutateKey(endpointURL, null, {
            populateCache: (_, entities) => entities, // keep the old data until it's revalidated
            revalidate: true,
          }),
      });
    } catch (x) {
      console.error(x, { entityId });
    }
  };

  return {
    json: data,
    saveEntity,
    patchEntity,
    ...(isNotNil(entityId)
      ? { deleteEntity: () => deleteEntity(entityId) }
      : {}),
  };
};

export const deleteEntity = _deleteEntity;
