import React, {
  forwardRef,
  useEffect,
  KeyboardEvent,
  ClipboardEvent,
} from "react";

import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";

import cx from "clsx";

import { useInnerRef } from "@/components/hooks";

export const pasteText = (text: string) => {
  // Technically, `document.execCommand` is deprecated, but:
  // 1. it's not going away any time soon, as removing it would break *a lot* of websites
  // 2. this is the only way to programmatically paste text w/o messing up undo/redo
  // See https://stackoverflow.com/a/70831583/741027 for more details
  document.execCommand("insertText", false, text);
};

type TextInputProps = {
  autoSelect?: boolean;
  newlines?: boolean;
} & TextareaAutosizeProps;

const TextInput = forwardRef<HTMLTextAreaElement, TextInputProps>(
  ({ className, autoSelect, newlines, ...props }, ref) => {
    const [innerRef, setInnerRef] = useInnerRef(ref);

    const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (!newlines && e.key === "Enter") {
        e.preventDefault();
      }
    };

    const onPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
      if (!newlines) {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        pasteText(text.replace(/(\r\n|\n|\r)+/gm, " "));
      }
    };

    useEffect(() => {
      if (autoSelect && innerRef.current) {
        innerRef.current.select();
      }
    }, [autoSelect, innerRef]);

    return (
      <TextareaAutosize
        ref={setInnerRef}
        {...props}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        className={cx(
          "bg-transparent resize-none input-text input-border",
          className
        )}
      />
    );
  }
);

export default TextInput;
