import React, { PropsWithChildren, useMemo } from "react";
import { Link } from "react-router-dom";

import uFuzzy from "@leeoniya/ufuzzy";
import { uniqBy, take } from "lodash";
import cx from "clsx";

import { toQueryString } from "@/lib/queryParams";
import { isNotNil } from "@/lib/functional";

import { useAPI } from "@/client/api";

import { Text, TranslationScope } from "@/components/hooks/useText";
import { useModalContext } from "@/components/Modal";

import { ChartAnimationProvider } from "@/components/insights/animation";
import { ChartDataProvider } from "@/components/insights/ChartData";
import ChartWidget2 from "@/components/insights/ChartWidget2";

import {
  type ChartIndexEntry,
  useDashboardChartIndexAPI,
} from "@/pages/insights/api";

import {
  fromDTO as fromReportDTO,
  type ReportIndexEntryDTO,
  type ReportMetadata,
} from "@/pages/analysis/reports/api";

import {
  fromDTO as fromQueryDTO,
  type AnalysisQuery,
  type AnalysisQueryDTO,
  type AnsweredQuery,
} from "@/pages/analysis/queries/api";

import {
  QueryStatusBadge,
  EnqueuedQuerySummary,
} from "@/pages/analysis/queries/components/QueryCard";

import KeyStats from "@/pages/analysis/components/KeyStats";

import { ROLLOUT_SEMANTIC_SEARCH } from "@/featureFlags";

import AutoSizeText from "./AutoSizeText";

const useChartIndex = (rawIndex: ChartIndexEntry[]) =>
  useMemo(
    () =>
      (rawIndex ?? []).map((item) =>
        [item.id, item.title].filter(Boolean).join(" ")
      ),
    [rawIndex]
  );

type ChartItem = { type: "chart" } & ChartIndexEntry;
type ReportFindingItem = {
  type: "report_finding";
  id: string;
  text: string;
  original_text?: string;
  report: ReportIndexEntryDTO;
};

type QueryItem = {
  type: "analysis_query";
  id: string;
  text: string;
  query: AnalysisQueryDTO;
};

export type QuickSearchItem = ChartItem | ReportFindingItem | QueryItem;

const useKeywordSearch = (
  rawIndex: ChartIndexEntry[],
  query: string
): QuickSearchItem[] => {
  const chartIndex = useChartIndex(rawIndex);
  const uf = useMemo(() => new uFuzzy({}), []);
  const [indexes] = useMemo(
    () => uf.search(chartIndex, query, true),
    [uf, chartIndex, query]
  );

  return (
    indexes
      ?.map((index) => rawIndex[index])
      .filter(isNotNil)
      .map((item) => ({ type: "chart", ...item })) ?? []
  );
};

type SemanticSearchChartResult = {
  type: "chart";
  id: string;
};

type SemanticSearchReportFindingResult = {
  type: "report_finding";
  id: string;
  report: ReportIndexEntryDTO;
};

type SemanticSearchQueryResult = {
  type: "analysis_query";
  id: string;
  query: AnalysisQueryDTO;
};

type SemanticSearchResult = {
  text: string;
  score: number;
} & (
  | SemanticSearchChartResult
  | SemanticSearchReportFindingResult
  | SemanticSearchQueryResult
);

const useSemanticSearch = (
  rawIndex: ChartIndexEntry[],
  query: string
): QuickSearchItem[] => {
  const index = useMemo(
    () => Object.fromEntries(rawIndex.map((item) => [item.id, item])),
    [rawIndex]
  );

  const { data, error } = useAPI<SemanticSearchResult[]>(
    ROLLOUT_SEMANTIC_SEARCH
      ? `insight/search/${toQueryString({ q: query })}`
      : null,
    {
      keepPreviousData: true,
    }
  );

  return error
    ? []
    : data
        ?.map(({ type, id, ...props }) =>
          type === "chart" && index[id]
            ? ({ type, ...index[id] } as ChartItem)
            : type === "report_finding" || type === "analysis_query"
            ? ({ type, id, ...props } as ReportFindingItem | QueryItem)
            : null
        )
        .filter(isNotNil) ?? [];
};

const useQuickSearchResults = (query: string) => {
  const rawIndex = useDashboardChartIndexAPI() ?? [];
  const keywordResults = useKeywordSearch(rawIndex, query);
  const semanticResults = useSemanticSearch(rawIndex, query);
  const isSemantic = keywordResults.length === 0 && query.trim().length > 0;
  return {
    results: take(uniqBy([...keywordResults, ...semanticResults], "id"), 5),
    isSemantic,
    isQuestion:
      isSemantic && query.trim().split(/\s+/).length > 2 && query.endsWith("?"),
  };
};

const ChartPreview = ({ id, category }: { id: string; category: string }) => (
  <TranslationScope ns="insights">
    <ChartDataProvider routeKey={category}>
      <ChartAnimationProvider {...{ animate: false, lazyRender: false }}>
        <fieldset disabled={true} className="contents">
          <ChartWidget2
            {...{ id }}
            className={cx(
              "h-[200%] w-[200%] origin-top-left scale-50 pointer-events-none select-none",
              "bg-bg-l2 p-4 rounded-xl"
            )}
          />
        </fieldset>
      </ChartAnimationProvider>
    </ChartDataProvider>
  </TranslationScope>
);

const SearchResultContainer = ({
  linkTo,
  scrollTo,
  children,
  className,
}: PropsWithChildren<{
  linkTo: string;
  scrollTo?: string;
  className: string;
}>) => {
  const { closeModal } = useModalContext();
  return (
    <Link
      to={linkTo}
      state={{ scrollTo }}
      preventScrollReset={true}
      onClick={() => closeModal()}
    >
      <li
        className={cx(
          "h-[200px] cursor-pointer outline-none border-[1.5px] border-transparent rounded-md",
          "hover:border-text-link focus:border-text-link overflow-hidden",
          className
        )}
        tabIndex={0}
      >
        {children}
      </li>
    </Link>
  );
};

const SearchChartResult = ({ chart }: { chart: ChartIndexEntry }) => (
  <SearchResultContainer
    linkTo={`/insights/${chart.category}`}
    {...{ scrollTo: chart.id, className: "w-[300px]" }}
  >
    <ChartPreview {...chart} />
  </SearchResultContainer>
);

const ReportFindingPreview = ({
  text,
  report,
}: {
  text: string;
  report: ReportMetadata;
}) => (
  <TranslationScope ns="insights" keyPrefix="reports.summary">
    <div className="flex flex-col bg-bg-l2 h-full gap-4 rounded-md px-7 pt-6 pb-5">
      <AutoSizeText
        className="flex-grow text-text-bright font-semibold leading-tight"
        maxFontSize={20}
        minFontSize={16}
      >
        {text}
      </AutoSizeText>
      <div className="flex items-center">
        <div className="flex-grow text-text-default text-sm font-semibold">
          {report.name}
        </div>
        <div className="text-text-muted text-xs leading-none mt-0.5">
          <Text i18nKey="publish_date" values={report} />
        </div>
      </div>
    </div>
  </TranslationScope>
);

const parseReportId = (id: string) => {
  const [reportId, anchorId] = id.split("#");
  return { reportId, anchorId };
};

const ReportFindingResult = ({
  id,
  text,
  original_text,
  report,
}: {
  id: string;
  text: string;
  original_text?: string;
  report: ReportIndexEntryDTO;
}) => {
  const { reportId, anchorId } = parseReportId(id);
  return (
    <SearchResultContainer
      linkTo={`/analysis/report/${reportId}`}
      {...{ scrollTo: anchorId, className: "aspect-[42/20]" }}
    >
      <ReportFindingPreview
        {...{ text: original_text ?? text, report: fromReportDTO(report) }}
      />
    </SearchResultContainer>
  );
};

const AnsweredQuerySummary = ({
  status,
  answer: { name, stats, text },
}: AnsweredQuery) => (
  <>
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between mb-1">
        <QueryStatusBadge {...{ status }} />
      </div>
      <div className="flex text-xl font-semibold leading-tight text-text-bright">
        {name}
      </div>
      <div
        className={cx(
          "relative text-sm leading-tight text-text-bright line-clamp-3",
          "after:absolute after:inset-0 after:bg-gradient-to-t after:from-bg-l2"
        )}
      >
        {text}
      </div>
    </div>
    <div className="flex items-center justify-between text-sm">
      <KeyStats {...{ stats }} />
    </div>
  </>
);

const AnalysisQueryPreview = ({ query }: { query: AnalysisQuery }) => {
  const answered = query.status === "ready";

  return (
    <TranslationScope ns="insights" keyPrefix="queries.query">
      <div
        className={cx(
          "group flex flex-col justify-between h-full rounded-md px-7 pt-6 pb-5 select-none",
          answered ? "bg-bg-l2 gap-2" : "gap-4 bg-stripes"
        )}
      >
        {answered ? (
          <AnsweredQuerySummary {...query} />
        ) : (
          <EnqueuedQuerySummary {...query} />
        )}
      </div>
    </TranslationScope>
  );
};

const AnalysisQueryResult = ({
  id,
  query,
}: {
  id: string;
  text: string;
  query: AnalysisQueryDTO;
}) => (
  <SearchResultContainer
    linkTo={`/analysis/#${id}`}
    {...{ scrollTo: id, className: "aspect-[42/20]" }}
  >
    <AnalysisQueryPreview {...{ query: fromQueryDTO(query) }} />
  </SearchResultContainer>
);

export const SearchResult = ({ item }: { item: QuickSearchItem }) =>
  item.type === "chart" ? (
    <SearchChartResult {...{ chart: item }} />
  ) : item.type === "report_finding" ? (
    <ReportFindingResult {...item} />
  ) : item.type === "analysis_query" ? (
    <AnalysisQueryResult {...item} />
  ) : null;

const QuickSearch = ({
  results,
  isSemantic,
}: {
  results: QuickSearchItem[];
  isSemantic: boolean;
}) => (
  <section className="flex flex-col gap-2">
    <header className="text-text-default font-medium">
      <Text i18nKey={isSemantic ? "answers_header" : "search_header"} />
    </header>
    <ul className="flex gap-2 w-full overflow-x-hidden">
      {results.map((item) => (
        <SearchResult key={item.id} {...{ item }} />
      ))}
    </ul>
  </section>
);

export const useQuickSearch = (query: string) => {
  const { results, isSemantic, isQuestion } = useQuickSearchResults(query);
  const quickSearch = useMemo(
    () =>
      results?.length ? (
        <QuickSearch {...{ results, isSemantic }} key="quick_search" />
      ) : null,
    [results, isSemantic]
  );

  return { isQuestion, quickSearch };
};
