import React from "react";

import SettingsPanel from "@/pages/settings/SettingsPanel";
import InviteUserDialog from "@/pages/settings/members/InviteUserDialog";
import InvitationList from "@/pages/settings/members/InvitationList";

const Members = () => (
  <SettingsPanel keyPrefix="workspace/members" actions={<InviteUserDialog />}>
    <InvitationList />
  </SettingsPanel>
);

export default Members;
