import React, { createContext, useContext, PropsWithChildren } from "react";
import cx from "clsx";

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

// @ts-ignore
import { ReactComponent as XIcon } from "@/assets/icons/x.svg";

export const Overlay = ({ className }: { className?: string }) => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div
      className={cx(
        "fixed inset-0 bg-bg-default",
        className ?? "bg-opacity-40"
      )}
    />
  </Transition.Child>
);

interface ModalContextType {
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextType>({
  closeModal: () => void 0,
});

export const useModalContext = () => useContext(ModalContext);

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export const Modal = ({
  open,
  onClose,
  children,
}: PropsWithChildren<ModalProps>) => (
  <ModalContext.Provider value={{ closeModal: onClose }}>
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Overlay className="bg-opacity-80" />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 pt-[5vh]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-150"
              enterFrom="opacity-0 scale-95 translate-y-48"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 scale-95 translate-y-48"
            >
              <Dialog.Panel className="transform overflow-hidden rounded-xl shadow-xl transition-all">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  </ModalContext.Provider>
);

const SlideInTransition = ({ children }: PropsWithChildren) => (
  <Transition.Child
    as={Fragment}
    enter="transform transition ease-in-out duration-300"
    enterFrom="translate-x-full"
    enterTo="translate-x-0"
    leave="transform transition ease-in-out duration-200"
    leaveFrom="translate-x-0"
    leaveTo="translate-x-full"
  >
    {children}
  </Transition.Child>
);

const FadeInTransition = ({ children }: PropsWithChildren) => (
  <Transition.Child
    as={Fragment}
    enter="ease-in-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in-out duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition.Child>
);

type CloseButtonSize = "md" | "lg";
const closeButtonStyle: Record<CloseButtonSize, string> = {
  md: "h-5",
  lg: "h-6",
};

export const CloseButton = ({
  onClick,
  className,
  size = "lg",
}: {
  onClick: () => void;
  className?: string;
  size?: CloseButtonSize;
}) => (
  <button
    type="button"
    className={cx(
      "p-2 rounded-md text-text-muted hover:text-text-bright focus:outline-none focus:text-text-link",
      className
    )}
    onClick={onClick}
  >
    <span className="sr-only">Close panel</span>
    <XIcon
      className={cx(closeButtonStyle[size], "aspect-square")}
      aria-hidden="true"
    />
  </button>
);

export const Drawer = ({
  children,
  open,
  onClose,
}: PropsWithChildren<ModalProps>) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Overlay className="bg-opacity-60" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <SlideInTransition>
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-xl">
                  <FadeInTransition>
                    <div className="absolute left-0 top-0 -ml-12 mt-3">
                      <CloseButton onClick={onClose} />
                    </div>
                  </FadeInTransition>
                  <div className="flex h-full flex-col overflow-y-scroll bg-bg-l2 shadow-xl">
                    {children}
                  </div>
                </Dialog.Panel>
              </SlideInTransition>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Drawer.Title = Dialog.Title;
