import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../themes/theme";
import { Block, ArrowBack } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

const NotFound = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        textAlign="center"
        sx={{
          flexGrow: 1,
        }}
      >
        <Grid>
          <Block sx={{ fontSize: 80 }} color="primary" />
          <Typography variant="h5" component="div">
            Page not found
          </Typography>
          <Typography sx={{ mb: 4 }} color="text.secondary">
            The requested URL does not exist.
          </Typography>
          <Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<ArrowBack />}
              href="/"
            >
              Return Home
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default NotFound;
