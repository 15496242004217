import React, { useEffect } from "react";
import { groupBy, pick } from "lodash";
import { useUploadsListAPI } from "../../api";
import useEasyForm from "@/components/easy-form";

import {
  DataWarehouseUploadsValues,
  dataWarehouseUploadsSchema,
} from "../schema";
import FileUpload from "../components/FileUpload";

// @ts-ignore
import { ReactComponent as PrivacyShieldIcon } from "@/assets/icons/privacy-shield.svg";
import { useText } from "@/components/hooks/useText";
import { type IntegrationComponent } from "../types";

const DataWarehouseExport: IntegrationComponent = ({
  registerNoopSubmitHandler,
}) => {
  const uploads = useUploadsListAPI();

  const { register, handleSubmit } = useEasyForm<DataWarehouseUploadsValues>({
    values: pick(groupBy(uploads, "category"), [
      "data_warehouse_export",
    ]) as DataWarehouseUploadsValues,
    schema: dataWarehouseUploadsSchema,
  });

  const t = useText();

  useEffect(
    () => registerNoopSubmitHandler(handleSubmit),
    [handleSubmit, registerNoopSubmitHandler]
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="text-text-bright font-medium leading-tight">
        {t("other.label")}
      </div>
      <FileUpload {...register("data_warehouse_export")} mode="multiple" />
      <div className="flex items-center gap-2">
        <PrivacyShieldIcon className="w-8 aspect-square text-text-muted/60 px-1" />
        <div className="text-text-muted text-sm leading-tight">
          {t("other.footer")}
        </div>
      </div>
    </div>
  );
};

export default DataWarehouseExport;
