import { isNil, omit, sortBy } from "lodash";
import { useAPI } from "@/client/api";
import { useModelAPI, deleteEntity } from "@/client/models-api";

import {
  ProductSetAPIJSON,
  ProductSetJSON,
  ProductComponentJSON,
} from "./serialization";

const productBaseURL = "product/";
const productComponentBaseURL = (productId: string) =>
  `product/${productId}/component/`;

export const useProductsListAPI = () => {
  const { data, mutate } = useAPI<ProductSetAPIJSON[]>(productBaseURL);
  return {
    products: data?.map(({ components, ...product }) => ({
      product,
      components: components ?? [],
    })),
    mutate,
  };
};

export const useProductComponentsListAPI = ({
  productId,
}: {
  productId: string;
}) => {
  const { data, mutate } = useAPI<ProductComponentJSON[]>(
    productComponentBaseURL(productId)
  );

  return {
    components: data,
    mutate,
  };
};

const normalize = (json: ProductSetAPIJSON): ProductSetJSON =>
  isNil(json)
    ? json
    : {
        product: omit(json, "components"),
        components: sortBy(json.components, [(c) => c.name.toLowerCase()]),
      };

export const useProductsAPI = ({ productId }: { productId?: string }) => {
  const { json, saveEntity, deleteEntity } = useModelAPI<ProductSetAPIJSON>({
    entityId: productId,
    endpointURL: productBaseURL,
  });

  return {
    product: normalize(json),
    saveProduct: saveEntity,
    deleteProduct: deleteEntity,
  };
};

export const useProductComponentsAPI = ({
  productId,
  productComponentId,
}: {
  productId: string;
  productComponentId: string;
}) => {
  const { json, saveEntity, deleteEntity } = useModelAPI<ProductComponentJSON>({
    entityId: productComponentId,
    endpointURL: productComponentBaseURL(productId),
  });

  return {
    productComponent: json,
    saveProductComponent: saveEntity,
    deleteProductComponent: deleteEntity,
  };
};

export const deleteProduct = async (productId: string, mutate: () => void) =>
  await deleteEntity({
    endpointURL: productBaseURL,
    entityId: productId,
    mutate,
  });
