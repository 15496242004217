import React, { useState } from "react";

import SearchModal from "./SearchModal";
import SearchButton from "./SearchButton";

const Search = () => {
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <>
      <SearchButton onClick={() => setSearchOpen(true)} shortcut="mod+K" />
      <SearchModal open={searchOpen} onClose={() => setSearchOpen(false)} />
    </>
  );
};

export default Search;
