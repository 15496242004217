import ComingSoon from "@/pages/ComingSoon";
import React from "react";

const routes = [
  {
    path: "experiments",
    children: [{ index: true, element: <ComingSoon /> }],
  },
];

export default routes;
