import React, { PropsWithChildren, ReactNode, useCallback } from "react";
import * as Tt from "@radix-ui/react-tooltip";
import { TooltipContentProps } from "@radix-ui/react-tooltip";
import cx from "clsx";
import { isString } from "lodash";

export interface TooltipProps {
  content: ReactNode;
  delayDuration?: number;
  disableHoverableContent?: boolean;
  ignoreClick?: boolean;
  align?: TooltipContentProps["align"];
  side?: TooltipContentProps["side"];
  className?: string;
}

interface HasPreventDefault {
  preventDefault(): void;
}

const TooltipImpl = ({
  children,
  content,
  delayDuration,
  disableHoverableContent,
  ignoreClick,
  align,
  side,
  className,
}: PropsWithChildren<TooltipProps>) => {
  const handleClick = useCallback(
    <Event extends HasPreventDefault>(event: Event) => {
      if (ignoreClick) {
        event.preventDefault();
      }
    },
    [ignoreClick]
  );

  return (
    <Tt.Root
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
    >
      <Tt.Trigger onClick={handleClick} asChild>
        {children}
      </Tt.Trigger>
      <Tt.Portal>
        <Tt.Content
          sideOffset={4}
          align={align}
          side={side}
          onPointerDownOutside={handleClick}
          className={cx(
            "radix-side-top:animate-slide-down-fade",
            "radix-side-right:animate-slide-left-fade",
            "radix-side-bottom:animate-slide-up-fade",
            "radix-side-left:animate-slide-right-fade",
            "select-none",
            className ??
              "bg-bg-l3 fill-bg-l3 inline-flex items-center rounded-md px-4 py-2.5 z-10"
          )}
        >
          <Tt.Arrow className="fill-current" />
          {isString(content) ? (
            <span className="block text-xs leading-none text-text-default">
              {content}
            </span>
          ) : (
            content
          )}
        </Tt.Content>
      </Tt.Portal>
    </Tt.Root>
  );
};

const Tooltip = ({
  children,
  content,
  ...props
}: PropsWithChildren<TooltipProps>) =>
  content ? (
    <TooltipImpl {...{ content, ...props }}>{children}</TooltipImpl>
  ) : (
    <>{children}</>
  );

export default Tooltip;

export const TooltipProvider = Tt.Provider;
