import React from "react";

import { capitalize } from "lodash";
import cx from "clsx";

const ProductAvatar = ({
  name,
  className,
}: {
  name: string;
  className?: string;
}) => (
  <div
    className={cx(
      "flex flex-col items-center justify-center aspect-square min-w-[80px] w-20 rounded-md bg-bg-d1",
      className
    )}
  >
    <span className="text-4xl text-text-bright font-semibold leadiding-0 select-none">
      {capitalize(name.trim().substring(0, 2) || "?")}
    </span>
  </div>
);

export default ProductAvatar;
