import React, { PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";

export const FadeInFadeOutTransition = ({
  show,
  className,
  children,
}: PropsWithChildren<{ show: boolean; className?: string }>) => (
  <Transition
    show={show}
    enter="transition-all duration-200"
    enterFrom="opacity-0 scale-50"
    enterTo="opacity-100 scale-100"
    leave="transition-opacity duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    className={className}
  >
    {children}
  </Transition>
);
