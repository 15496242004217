import React from "react";

import reportsRoutes from "./reports/routes";
import queriesRoutes from "./queries/routes";
import AnalysisHome from "./Index";

const routes = [
  {
    path: "analysis",
    children: [
      { index: true, element: <AnalysisHome /> },
      ...reportsRoutes,
      ...queriesRoutes,
    ],
  },
];

export default routes;
