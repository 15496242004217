import { InsightsOutlined } from "@mui/icons-material";
import cx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

const InsightsPending = () => {
  const { t } = useTranslation("insights", { keyPrefix: "pending" });
  return (
    <div
      className={cx(
        "h-screen flex justify-center text-center place-items-center grow"
      )}
    >
      <div>
        <InsightsOutlined sx={{ fontSize: 80 }} color="primary" />
        <h3 className={cx("text-5xl")}>{t("title")}</h3>
        <h6
          className={cx("text-2xl mt-4 text-text-default whitespace-pre-line")}
        >
          {t("message")}
        </h6>
      </div>
    </div>
  );
};

export default InsightsPending;
