import React, { useEffect } from "react";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as ChevronRight } from "@/assets/icons/ctrl-right.svg";
// @ts-ignore
import { ReactComponent as CircleCheck } from "@/assets/icons/circle-check.svg";
// @ts-ignore
import { ReactComponent as HubSpotLogo } from "@/assets/logos/hubspot.svg";

import useText, { Text } from "@/components/hooks/useText";

import { type IntegrationComponent } from "../types";

import { oauthInstallationSchema } from "../schema";
import { Infer } from "myzod";
import useEasyForm from "@/components/easy-form";
import { isEmpty } from "lodash";

import {
  useHubSpotInstallAPI,
  HubSpotConfig,
  HubSpotInstallLink,
} from "@/pages/settings/panels/integrations/HubSpot";
import ErrorMessage from "@/components/input/ErrorMessage";

const InstanceSummary = ({ hubDomain, url }: HubSpotConfig) => {
  const t = useText();
  return (
    <div className="flex flex-col gap-2">
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={cx(
          "group flex items-center justify-between text-text-bright text-xl font-medium bg-bg-l4/60 pl-5 pr-3 py-3 border border-transparent rounded-md",
          "hover:text-text-link hover:border-text-link hover:bg-bg-l4/80 active:bg-bg-l4/100 state-transition-colors select-none",
          "focus-ring-rounded-md"
        )}
        draggable={false}
      >
        <span>{hubDomain}</span>
        <ChevronRight
          className={cx(
            "w-6 shrink-0 grow-0 group-hover:translate-x-[2px] group-hover:scale-[1.1]",
            "transition-transform state-transition"
          )}
        />
      </a>
      <span className="flex text-text-success gap-2 px-1 py-1">
        <CircleCheck className="w-5" />
        {t("status.connected")}
      </span>
    </div>
  );
};

type HubSpotInstallationStatus = Infer<typeof oauthInstallationSchema>;

const HubSpotSetup: IntegrationComponent = ({ registerNoopSubmitHandler }) => {
  const config = useHubSpotInstallAPI();
  const connected = Boolean(config.hubDomain);

  const { handleSubmit, errors } = useEasyForm<HubSpotInstallationStatus>({
    values: { connected },
    schema: oauthInstallationSchema,
  });

  useEffect(
    () => registerNoopSubmitHandler(handleSubmit),
    [handleSubmit, registerNoopSubmitHandler]
  );

  return (
    <div className="flex flex-col gap-9">
      {connected ? (
        <InstanceSummary {...config} />
      ) : (
        <div>
          <div className="flex rounded-md p-4 gap-4">
            <HubSpotLogo className="w-14 p-1 self-start" />
            <div className="flex flex-row gap-2 items-center grow">
              <div className="flex-initial">
                <HubSpotInstallLink
                  url={config.url}
                  className="button-base button-primary px-4 py-2"
                />
              </div>
            </div>
          </div>
          {!isEmpty(errors) && (
            <ErrorMessage>
              <Text i18nKey="hub_spot.error.not_installed" />
            </ErrorMessage>
          )}
        </div>
      )}
    </div>
  );
};

export default HubSpotSetup;
