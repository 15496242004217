import React from "react";
import { Navigate, type RouteObject } from "react-router-dom";

import Layout from "./Layout";
import Dataflow from "./Dataflow";

// const Blank = () => <div></div>;

const subroutes: RouteObject[] = [
  // { path: "summary", element: <Blank /> },
  { path: "dataflow", element: <Dataflow /> },
];

const routes = [
  {
    path: "data-quality",
    element: <Layout routes={subroutes.map((r) => r.path ?? "")} />,
    children: [
      {
        index: true,
        element: <Navigate to={subroutes[0]?.path || ""} />,
      } as RouteObject,
    ].concat(subroutes),
  },
];

export default routes;
