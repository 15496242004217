import React, { ReactNode, forwardRef, ForwardedRef, FocusEvent } from "react";
import { Switch } from "@headlessui/react";
import { isString } from "lodash";
import cx from "clsx";

export interface ToggleProps {
  value: boolean;
  onChange: (value: boolean) => void;
  onBlur?: VoidFunction;
  className?: string;
  label?: ReactNode;
}

const Toggle = forwardRef(
  (
    { value, onChange, onBlur, className = "h-7", label }: ToggleProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <Switch.Group>
      <div
        className="flex gap-2 items-center"
        onBlur={(event: FocusEvent<HTMLDivElement>) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            onBlur?.();
          }
        }}
      >
        {label && (
          <Switch.Label>
            {isString(label) ? (
              <div className="text-text-default select-none cursor-pointer group-disabled/fieldset:cursor-default">
                {label}
              </div>
            ) : (
              label
            )}
          </Switch.Label>
        )}
        <Switch
          checked={value}
          onChange={onChange}
          className={cx(
            "relative inline-flex aspect-[1.65] shrink-0 rounded-full border-2 border-transparent",
            "focus:outline-none focus-visible:ring-2 focus-visible:ring-border-focus focus-visible:ring-opacity-75",
            "transition-colors duration-200 ease-in-out cursor-pointer disabled:opacity-70 disabled:cursor-default",
            value ? "bg-brand-purple" : "bg-bg-l4",
            className
          )}
          ref={ref}
        >
          <span
            aria-hidden="true"
            className={cx(
              "absolute inline-block h-full aspect-square py-0.5 rounded-full bg-base-white shadow-lg",
              "ring-0 pointer-events-none transition duration-200 ease-in-out transform",
              value ? "translate-x-[75%]" : "translate-x-0"
            )}
          />
        </Switch>
      </div>
    </Switch.Group>
  )
);

export default Toggle;
