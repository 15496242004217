import React from "react";

import { FieldValues, FieldPathByValue } from "react-hook-form";

import { CustomInputProps } from "@/components/input/props";
import EntitySelect from "@/components/input/EntitySelect";

import { PriceRuleSubject } from "../models/price-rules";

const PriceRuleSubjectSelect = <
  Entity extends FieldValues,
  Name extends FieldPathByValue<Entity, PriceRuleSubject | undefined>
>(
  props: CustomInputProps<Entity, Name> & {
    options: PriceRuleSubject[];
    placeholder?: string;
    className?: string;
  }
) => (
  <EntitySelect
    {...props}
    searchKeys={["type", "field"]}
    optionKey={(subj) => `${subj.type}-${subj.field}`}
    displayValue={(subj) =>
      subj ? [subj.type, subj.field].filter(Boolean).join(" ") : ""
    }
  />
);

export default PriceRuleSubjectSelect;
