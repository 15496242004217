import { omit } from "lodash";
import type { MetricDatum } from "./types";

export const toLineSeries = (data: MetricDatum[], indexBy: string) => {
  const keys = Object.keys(omit(data[0], [indexBy]));
  return keys.map((key) => ({
    id: key,
    data: data.map((row) => ({ x: row[indexBy], y: row[key] })),
  }));
};
