import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import cx from "clsx";
import { Trans } from "react-i18next";
import type { TFunction } from "i18next";
import { pick, keyBy, mapValues } from "lodash";
import type { Merge } from "type-fest";

import MacaButton from "@/components/Button";

import Menu, { MenuDropdown } from "@/components/Menu";

import { useAPI } from "@/client/api";

import { useText, useTranslationScope } from "@/components/hooks/useText";

// @ts-ignore
import { ReactComponent as EditOptionsIcon } from "@/assets/icons/ctrl-down.svg";

import { SlackUser } from "./components/SlackUserSelect";
import { UserList, RuleBlockUser, ListType } from "./components/UserList";

import {
  IPriceRule,
  PriceRule,
  RuleBlock,
  priceRuleEditingOptions,
} from "./models/price-rules";

import { usePriceRulesListAPI, deleteRule } from "./models/api";

import {
  PriceRuleBlockJSON,
  serializeRuleBlock,
  rangeOperatorsList,
} from "./models/serialization";

import {
  usePriceRuleRefs,
  PriceRuleRefs,
  hydrateRule,
} from "./models/hydration";

import { NumericInputOptions } from "@/components/input/types";

const toRuleBlockUser = (user: SlackUser) => ({
  id: user.id,
  name: user.display_name || user.email,
  title: user.metadata.title || (user.display_name ? user.email : ""),
});

const usePriceRulesUsers = (): Record<string, RuleBlockUser> => {
  const { data: users } = useAPI<SlackUser[]>("slack/recipient");
  const usersById = useMemo(
    () => keyBy((users ?? []).map(toRuleBlockUser), "id"),
    [users]
  );

  return usersById;
};

type PriceRuleBlockSummary = Merge<
  PriceRuleBlockJSON,
  { approvers: RuleBlockUser[]; notifications: RuleBlockUser[] }
>;

type SummarizedPriceRule = Merge<
  IPriceRule,
  {
    rules: PriceRuleBlockSummary[];
  }
>;

interface PriceRuleSummaryProps {
  rule: SummarizedPriceRule;
}

interface RuleEditComponentProps {
  ruleId: string;
}

const RuleEditButton = ({ ruleId }: RuleEditComponentProps) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center bg-bg-l2 rounded-md">
      <button
        className={cx(
          "pl-5 pr-4 py-1 text-text-default rounded-l-md hover:bg-bg-l3 hover:text-text-link",
          "outline-none border border-border-default border-r-transparent",
          "focus:border focus:border-border-focus"
        )}
        onClick={() => navigate(ruleId)}
      >
        Edit
      </button>
      <RuleMenu ruleId={ruleId} />
    </div>
  );
};

const RuleMenu = ({ ruleId }: RuleEditComponentProps) => {
  const { mutate } = usePriceRulesListAPI();

  return (
    <div className="relative">
      <Menu>
        <Menu.Button
          className={cx(
            "text-text-default py-2.5 px-2 hover:text-text-bright rounded-r-md",
            "outline-none border border-border-default border-l-transparent focus:border-border-focus",
            "hover:bg-bg-l3"
          )}
        >
          <EditOptionsIcon className="w-3" />
        </Menu.Button>
        <MenuDropdown
          items={[
            {
              item: "Delete rule",
              action: () => deleteRule(ruleId, mutate),
              danger: true,
            },
          ]}
        />
      </Menu>
    </div>
  );
};

interface UserListTransProps {
  i18nKey: string;
  t: TFunction;
  users: RuleBlockUser[];
  listType: ListType;
}

const UserListTrans = ({ i18nKey, t, users, listType }: UserListTransProps) =>
  users.length ? (
    <Trans
      i18nKey={i18nKey}
      t={t}
      components={[<UserList users={users} listType={listType} />]}
    />
  ) : (
    <></>
  );

const BlockDescription = ({
  index,
  block,
  values,
  inputOptions,
}: {
  index: number;
  block: PriceRuleBlockSummary;
  values: Record<string, string | undefined>;
  inputOptions: NumericInputOptions;
}) => {
  const t = useText({ keyPrefix: "summary" });
  const components = [
    <Trans
      i18nKey={`condition_clause.${rangeOperatorsList(block).join("_")}`}
      t={t}
      values={{
        ...block,
        formatParams: mapValues(block, () => inputOptions),
      }}
      components={[<strong />]}
    />,
    <>
      <UserListTrans
        i18nKey="approval_clause"
        t={t}
        users={block.approvers}
        listType="disjunction"
      />
      <UserListTrans
        i18nKey="notification_clause"
        t={t}
        users={block.notifications}
        listType="conjunction"
      />
    </>,
  ];

  return (
    <Trans
      i18nKey={`template.${index === 0 ? "1st" : "nth"}`}
      t={t}
      values={values}
      components={components}
    />
  );
};

const RuleDescription = ({ rule }: PriceRuleSummaryProps) => {
  const t = useText();

  const { ruleType, subjectFields } = priceRuleEditingOptions(rule.type);
  const subject = t(
    [`if.subject_${ruleType}`, "if.subject"],
    pick(rule, subjectFields)
  );

  return (
    <>
      {rule.rules.map((block, i) => (
        <div key={`block-${i}`} className={cx(i > 0 && "mt-2")}>
          <BlockDescription
            index={i}
            block={block}
            values={{ subject }}
            inputOptions={PriceRule.inputOptions(rule)}
          />
        </div>
      ))}
    </>
  );
};

const PriceRuleSummary = ({ rule }: PriceRuleSummaryProps) => {
  const { t, TranslationScope } = useTranslationScope("price-rules", {
    keyPrefix: "rule",
  });

  return (
    <TranslationScope>
      <div className="relative">
        <div className="grid border border-border-default rounded-md p-4 grid-cols-8 gap-8">
          <div className="flex flex-col col-span-2 items-start justify-between gap-y-2">
            <div className="flex flex-col gap-y-0.5">
              <h2 className="mt-1 text-lg text-text-bright font-semibold leading-tight">
                {rule.name}
              </h2>
              <div className="text-text-default text-sm">
                {t(`type.${rule.type}`)}
              </div>
            </div>
          </div>

          <div className="max-w-xl text-text-bright col-span-5">
            <RuleDescription rule={rule} />
          </div>
          <div className="flex flex-col items-end justify-center">
            <RuleEditButton ruleId={rule.id} />
          </div>
        </div>
      </div>
    </TranslationScope>
  );
};

const summarizeRuleBlock = (
  block: RuleBlock,
  usersById: Record<string, RuleBlockUser>
): PriceRuleBlockSummary => {
  const { approvers, notifications, ...props } = serializeRuleBlock(block);
  const mapUser = (userId: string) =>
    usersById[userId] ?? { id: userId, name: "[Unknown user]", title: "" };

  return {
    ...props,
    approvers: approvers.map((a) => mapUser(a)),
    notifications: notifications.map((n) => mapUser(n)),
  };
};

const summarizeRule = (
  { rules, ...props }: PriceRule,
  refs: PriceRuleRefs,
  usersById: Record<string, RuleBlockUser>
): SummarizedPriceRule => ({
  ...hydrateRule(props, refs),
  rules: (rules ?? []).map((rule) => summarizeRuleBlock(rule, usersById)),
});

const PriceRulesList = ({ rules }: { rules: PriceRule[] }) => {
  const refs = usePriceRuleRefs();
  const usersById = usePriceRulesUsers();

  return (
    <div className="flex flex-col gap-6 mt-2">
      {rules.map((rule) => (
        <PriceRuleSummary
          key={rule.id}
          rule={summarizeRule(rule, refs, usersById)}
        />
      ))}
    </div>
  );
};

export const PriceRulesPage: React.FC = () => {
  const navigate = useNavigate();
  const { rules } = usePriceRulesListAPI();

  useEffect(() => {
    if (rules?.length === 0) {
      navigate("new");
    }
  }, [rules?.length, navigate]);

  return (
    <div className="flex flex-col w-full max-w-6xl my-8 mx-auto px-8">
      <div className="flex justify-between mx-4">
        <h1 className="text-3xl font-semibold text-text-bright mb-6">
          Price rules
        </h1>
        <MacaButton
          text="New rule"
          variant="contained"
          onClick={() => navigate("new")}
        />
      </div>
      <PriceRulesList rules={rules || []} />
    </div>
  );
};

export default PriceRulesPage;
