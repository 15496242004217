import React, { useMemo } from "react";
import { useController, FieldValues, FieldPathByValue } from "react-hook-form";
import { keyBy } from "lodash";

import { CustomInputProps } from "@/components/input/props";
import { Select } from "@/components/input/EntitySelect";

import useText from "@/components/hooks/useText";

import { ProductPlanDetailsJSON } from "@/pages/plans/models/serialization";

const PlanSelect = <
  Entity extends FieldValues,
  Name extends FieldPathByValue<Entity, string | undefined>
>({
  plans,
  control,
  name,
  required,
  ...props
}: CustomInputProps<Entity, Name> & {
  plans: ProductPlanDetailsJSON[];
  placeholder?: string;
  className?: string;
}) => {
  const plansById = useMemo(() => keyBy(plans, "id"), [plans]);
  const t = useText();

  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid },
  } = useController({ control, name, rules: { required } });

  return (
    <Select
      options={plans?.map((plan) => plan.id) ?? []}
      searchKeys={["name"]}
      optionKey={(planId) => planId ?? ""}
      displayValue={(planId) =>
        planId
          ? t("components.base_plan.option", {
              planName: plansById[planId]?.name,
            })
          : ""
      }
      value={value ?? null}
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
      invalid={invalid}
      padding="snug"
      {...props}
    />
  );
};

export default PlanSelect;
