export interface Point {
  x: number;
  y: number;
}

export interface Rectangle {
  left: number;
  top: number;
  width: number;
  height: number;
}

export const inflateRect = (
  { left, top, width, height }: Rectangle,
  extent: number | Point
) => {
  const margin = typeof extent === "number" ? { x: extent, y: extent } : extent;
  return {
    left: left - margin.x / 2,
    top: top - margin.y / 2,
    width: width + margin.x,
    height: height + margin.y,
  };
};

export const rectCenter = ({ left, top, width, height }: Rectangle) => ({
  x: left + width / 2,
  y: top + height / 2,
});

export const unionRect = (a: Rectangle, b: Rectangle) => {
  const left = Math.min(a.left, b.left);
  const top = Math.min(a.top, b.top);
  const right = Math.max(a.left + a.width, b.left + b.width);
  const bottom = Math.max(a.top + a.height, b.top + b.height);

  return {
    left,
    top,
    width: right - left,
    height: bottom - top,
  };
};

export const boundingRect = (rects: Rectangle[]) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  rects.length > 0 ? rects.reduce(unionRect, rects[0]!) : undefined;
