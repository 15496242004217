import ELK from "elkjs/lib/elk.bundled.js";
import { type ElkShape } from "elkjs/lib/elk-api";

import { keyBy } from "lodash";

import { type FieldGroupDef, type Dependency } from "./types";
import { FIELD_GROUP_MARGIN, FIELD_GROUP_WIDTH } from "./FieldGroup";
import { FIELD_SLOT_HEIGHT } from "./FieldSlot";
import { tableName } from "./common";

export type NodeShape = ElkShape;

const elk = new ELK();

const layoutOptions = {
  "elk.algorithm": "layered",
  "elk.layered.considerModelOrder.strategy": "PREFER_NODES",
  "elk.layered.nodePlacement.strategy": "NETWORK_SIMPLEX",
  "elk.layered.spacing.nodeNodeBetweenLayers": "300",
  "elk.spacing.nodeNode": "50",
  "elk.direction": "RIGHT",
};

const groupDeps = (deps: Dependency[]) =>
  deps.map(({ source, target }) => {
    const sourceGroupId = tableName(source);
    const targetGroupId = tableName(target);
    return {
      id: `${sourceGroupId}-${targetGroupId}`,
      sources: [sourceGroupId],
      targets: [targetGroupId],
    };
  });

const nodeLayout = async (
  nodes: FieldGroupDef[],
  deps: Dependency[]
): Promise<Record<string, NodeShape> | undefined> => {
  const graph = {
    id: "#root",
    children: nodes.map(({ id, fields }) => ({
      id,
      width: FIELD_GROUP_WIDTH,
      height:
        FIELD_GROUP_MARGIN.top +
        fields.length * FIELD_SLOT_HEIGHT +
        FIELD_GROUP_MARGIN.bottom,
    })),
    edges: groupDeps(deps),
  };

  try {
    const { children } = await elk.layout(graph, { layoutOptions });
    return keyBy(children, "id");
  } catch (x) {
    console.error(x);
    return undefined;
  }
};

export default nodeLayout;
