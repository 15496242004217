import { at, isObject } from "lodash";
import memoize from "micro-memoize";

import tw from "@/styles/tailwind";

export const themeColor = memoize((name: string) => {
  const color = at(tw.theme.colors, [name.replaceAll("-", ".")])[0];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (isObject(color) ? (color as any)?.DEFAULT : color)?.toLowerCase();
});
