import React, { type PropsWithChildren } from "react";

import { type CompleteTheme } from "@nivo/core";

import { toHTMLTextStyle } from "./common";

const LegendItem = ({
  children,
  color,
}: PropsWithChildren<{ color: string | undefined }>) => (
  <div className="flex items-center gap-x-2 select-none">
    <div
      className="w-3 aspect-square shrink-0 rounded-full"
      style={{ backgroundColor: color }}
    />
    {children}
  </div>
);

const Legend = ({
  keys,
  colors,
  theme,
}: {
  keys: string[];
  colors: string[];
  theme: CompleteTheme;
}) => {
  const textStyle = toHTMLTextStyle(theme.legends?.text);
  return (
    <div>
      <div className="hidden @md/chart:flex flex-col gap-2 pl-4 pr-1 max-w-[150px]">
        {keys.map((key, i) => (
          <LegendItem key={key} color={colors[i % colors.length]}>
            <div style={textStyle} className="leading-tight break-words">
              {key.split("/").join("/\u200b")}
            </div>
          </LegendItem>
        ))}
      </div>
    </div>
  );
};

export default Legend;
