import React, { useEffect } from "react";

export const StripePricingTable = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": "prctbl_1Nda8sIxOftt2DKdN0vK6Gex",
    "publishable-key":
      "pk_live_51Lf68uIxOftt2DKdePSsPfRDnPebpE1LYNnCHzKxJJ9Pli2ZAlXZMkMIMSK0TPXgKpenhd8sSKJWutcYKLJYyTWX0089KLISNt",
  });
};

const StripeCheckout = () => {
  return (
    <div className="w-screen h-[90vh] max-w-[70rem] md:max-h-[35rem] bg-bg-l250 overflow-y-auto">
      <h1 className="text-3xl font-semibold text-center pt-10">
        Please select your plan
      </h1>
      <div className="mt-20">
        <StripePricingTable />
      </div>
    </div>
  );
};

export default StripeCheckout;
