import React, {
  useMemo,
  useRef,
  useLayoutEffect,
  useCallback,
  FC,
  SyntheticEvent,
  PropsWithChildren,
} from "react";

import {
  Outlet,
  RouteObject,
  useNavigate,
  Location,
  useLocation,
  useOutletContext,
} from "react-router-dom";

import * as Progress from "@radix-ui/react-progress";
import { useTranslation } from "react-i18next";
import { last } from "lodash";
import cx from "clsx";

import { isNotNil } from "@/lib/functional";
import { slug } from "@/lib/string";

import { TranslationScope } from "@/components/hooks/useText";
import { PrimaryButton } from "@/components/CoreButtons";

import StepsSidebar from "./StepsSidebar";

import { useOnboardingStatusAPI } from "@/pages/onboarding/api";

interface StepsLayoutProps {
  sections: string[];
  routes: RouteObject[];
}

interface StepSubmitCallbackOptions {
  next: VoidFunction;
}

type SubmitCallback = (
  event: SyntheticEvent,
  options: StepSubmitCallbackOptions
) => Promise<void>;

interface StepPanelProps extends PropsWithChildren {
  handleSubmit?: SubmitCallback;
}

const ProgressIndicator = ({ className }: { className?: string }) => {
  const progress = useOnboardingStatusAPI()?.percentageComplete || 0;

  return (
    <Progress.Root
      className={cx("relative overflow-hidden", className)}
      value={progress}
    >
      <Progress.Indicator
        className="absolute bg-brand-purple-l2 left-0 top-0 h-full transition-all"
        style={{ width: `${progress}%` }}
      />
    </Progress.Root>
  );
};

const StepPanel = ({
  handleSubmit,
  nextStepRoute,
  slug: keyPrefix,
  children,
}: StepPanelProps & {
  nextStepRoute: string;
  slug: string;
}) => {
  const ns = "onboarding";
  const { t } = useTranslation(ns);

  const navigate = useNavigate();
  const onSubmit = useCallback(
    (event: SyntheticEvent) =>
      handleSubmit?.(event, { next: () => navigate(nextStepRoute) }),
    [handleSubmit, navigate, nextStepRoute]
  );

  const description = t("description", { keyPrefix, defaultValue: "" });

  return (
    <TranslationScope {...{ ns, keyPrefix }}>
      <div className="flex flex-col flex-grow justify-between p-8 pt-6 gap-9 bg-bg-l250 theme-l1">
        <div className="flex flex-col gap-2">
          <h1 className="text-text-bright text-3xl font-semibold leading-tight">
            {t("title", { keyPrefix })}
          </h1>
          {description && (
            <div className="text-text-default">{description}</div>
          )}
        </div>
        <div className="flex flex-col flex-grow">{children}</div>
        {handleSubmit && (
          <div className="flex flex-col">
            <PrimaryButton onClick={onSubmit} data-testid="next-step">
              {t([`${keyPrefix}.next`, "step.next"])}
            </PrimaryButton>
          </div>
        )}
      </div>
    </TranslationScope>
  );
};

// Loosely based on https://github.com/remix-run/react-router/pull/10468#issuecomment-1574557083
const useContainerScrollToTop = <Element extends HTMLElement>(
  getKey?: (location: Location) => string
) => {
  const container = useRef<Element>(null);
  const previousKey = useRef<string | null>(null);
  getKey = getKey ?? ((location) => location.key);
  const key = getKey(useLocation());

  useLayoutEffect(() => {
    if (key !== previousKey.current) {
      previousKey.current = key;
      container.current?.scrollTo(0, 0);
    }
  }, [key]);

  return { containerRef: container };
};

const StepContainer = ({
  paths,
  basePath,
  stepIndex,
  slug,
}: {
  paths: string[];
  basePath: string;
  stepIndex: number;
  slug: string;
}) => {
  const nextStepRoute = paths[stepIndex + 1]
    ? `${basePath}${paths[stepIndex + 1]}`
    : "/";

  const context = useMemo(
    () => ({
      Panel: (props: StepPanelProps) => (
        <StepPanel {...{ nextStepRoute, slug, ...props }} />
      ),
    }),
    [nextStepRoute, slug]
  );

  const { containerRef } = useContainerScrollToTop<HTMLDivElement>(
    (location) => location.pathname
  );

  return (
    <div className="relative h-full">
      <div className="absolute z-10 top-0 left-0 right-0 h-2 pt-[2px] bg-gradient-to-b from-bg-l250 from-25%">
        <ProgressIndicator className="h-1" />
      </div>
      <div
        className="absolute inset-0 flex flex-col overflow-y-auto"
        ref={containerRef}
        tabIndex={-1}
      >
        <Outlet context={context} />
      </div>
    </div>
  );
};

export const useStepContext = () =>
  useOutletContext<{ Panel: FC<StepPanelProps> }>();

const useCurrentStep = (paths: string[]) => {
  const currentPath = useLocation().pathname;
  const stepIndex = paths.findIndex((p) => currentPath.endsWith(p));
  return stepIndex !== -1
    ? {
        stepIndex,
        basePath: currentPath.substring(
          0,
          currentPath.length - (paths[stepIndex]?.length ?? 0)
        ),
        slug: slug(last(currentPath.split("/")) ?? ""),
      }
    : null;
};

const StepsLayout = ({ routes, sections }: StepsLayoutProps) => {
  const paths = routes.map((r) => r.path).filter(isNotNil);
  const step = useCurrentStep(paths);
  return (
    step && (
      <div
        className={cx(
          "grid [grid-template-columns:300px_1fr] w-[90vw] h-[80vh] max-w-4xl max-h-[42rem] min-h-[20rem]",
          "bg-bg-l250"
        )}
      >
        <StepsSidebar {...{ sections, paths, ...step }} />
        <StepContainer {...step} paths={paths} />
      </div>
    )
  );
};

export default StepsLayout;
