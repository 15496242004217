import React from "react";
import cx from "clsx";

import { Text, TranslationScope } from "@/components/hooks/useText";

import BarChart from "@/components/charts/BarChart";

import { useFieldMetadata, type FieldMetadata } from "./api";
import { FieldStatsNote } from "./common";
import { TableViewLink } from "./TableView";

const FieldName = ({ name }: { name: string }) => (
  <div
    className={cx(
      "px-2 py-1.5 border rounded-[4px] border-text-muted text-text-bright",
      "font-medium leading-none"
    )}
  >
    {name}
  </div>
);

const FieldAccuracyChart = ({ metadata }: { metadata: FieldMetadata }) => (
  <div className="h-80 w-full">
    <BarChart
      id="#field-accuracy"
      presentation={{
        metricType: "percentage",
        indexBy: "metric",
        groupMode: "grouped",
        showAxesLabels: false,
      }}
      data={[
        {
          value: metadata.complete_ratio,
          metric: "Completeness",
        },
        {
          value: metadata.accuracy,
          metric: "Accuracy",
        },
      ]}
    />
  </div>
);

const FieldValueStats = ({
  metadata,
  onClick,
}: {
  metadata: FieldMetadata;
  onClick?: () => void;
}) => (
  <div
    {...{ onClick }}
    className={cx(
      "text-text-default leading-tight",
      onClick && "hover:text-text-link hover:underline cursor-pointer"
    )}
  >
    <FieldStatsNote
      keyPrefix="chart_accuracy.note"
      stats={{
        missing_values_count: metadata.nas,
        unexpected_values_count: metadata.unexpected,
      }}
    />
  </div>
);

export const FieldDetails = ({ metadata }: { metadata: FieldMetadata }) => (
  <TranslationScope ns="data-quality">
    <div className="flex flex-col items-start gap-4">
      <FieldName name={metadata.name} />
      <div className="flex gap-4">
        <span className="text-text-default">
          <Text i18nKey="field.type" />
        </span>
        <span className="text-text-bright font-medium">{metadata.type}</span>
      </div>
      <FieldAccuracyChart {...{ metadata }} />
      {metadata.accuracy < 1 ? (
        metadata.errors ? (
          <TableViewLink fieldId={metadata.id}>
            {({ onClick }) => <FieldValueStats {...{ metadata, onClick }} />}
          </TableViewLink>
        ) : (
          <FieldValueStats {...{ metadata }} />
        )
      ) : null}
    </div>
  </TranslationScope>
);

export const FieldDetailsPanel = ({ fieldId }: { fieldId: string }) => {
  const metadata = useFieldMetadata({ fieldId });
  return metadata ? <FieldDetails {...{ metadata }} /> : null;
};
