import {
  differenceWith,
  fromPairs,
  groupBy,
  isEqual,
  omit,
  pick,
  toPairs,
  zipObject,
  map,
} from "lodash";

import { KeyOfType } from "@/lib/types";
import api, { useAPI } from "@/client/api";
import { EntityId, SaveOptions, useModelAPI } from "@/client/models-api";

import {
  ProductPlanComponentJSON,
  ProductPlanPriceTierJSON,
  ProductPlanPricingJSON,
  ProductPlanSetJSON,
} from "./serialization";

import { summaryPrice } from "./pricing-plan";

import { isNotNil } from "@/lib/functional";

export const useProductPlansListAPI = () => {
  const { data } = useAPI<ProductPlanSetJSON[]>("plan/");
  return data || [];
};

export const planBaseURL = (planId: string) => `/plan/${planId}/`;

const productPlansetBaseURL = (productId: string) =>
  `product/${productId}/plan/`;

export const useProductPlansetListAPI = ({
  productId,
}: {
  productId?: string;
}) => {
  const { data } = useAPI<ProductPlanSetJSON[]>(
    productId && productPlansetBaseURL(productId)
  );

  return data;
};

type PlansetCRUDEntity =
  | ProductPlanPriceTierJSON
  | ProductPlanPricingJSON
  | ProductPlanComponentJSON;

const findDeleted = (
  updated: PlansetCRUDEntity[],
  original: PlansetCRUDEntity[]
): PlansetCRUDEntity[] => {
  // Lodash differenceWith ignores elements which exists on the right but not on left.
  // We need to find those elements to ensure we are deleting them to match user expectation.
  const updatedIds = updated.map((e) => e.id);
  return original.filter((e) => !updatedIds.includes(e.id));
};

export const calculatePlansetDiff = (
  updated: ProductPlanSetJSON,
  original: ProductPlanSetJSON
) => {
  const updatedTiers = updated.prices
    .map((p) => (p.tiers ?? []).map((t) => ({ ...t, price_id: p.id })))
    .flat()
    // Treat tiers with empty price as deleted
    .filter((t) => isNotNil(t.tier_price));

  const originalTiers = original.prices
    .map((p) => (p.tiers ?? []).map((t) => ({ ...t, price_id: p.id })))
    .flat();

  const tierTotalDiff = differenceWith(updatedTiers, originalTiers, isEqual);
  const tierDiffGroup = groupBy(tierTotalDiff, (t) =>
    EntityId.isNew(t.id) ? "created" : "updated"
  );
  const tierDiff = {
    created: tierDiffGroup["created"] || [],
    updated: tierDiffGroup["updated"] || [],
    deleted: findDeleted(updatedTiers, originalTiers),
  };

  const updatedPrices = updated.prices
    // Treat prices with empty default_amount as deleted
    .filter((p) => isNotNil(summaryPrice(p)))
    .map((p) => omit(p, "tiers"))
    .map((p) => ({ ...p, plan_id: updated.id }));

  const originalPrices = original.prices
    .map((p) => omit(p, "tiers"))
    .map((p) => ({ ...p, plan_id: original.id }));

  const priceTotalDiff = differenceWith(updatedPrices, originalPrices, isEqual);
  const priceDiffGroup = groupBy(priceTotalDiff, (t) =>
    EntityId.isNew(t.id) ? "created" : "updated"
  );

  const priceDiff = {
    created: priceDiffGroup["created"] || [],
    updated: priceDiffGroup["updated"] || [],
    deleted: findDeleted(updatedPrices, originalPrices),
  };

  const planComponentAttrs = [
    "id",
    "product_component_id",
    "quantity",
    "order",
  ];

  const componentTotalDiff = differenceWith(
    updated.components.map((c) => pick(c, planComponentAttrs)),
    original.components.map((c) => pick(c, planComponentAttrs)),
    isEqual
  );
  const componentDiffGroup = groupBy(componentTotalDiff, (c) =>
    EntityId.isNew(c.id) ? "created" : "updated"
  );
  const componentDiff = {
    created: componentDiffGroup["created"] || [],
    updated: componentDiffGroup["updated"] || [],
    deleted: findDeleted(updated.components, original.components),
  };

  const updatedPlan = toPairs(omit(updated, ["components", "prices"]));
  const originalPlan = toPairs(omit(original, ["components", "prices"]));
  const planPatchData = fromPairs(
    differenceWith(updatedPlan, originalPlan, isEqual)
  );

  return { tierDiff, priceDiff, componentDiff, planPatchData };
};

type DiffGroupAction = (
  resourcePath: string,
  obj: Partial<PlansetCRUDEntity>
) => Promise<PlansetCRUDEntity>;

interface DiffGroupActions {
  deleted: DiffGroupAction;
  created: DiffGroupAction;
  updated: DiffGroupAction;
}

const DiffGroupActionTable: DiffGroupActions = {
  deleted: async (resourcePath: string, obj: Partial<PlansetCRUDEntity>) => {
    const entity =
      obj.id && (await api.delete(`${resourcePath}${obj.id}/`).json());
    return entity as PlansetCRUDEntity;
  },
  created: async (resourcePath: string, obj: Partial<PlansetCRUDEntity>) => {
    return await api.post(`${resourcePath}`, { json: omit(obj, "id") }).json();
  },
  updated: async (resourcePath: string, obj: Partial<PlansetCRUDEntity>) => {
    const entity =
      obj.id &&
      (await api.patch(`${resourcePath}${obj.id}/`, { json: obj }).json());
    return entity as PlansetCRUDEntity;
  },
};

const remapIds = <T extends object, Name extends KeyOfType<T, string>>(
  input: T[],
  idMap: Record<string, string>,
  propName: Name
) =>
  input.map((obj) => ({
    ...obj,
    [propName]: idMap[obj[propName] as string] ?? obj[propName],
  }));

export const useProductPlansetAPI = ({
  productId,
  plansetId,
  options,
}: {
  productId: string;
  plansetId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
}) => {
  const { json, saveEntity, patchEntity, deleteEntity } =
    useModelAPI<ProductPlanSetJSON>({
      entityId: plansetId,
      endpointURL: productPlansetBaseURL(productId),
      options,
    });

  const patchPlanset = async (
    entity: ProductPlanSetJSON,
    options?: SaveOptions
  ) => {
    // Planset is a complicated tree of objects:
    // - PlanComponent
    // - Price
    // - Tier (through Price)
    // In patch scenario, we need to go bottom-up on this order, to make sure
    // that the returned response is the most recent data.
    //
    // Within those objects, we process the diff in the following order, top-down:
    // - which objects are deleted?
    // - which objects are created?
    // - which objects are updated?
    const { tierDiff, priceDiff, componentDiff, planPatchData } =
      calculatePlansetDiff(entity, json);

    const planComponentURL = `plan/${plansetId}/component/`;
    // 1. Deleted -- has different order compared to created and updated lists because of foreign key dependency
    await Promise.all([
      ...tierDiff.deleted.map((t) => DiffGroupActionTable.deleted("tier/", t)),
      ...priceDiff.deleted.map((p) =>
        DiffGroupActionTable.deleted("price/", p)
      ),
      ...componentDiff.deleted.map((c) =>
        DiffGroupActionTable.deleted(planComponentURL, c)
      ),
    ]);

    // 2. Created
    await Promise.all(
      componentDiff.created.map((c) =>
        DiffGroupActionTable.created(
          planComponentURL,
          pick(c, ["product_component_id", "quantity"])
        )
      )
    );

    const createdPrices = await Promise.all(
      priceDiff.created.map((p) => DiffGroupActionTable.created("price/", p))
    );

    const priceIdsMap = zipObject(
      map(priceDiff.created, "id") as string[],
      map(createdPrices, "id") as string[]
    );

    tierDiff.created = remapIds(tierDiff.created, priceIdsMap, "price_id");

    await Promise.all(
      tierDiff.created.map((t) => DiffGroupActionTable.created("tier/", t))
    );

    // 3. Updated
    await Promise.all(
      componentDiff.updated.map((c) =>
        DiffGroupActionTable.updated(planComponentURL, c)
      )
    );
    await Promise.all(
      priceDiff.updated.map((p) => DiffGroupActionTable.updated("price/", p))
    );
    await Promise.all(
      tierDiff.updated.map((t) => DiffGroupActionTable.updated("tier/", t))
    );

    return patchEntity(planPatchData, options);
  };

  const createOrPatchPlanset = async (
    entity: ProductPlanSetJSON,
    options?: SaveOptions | undefined
  ) => {
    if (EntityId.isNew(entity.id)) {
      // Filter out Price objects which doesn't have amounts declared,
      // which means it's probably custom pricing.
      const prices = entity.prices.filter((p) => isNotNil(summaryPrice(p)));
      return await saveEntity({ ...entity, prices }, options);
    }
    return await patchPlanset(entity, options);
  };

  return {
    planset: json,
    createOrPatchPlanset,
    patchPlanset,
    savePlanset: saveEntity,
    deletePlanset: deleteEntity,
  };
};
