import React, { ReactNode, useState } from "react";
import { useInView, IntersectionOptions } from "react-intersection-observer";
import cx from "clsx";

const LazyRender = ({
  children,
  fadeIn,
  options,
}: {
  children: ({
    inView,
    bounds,
  }: {
    inView: boolean;
    bounds?: DOMRectReadOnly;
  }) => ReactNode;
  fadeIn?: boolean;
  options?: IntersectionOptions;
}) => {
  const [mounted, setMounted] = useState(false);
  const { ref, inView, entry } = useInView({
    trackVisibility: false,
    onChange: (inView) => {
      if (inView && !mounted) setMounted(true);
    },
    ...options,
  });

  return (
    <div
      className={
        fadeIn
          ? cx(
              "h-full w-full transition-opacity duration-500",
              mounted ? "opacity-1" : "opacity-0"
            )
          : "contents"
      }
      {...(mounted && { ref })}
    >
      {mounted ? (
        children({ inView, bounds: entry?.boundingClientRect })
      ) : (
        <div ref={ref} className="h-full w-full" />
      )}
    </div>
  );
};

export default LazyRender;
