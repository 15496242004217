// see https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript/
export const isNotNil = <T>(item: T | null | undefined): item is T =>
  item !== null && item !== undefined;

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const entries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

export type KeysByValueType<T, V> = Exclude<
  {
    [K in keyof T]: T[K] extends V ? K : never;
  }[keyof T],
  undefined
>;
