import React, { useMemo, type PropsWithChildren } from "react";
import * as Progress from "@radix-ui/react-progress";

import cx from "clsx";

import format from "@/lib/format";

import tw from "@/styles/tailwind";

export type FieldSlotProps = {
  id: string;
  name: string;
  accuracy: number;
  inPort?: boolean;
  outPort?: boolean;
};

const Label = ({
  children,
  className,
  clipPercentage,
  reverseClip,
}: PropsWithChildren<{
  className: string;
  clipPercentage: number;
  reverseClip?: boolean;
}>) => {
  const clipBound = Math.round(clipPercentage * 100);
  return (
    <span
      className={cx("text-xs font-semibold text-base-green-400", className)}
      style={{
        clipPath: reverseClip
          ? `polygon(${clipBound}% 0, ${clipBound}% 100%, 100% 100%, 100% 0)`
          : `polygon(0 0, 0 100%, ${clipBound}% 100%, ${clipBound}% 0)`,
      }}
    >
      {children}
    </span>
  );
};

const baseColors = tw.theme.colors.base;

export const useAccuracyColor = (
  value: number,
  level: "all" | "warning" | "error" = "all"
) => {
  if (value <= 0.6) {
    // see https://tailwindcss.com/docs/content-configuration#dynamic-class-names
    return {
      bg: "bg-base-red-400",
      border: "border-base-red-400",
      text: "text-base-red-400",
      stroke: "stroke-base-red-400",
      rgb: baseColors.red["400"],
    };
  }

  if (level === "error") return undefined;
  if (value < 0.85) {
    return {
      bg: "bg-base-orange-400",
      border: "border-base-orange-400",
      text: "text-base-orange-400",
      rgb: baseColors.orange["400"],
    };
  }

  if (level === "warning") return undefined;
  return {
    bg: "bg-base-green-400",
    border: "border-base-green-400",
    text: "text-base-green-400",
    rgb: baseColors.green["400"],
  };
};

type MeterSize = "sm" | "md";

const labelStyle: Record<MeterSize, string> = {
  sm: "leading-none px-1 py-[3px]",
  md: "leading-tight px-1 py-0.5",
};

export const AccuracyMeter = ({
  value,
  size = "md",
}: {
  value: number;
  size?: MeterSize;
}) => {
  const color = useAccuracyColor(value);
  const label = useMemo(
    () =>
      format.percentage(value, undefined, {
        fractional: true,
        maxFractionDigits: 0,
      }),
    [value]
  );

  return (
    <Progress.Root
      className={cx(
        "relative flex border rounded-[4px] items-center overflow-hidden",
        color?.border
      )}
      value={value}
    >
      <Label
        className={cx(color?.text, labelStyle[size])}
        clipPercentage={value}
        reverseClip={true}
      >
        {label}
      </Label>
      <Progress.Indicator
        className={cx(
          "absolute left-0 top-0 h-full transition-all",
          color?.bg,
          value > 0.95 ? "rounded-[3px]" : "rounded-l-[3px]"
        )}
        style={{ width: `${value * 100}%` }}
      />
      <Label
        className={cx(
          "absolute left-0 top-1/2 -translate-y-1/2 text-bg-l1",
          labelStyle[size]
        )}
        clipPercentage={value}
      >
        {label}
      </Label>
    </Progress.Root>
  );
};
