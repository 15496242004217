import React from "react";
import {
  PriceRuleType,
  IPriceRuleBase,
  PRICE_RULE_TYPES,
} from "./models/price-rules";
import PriceRuleTemplateCard from "./PriceRuleTemplateCard";

export type PriceRuleTemplatesProps = {
  onCreate: (type: PriceRuleType) => void;
  ruleTemplates?: IPriceRuleBase[];
};

export const PriceRuleTemplates: React.FC<PriceRuleTemplatesProps> = ({
  onCreate,
  ruleTemplates = PRICE_RULE_TYPES.map((pr) => ({
    id: pr,
    type: pr,
  })),
}) => {
  return (
    <div className="grid grid-cols-3 gap-4">
      {ruleTemplates.map((ruleTemplate, index) => (
        <PriceRuleTemplateCard
          key={`${ruleTemplate.id}-${index}`}
          onClick={onCreate}
          ruleTemplate={ruleTemplate}
        />
      ))}
    </div>
  );
};

export default PriceRuleTemplates;
