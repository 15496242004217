import React, { PropsWithChildren } from "react";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as NivoThemeProvider } from "@nivo/core";

import { TooltipProvider } from "@/components/Tooltip";

import { darkTheme } from "@/themes/theme";
import insightsTheme from "@/themes/insightsTheme";

const RootProviders = ({ children }: PropsWithChildren) => (
  <ThemeProvider theme={darkTheme}>
    <NivoThemeProvider theme={insightsTheme}>
      <CssBaseline>
        <TooltipProvider>{children}</TooltipProvider>
      </CssBaseline>
    </NivoThemeProvider>
  </ThemeProvider>
);

export default RootProviders;
