export const toQueryString = (params: Record<string, string>) => {
  // use `encodeURIComponent` instead of `URLSearchParams`: the latter encodes spaces as `+` instead of `%20`,
  // but '+' in the `mailto:` links is interpreted literally, not as a space
  const queryParams = Object.entries(params)
    .filter(([_, value]) => Boolean(value))
    .map(([key, value]) => [key, encodeURIComponent(value)])
    .map(([key, value]) => `${key}=${value}`);

  return queryParams.length ? `?${queryParams.join("&")}` : "";
};
