import { useAPI } from "@/client/api";
import { useModelAPI, deleteEntity, SaveOptions } from "@/client/models-api";

import { IPriceRule } from "./price-rules";

import {
  PriceRuleJSON,
  toPriceRule,
  serializePriceRule,
} from "@/pages/priceRules/models/serialization";

const rulesetBaseURL = "salesforce/builder/ruleset/";

export type SaveRuleCallback = (
  rule: IPriceRule,
  options: SaveOptions
) => Promise<void>;

export const usePriceRulesAPI = ({ ruleId }: { ruleId?: string }) => {
  const { json, saveEntity } = useModelAPI<PriceRuleJSON>({
    entityId: ruleId,
    endpointURL: rulesetBaseURL,
  });

  return {
    priceRule: json ? toPriceRule(json) : null,
    saveRule: async (rule: IPriceRule, options: SaveOptions) =>
      await saveEntity(serializePriceRule(rule), options),
  };
};

export const usePriceRulesListAPI = () => {
  const { data, mutate } = useAPI<PriceRuleJSON[]>(rulesetBaseURL);
  return { rules: data && data.map(toPriceRule), mutate };
};

export const deleteRule = async (ruleId: string, mutate: () => void) =>
  await deleteEntity({
    endpointURL: rulesetBaseURL,
    entityId: ruleId,
    mutate,
  });
