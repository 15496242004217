import React, { SVGProps } from "react";

const GradientHero = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="none"
    viewBox="0 0 988 893"
    {...props}
  >
    <g filter="url(#filter0_f_566_649)" opacity="0.6">
      <mask
        id="mask0_566_649"
        style={{ maskType: "alpha" }}
        width="729"
        height="780"
        x="147"
        y="99"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#0004FF"
          d="M154.77 489.456C154.77 274.365 316.223 100 515.385 100S876 274.365 876 489.456 714.547 878.913 515.385 878.913C313.088 860.865 103.756 716.481 154.77 489.456z"
        ></path>
      </mask>
      <g mask="url(#mask0_566_649)">
        <path
          fill="url(#pattern0)"
          fillOpacity="0.85"
          d="M876.359 792.579H1713.4250000000002V1629.645H876.359z"
          transform="rotate(-180 876.359 792.579)"
        ></path>
      </g>
    </g>
    <path
      fill="#fff"
      fillOpacity="0.02"
      d="M849.98 458.868c-70.784 108.166-208.988 149.539-342.381 114.206-10.1-3.742-12.514-12.296-8.456-18.5L797.326 98.592c4.057-6.204 12.853-7.41 20.319.36 85.697 108.27 103.096 251.632 32.383 359.843"
    ></path>
    <path
      fill="#fff"
      fillOpacity="0.015"
      d="M924.773 597.843c-85.209 130.21-251.581 180.015-412.161 137.481-12.158-4.505-15.064-14.801-10.179-22.271L861.388 164.14c4.884-7.47 15.473-8.922 24.46.433 103.163 130.336 124.112 302.916 38.983 433.182"
    ></path>
    <path
      fill="#fff"
      fillOpacity="0.02"
      d="M166.48 393.921c72.611-106.948 211.498-145.966 344.272-108.372 10.035 3.913 12.303 12.506 8.141 18.641L213.005 755.04c-4.162 6.134-12.977 7.191-20.31-.706-83.845-109.71-98.806-253.346-26.264-360.341"
    ></path>
    <path
      fill="#fff"
      fillOpacity="0.015"
      d="M57.706 288.658c83.005-122.256 241.773-166.859 393.552-123.884 11.471 4.473 14.064 14.297 9.306 21.31L110.891 701.468c-4.758 7.013-14.835 8.221-23.217-.806C-8.173 575.248-25.276 411.051 57.65 288.741"
    ></path>
    <defs>
      <filter
        id="filter0_f_566_649"
        width="929"
        height="892.58"
        x="47"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feGaussianBlur
          result="effect1_foregroundBlur_566_649"
          stdDeviation="50"
        ></feGaussianBlur>
      </filter>
      <pattern
        id="pattern0"
        width="1"
        height="1"
        patternContentUnits="objectBoundingBox"
      >
        <use transform="scale(.0005)" xlinkHref="#image0_566_649"></use>
      </pattern>
      <image
        id="image0_566_649"
        width="2000"
        height="2000"
        href="/gradient-hero-base.webp"
      ></image>
    </defs>
  </svg>
);

export default GradientHero;
