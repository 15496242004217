import React, { memo } from "react";

import cx from "clsx";

//@ts-ignore
import { ReactComponent as ProcessIcon } from "../icons/process.svg";
//@ts-ignore
import { ReactComponent as DiscountsIcon } from "../icons/discounts.svg";
//@ts-ignore
import { ReactComponent as DealsIcon } from "../icons/deals.svg";

import { Text, TranslationScope, useText } from "@/components/hooks/useText";

type QuestionCategory = "discounts" | "deals" | "process";

export interface SuggestedQuestion {
  id: string;
  category: QuestionCategory;
}

const useSuggestedQuestions = (query: string): SuggestedQuestion[] => {
  return query.length > 100
    ? []
    : [
        { id: "discounts_vs_win_rates", category: "discounts" },
        { id: "opps_in_lost_deals", category: "deals" },
        { id: "sales_process_improvement", category: "process" },
      ];
};

const icon: Record<QuestionCategory, JSX.Element> = {
  discounts: <DiscountsIcon className="text-base-orange-300" />,
  deals: <DealsIcon className="text-base-green-300" />,
  process: <ProcessIcon className="text-base-pink-300" />,
};

const QuestionIcon = ({ category }: { category: QuestionCategory }) => (
  <div className="w-9 aspect-square shrink-0">{icon[category]}</div>
);

const Question = ({
  question,
  onClick,
}: {
  question: SuggestedQuestion;
  onClick: (query: string) => void;
}) => {
  const keyPrefix = `question.${question.id}`;
  const t = useText({ keyPrefix });
  return (
    <TranslationScope {...{ keyPrefix }}>
      <li
        className={cx(
          "flex flex-col gap-3 w-[360px] cursor-pointer outline-none bg-bg-l3 p-4 rounded-md",
          "border-[1.5px] border-transparent hover:border-text-link focus:border-text-link overflow-hidden"
        )}
        tabIndex={0}
        onClick={() => onClick(t("query"))}
      >
        <div className="flex gap-3 items-center">
          <QuestionIcon {...question} />
          <div className="text-text-bright leading-tight">
            <Text i18nKey="query" />
          </div>
        </div>
        <div className="text-sm text-text-default leading-tight">
          <span className="font-semibold">
            <Text i18nKey="insights:search:suggested_questions:why" />
          </span>{" "}
          <Text i18nKey="rationale" />
        </div>
      </li>
    </TranslationScope>
  );
};

// `useDeferredValue` optimization in `SearchPanel` also requires the use of `memo` here,
// see https://react.dev/reference/react/useDeferredValue#deferring-re-rendering-for-a-part-of-the-ui
const SuggestedQuestions = memo(
  ({
    query,
    onSelected,
  }: {
    query: string;
    onSelected: (query: string) => void;
  }) => {
    const questions = useSuggestedQuestions(query);
    return questions?.length !== 0 ? (
      <section className="flex flex-col gap-2">
        <header className="text-text-default font-medium">
          <Text i18nKey="header" />
        </header>
        <ul className="flex gap-2 w-full overflow-x-hidden">
          {questions.map((question) => (
            <Question
              key={question.id}
              {...{ question }}
              onClick={onSelected}
            />
          ))}
        </ul>
      </section>
    ) : null;
  }
);

export default SuggestedQuestions;
