import useLocalStorageState, {
  LocalStorageOptions,
} from "use-local-storage-state";

import { useAuth } from "@/components/Auth";

const useOrgSessionLocalStorage = <T,>(
  key: string,
  options?: LocalStorageOptions<T>
) => {
  const { session } = useAuth();
  console.assert(session.organization?.id);

  return useLocalStorageState<T>(
    `${session.organization?.id || "unknown"}-${key}`,
    options
  );
};

export default useOrgSessionLocalStorage;
