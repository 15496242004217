import React from "react";
import cx from "clsx";

import { isNotNil } from "@/lib/functional";

import { TranslationScope } from "@/components/hooks/useText";

import { ChartAnimationProvider } from "@/components/insights/animation";
import { ChartDataProvider } from "@/components/insights/ChartData";
import ChartWidget2 from "@/components/insights/ChartWidget2";

const ChartPreview = ({ id, category }: { id: string; category: string }) => (
  <TranslationScope ns="insights">
    <ChartDataProvider routeKey={category}>
      <ChartAnimationProvider {...{ animate: false, lazyRender: false }}>
        <fieldset disabled={true} className="contents">
          <ChartWidget2
            {...{
              id,
              presentation: { showAxesLabels: false, showTabs: false },
            }}
            className={cx(
              "h-[400%] w-[400%] origin-top-left scale-[0.25] pointer-events-none select-none",
              "bg-bg-l250 p-4 rounded-xl"
            )}
          />
        </fieldset>
      </ChartAnimationProvider>
    </ChartDataProvider>
  </TranslationScope>
);

const parseChartId = (compositeId: string) => {
  const [category, id] = compositeId.split("/");
  return id && category ? { id, category } : null;
};

const KeyCharts = ({ charts }: { charts: string[] }) => (
  <div className="flex gap-2 mt-1">
    {charts
      ?.map(parseChartId)
      .filter(isNotNil)
      .map((c, i) => (
        <div
          key={c.id}
          className={cx(
            "w-[100px] h-[70px] shrink-0 opacity-0",
            [
              "animate-fade-in-nth-0",
              "animate-fade-in-nth-1",
              "animate-fade-in-nth-2",
              "animate-fade-in-nth-3",
              "animate-fade-in-nth-4",
            ][i] ?? "animate-fade-in-nth-4"
          )}
        >
          <ChartPreview {...c} />
        </div>
      ))}
  </div>
);

export default KeyCharts;
