import React, { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";

import useOrgSessionLocalStorage from "@/components/hooks/useSessionLocalStorage";

import SetupGuide from "@/pages/onboarding/v2/SetupGuide";
import {
  useOnboardingStatusAPI,
  OnboardingStatus,
  confirmSalesforcePackageInstallation,
} from "@/pages/onboarding/api";

export const useOnboardingLocalState = () =>
  useOrgSessionLocalStorage<OnboardingStatus | null>("onboarding", {
    defaultValue: null,
  });

export const useOnboardingStatus = () => {
  const [localState, setLocalState] = useOnboardingLocalState();
  const serverState = useOnboardingStatusAPI(localState);
  useEffect(() => {
    if (serverState) setLocalState(serverState);
  }, [serverState, setLocalState]);

  return {
    completedOrUnknown: localState?.completed || !serverState,
    ...serverState,
  };
};

const checkSalesforceInstallConfirmation = async (
  rootQuery: [string, string][]
) => {
  const serviceUser = Object.fromEntries(rootQuery)["salesforce_svc"];
  if (serviceUser) {
    await confirmSalesforcePackageInstallation({ serviceUser });
  }
};

const OnboardingCheck = ({ children }: PropsWithChildren) => {
  const { completedOrUnknown, completedRequired } = useOnboardingStatus();

  const location = useLocation();
  useEffect(() => {
    checkSalesforceInstallConfirmation(location.state?.rootQuery ?? []);
  }, [location.state]);

  if (completedOrUnknown) return <>{children}</>;

  return (
    <>
      {!completedRequired && <SetupGuide open={true} onClose={() => {}} />}
      {children}
    </>
  );
};

export default OnboardingCheck;
