import React, {
  useMemo,
  PropsWithChildren,
  MouseEventHandler,
  useCallback,
} from "react";

import { useController, FieldValues, FieldPathByValue } from "react-hook-form";

import { NumericFormat } from "react-number-format";

import { isNil, findIndex } from "lodash";
import cx from "clsx";

import { CustomInputProps } from "@/components/input/props";
import {
  NumericInputCurrencyOptions,
  NumericInputOptions,
  InputPadding,
} from "@/components/input/types";

import { useInnerRef } from "@/components/hooks";

export const adornmentFormatter = (
  options: NumericInputOptions,
  locale?: string
) => {
  try {
    return new Intl.NumberFormat(locale, {
      style: options.valueType,
      ...(options.valueType === "currency" && {
        currency: options.currency ?? "USD",
      }),
      ...(options.valueType === "unit" && {
        unit: options.unit,
      }),
    });
  } catch (x) {
    return null;
  }
};

const priceSuffixIfAny = (price: NumericInputCurrencyOptions["asPrice"]) =>
  price && typeof price === "object" && price.perUnit
    ? { suffix: `/${price.perUnit}` }
    : {};

export const inputAdornment = (
  formatter: Intl.NumberFormat | null,
  options: NumericInputOptions,
  value: number
) => {
  if (options.valueType === "decimal") return {};

  const priceSuffix =
    options.valueType === "currency" && priceSuffixIfAny(options.asPrice);

  if (formatter) {
    const formatParts = formatter.formatToParts(value);
    const index = findIndex(formatParts, (p) =>
      p.type.startsWith(options.valueType)
    );

    return {
      ...priceSuffix,
      [index === formatParts.length - 1 ? "suffix" : "prefix"]:
        formatParts[index]?.value ?? "?",
    };
  }

  if (options.valueType === "unit") {
    return {
      prefix: undefined,
      suffix: options.unit,
    };
  }

  return {};
};

const Adornment = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <span
    className={cx(
      "flex items-center leading-none state-transition-colors",
      "text-text-muted group-focus-within:text-text-default select-none pointer-events-none",
      className
    )}
  >
    <span className="max-w-[8em] py-0.5 whitespace-nowrap overflow-hidden text-ellipsis">
      {children}
    </span>
  </span>
);

const calcInputProps = (options?: NumericInputOptions) => ({
  allowNegative: options?.allowNegative ?? false,
  decimalScale:
    options?.decimalScale ??
    (options?.valueType === "currency" && options?.asPrice ? 6 : undefined),
});

const NumericInput = <
  Entity extends FieldValues,
  Name extends FieldPathByValue<Entity, number | null | undefined>
>({
  name,
  control,
  options = { valueType: "decimal" },
  placeholder,
  required,
  disabled,
  onBlur,
  className,
  inputClassName,
  adornmentClassName,
  padding = "normal",
  autoSize = false,
  hidePlaceholderWhenFocused = false,
}: CustomInputProps<Entity, Name> & {
  onBlur?: (value: number | null) => void;
  options?: NumericInputOptions;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  adornmentClassName?: string;
  padding?: InputPadding;
  autoSize?: boolean;
  hidePlaceholderWhenFocused?: boolean;
}) => {
  const {
    field: { ref, value, onChange, ...field },
    fieldState: { invalid },
  } = useController({ control, name, rules: { required } });

  const [inputRef, setInputRef] = useInnerRef(ref);

  const focusInput: MouseEventHandler<HTMLSpanElement> = useCallback(
    (e) => {
      if (e.target !== inputRef.current) {
        e.preventDefault();
        inputRef.current?.focus();
      }
    },
    [inputRef]
  );

  const handleBlur = useCallback(
    () => onBlur?.(value ?? null),
    [onBlur, value]
  );

  const adFormatter = useMemo(
    () => adornmentFormatter(options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(options)]
  );

  const { prefix, suffix } = inputAdornment(adFormatter, options, value);
  const inputProps = calcInputProps(options);

  const unitsInput = options.valueType === "unit";
  const snugPadding = padding === "snug";

  return (
    <span
      className={cx(
        "inline-flex group input-border items-stretch min-w-0",
        invalid && "!border-border-error",
        !disabled && "cursor-text",
        className
      )}
      onClick={focusInput}
      onBlur={handleBlur}
      data-headlessui-state={disabled ? "disabled" : ""}
    >
      {prefix && (
        <Adornment
          className={cx(snugPadding ? "pl-1.5" : "pl-2.5", adornmentClassName)}
        >
          {prefix}
        </Adornment>
      )}
      <span
        className={cx(
          "inline-grid grow shrink",
          prefix ? "pl-1" : snugPadding ? "pl-1.5" : "pl-2.5",
          suffix ? "pr-1.5" : snugPadding ? "pr-1.5" : "pr-2.5"
        )}
      >
        {autoSize && (
          <NumericFormat
            thousandSeparator={true}
            displayType="text"
            className="col-start-1 row-start-1 input-text min-w-0 invisible input-like-tracking"
            value={value ?? 0}
          />
        )}
        {isNil(value) && (autoSize || hidePlaceholderWhenFocused) && (
          <span
            className={cx(
              "col-start-1 row-start-1 input-text min-w-0",
              hidePlaceholderWhenFocused
                ? "flex items-center text-text-muted group-focus-within:hidden select-none pointer-events-none"
                : "invisible"
            )}
            aria-hidden="true"
          >
            <span>{placeholder}</span>
          </span>
        )}
        <NumericFormat
          thousandSeparator={true}
          className={cx(
            "col-start-1 row-start-1 input-text bg-transparent min-w-0 outline-none",
            snugPadding ? "py-1" : "py-2",
            hidePlaceholderWhenFocused && "placeholder:opacity-0",
            inputClassName
          )}
          autoComplete="off"
          getInputRef={setInputRef}
          placeholder={placeholder}
          value={value ?? null}
          onValueChange={({ floatValue }) => onChange(floatValue ?? null)}
          disabled={disabled}
          {...inputProps}
          {...(autoSize && { size: 1 })}
          {...field}
        />
      </span>
      {suffix && (
        <Adornment
          className={cx(
            unitsInput && isNil(value) && "hidden group-focus-within:flex",
            snugPadding ? "pr-1.5" : "pr-2.5",
            adornmentClassName
          )}
        >
          {suffix}
        </Adornment>
      )}
    </span>
  );
};

export default NumericInput;
