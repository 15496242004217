import React from "react";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as DeleteIcon } from "@/assets/icons/trash.svg";

export const DeleteItemButton = ({
  onClick,
  className,
}: {
  onClick: VoidFunction;
  className?: string;
}) => (
  <button
    type="button"
    className={cx(
      "w-9 aspect-square flex items-center justify-center text-border-bright rounded-md",
      "outline-none focus:text-text-error hover:text-text-error",
      className
    )}
    onClick={onClick}
  >
    <DeleteIcon />
  </button>
);
