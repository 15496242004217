import React, { useEffect, useMemo, useState } from "react";
import { useBypassAPI, useBypassListAPI } from "./api";
import { BypassData, BypassJSON, bypassParseJSON } from "./serialization";
import { groupBy, orderBy } from "lodash";

// @ts-ignore
import { ReactComponent as SuccessIcon } from "@/assets/icons/check-circle.svg";
// @ts-ignore
import { ReactComponent as WarningIcon } from "@/assets/icons/exclamation-triangle.svg";
// @ts-ignore
import { ReactComponent as CalendarIcon } from "@/assets/icons/calendar.svg";
// @ts-ignore
import { ReactComponent as MinusIcon } from "@/assets/icons/minus.svg";
// @ts-ignore
import { ReactComponent as PlusIcon } from "@/assets/icons/plus.svg";
// @ts-ignore
import { ReactComponent as InformationIcon } from "@/assets/icons/information-circle.svg";

import "@github/relative-time-element";
import cx from "clsx";
import { useBoolean } from "usehooks-ts";
import Toggle from "@/components/input/Toggle";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import TextInput from "@/components/input/TextInput";
import { PrimaryButton, CancelButton } from "@/components/CoreButtons";
import { add, formatISO } from "date-fns";

const relativeTimelines = ["current", "upcoming", "past"] as const;
type relativeTimelineOptions = (typeof relativeTimelines)[number];

const currentLocale = (): string => {
  if (typeof Intl !== "undefined") {
    try {
      return Intl.NumberFormat().resolvedOptions().locale;
    } catch (err) {
      console.error("Cannot get locale from Intl, defaulting to en-US.");
    }
  }
  return "en-US";
};

const BypassDisplayDateTime = ({
  epoch,
  className,
}: {
  epoch: number;
  className?: string;
}) => {
  const value = new Date(epoch);
  const dateOpts: Partial<Intl.DateTimeFormatOptions> = {
    month: "short",
    day: "numeric",
  };
  const currentYear = new Date().getFullYear();
  if (currentYear !== value.getFullYear()) {
    dateOpts["year"] = "numeric";
  }

  return (
    <span className={className}>
      {value.toLocaleDateString(currentLocale(), dateOpts)}
      <br />
      {value.toLocaleTimeString(currentLocale(), {
        hour: "2-digit",
        minute: "2-digit",
      })}
    </span>
  );
};

const BypassDisplay = ({
  bypass,
  relativeTimeline,
}: {
  bypass: BypassData;
  relativeTimeline: relativeTimelineOptions;
}) => {
  const relativeTime = new Date(
    relativeTimeline === "upcoming" ? bypass.start_date : bypass.end_date
  ).toISOString();

  const {
    bypass: reloaded,
    deleteBypass,
    setBypassActive,
  } = useBypassAPI({
    bypassId: bypass.id,
  });
  bypass = (reloaded && bypassParseJSON(reloaded)) || bypass;

  return (
    <div
      className={cx(
        "w-full",
        bypass.is_active ? "text-text-default" : "text-text-muted"
      )}
    >
      <div className="flex border border-border-default rounded-md p-4 gap-8">
        <div className="max-w-xs flex items-center">
          <Toggle value={bypass.is_active} onChange={setBypassActive} />
        </div>
        <div className="flex-grow grid md:grid-cols-4">
          <div className="flex flex-col gap-y-1">
            <div className="flex items-center gap-x-2">
              <h2
                className={cx(
                  "my-1 text-lg font-semibold leading-tight",
                  bypass.is_active && "text-text-bright"
                )}
              >
                {bypass.name}
              </h2>
            </div>
            {bypass.is_active ? (
              <div className="text-sm">
                {relativeTimeline === "upcoming" && "Starts "}
                {relativeTimeline === "current" && "Ends "}
                {relativeTimeline === "past" && "Ended "}
                <relative-time
                  formatStyle="long"
                  month="long"
                  datetime={relativeTime}
                >
                  {relativeTime}
                </relative-time>
              </div>
            ) : (
              <span className="py-0.5 px-1 uppercase rounded bg-bg-l3 text-text-default text-2xs mr-auto">
                inactive
              </span>
            )}
          </div>
          <div className="hidden col-span-3 md:grid grid-cols-4 items-center justify-start gap-1">
            <div
              className={cx(
                "md:col-span-3 flex items-center gap-4 px-8 rounded",
                bypass.is_active || "line-through"
              )}
            >
              <CalendarIcon className="h-6 w-6 mx-2" />
              <div className="grid grid-cols-5 gap-1 w-full items-center">
                <BypassDisplayDateTime
                  className="col-span-2"
                  epoch={bypass.start_date}
                />
                <MinusIcon className="h-6 w-6 mx-auto" />
                <BypassDisplayDateTime
                  className="col-span-2"
                  epoch={bypass.end_date}
                />
              </div>
            </div>
            <button
              className={cx(
                "px-3 py-2 rounded-md",
                deleteBypass
                  ? "text-text-default hover:bg-bg-l3 hover:text-text-error hover:border border-border-error focus:border focus:border-border-error"
                  : "cursor-not-allowed text-text-muted"
              )}
              onClick={() => {
                if (
                  deleteBypass &&
                  window.confirm(
                    `"${bypass.name}" will be deleted. Please confirm as this action takes effect immediately and cannot be undone!`
                  )
                )
                  deleteBypass();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const BypassSectionList = ({
  timeline,
  bypasses,
}: {
  timeline: relativeTimelineOptions;
  bypasses: BypassData[];
}) => {
  const maxPerSection = 2;

  // TODO: there has to be better naming than this...
  const nearBypasses = useMemo(
    () => bypasses.slice(0, maxPerSection),
    [bypasses, maxPerSection]
  );
  const farBypasses = useMemo(
    () => bypasses.slice(maxPerSection),
    [bypasses, maxPerSection]
  );

  const { value: showMore, setTrue } = useBoolean(false);

  return (
    <>
      {bypasses.length > 0 && (
        <div key={timeline} className="flex flex-col gap-4">
          <h3 className="capitalize text-xl font-semibold">{timeline}</h3>
          {nearBypasses.map((b) => (
            <BypassDisplay key={b.id} bypass={b} relativeTimeline={timeline} />
          ))}
          {showMore &&
            farBypasses.map(
              (b) =>
                b && (
                  <BypassDisplay
                    key={b.id}
                    bypass={b}
                    relativeTimeline={timeline}
                  />
                )
            )}
          {farBypasses.length > 0 && !showMore && (
            <button
              className={cx(
                "py-1 px-2 rounded-md text-sm text-text-default mx-auto",
                "hover:bg-bg-l3 hover:text-text-link outline-none",
                "border border-border-default focus:border focus:border-border-focus"
              )}
              onClick={setTrue}
            >
              Show all {bypasses.length} {timeline} bypasses
            </button>
          )}
        </div>
      )}
    </>
  );
};

const bypassOrderConfig = {
  // Upcoming is a special case because the closest event will be the start date.
  // In other timelines, the closest event will be end date, whether it's past or not.
  upcoming: [
    ["start_date", "end_date", "name"],
    ["asc", "desc", "asc"],
  ],
  current: [
    ["end_date", "start_date", "name"],
    ["asc", "desc", "asc"],
  ],
  past: [
    ["end_date", "start_date", "name"],
    ["desc", "desc", "asc"],
  ],
};

const BypassList = ({
  setBypassUntil,
}: {
  setBypassUntil: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { bypasses } = useBypassListAPI();
  const now = Date.now();
  const groupedBypasses = useMemo(
    () =>
      groupBy(
        bypasses.map((b) => bypassParseJSON(b)),
        (b) => {
          if (b.start_date > now) return "upcoming";
          else if (b.end_date < now) return "past";
          else return "current";
        }
      ),
    [now, bypasses]
  );
  const orderedBypasses = useMemo(() => {
    const data = {
      past: [] as BypassData[],
      current: [] as BypassData[],
      upcoming: [] as BypassData[],
    };
    relativeTimelines.forEach((timeline) => {
      data[timeline] = orderBy(
        groupedBypasses[timeline],
        ...bypassOrderConfig[timeline]
      );
    });
    return data;
  }, [groupedBypasses]);
  const bypassUntil = useMemo(() => {
    let bypassUntil = 0;
    orderedBypasses["current"].forEach((b) => {
      if (b.is_active && b.end_date > bypassUntil) bypassUntil = b.end_date;
    });
    return bypassUntil;
  }, [orderedBypasses]);
  useEffect(() => setBypassUntil(bypassUntil), [bypassUntil, setBypassUntil]);
  return (
    <div className="flex flex-col gap-12 mt-2">
      {relativeTimelines.map((timeline) => {
        return (
          <BypassSectionList
            key={timeline}
            timeline={timeline}
            bypasses={orderedBypasses[timeline]}
          />
        );
      })}
    </div>
  );
};

const schema = z
  .object({
    name: z.string().nonempty({ message: "cannot be empty." }),
    start_date: z.coerce
      .date()
      .min(new Date(), { message: "cannot be in the past." }),
    end_date: z.coerce.date(),
  })
  .refine((data) => data.start_date > new Date(), {
    message: "cannot be in the past.",
    path: ["start_date"],
  })
  .refine((data) => data.end_date > data.start_date, {
    message: "cannot be earlier than start time.",
    path: ["end_date"],
  });

const datetimeInputString = (d: Date) => {
  let result = formatISO(d);

  // Remove:
  // - timezone information as we are now on user timezone
  // - seconds
  result = result.substring(0, 16);

  return result;
};

const BypassDraft = () => {
  const { bypass, saveBypass } = useBypassAPI({});
  const { value: showForm, setTrue, setFalse } = useBoolean(false);

  const draft: BypassJSON = bypass || {
    name: "",
    start_date: "",
    end_date: "",
    is_active: true,
  };

  // We simply want `now` to be re-calculated when showForm is toggled on, but
  // I couldn't figure out how to write it in useEffect without making eslint upset.
  const now = useMemo(() => (showForm || !showForm) && new Date(), [showForm]);
  const nowish = useMemo(() => add(now, { minutes: 5 }), [now]);
  const tomorrow = useMemo(() => add(nowish, { days: 1 }), [nowish]);

  const {
    register,
    formState: { isSubmitted, isDirty, isValid, errors },
    watch,
    handleSubmit,
    reset: resetFn,
  } = useForm({
    defaultValues: draft,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
    resolver: zodResolver(schema),
  });

  const reset = () => {
    resetFn({
      start_date: datetimeInputString(nowish),
      end_date: datetimeInputString(tomorrow),
    });
  };

  const watchDates = watch(["start_date", "end_date"]);

  const renderErrors = useMemo(() => {
    return (
      (isSubmitted || isDirty) &&
      !isValid && (
        <div className="p-2 rounded-md border border-border-error text-text-error">
          <span>Invalid input:</span>
          <ul className="list-inside list-disc">
            {errors.name?.message && (
              <li>
                <b>Name</b>: {errors.name.message}
              </li>
            )}
            {errors.start_date?.message && (
              <li>
                <b>Start time</b>: {errors.start_date.message}
              </li>
            )}
            {errors.end_date?.message && (
              <li>
                <b>End time</b>: {errors.end_date.message}
              </li>
            )}
          </ul>
        </div>
      )
    );
  }, [isSubmitted, isDirty, isValid, errors]);
  return showForm ? (
    <div className="w-full flex flex-col gap-8 border border-border-default rounded-md p-4">
      <TextInput
        {...register("name")}
        placeholder="Name, e.g. End of Q2"
        className="py-2 px-4"
      />
      <div className="grid grid-cols-2 gap-2 items-center text-text-default">
        <input
          {...register("start_date")}
          className={cx(
            "py-1 px-4 rounded",
            "bg-transparent hover:bg-bg-l2 border border-border-default hover:border-border-bright"
          )}
          type="datetime-local"
        />
        <input
          {...register("end_date")}
          className={cx(
            "py-1 px-4 rounded",
            "bg-transparent hover:bg-bg-l2 border border-border-default hover:border-border-bright"
          )}
          type="datetime-local"
        />
      </div>
      {renderErrors}
      <div className="flex items-center">
        <span className="flex-grow text-text-default px-1 font-sm">
          {watchDates && watchDates[0] && (
            <>
              Starts{" "}
              <relative-time
                formatStyle="long"
                month="long"
                datetime={watchDates[0]}
              ></relative-time>
            </>
          )}
          {watchDates && watchDates[1] && (
            <>
              {", ends "}
              <relative-time
                formatStyle="long"
                month="long"
                datetime={watchDates[1]}
              ></relative-time>
            </>
          )}
          .
        </span>
        <div className="flex items-center justify-end gap-2">
          <CancelButton onClick={setFalse}>Cancel</CancelButton>
          <PrimaryButton
            onClick={handleSubmit((data) =>
              saveBypass(
                {
                  ...data,
                  is_active: true,
                },
                { forceSync: true }
              ).then(() => {
                setFalse();
              })
            )}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  ) : (
    <button
      onClick={() => {
        reset();
        setTrue();
      }}
      className={cx(
        "w-full flex items-center px-4 py-4 gap-4",
        "border border-border-default rounded-md text-text-default text-lg",
        "hover:bg-bg-l2"
      )}
    >
      <PlusIcon className="h-6 w-6" /> Create
    </button>
  );
};

const BypassSummary = ({ bypassUntil }: { bypassUntil: number }) => {
  const bypassInEffect = bypassUntil > 0;
  const bypassEndDate = new Date(bypassUntil).toISOString();

  return (
    <div className="flex flex-col sm:grid sm:grid-cols-6 gap-8 my-2 py-4">
      <div className="col-span-4 flex flex-col gap-4">
        <div
          className={cx(
            "flex flex-col gap-1 border-2 rounded-md p-4 bg-bg-l2",
            bypassInEffect ? "border-base-orange-800" : "border-base-green-800"
          )}
        >
          <span
            className={cx(
              "font-semibold flex items-center gap-1 text-lg",
              bypassInEffect ? "text-base-orange-400" : "text-base-green-400"
            )}
          >
            {bypassInEffect ? (
              <>
                <WarningIcon className="h-6 w-6" /> Bypass is in effect.
              </>
            ) : (
              <>
                <SuccessIcon className="h-6 w-6" /> No active bypasses found.
              </>
            )}
          </span>
          <span className={cx("text-text-default")}>
            {bypassInEffect ? (
              <>
                Rule evaluation will reactivate{" "}
                <relative-time
                  formatStyle="long"
                  month="long"
                  datetime={bypassEndDate}
                />
                .
              </>
            ) : (
              <>All rules are enforced.</>
            )}
          </span>
        </div>
        <BypassDraft />
      </div>
      <div className="text-text-muted col-span-2 flex flex-col gap-2">
        <span className="font-semibold uppercase flex items-center gap-2 text-text-default">
          <InformationIcon className="h-5 w-5" /> About Bypasses
        </span>
        <span>
          Bypass allows rule evaluation to be paused within a specific time
          range.
        </span>
        <span>
          When bypass is in effect, opportunities / quotes matching a rule would
          not require approval to proceed.
        </span>
      </div>
    </div>
  );
};

const Bypass = () => {
  const [bypassUntil, setBypassUntil] = useState(0);

  return (
    <React.Suspense>
      <div className="flex flex-col w-full max-w-6xl my-8 mx-auto px-8">
        <div className="flex justify-between mx-4">
          <h1 className="text-3xl font-semibold text-text-bright mb-2">
            Rule Bypass
          </h1>
        </div>
        <div className="flex flex-col mx-4 gap-4">
          <BypassSummary bypassUntil={bypassUntil} />
          <div className="flex flex-col min-w-1/3">
            <BypassList setBypassUntil={setBypassUntil} />
          </div>
        </div>
      </div>
    </React.Suspense>
  );
};

export default Bypass;
