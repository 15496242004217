import React, { memo } from "react";
import { type NodeProps } from "@xyflow/react";

// @ts-ignore
import { ReactComponent as SalesforceLogo } from "@/assets/logos/salesforce.svg";
// @ts-ignore
import { ReactComponent as SnowflakeLogo } from "@/assets/logos/snowflake.svg";
// @ts-ignore
import { ReactComponent as HubSpotLogo } from "@/assets/logos/hubspot.svg";
// @ts-ignore
import { ReactComponent as MacaLogo } from "@/assets/maca-logo-mark.svg";

import { Text } from "@/components/hooks/useText";

// @ts-ignore
import { ReactComponent as TableIcon } from "./icons/table.svg";
// @ts-ignore
import { ReactComponent as MetricIcon } from "./icons/metric.svg";

import { type FieldGroupDef } from "./types";

export const FIELD_GROUP_WIDTH = 346;
export const FIELD_GROUP_MARGIN = { top: 64, left: 0, bottom: 48, right: 0 };

export type FieldGroupProps = {
  name: string;
  type: FieldGroupDef["type"];
  attribution: FieldGroupDef["attribution"];
  highlighted?: boolean;
};

const logos: Record<FieldGroupDef["attribution"], JSX.Element> = {
  salesforce: <SalesforceLogo className="h-6" />,
  hubspot: <HubSpotLogo className="h-6" />,
  snowflake: <SnowflakeLogo className="h-6" />,
  maca: <MacaLogo className="h-5 text-text-bright" />,
};

const Attribution = ({
  attribution,
}: {
  attribution: FieldGroupDef["attribution"];
}) => (
  <div className="grow-0 flex gap-2">
    {logos[attribution]}
    <div className="text-sm font-medium text-text-default">
      <Text i18nKey={`attribution.${attribution}`} />
    </div>
  </div>
);

const groupIcon: Record<FieldGroupDef["type"], JSX.Element> = {
  table: <TableIcon className="w-5 h-5 text-text-default" />,
  metric: <MetricIcon className="w-5 h-5 text-text-default" />,
};

const FieldGroup = memo(
  ({ data: { name, type, attribution } }: NodeProps<FieldGroupProps>) => (
    <div className="h-full flex flex-col justify-between gap-3">
      <div className="grow gap-2 bg-bg-l2 border border-border-default rounded-[8px]">
        <div className="flex px-4 pt-3 pb-2 gap-2 items-center">
          {groupIcon[type]}
          <span className="text-lg font-semibold text-text-bright">{name}</span>
        </div>
        <hr className="border border-border-default" />
      </div>
      <Attribution {...{ attribution }} />
    </div>
  )
);

export default FieldGroup;
