import { useCallback, useState, type MouseEvent } from "react";
import {
  useReactFlow,
  getOutgoers,
  getIncomers,
  getConnectedEdges,
  type ReactFlowInstance,
  type NodeMouseHandler,
} from "@xyflow/react";

import { type DataflowNode, type DataflowEdge } from "./dataflowLayout";

type Highlightable = { data?: { highlighted?: boolean } };
type RFInstace = ReactFlowInstance<DataflowNode, DataflowEdge>;

const highlightElement = <T extends Highlightable>(
  el: T,
  highlighted: boolean
) => ({
  ...el,
  data: { ...el.data, highlighted },
});

const highlightNodes = (rf: RFInstace, ids: string[], highlighted: boolean) =>
  rf.setNodes((nodes) =>
    nodes.map((node) =>
      ids.includes(node.id) ? highlightElement(node, highlighted) : node
    )
  );

const highlightEdges = (rf: RFInstace, ids: string[], highlighted: boolean) =>
  rf.setEdges((edges) =>
    edges.map((edge) =>
      ids.includes(edge.id) ? highlightElement(edge, highlighted) : edge
    )
  );

const getConnectedElements = (rf: RFInstace, node: DataflowNode) => {
  const targetNodes = getOutgoers(node, rf.getNodes(), rf.getEdges());
  const sourceNodes = getIncomers(node, rf.getNodes(), rf.getEdges());
  const nodeIds = [node, ...targetNodes, ...sourceNodes].map((n) => n.id);

  const edges = getConnectedEdges([node], rf.getEdges());
  const edgeIds = edges.map((e) => e.id);

  return { nodeIds, edgeIds };
};

const highlightConnected = (
  rf: RFInstace,
  node: DataflowNode,
  highlighted: boolean
) => {
  const { nodeIds, edgeIds } = getConnectedElements(rf, node);
  highlightNodes(rf, nodeIds, highlighted);
  highlightEdges(rf, edgeIds, highlighted);
};

export const useEventHandlers = () => {
  const rf = useReactFlow<DataflowNode>();

  const [selectedNodes, setSelectedNodes] = useState<DataflowNode[]>([]);
  const onSelectionChange = useCallback(
    ({ nodes }: { nodes: DataflowNode[] }) => {
      const selected = nodes.filter((node) => node.type === "fieldslot");
      setSelectedNodes((previousSelection) => {
        if (previousSelection.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          highlightConnected(rf, previousSelection[0]!, false);
        }

        if (selected.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          highlightConnected(rf, selected[0]!, true);
        }

        return selected;
      });
    },
    [rf]
  );

  const onEdgeMouseEnter = useCallback(
    (_: MouseEvent, edge: DataflowEdge) => {
      const ids = edge.id.split("-");
      highlightNodes(rf, ids, true);
      highlightEdges(rf, [edge.id], true);
    },
    [rf]
  );

  const onEdgeMouseLeave = useCallback(
    (_: MouseEvent, edge: DataflowEdge) => {
      const ids = edge.id.split("-");
      highlightNodes(rf, ids, false);
      highlightEdges(rf, [edge.id], false);
    },
    [rf]
  );

  const onNodeMouseEnter = useCallback(
    (_: MouseEvent, node: DataflowNode) => {
      if (node.type !== "fieldslot") return;
      highlightConnected(rf, node, true);
    },
    [rf]
  );

  const onNodeMouseLeave = useCallback(
    (_: MouseEvent, node: DataflowNode) => {
      if (node.type !== "fieldslot") return;
      highlightConnected(rf, node, false);
    },
    [rf]
  );

  return selectedNodes.length
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { onSelectionChange: onSelectionChange as any }
    : {
        onEdgeMouseEnter,
        onEdgeMouseLeave,
        onNodeMouseEnter: onNodeMouseEnter as NodeMouseHandler,
        onNodeMouseLeave: onNodeMouseLeave as NodeMouseHandler,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSelectionChange: onSelectionChange as any,
      };
};
