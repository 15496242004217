import React from "react";

import DataflowDiagram, {
  DataflowProvider,
} from "@/components/dataflow/DataflowDiagram";

import { useDalaflowIndex } from "./api";

const Dataflow = () => {
  const { data } = useDalaflowIndex();
  return (
    <DataflowProvider>
      <div className="-m-2">{data && <DataflowDiagram {...data} />}</div>
    </DataflowProvider>
  );
};

export default Dataflow;
