import { FieldPath } from "react-hook-form";
import { t } from "i18next";
import { merge } from "lodash";
import { PartialDeep } from "type-fest";

import { EntityId } from "@/client/models-api";
import { NumericInputOptions } from "@/components/input/types";

import { PriceRuleBlockOperator } from "./serialization";

type QuoteOrOpportunity = {
  type: "Quote" | "Opportunity";
  field: undefined;
};

type OpportunityField = {
  type: "Opportunity";
  field: "Amount" | "Average Discount";
};

type QuoteField = {
  type: "Quote";
  field: "Total Amount" | "Discount" | "Average Discount";
};

export type PriceRuleSubject =
  | QuoteOrOpportunity
  | OpportunityField
  | QuoteField;

export const PRICE_RULE_SUBJECTS: PriceRuleSubject[] = [
  { type: "Opportunity", field: undefined },
  { type: "Opportunity", field: "Amount" },
  { type: "Opportunity", field: "Average Discount" },
  { type: "Quote", field: undefined },
  { type: "Quote", field: "Total Amount" },
  { type: "Quote", field: "Discount" },
  { type: "Quote", field: "Average Discount" },
];

export enum PriceRuleType {
  GtLt = "greater_than_less_than",
  Range = "within_a_range",
  LineItem = "line_item",
  AvgDiscount = "average_discount",
  UnitMeasurement = "unit_measurement",
  PricePercentageRate = "price_percentage_rate",
}

export type PriceRuleBlockType = PriceRuleBlockOperator | "between";

export const PRICE_RULE_TYPES: PriceRuleType[] = [
  PriceRuleType.GtLt,
  PriceRuleType.Range,
  PriceRuleType.AvgDiscount,
  PriceRuleType.LineItem,
  PriceRuleType.UnitMeasurement,
  PriceRuleType.PricePercentageRate,
];

export type PriceRuleAudit = {
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
};

export interface IPriceRuleBase {
  id: string;
  type: PriceRuleType;
}

export type PriceRulePlan = {
  id?: string;
  name?: string;
};

export type PriceRulePlanComponent = {
  id?: string;
  name?: string;
  quantity_unit_of_measure?: string;
};

export interface IPriceRule extends IPriceRuleBase, PriceRuleAudit {
  name: string;
  subject?: PriceRuleSubject;
  plan?: PriceRulePlan;
  planComponent?: PriceRulePlanComponent;
  rules?: RuleBlock[];
}

export type RuleBlock = {
  type: PriceRuleBlockType;
  values: (number | undefined)[];
  approvers: string[];
  notifications: string[];
};

export type PriceRuleEditingOptions = {
  ruleType: PriceRuleType;
  subjectFields: FieldPath<IPriceRule>[];
  multiBlock: boolean;
  operatorList?: PriceRuleBlockType[];
};

const ruleSubjectFields: Partial<
  Record<PriceRuleType, FieldPath<IPriceRule>[]>
> = {
  [PriceRuleType.LineItem]: ["subject", "plan", "planComponent"],
  [PriceRuleType.UnitMeasurement]: ["subject", "plan"],
  [PriceRuleType.PricePercentageRate]: ["subject", "plan"],
};

const ruleOperatorsList: Partial<Record<PriceRuleType, PriceRuleBlockType[]>> =
  {
    [PriceRuleType.GtLt]: ["gte", "lte"],
    [PriceRuleType.UnitMeasurement]: ["gt", "lt"],
  };

export const priceRuleEditingOptions = (
  ruleType: PriceRuleType
): PriceRuleEditingOptions => ({
  ruleType,
  subjectFields: ruleSubjectFields[ruleType] ?? ["subject"],
  multiBlock: [PriceRuleType.Range, PriceRuleType.PricePercentageRate].includes(
    ruleType
  ),
  operatorList: ruleOperatorsList[ruleType],
});

export class PriceRule implements IPriceRule {
  id: string;
  name: string;
  type: PriceRuleType;
  subject?: PriceRuleSubject;
  plan?: PriceRulePlan;
  planComponent?: PriceRulePlanComponent;
  rules?: RuleBlock[];

  constructor({
    id,
    name,
    type,
    subject,
    plan,
    planComponent,
    rules,
  }: IPriceRule) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.subject = subject;
    this.plan = plan;
    this.planComponent = planComponent;
    this.rules = rules;
  }

  isNew() {
    return EntityId.isNew(this.id);
  }

  isValid() {
    return (
      this.name &&
      this.subject &&
      this.rules &&
      this.rules.length > 0 &&
      !this.rules.some(
        (rule) =>
          rule.values.length === 0 ||
          (rule.approvers.length === 0 && rule.notifications.length === 0) ||
          !rule.type
      )
    );
  }

  get subjectOptions(): PriceRuleSubject[] {
    switch (this.type) {
      case PriceRuleType.GtLt:
      case PriceRuleType.Range:
        return PRICE_RULE_SUBJECTS.filter(({ field }) => Boolean(field));

      case PriceRuleType.AvgDiscount:
        return PRICE_RULE_SUBJECTS.filter(
          ({ field }) => field === "Average Discount"
        );

      case PriceRuleType.LineItem:
      case PriceRuleType.UnitMeasurement:
      case PriceRuleType.PricePercentageRate:
        return PRICE_RULE_SUBJECTS.filter(({ field }) => !field);
    }
  }

  static inputOptions({
    type,
    subject,
    planComponent,
  }: Pick<
    IPriceRule,
    "subject" | "planComponent" | "type"
  >): NumericInputOptions {
    if (subject?.field !== undefined) {
      return {
        valueType:
          subject.field === "Discount" || subject.field === "Average Discount"
            ? "percent"
            : "currency",
      };
    }

    if (type === PriceRuleType.PricePercentageRate) {
      return { valueType: "percent" };
    }

    return planComponent?.quantity_unit_of_measure
      ? { valueType: "unit", unit: planComponent?.quantity_unit_of_measure }
      : { valueType: "decimal" };
  }

  static newRule(ruleType: PriceRuleType) {
    type PartialRule = PartialDeep<PriceRule, { recurseIntoArrays: true }>;

    const ruleDef: Record<PriceRuleType, PartialRule> = {
      [PriceRuleType.GtLt]: {
        rules: [{ type: "gte" }],
      },

      [PriceRuleType.Range]: {
        rules: [{ type: "between" }],
      },

      [PriceRuleType.AvgDiscount]: {
        rules: [{ type: "gt" }],
      },

      [PriceRuleType.LineItem]: {
        rules: [{ type: "gt" }],
      },

      [PriceRuleType.UnitMeasurement]: {
        rules: [{ type: "gt" }],
      },

      [PriceRuleType.PricePercentageRate]: {
        rules: [{ type: "lt" }],
      },
    };

    const commonProps: PartialRule = {
      id: EntityId.new(),
      type: ruleType,
      name: t(`rule.type.${ruleType}`, { ns: "price-rules" }),
      rules: [
        {
          values: [],
          approvers: [],
          notifications: [],
        },
      ],
    };

    return new PriceRule(merge(commonProps, ruleDef[ruleType]) as IPriceRule);
  }

  static newRuleBlock(type: PriceRuleBlockType, values: RuleBlock["values"]) {
    return {
      type,
      values,
      approvers: [],
      notifications: [],
    };
  }
}
