import React, {
  ReactNode,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import { Link } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

import {
  useForm,
  useWatch,
  UseFormRegister,
  UseFormSetValue,
  Control,
  DeepPartial,
  FieldPath,
  FieldValues,
} from "react-hook-form";

import { isFunction, some, assign, set, pick } from "lodash";
import type { TFunction } from "i18next";

import Menu, {
  MenuThreeDotsButton,
  MenuDropdown,
  MenuItem,
} from "@/components/Menu";

import Form from "@/components/input/Form";
import { CancelButton, PrimaryButton } from "@/components/CoreButtons";

import { WithId } from "@/client/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EditableEntity = Record<string, any> & WithId;
type UseControllerRegister<Entity extends EditableEntity> = (
  field: FieldPath<Entity>
) => {
  control: Control<Entity>;
  name: FieldPath<Entity>;
};

export interface EditableCardChildrenProps<Entity extends EditableEntity> {
  editing: boolean;
  setEditing: (value: boolean) => void;
  state: Entity;
  register: UseFormRegister<Entity>;
  registerController: UseControllerRegister<Entity>;
  control: Control<Entity>;
  setValue: UseFormSetValue<Entity>;
  cancelEditing: VoidFunction;
  isSubmitting: boolean;
}

export interface EditableCardProps<Entity extends EditableEntity> {
  entity: Entity;
  onSave: (entity: Entity) => Promise<void>;
  discardDraft?: (id: string) => void;
  link?: string;
  className: string | ((editing: boolean) => string);
  elementId?: string;
  children: (props: EditableCardChildrenProps<Entity>) => ReactNode;
}

export const EditableCard = <Entity extends EditableEntity>({
  entity,
  onSave,
  discardDraft,
  link,
  className,
  elementId,
  children,
  ...props
}: EditableCardProps<Entity>) => {
  const [editing, setEditing_] = useState(Boolean(discardDraft));
  const timestamp = useRef(new Date());
  const setEditing = useCallback(
    (value: boolean) => {
      if (value) timestamp.current = new Date();
      setEditing_(value);
    },
    [setEditing_]
  );

  const {
    register: register_,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: entity as DeepPartial<Entity>,
  });

  // Track registered fields ourselves to avoid using `formState`'s `dirtyFields`/`touchedFields`,
  // which are prone to causing extraeous form re-renders/resets of focus.
  const registeredFields = useRef({}).current;
  const register = useCallback<UseFormRegister<Entity>>(
    (name, options) => {
      set(registeredFields, name, true);
      return {
        ...register_(name, options),
        key: `editable-card-${name}-${timestamp.current.getTime()}`,
      };
    },
    [register_, registeredFields]
  );

  const registerController = useCallback<UseControllerRegister<Entity>>(
    (name) => {
      set(registeredFields, name, true);
      return { control, name };
    },
    [control, registeredFields]
  );

  const state = useRef(entity).current;
  useEffect(() => {
    assign(state, entity);
  }, [state, entity]);

  useEffect(() => {
    if (!editing) reset(entity);
  }, [editing, reset, entity]);

  const cancelEditing = useCallback(
    (revertState = true) => {
      if (discardDraft) {
        discardDraft(entity.id!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
      } else {
        if (revertState) assign(state, entity);
        setEditing(false);
      }
    },
    [discardDraft, state, entity, setEditing]
  );

  const ref = useHotkeys<HTMLDivElement>(
    "escape",
    (event) => {
      // Don't cancel editing if the user has pressed `Esc` while interacting with
      // an open select element
      if (!(event.target as HTMLElement).getAttribute("aria-expanded")) {
        cancelEditing();
      }
    },
    {
      enabled: editing,
      enableOnFormTags: true,
    }
  );

  const onSubmit = useCallback(
    async (formData: Entity) => {
      await onSave({
        ...state,
        ...pick(formData, Object.keys(registeredFields)),
      });

      cancelEditing(false);
    },
    [cancelEditing, onSave, state, registeredFields]
  );

  const linkCard = link && !editing;
  const Card = useMemo(
    () =>
      linkCard
        ? //@ts-ignore
          (props) => <Link to={link} draggable={false} {...props} />
        : //@ts-ignore
          (props) => (
            <Form
              ref={ref}
              onSubmit={handleSubmit(onSubmit)}
              disabled={isSubmitting}
              onDoubleClick={() => setEditing(true)}
              {...props}
              tabIndex={-1}
            />
          ),
    [linkCard, link, ref, handleSubmit, onSubmit, isSubmitting, setEditing]
  );

  return (
    <Card
      id={elementId}
      data-entityid={entity.id}
      data-editing={editing}
      className={isFunction(className) ? className(editing) : className}
      {...props}
    >
      {children({
        editing,
        setEditing,
        state,
        register,
        registerController,
        control,
        setValue,
        cancelEditing,
        isSubmitting,
      })}
    </Card>
  );
};

interface CardMenuProps {
  className?: string;
  items: MenuItem[];
}

export const CardMenu = ({ className, items }: CardMenuProps) => (
  <div className={className} onClick={(event) => event.preventDefault()}>
    <Menu>
      {({ open }) => (
        <>
          <MenuThreeDotsButton open={open} />
          <MenuDropdown items={items} />
        </>
      )}
    </Menu>
  </div>
);

interface EditingButtonsProps<Entity extends FieldValues> {
  control: Control<Entity>;
  cancelEditing: () => void;
  isDraft: boolean;
  isSubmitting: boolean;
  required: FieldPath<Entity>[];
  t: TFunction;
}

export const EditingButtons = <Entity extends FieldValues>({
  control,
  cancelEditing,
  isDraft,
  isSubmitting,
  required,
  t,
}: EditingButtonsProps<Entity>) => {
  const requiredFields = useWatch({ control, name: required });
  return (
    <div className="flex justify-end gap-4 mt-4 pt-0.5">
      <CancelButton onClick={cancelEditing} />
      <PrimaryButton
        disabled={some(requiredFields, (v) => !v) || isSubmitting}
        type="submit"
      >
        {isDraft ? t("action.create") : t("action.update")}
      </PrimaryButton>
    </div>
  );
};

export { useWatch } from "react-hook-form";
export type { Control } from "react-hook-form";
