import React from "react";
import { useWatch, Control } from "react-hook-form";

import cx from "clsx";

import Label from "@/components/input/Label";
import NumericInput from "@/components/input/NumericInput";
import OptionsSwitch from "@/components/input/OptionsSwitch";

import useText from "@/components/hooks/useText";

import { IPriceRule, PriceRuleBlockType } from "../models/price-rules";

import { NumericInputOptions } from "@/components/input/types";

interface ConditionBlockProps {
  index: number;
  inputOptions: NumericInputOptions;
  control: Control<IPriceRule>;
}

const RangeConditionBlock = ({
  index,
  inputOptions,
  control,
}: ConditionBlockProps) => {
  const t = useText();
  return (
    <div className="flex gap-4 items-center min-w-0">
      <Label>{t("block_type.between.lower")}</Label>
      <NumericInput
        name={`rules.${index}.values.0`}
        control={control}
        options={inputOptions}
        required={true}
      />
      <div className="text-text-default whitespace-nowrap">
        {t("block_type.between.upper")}
      </div>
      <NumericInput
        name={`rules.${index}.values.1`}
        control={control}
        options={inputOptions}
        required={true}
      />
    </div>
  );
};

const ConditionBlock = ({
  index,
  inputOptions,
  control,
  operatorList,
  operatorLabel,
}: ConditionBlockProps & {
  operatorList?: PriceRuleBlockType[];
  operatorLabel: boolean;
}) => {
  const t = useText();
  const blockType = useWatch({ control, name: `rules.${index}.type` });
  if (blockType === "between")
    return (
      <RangeConditionBlock
        index={index}
        inputOptions={inputOptions}
        control={control}
      />
    );

  return (
    <div
      className={cx(
        "flex gap-4 self-start",
        operatorList ? "flex-col" : "items-center"
      )}
    >
      {operatorList ? (
        <OptionsSwitch
          options={operatorList}
          name={`rules.${index}.type`}
          control={control}
          keyPrefix="block_type"
        />
      ) : (
        operatorLabel && <Label>{t(`block_type.${blockType}`)}</Label>
      )}
      <NumericInput
        name={`rules.${index}.values.0`}
        control={control}
        options={inputOptions}
        required={true}
      />
    </div>
  );
};

export default ConditionBlock;
