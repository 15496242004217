import React, { useMemo } from "react";
import {
  useController,
  useWatch,
  Control,
  FieldPathByValue,
} from "react-hook-form";

import { map, keyBy } from "lodash";

import { isNotNil } from "@/lib/functional";

import { useAPI } from "@/client/api";

import { CustomInputProps } from "@/components/input/props";
import Label from "@/components/input/Label";

import useText from "@/components/hooks/useText";

import SlackUserSelect, { SlackUser } from "./SlackUserSelect";

import { IPriceRule } from "../models/price-rules";

const UserSelect = <Name extends FieldPathByValue<IPriceRule, string[]>>({
  users,
  name,
  control,
  placeholder,
  required,
}: CustomInputProps<IPriceRule, Name> & {
  users?: SlackUser[];
  placeholder: string;
}) => {
  const usersById = useMemo(() => keyBy(users, "id"), [users]);
  const {
    field: { ref, value, onChange, onBlur },
    fieldState: { invalid },
  } = useController({ control, name, rules: { required } });

  return (
    <SlackUserSelect
      className={required && invalid ? "!border-border-error" : ""}
      options={users || []}
      selection={(value || []).map((id) => usersById[id]).filter(isNotNil)}
      setSelection={(users) => onChange(map(users, "id"))}
      placeholder={placeholder}
      onBlur={onBlur}
      inputRef={ref}
    />
  );
};

const ApprovalsBlock = ({
  index,
  control,
}: {
  index: number;
  control: Control<IPriceRule>;
}) => {
  const t = useText();
  const { data: users } = useAPI<SlackUser[]>("slack/recipient");
  const approverIds = map(
    useWatch({ name: `rules.${index}.approvers`, control }),
    "id"
  );

  const recipientIds = map(
    useWatch({ name: `rules.${index}.notifications`, control }),
    "id"
  );

  const required = !approverIds.length && !recipientIds.length;

  return (
    <>
      <div className="flex flex-col gap-2">
        <Label>{t("approvers.label")}</Label>
        <UserSelect
          users={users?.filter(({ id }) => !recipientIds.includes(id))}
          name={`rules.${index}.approvers`}
          control={control}
          placeholder={t("approvers.placeholder")}
          required={required}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label>{t("recipients.label")}</Label>
        <UserSelect
          users={users?.filter(({ id }) => !approverIds.includes(id))}
          name={`rules.${index}.notifications`}
          control={control}
          placeholder={t("recipients.placeholder")}
          required={required}
        />
      </div>
    </>
  );
};

export default ApprovalsBlock;
