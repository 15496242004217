import React, { useState, ReactNode } from "react";

import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
import cx from "clsx";

import { createdAtSuffix } from "@/lib/date";

// @ts-ignore
import { ReactComponent as PlusSign } from "@/assets/icons/big-plus.svg";

import {
  EditableCard,
  EditableCardChildrenProps,
  CardMenu,
  EditingButtons,
} from "@/components/EditableCard";

import TextInput from "@/components/input/TextInput";

import { EntityId } from "@/client/models-api";

import { useProductPlansetListAPI } from "@/pages/plans/models/api";

import ProductAvatar from "./components/ProductAvatar";

import {
  ProductJSON,
  ProductComponentJSON,
  ProductSetJSON,
} from "./serialization";

import { useProductsListAPI, useProductsAPI } from "./api";

//@ts-ignore
const ProductMenu = ({ onEdit, onDelete }) => (
  <CardMenu
    className="absolute right-4 bottom-4"
    items={[
      {
        item: "Edit product",
        action: () => onEdit(),
      },
      {
        item: "Delete product",
        action: onDelete && (() => onDelete()),
        danger: true,
      },
    ]}
  />
);

const ProductCard = ({
  elementId,
  product,
  link,
  onDelete,
  onSave,
  discardDraft,
  children,
  ...props
}: {
  elementId?: string;
  product: ProductJSON;
  link: string;
  onSave: (entity: ProductJSON) => Promise<void>;
  onDelete?: () => Promise<void> | undefined;
  discardDraft?: (id: string) => void;
  children: (props: EditableCardChildrenProps<ProductJSON>) => ReactNode;
} & object) => (
  <EditableCard
    entity={product}
    link={link}
    {...{ elementId, onSave, discardDraft, ...props }}
    className={(editing) =>
      cx(
        "relative flex flex-col justify-between bg-bg-l2 rounded-md p-4 gap-4 w-[19rem] min-h-[23rem]",
        "border-2 border-transparent group",
        !editing && "hover:border-text-link hover:shadow-lg cursor-pointer"
      )
    }
  >
    {({ editing, setEditing, ...props }) => (
      <>
        {!editing && (
          <ProductMenu onEdit={() => setEditing(true)} onDelete={onDelete} />
        )}
        {children({
          editing,
          setEditing,
          ...props,
        })}
      </>
    )}
  </EditableCard>
);

//@ts-ignore
const EditingAvatar = ({ control }) => {
  const productName = useWatch({ control, name: "name" });
  return <ProductAvatar name={productName} />;
};

const ProductSummary = ({
  elementId,
  product,
  components,
  discardDraft,
}: {
  elementId?: string;
  product: ProductJSON;
  components: ProductComponentJSON[];
  discardDraft?: (productId: string) => void;
}) => {
  const { t } = useTranslation("common", { keyPrefix: "products" });
  const {
    product: reloaded,
    saveProduct,
    deleteProduct,
  } = useProductsAPI({
    productId: product.id,
  });

  const isDraft = Boolean(discardDraft);
  const plans = useProductPlansetListAPI({
    productId: isDraft ? undefined : product.id,
  });

  product = reloaded?.product || product;
  components = reloaded?.components || components;
  return (
    <ProductCard
      {...{ elementId, product, discardDraft }}
      link={
        !components.length && !plans?.length
          ? `${product.id}/components`
          : product.id
      }
      onDelete={
        !plans?.length && !components.length
          ? () => deleteProduct?.()
          : undefined
      }
      onSave={async <T,>(data: T) =>
        await saveProduct({ ...product, ...data }, { forceSync: true })
      }
      data-entity-type="product"
      data-plans-count={plans?.length}
      data-components-count={components.length}
    >
      {
        //@ts-ignore
        ({ editing, register, control, cancelEditing, isSubmitting }) => (
          <>
            <div className="flex items-center gap-5">
              {editing ? (
                <>
                  <EditingAvatar control={control} />
                  <TextInput
                    {...register("name")}
                    maxLength={80}
                    placeholder="Product name"
                    className="mt-1 p-2 -mx-2 text-xl font-semibold shrink leading-tight w-full -mr-1 overflow-y-hidden"
                    autoSelect
                  />
                </>
              ) : (
                <>
                  <ProductAvatar name={product.name} />
                  <h2
                    className={cx(
                      "mt-1 min-w-0 text-xl font-semibold leading-tight text-text-bright",
                      "border border-transparent break-words"
                    )}
                  >
                    {product.name}
                  </h2>
                </>
              )}
            </div>

            <div className="px-1 flex flex-col items-stretch">
              {editing ? (
                <TextInput
                  {...register("description")}
                  maxLength={512}
                  minRows={product.description ? undefined : 2}
                  placeholder="Product description"
                  className="p-2 -mx-2"
                />
              ) : (
                <div className="text-text-default border border-transparent whitespace-pre-line break-words">
                  {product.description}
                </div>
              )}
            </div>

            {editing ? (
              <EditingButtons
                control={control}
                cancelEditing={cancelEditing}
                isSubmitting={isSubmitting}
                isDraft={isDraft}
                required={["name"]}
                t={t}
              />
            ) : (
              <div className="flex flex-col text-text-bright font-semibold gap-1 p-1">
                <div>{plans?.length} pricing plans</div>
                <div>{components.length} components</div>
              </div>
            )}
          </>
        )
      }
    </ProductCard>
  );
};

//@ts-ignore
const NewProductPlaceholder = ({ draftsCount, onClick }) => (
  <div
    id={
      draftsCount
        ? `new-product-placeholder-${draftsCount}`
        : "new-product-placeholder"
    }
    className={cx(
      "relative flex flex-col items-center justify-between rounded-md p-4 w-[19rem] min-h-[23rem]",
      "border-2 border-border-default group hover:border-border-bright",
      "hover:shadow-lg cursor-pointer"
    )}
    onClick={onClick}
  >
    <div className="flex flex-col flex-grow items-center justify-center">
      <PlusSign className="w-24 stroke-text-muted group-hover:stroke-text-link" />
    </div>
    <span className="text-text-muted font-medium pb-2 group-hover:text-text-link ">
      Create a new product
    </span>
  </div>
);

const newProduct = (): ProductSetJSON => ({
  product: {
    id: EntityId.new(),
    name: "Untitled product",
  },
  components: [],
});

const DraftProducts = ({ recentlySaved }: { recentlySaved: string[] }) => {
  const [drafts, setDrafts] = useState<ProductSetJSON[]>([]);
  const discardDraft = (productId: string) =>
    setDrafts(drafts.filter(({ product }) => product.id !== productId));

  return (
    <>
      {drafts
        .filter(({ product }) => !recentlySaved.includes(product.id))
        .map(({ product, components }, i) => (
          <ProductSummary
            key={product.id}
            elementId={`draft-product-${i + 1}`}
            product={product}
            components={components}
            discardDraft={discardDraft}
          />
        ))}
      <NewProductPlaceholder
        draftsCount={drafts.length}
        onClick={() => setDrafts([...drafts, newProduct()])}
      />
    </>
  );
};

const productElementId = ({ created_at }: ProductJSON, last: boolean) =>
  last ? `latest-product${createdAtSuffix(created_at)}` : undefined;

const ProductList = ({ products }: { products: ProductSetJSON[] }) => {
  return (
    <div
      className="flex flex-wrap gap-6 mt-2"
      data-product-count={products.length}
    >
      {products.map(({ product, components }, i) => (
        <ProductSummary
          key={product.id}
          elementId={productElementId(product, i === products.length - 1)}
          product={product}
          components={components}
        />
      ))}

      <DraftProducts
        recentlySaved={(products as any[]) // eslint-disable-line @typescript-eslint/no-explicit-any
          .map(({ _previousId }) => _previousId)
          .filter(Boolean)}
      />
    </div>
  );
};

export const ProductsPage = () => {
  const { products } = useProductsListAPI();

  return (
    <div className="flex flex-col w-full max-w-6xl my-8 mx-auto px-8">
      <div className="flex justify-between mx-4">
        <h1 className="text-3xl font-semibold text-text-bright mb-6">
          Products
        </h1>
      </div>
      {products && <ProductList products={sortBy(products, "created_at")} />}
    </div>
  );
};

export default ProductsPage;
