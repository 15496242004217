import React, { useCallback } from "react";

import { useForm, Control, useWatch } from "react-hook-form";
import useSWR from "swr";
import isValidHostname from "is-valid-hostname";
import { some, every, isMatch } from "lodash";

// @ts-ignore
import { ReactComponent as SnowflakeLogo } from "@/assets/logos/snowflake.svg";
// @ts-ignore
import { ReactComponent as SendIcon } from "@/assets/icons/send.svg";
// @ts-ignore
import { ReactComponent as InfoIcon } from "@/assets/icons/circle-info.svg";

import api, { useAPI } from "@/client/api";
import mailToUrl from "@/lib/mailto";

import Form from "@/components/input/Form";
import ErrorMessage from "@/components/input/ErrorMessage";
import UndoButton from "@/components/UndoButton";
import { Text, useText, TranslationScope } from "@/components/hooks/useText";

import DomainInput from "./components/DomainInput";
import ConnectButton, {
  type ConnectButtonProps,
} from "./components/ConnectButton";

import OpenExternalLink from "./components/OpenExternalLink";

import SettingsCard from "./SettingsCard";
import { HTTPError } from "ky";

const snowflakeAccountDomainSuffix = ".snowflakecomputing.com";
const snowflakeAccountHostname = (account: string) =>
  `${account}${snowflakeAccountDomainSuffix}`;

interface SnowflakeCreds {
  user: string;
  password: string;
  account: string;
}

export interface SnowflakeConfigJSON extends SnowflakeCreds {
  connected_at: string;
}

export const useSnowflakeConfigAPI = () => {
  const endpoint = "snowflake/authorization";
  const { data, mutate } = useAPI<SnowflakeConfigJSON>(endpoint);

  const authorize = async (creds: SnowflakeCreds) =>
    await mutate(
      (await api.put(endpoint, { json: creds }).json()) as SnowflakeConfigJSON,
      { revalidate: true }
    );

  return {
    config: data ?? {
      user: "",
      password: "",
      account: "",
    },
    authorize,
  };
};

const cleanErrorMessage = (message: string) =>
  message.replace(/^.*?(Failed to .*)$/g, "$1");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractErrorMessage = async (err: any) => {
  const message =
    err instanceof HTTPError
      ? (await err.response.json())?.error ?? err.toString()
      : err.toString();

  return cleanErrorMessage(message);
};

export const OpenSnowflakeLink = ({
  control,
}: {
  control: Control<SnowflakeConfigJSON>;
}) => {
  const account = useWatch({ control, name: "account" });
  const accountHostname = snowflakeAccountHostname(account);
  const disabled = !account || !isValidHostname(accountHostname);

  return (
    <OpenExternalLink href={`https://${accountHostname}`} disabled={disabled} />
  );
};

export const useSnowflakeSettingsForm = ({
  keyPrefix,
}: {
  keyPrefix?: string;
} = {}) => {
  const t = useText({ keyPrefix });
  const { config, authorize } = useSnowflakeConfigAPI();

  const {
    register,
    control,
    resetField,
    setError,
    clearErrors,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<SnowflakeConfigJSON>({
    values: config,
  });

  const onSubmit = useCallback(
    async (config: SnowflakeConfigJSON) => {
      clearErrors();
      try {
        await authorize(config);
      } catch (err) {
        console.error(err);
        setError("root", {
          type: "server",
          message: await extractErrorMessage(err),
        });
      }
    },
    [authorize, setError, clearErrors]
  );

  const undoEdits = useCallback(() => {
    const fields: (keyof SnowflakeConfigJSON)[] = [
      "account",
      "user",
      "password",
    ];

    fields.forEach((field) => resetField(field));
    clearErrors();
  }, [resetField, clearErrors]);

  const [account, user, password] = useWatch({
    control,
    name: ["account", "user", "password"],
  });

  const connected =
    Boolean(config.account) && isMatch(config, { account, user }) && !password;

  const unsavedChanges = Boolean(config.account) && !connected;
  const disabled =
    some([account, user, password], (v) => !v) ||
    !isValidHostname(snowflakeAccountHostname(account)) ||
    connected;

  return {
    showHelp: every([account, user, password], (v) => !v) && !config.account,
    unsavedChanges,
    connected,
    getFormProps: () => ({
      onSubmit: handleSubmit(onSubmit),
      disabled: isSubmitting,
    }),
    getUsernameInputProps: () => ({
      ...register("user"),
      placeholder: t("placeholder.username"),
    }),
    getPasswordInputProps: () => ({
      ...register("password"),
      type: "password",
      placeholder: t("placeholder.password"),
    }),
    getAccountInputProps: () => ({
      ...register("account"),
      domainSuffix: snowflakeAccountDomainSuffix,
      placeholder: "account-identifier",
    }),
    getConnectButtonProps: (): ConnectButtonProps => ({
      status: isSubmitting ? "connecting" : connected ? "connected" : "ready",
      disabled,
    }),
    getUndoButtonProps: () => ({ onClick: undoEdits }),
    getOpenSFLinkProps: () => ({ control }),
    footerText:
      config.connected_at && connected
        ? t("footer.configured", {
            updatedAt: new Date(config.connected_at),
            formatParams: {
              updatedAt: { dateStyle: "medium", timeStyle: "long" },
            },
          })
        : t("footer.setting_up"),
    error: errors.root?.message,
  };
};

export const HelpBlock = () => {
  const t = useText();
  const { data: emailBody } = useSWR("snowflake/help", (url) =>
    api.get(url).text()
  );

  return (
    <div className="grid [grid-template-columns:min-content_10fr] bg-bg-l3 rounded-lg px-6 py-5 gap-4">
      <div className="flex items-center">
        <InfoIcon className="w-8 aspect-square shrink-0 text-base-orange-200" />
      </div>
      <div className="text-text-bright leading-tight">
        <Text i18nKey="help.text" />
      </div>
      <div />
      <a
        href={
          emailBody
            ? mailToUrl({ subject: t("help.email.subject"), body: emailBody })
            : undefined
        }
        className="button-base button-primary leading-tight px-4 py-3 flex justify-between items-center gap-2"
      >
        <Text i18nKey="help.cta" />
        <SendIcon className="w-6 aspect-square shrink-0 text-text-bright" />
      </a>
    </div>
  );
};

const SnowflakeSettings = () => {
  const {
    showHelp,
    unsavedChanges,
    getFormProps,
    getUsernameInputProps,
    getPasswordInputProps,
    getAccountInputProps,
    getConnectButtonProps,
    getUndoButtonProps,
    getOpenSFLinkProps,
    footerText,
    error,
  } = useSnowflakeSettingsForm();

  return (
    <SettingsCard unsavedChanges={unsavedChanges}>
      <div className="flex flex-col gap-4">
        {showHelp && <HelpBlock />}
        <Form {...getFormProps()} className="flex flex-col gap-2">
          <div className="grid [grid-template-columns:10fr_min-content] gap-2">
            <div className="flex flex-col gap-2 flex-grow">
              <DomainInput {...getAccountInputProps()}>
                <OpenSnowflakeLink {...getOpenSFLinkProps()} />
              </DomainInput>
            </div>
            <div className="flex gap-2">
              {unsavedChanges && <UndoButton {...getUndoButtonProps()} />}
              <ConnectButton
                {...getConnectButtonProps()}
                className="button-base button-primary px-4 py-1.5"
              />
            </div>
            <div className="flex justify-between gap-2">
              <input
                className="input-base flex-grow"
                {...getUsernameInputProps()}
              />
              <input
                className="input-base flex-grow"
                {...getPasswordInputProps()}
              />
            </div>
            <div />
          </div>
          <div className="text-sm text-text-muted mx-1 cursor-default">
            {footerText}
          </div>
          {error && <ErrorMessage message={error} />}
        </Form>
      </div>
    </SettingsCard>
  );
};

const Snowflake = () => (
  <TranslationScope keyPrefix="snowflake">
    <div className="flex bg-bg-l2 rounded-md p-4 gap-4">
      <SnowflakeLogo className="w-14 self-start shrink-0" />
      <SnowflakeSettings />
    </div>
  </TranslationScope>
);

export default Snowflake;
