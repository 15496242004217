import React, { useState } from "react";

import Button from "@/components/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import clsx from "clsx";

import { InvitationData, useInviteUserAPI } from "./api";

const InviteUserDialog = () => {
  const { saveInvitation } = useInviteUserAPI({ inviteId: undefined });
  const [email, setEmail] = useState<string>("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEmail("");
    setOpen(false);
  };

  const handleSubmit = () => {
    saveInvitation({ email } as InvitationData);
    setOpen(false);
  };

  return (
    <div>
      <Button text="Invite" variant="contained" onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        key="invite-card"
        color="secondary"
        maxWidth="md"
      >
        <DialogTitle>
          Invite Members
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              marginRight: 2,
              marginTop: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Invited members will receive an email invitation to join the Maca
            organization.
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <TextField
            label="Email"
            type="email"
            required
            fullWidth
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <div className={clsx("px-4 pb-2")}>
            <Button
              text="Save"
              variant="contained"
              onClick={() => handleSubmit()}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InviteUserDialog;
