import { min, map, dropRight, last, isNil } from "lodash";

import { isNotNil } from "@/lib/functional";

import {
  PricingType,
  PricingFormula,
  BillingPeriod,
  ProductPlanPricingJSON,
  ProductPlanPriceTierJSON,
} from "./serialization";

export const PRICING_TYPES = [
  PricingType.UNIT,
  PricingType.FLAT_RATE,
  PricingType.USAGE,
];

export const UNIT_PRICING_FORMULAS = [
  PricingFormula.SINGLE_RATE,
  PricingFormula.TIERED,
  PricingFormula.VOLUME,
  PricingFormula.STAIRSTEP,
];

export const USAGE_PRICING_FORMULAS = [
  PricingFormula.VOLUME,
  PricingFormula.PERCENTAGE,
];

export const BILLING_PERIODS = [BillingPeriod.MONTHLY, BillingPeriod.ANNUALLY];

export const DEFAULT_PRICING_UNIT = "unit";

export const DEFAULT_USAGE_METRIC = "metric";

export const isTiered = ({
  pricing_type,
  pricing_formula,
}: ProductPlanPricingJSON) =>
  (pricing_type === PricingType.UNIT &&
    pricing_formula !== PricingFormula.SINGLE_RATE) ||
  (pricing_type === PricingType.USAGE &&
    pricing_formula === PricingFormula.VOLUME);

export const isPercentageRate = ({
  pricing_type,
  pricing_formula,
}: ProductPlanPricingJSON) =>
  pricing_type === PricingType.USAGE &&
  pricing_formula === PricingFormula.PERCENTAGE;

export const isNilPrice = (price: number | null | undefined) =>
  isNil(price) || isNaN(price);

export const normalizePrice = (price: number | null | undefined) =>
  (isNilPrice(price) ? undefined : price) as number | undefined;

export const isFullRangeTier = ({
  lower_bound,
  upper_bound,
}: ProductPlanPriceTierJSON) =>
  isNotNil(upper_bound) && !isNaN(upper_bound) && upper_bound >= lower_bound;

const startingPrice = (tiers?: ProductPlanPriceTierJSON[]) =>
  min(map(tiers ?? [], "tier_price"));

export const defaultPrice = (pricing: ProductPlanPricingJSON) =>
  normalizePrice(
    isTiered(pricing) ? startingPrice(pricing.tiers) : pricing.default_amount
  );

export const defaultPercentRate = (pricing: ProductPlanPricingJSON) =>
  normalizePrice(pricing.default_percent_rate);

export const summaryPrice = (pricing: ProductPlanPricingJSON) =>
  isPercentageRate(pricing)
    ? defaultPercentRate(pricing)
    : defaultPrice(pricing);

const normalizeTier = ({ tier_price, ...tier }: ProductPlanPriceTierJSON) => ({
  tier_price: Number(tier_price),
  ...tier,
});

const populateTiers = (tiers?: ProductPlanPriceTierJSON[]) =>
  (!tiers || !tiers.length ? [{ lower_bound: 1 }] : tiers)
    .map(normalizeTier)
    .sort((a, b) => (a.upper_bound ?? Infinity) - (b.upper_bound ?? Infinity));

const deducePricingFormula = ({
  pricing_type,
  tiers,
}: Partial<ProductPlanPricingJSON>) =>
  pricing_type === PricingType.UNIT
    ? (tiers ?? []).length > 0
      ? PricingFormula.VOLUME
      : PricingFormula.SINGLE_RATE
    : undefined;

// populate/normalize pricing fields for showing/editing in the UI
export const hydratePricing = ({
  pricing_type,
  pricing_formula,
  default_amount,
  default_percent_rate,
  tiers,
  ...pricing
}: ProductPlanPricingJSON) => ({
  pricing_type,
  pricing_formula:
    pricing_formula || deducePricingFormula({ pricing_type, tiers }),
  default_amount: Number(default_amount),
  default_percent_rate: Number(default_percent_rate),
  tiers: populateTiers(tiers),
  ...pricing,
});

const normalizePricingFormula = ({
  pricing_formula,
  pricing_type,
}: Partial<ProductPlanPricingJSON>) => {
  if (!pricing_formula) return undefined;

  if (pricing_type === PricingType.UNIT) {
    return UNIT_PRICING_FORMULAS.includes(pricing_formula)
      ? pricing_formula
      : PricingFormula.SINGLE_RATE;
  }

  if (pricing_type === PricingType.USAGE) {
    return USAGE_PRICING_FORMULAS.includes(pricing_formula)
      ? pricing_formula
      : PricingFormula.VOLUME;
  }

  return undefined;
};

const normalizePricingTiers = (tiers?: ProductPlanPriceTierJSON[]) => {
  // drop tiers without price
  const tiersWithPrice = tiers?.filter((tier) => !isNilPrice(tier.tier_price));
  if (!tiersWithPrice || tiersWithPrice.length === 0) return [];

  // make sure the last tier does not have an upper bound
  return [
    ...dropRight(tiersWithPrice, 1),
    {
      ...last(tiersWithPrice),
      upper_bound: undefined,
    } as ProductPlanPriceTierJSON,
  ];
};

export const normalizePricing = (pricing: ProductPlanPricingJSON) => {
  const normalizeFormula = (pricing: ProductPlanPricingJSON) => ({
    ...pricing,
    pricing_formula: normalizePricingFormula(pricing),
  });

  const normalizeTiers = (pricing: ProductPlanPricingJSON) => ({
    ...pricing,
    // remove stale/placeholder tiers if it's not tiered pricing
    tiers: isTiered(pricing) ? normalizePricingTiers(pricing.tiers) : [],
  });

  const normalizeAmounts = (pricing: ProductPlanPricingJSON) => ({
    ...pricing,
    ...(isPercentageRate(pricing)
      ? {
          default_amount: undefined,
          // populate default percent rate (for usage-based pricing), if any
          default_percent_rate: defaultPercentRate(pricing),
        }
      : {
          // populate default amount from tiered pricing, if any
          default_amount: defaultPrice(pricing),
          default_percent_rate: undefined,
        }),
  });

  return normalizeAmounts(normalizeTiers(normalizeFormula(pricing)));
};
