import React from "react";
import { useNavigate } from "react-router-dom";

import PriceRuleTemplates from "./PriceRuleTemplates";
import { PriceRuleType } from "./models/price-rules";

import MacaButton from "@/components/Button";

export const CreatePriceRulePage = ({
  ruleTypePaths,
}: {
  ruleTypePaths: Record<PriceRuleType, string>;
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full max-w-6xl my-8 mx-auto px-8 gap-8">
      <div className="flex flex-col mx-4 gap-2">
        <h1 className="text-3xl font-semibold text-text-bright">
          Create a new rule
        </h1>

        <div className="text-text-default">
          First, let's pick a pricing rule type
        </div>
      </div>

      <PriceRuleTemplates onCreate={(type) => navigate(ruleTypePaths[type])} />

      <div className="flex justify-end mx-4">
        <MacaButton
          text="Cancel"
          variant="text"
          onClick={() => {
            navigate("/price-rules");
          }}
        />
      </div>
    </div>
  );
};

export default CreatePriceRulePage;
