import { useAPI } from "@/client/api";

import {
  type FieldGroupDef,
  type Dependency,
} from "@/components/dataflow/types";

export interface DataflowIndex {
  nodes: FieldGroupDef[];
  deps: Dependency[];
}

export const useDalaflowIndex = () =>
  useAPI<DataflowIndex>("insight/dataflow/");
