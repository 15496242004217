import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { Box, Button, Grid, Typography, Alert, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// @ts-ignore
import { ReactComponent as CircleCheck } from "@/assets/icons/circle-check.svg";

import { darkTheme } from "../../themes/theme";
import Cookies from "js-cookie";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [token, setToken] = useState(searchParams.get("token") ?? "");
  const [email, setEmail] = useState(searchParams.get("email") ?? "");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const [alertBox, setAlertBox] = useState(<></>);

  useEffect(() => {
    if (!email) navigate("/user/reset-password-token/");
  }, [email, navigate]);

  useEffect(() => {
    if (Cookies.get("csrftoken") === undefined) {
      fetch(process.env["REACT_APP_API_HOST"] + "/user/session/", {
        credentials: "include",
      });
    }
  }, []); // Only run once at the beginning

  const resetPassword = () => {
    if (password !== passwordConfirm) {
      setAlertBox(
        <Alert data-testid="alert-box" severity="warning">
          Provided passwords do not match. Please try again.
        </Alert>
      );
      return;
    }
    const new_password = password;
    try {
      fetch(process.env["REACT_APP_API_HOST"] + "/user/reset-password/", {
        method: "POST",
        body: JSON.stringify({ email, token, new_password }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        credentials: "include",
      })?.then((response) => {
        if (response.ok) {
          setIsSubmitSuccessful(true);
          setAlertBox(<></>);
        } else {
          console.log("unexpected return code: " + response?.status);
          setAlertBox(
            <Alert data-testid="alert-box" severity="error">
              Unable to reset password.
            </Alert>
          );
        }
      });
    } catch (err) {
      console.log(err);
      setAlertBox(
        <Alert data-testid="alert-box" severity="error">
          Unable to reset password.
        </Alert>
      );
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        m="auto"
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Typography variant="h4" style={{ paddingBottom: "20px" }}>
          Reset password
        </Typography>
        <Grid
          alignItems="center"
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          {isSubmitSuccessful ? (
            <div className="w-full text-lg text-text-bright">
              <CircleCheck className="inline-block mr-2 mb-0.5 w-5 text-text-success" />
              Your new password is set!{" "}
              <Link
                to={`/user/login/?email=${encodeURIComponent(email)}`}
                className="text-text-link hover:underline"
              >
                Continue to sign in →
              </Link>
            </div>
          ) : (
            <>
              <div hidden>
                <TextField
                  hidden
                  id="token"
                  data-testid="token-form"
                  value={token}
                  onChange={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    setToken(target.value);
                  }}
                />
              </div>
              <TextField
                id="email"
                type="email"
                label="Email"
                data-testid="email-form"
                fullWidth
                disabled
                value={email}
                onChange={(e) => {
                  const target = e.target as HTMLTextAreaElement;
                  setEmail(target.value);
                }}
              />
              <TextField
                id="password"
                type="password"
                label="Password"
                data-testid="password-form"
                required
                fullWidth
                value={password}
                onChange={(e) => {
                  const target = e.target as HTMLTextAreaElement;
                  setPassword(target.value);
                }}
              />
              <TextField
                id="password-confirm"
                type="password"
                label="Password (confirm)"
                data-testid="password-confirm-form"
                required
                fullWidth
                value={passwordConfirm}
                onChange={(e) => {
                  const target = e.target as HTMLTextAreaElement;
                  setPasswordConfirm(target.value);
                }}
              />
              <Button
                data-testid="reset-button"
                variant="contained"
                color="primary"
                fullWidth
                onClick={resetPassword}
              >
                Reset password
              </Button>
            </>
          )}
          {alertBox}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
