/* eslint-disable @typescript-eslint/no-var-requires */
const mapValues = require("lodash/mapValues");
const omit = require("lodash/omit");
const range = require("lodash/range");

const basePalette = {
  red: {
    50: "#fff3f3",
    100: "#ffe3e3",
    200: "#ffcbcb",
    300: "#ffacac",
    400: "#ff8d8d",
    500: "#ef6565",
    600: "#de3f3f",
    700: "#cc2121",
    800: "#b20c0c",
    900: "#8b0404",
    950: "#5d0808",
  },
  orange: {
    50: "#fff7ed",
    100: "#ffedd5",
    200: "#fed7aa",
    300: "#fcbf80",
    400: "#f3a964",
    500: "#f4953d",
    600: "#ed811e",
    700: "#db6900",
    800: "#bc5a00",
    900: "#93441c",
    950: "#663527",
  },
  green: {
    50: "#f0fdf3",
    100: "#d7fce3",
    200: "#aff5c9",
    300: "#7fe7a9",
    400: "#4bd382",
    500: "#1dba59",
    600: "#07a140",
    700: "#01873d",
    800: "#006c3f",
    900: "#02533f",
    950: "#043b36",
  },
  teal: {
    50: "#F2FFFC",
    100: "#D4FFF5",
    200: "#A5FBE9",
    300: "#70f1d6",
    400: "#40e4c9",
    500: "#1ed2bd",
    600: "#07bcb1",
    700: "#00a19d",
    800: "#007a83",
    900: "#025c6a",
    950: "#053c4f",
  },
  indigo: {
    50: "#e3e8ff",
    100: "#d5dcff",
    200: "#bbc4fe",
    300: "#929efe",
    400: "#707cfc",
    500: "#5960f8",
    600: "#4a4cee",
    700: "#3c3ee3",
    800: "#3032d6",
    900: "#2629ba",
    950: "#1b2084",
  },
  pink: {
    50: "#ffedf8",
    100: "#ffdbef",
    200: "#ffc2e4",
    300: "#ffa6d5",
    400: "#fb84c1",
    500: "#f360a8",
    600: "#e63989",
    700: "#cb1264",
    800: "#aa004f",
    900: "#830142",
    950: "#50072e",
  },
};

const insightsExtPalette = () =>
  mapValues(basePalette, (palette) => omit(palette, ["50", "950"]));

const navbarHeightPx = 56;

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    extend: {
      variables: {
        DEFAULT: {
          navbar: {
            "height-px": navbarHeightPx,
            height: `${navbarHeightPx}px`,
          },
        },
      },
      spacing: {
        84: "21rem",
        "navbar-height": "var(--navbar-height)",
      },
      gridTemplateColumns: {
        sidebar: "250px 1fr",
      },
      screens: {
        "3xl": "1920px",
      },
      fontSize: {
        "2xs": ".625rem",
      },
      animation: {
        "fade-in": "fade-in 0.5s ease-in-out 0.1s forwards",
        ...range(5).reduce(
          (acc, i) => ({
            ...acc,
            [`fade-in-nth-${i}`]: `fade-in 0.5s ease-in-out ${
              i * 0.2
            }s forwards`,
          }),
          {}
        ),
        "flash-on-drag": "flash 0.3s ease-in-out 0.5s infinite",
        "hand-wave": "wave 2s linear",
        "flowing-border": "flowing-border-8-4 1s infinite linear",
        "beacon-inner": "beacon-inner 1.2s infinite ease-in-out",
        "beacon-outer": "beacon-outer 1.2s infinite ease-in-out",
      },
      keyframes: {
        "fade-in": {
          "0%": { opacity: 0 },
          "100%": { opacity: 1 },
        },
        flash: {
          "0%, 100%": { opacity: "100%" },
          "50%": { opacity: "70%" },
        },
        wave: {
          "0%": { transform: "rotate(0.0deg)" },
          "15%": { transform: "rotate(16.0deg)" },
          "30%": { transform: "rotate(-8.0deg)" },
          "40%": { transform: "rotate(16.0deg)" },
          "50%": { transform: "rotate(-4.0deg)" },
          "60%": { transform: "rotate(10.0deg)" },
          "70%": { transform: "rotate(0.0deg)" },
          "100%": { transform: "rotate(0.0deg)" },
        },
        "beacon-inner": {
          "20%": { opacity: 0.9 },
          "90%": { opacity: 0.7 },
        },
        "beacon-outer": {
          "0%": {
            transform: "scale(1)",
          },
          "45%": {
            opacity: 0.7,
            transform: "scale(0.75)",
          },
          "100%": {
            opacity: 0.9,
            transform: "scale(1)",
          },
        },
        "flowing-border-8-4": {
          from: { "background-position": "0 0, -12px 0, 100% -12px, 0 100%" },
          to: { "background-position": "0 -12px, 0 0, 100% 0, -12px 100%" },
        },
      },
      typography: ({ theme }) => ({
        DEFAULT: {
          css: {
            "--tw-prose-invert-body": theme("colors.text.bright"),
            "--tw-prose-invert-headings": theme("colors.text.bright"),
            "--tw-prose-invert-lead": theme("colors.text.error"),
            "--tw-prose-invert-links": theme("colors.text.link"),
            "--tw-prose-invert-bold": theme("colors.text.default"),
            "--tw-prose-invert-counters": theme("colors.text.default"),
            "--tw-prose-invert-bullets": theme("colors.text.default"),
            "--tw-prose-invert-hr": theme("colors.border.default"),
            "--tw-prose-invert-quotes": theme("colors.text.bright"),
            "--tw-prose-invert-quote-borders": theme("colors.border.bright"),
            "--tw-prose-invert-captions": theme("colors.text.muted"),
            "--tw-prose-invert-code": theme("colors.text.bright"),
            "--tw-prose-invert-pre-code": theme("colors.text.bright"),
            "--tw-prose-invert-pre-bg": "transparent",
            "--tw-prose-invert-th-borders": theme("colors.border.default"),
            "--tw-prose-invert-td-borders": theme("colors.border.default"),
            h4: {
              "@apply font-medium": {},
            },
            "h1, h2, h3, h4, h5, h6, li, a": {
              "scroll-margin-top": "calc(var(--navbar-height) + 1rem)",
            },
            "[data-highlighted='true']": {
              "text-decoration": "underline",
              "text-decoration-thickness": "3px",
              "text-decoration-skip-ink": "none",
              "text-decoration-color": "rgba(243,169,100, 0.9)",
            },
            "blockquote p:first-of-type::before": {
              content: "",
            },
            "blockquote p:last-of-type::after": {
              content: "",
            },
            "code::before": {
              content: "",
            },
            "code::after": {
              content: "",
            },
            ".katex": {
              "@apply text-text-default hover:text-text-bright border-l-[4px] border-border-default hover:border-border-bright state-transition-colors":
                {},
            },
            ".katex *": {
              "@apply pointer-events-none": {},
            },
            ".katex .mord.text": {
              "@apply hover:text-text-link state-transition-colors cursor-text":
                {},
              "pointer-events": "all !important",
            },
            ".katex .mord.textsf": {
              "font-family": "var(--ui-font-family) !important",
              "font-size": "16px !important",
              "font-weight": "500 !important",
              "font-style": "italic !important",
            },
            "a[data-footnote-ref='true']": {
              "@apply text-text-link no-underline hover:underline focus:underline -mx-2 -mb-4 pr-2 pl-[9px] pb-4 outline-none":
                {},
            },
            ".footnotes": {
              "@apply text-sm text-text-default mt-10 -mx-10 px-10 border-t border-border-default":
                {},
            },
            ".footnotes h2": {
              "@apply text-xl text-text-default mt-6": {},
            },
            ".footnotes li p": {
              "@apply my-3": {},
            },
            ".footnotes li a": {
              "@apply text-text-link no-underline hover:underline -m-1 p-1": {},
            },
            ".alert p": {
              "@apply m-0": {},
            },
          },
        },
      }),
    },
    colors: {
      transparent: "transparent",
      base: {
        black: "#000000",
        white: "#ffffff",
        ...basePalette,
      },
      text: {
        bright: "#dddde2",
        default: "#a7aab1",
        muted: "#828790",
        error: basePalette.red["400"],
        success: basePalette.green["400"],
        link: "#d3bbff",
      },
      bg: {
        d1: "#070b0d",
        default: "#121416",
        l1: "#16181d",
        l2: "#1b1c21",
        l250: "#25262F",
        l3: "#292a35",
        l4: "#36374b",
      },
      border: {
        default: "#2b2c35",
        bright: "#47495b",
        focus: "#6f27b7",
        error: "#ec6161",
        success: basePalette.green["400"],
      },
      brand: {
        purple: {
          DEFAULT: "#7605e8",
          l1: "#9830ff",
          l2: "#aa7bff",
          l3: "#d3bbff",
        },
      },
      insights: {
        red: {
          1: basePalette.red["400"],
          2: basePalette.red["600"],
          3: basePalette.red["800"],
        },
        orange: {
          1: basePalette.orange["400"],
          2: basePalette.orange["600"],
          3: basePalette.orange["800"],
        },
        green: {
          1: basePalette.green["400"],
          2: basePalette.green["600"],
          3: basePalette.green["800"],
        },
        teal: {
          1: basePalette.teal["400"],
          2: basePalette.teal["600"],
          3: basePalette.teal["800"],
        },
        indigo: {
          1: basePalette.indigo["400"],
          2: basePalette.indigo["600"],
          3: basePalette.indigo["800"],
        },
        pink: {
          1: basePalette.pink["400"],
          2: basePalette.pink["600"],
          3: basePalette.pink["800"],
        },
      },
      insightsExt: insightsExtPalette(),
    },
    fontFamily: {
      ui: ["var(--ui-font-family)"],
    },
  },
  plugins: [
    require("@tailwindcss/container-queries"),
    require("@tailwindcss/typography"),
    require("@mertasan/tailwindcss-variables"),
    require("@shrutibalasa/tailwind-grid-auto-fit"),
    require("@headlessui/tailwindcss"),
    require("tailwindcss-radix")(),
    // custom classes, defined here vs `index.css` to make the visible to the VS Code extension
    // see https://github.com/tailwindlabs/tailwindcss-intellisense/issues/227#issuecomment-1269592872
    /** @type {import('tailwindcss/types/config').PluginCreator} */
    ({ addUtilities, addComponents, theme }) => {
      addUtilities({
        ".content-empty": {
          content: "''",
        },
        ".has-focus-visible": {
          "@apply group-[&:has(:focus-visible)]": {},
        },
        ".text-sideways-lr": {
          // see https://developer.mozilla.org/en-US/docs/Web/CSS/writing-mode#using_writing-mode_with_transforms
          "@apply [writing-mode:vertical-lr] rotate-180": {},
        },
      });

      addComponents({
        ".bg-stripes": {
          background: `repeating-linear-gradient(-55deg, ${theme(
            "colors.bg.l2"
          )}, ${theme("colors.bg.l2")} 32px, ${theme(
            "colors.bg.l1"
          )} 32px, ${theme("colors.bg.l1")} 64px)`,
        },
        ".state-transition": {
          "@apply ease-in-out duration-300": {},
        },
        ".state-transition-colors": {
          "@apply transition-colors state-transition": {},
        },
        ".input-like-tracking": {
          "@apply tracking-[-0.0003em]": {},
        },
        ".border-border-default-var": {
          "@apply border-[color:var(--color-border-default)]": {},
        },
        ".border-border-bright-var": {
          "@apply border-[color:var(--color-border-bright)]": {},
        },
        ".theme-l1": {
          "--color-border-default": theme("colors.border.bright"),
          "--color-border-bright": "#5E6178",
        },
        ".group-focus-ring": {
          [`@apply outline-none relative group-[&:has(:focus-visible)]:after:content-empty 
          group-[&:has(:focus-visible)]:after:absolute group-[&:has(:focus-visible)]:after:-inset-[5px] 
          group-[&:has(:focus-visible)]:after:border-[2px] group-[&:has(:focus-visible)]:after:pointer-events-none
          group-[&:has(:focus-visible)]:after:border-border-focus
          border-[2px] border-transparent group-focus-within:outline-[var(--fallback-group-ring-color)]`]:
            {},
        },
        ".group-focus-ring-rounded-md": {
          "@apply group-focus-ring group-[&:has(:focus-visible)]:after:[border-radius:8px]":
            {},
        },
        ".group-focus-ring-rounded-lg": {
          "@apply group-focus-ring group-[&:has(:focus-visible)]:after:[border-radius:10px]":
            {},
        },
        ".group-focus-ring-rounded-full": {
          "@apply group-focus-ring group-[&:has(:focus-visible)]:after:rounded-full":
            {},
        },
        ".focus-ring": {
          [`@apply outline-none relative focus-visible:after:content-empty focus-visible:after:absolute
          focus-visible:after:-inset-[3px] focus-visible:after:border-[2px] focus-visible:after:pointer-events-none
          focus-visible:after:border-border-focus`]: {},
        },
        ".focus-ring-rounded-md": {
          "@apply focus-ring focus-visible:after:[border-radius:8px]": {},
        },
        ".focus-ring-rounded-lg": {
          "@apply focus-ring focus-visible:after:[border-radius:10px]": {},
        },
        ".input-border": {
          // `focus-within` instead of `focus` to support composite input-like controls
          [`@apply rounded-md border border-border-default-var outline-none
            hover:border-border-bright-var hover:focus-within:border-border-focus focus-within:border-border-focus
            disabled:border-border-default-var group-disabled/fieldset:border-border-default-var
            ui-disabled:border-border-default-var hover:focus-within:ui-disabled:border-border-default-var
            state-transition-colors`]: {},
        },
        ".input-text": {
          [`@apply text-text-bright placeholder:text-text-muted disabled:text-text-muted
            disabled:placeholder:text-border-default group-disabled/fieldset:text-text-muted
            group-disabled/fieldset:placeholder:text-border-default ui-disabled:text-text-muted
            ui-disabled:placeholder:text-border-default state-transition-colors 
            placeholder:state-transition-colors`]: {},
        },
        ".input-base": {
          "@apply bg-transparent input-text input-border px-3 py-2": {},
        },
        ".button-base": {
          "@apply font-medium whitespace-nowrap rounded-md outline-none": {},
        },
        ".button-primary": {
          [`@apply text-text-bright bg-brand-purple active:bg-brand-purple active:text-text-link
          hover:bg-brand-purple-l1 disabled:bg-bg-l3 disabled:text-text-muted focus-ring-rounded-md
          state-transition-colors`]: {},
        },
        ".wg-span-1\\/2": {
          "@apply col-span-6 lg:col-span-3 3xl:col-span-2": {},
        },
        ".wg-span-1\\/3": {
          "@apply col-span-6 lg:col-span-3 xl:col-span-2": {},
        },
        ".wg-span-2\\/3": {
          "@apply col-span-6 lg:col-span-4": {},
        },
        ".wg-span-full": {
          "@apply col-span-6": {},
        },
        ".wg-highlight-scroll-to": {
          [`@apply border border-transparent data-[scrolled-to=true]:border-text-link 
          transition-[border-color] ease-in-out duration-500`]: {},
        },
        ".wg-title-highlight-scroll-to": {
          "@apply group-data-[scrolled-to=true]:text-text-link transition-[color] ease-in-out duration-500":
            {},
        },
        ".shadow-l-lg": {
          "box-shadow":
            "-10px 0 15px 0 rgb(0 0 0 / 0.1), -4px 0 6px 0 rgb(0 0 0 / 0.1)",
        },
      });

      ["border.bright", "text.link"].forEach((colorName) => {
        const color = theme(`colors.${colorName}`);
        const borderWidth = "2px";
        // see https://css-tricks.com/more-control-over-css-borders-with-background-image/
        addComponents({
          [`.border-dashed-8-4-${colorName.replaceAll(".", "-")}`]: {
            backgroundImage: `repeating-linear-gradient(0deg, ${color}, ${color} 8px, transparent 8px, transparent 12px, ${color} 12px),
                              repeating-linear-gradient(90deg, ${color}, ${color} 8px, transparent 8px, transparent 12px, ${color} 12px),
                              repeating-linear-gradient(180deg, ${color}, ${color} 8px, transparent 8px, transparent 12px, ${color} 12px),
                              repeating-linear-gradient(270deg, ${color}, ${color} 8px, transparent 8px, transparent 12px, ${color} 12px)`,
            backgroundSize: `${borderWidth} calc(100% + 12px), calc(100% + 12px) ${borderWidth}, ${borderWidth} calc(100% + 12px), calc(100% + 12px) ${borderWidth}`,
            backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
            backgroundRepeat: "no-repeat",
          },
        });
      });
    },
  ],
};
