import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import Form from "@/components/input/Form";

// @ts-ignore
import { ReactComponent as SalesforceLogo } from "@/assets/logos/salesforce.svg";

import {
  OpenSalesforceLink,
  useSalesforceSettingsForm,
} from "@/pages/settings/panels/integrations/Salesforce";
import UndoButton from "@/components/UndoButton";
import ConnectButton from "@/pages/settings/panels/integrations/components/ConnectButton";
import DomainInput from "@/pages/settings/panels/integrations/components/DomainInput";
import { Text } from "@/components/hooks/useText";
import ErrorMessage from "@/components/input/ErrorMessage";

import { Infer } from "myzod";
import { oauthInstallationSchema } from "../schema";
import { type IntegrationComponent } from "../types";

import useEasyForm from "@/components/easy-form";

type SFInstallationStatus = Infer<typeof oauthInstallationSchema>;

const SalesforceSetupOAuth: IntegrationComponent = ({
  registerNoopSubmitHandler,
}) => {
  const {
    unsavedChanges,
    connected,
    getFormProps,
    getInputProps,
    getConnectButtonProps,
    getUndoButtonProps,
    getOpenSFLinkProps,
  } = useSalesforceSettingsForm();

  const { handleSubmit, errors } = useEasyForm<SFInstallationStatus>({
    values: { connected },
    schema: oauthInstallationSchema,
  });

  useEffect(
    () => registerNoopSubmitHandler(handleSubmit),
    [handleSubmit, registerNoopSubmitHandler]
  );

  return (
    <div className="flex flex-col gap-4">
      <Form {...getFormProps()} className="flex flex-col gap-1">
        <div className="flex items-center justify-between gap-2">
          <SalesforceLogo className="w-14 self-start" />
          <DomainInput {...getInputProps()}>
            <OpenSalesforceLink {...getOpenSFLinkProps()} />
          </DomainInput>
          {unsavedChanges && <UndoButton {...getUndoButtonProps()} />}
          <ConnectButton
            {...getConnectButtonProps()}
            className="button-base button-primary px-4 py-1.5"
          />
        </div>
        <div className="text-sm text-text-muted mx-1 cursor-default">
          <Text i18nKey="salesforce.footer.setting_up" />
        </div>
      </Form>
      {!isEmpty(errors) && (
        <ErrorMessage>
          <Text i18nKey="salesforce.error.not_installed" />
        </ErrorMessage>
      )}
    </div>
  );
};

export default SalesforceSetupOAuth;
