import React, { useState } from "react";
import { Grid, Box, Button, Typography, Alert, TextField } from "@mui/material";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../../themes/theme";
import { useAuth } from "../../components/Auth";

const Login = () => {
  const [searchParams] = useSearchParams();
  const { isSignedIn, signIn } = useAuth();
  const [email, setEmail] = useState(searchParams.get("email") ?? "");
  const [password, setPassword] = useState("");
  const [alertBox, setAlertBox] = useState(<></>);
  const navigate = useNavigate();

  if (isSignedIn) {
    return <Navigate replace to="/organization/select" />;
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const session = await signIn({ email, password });
      if (session && session.authenticated) {
        navigate("/organization/select", { replace: true });
      } else {
        setAlertBox(
          <Alert severity="warning" style={{ marginBottom: "10px" }}>
            There was an error validating your email and/or password. <br />{" "}
            Please try again.
          </Alert>
        );
      }
    } catch (error) {
      setAlertBox(
        <Alert severity="warning" style={{ marginBottom: "10px" }}>
          There was an error validating your email and/or password. <br />{" "}
          Please try again.
        </Alert>
      );
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        m="auto"
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Typography variant="h4" style={{ paddingBottom: "20px" }}>
          Sign in to your account
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            alignItems="center"
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1em",
            }}
          >
            <TextField
              data-testid="email"
              label="Email"
              type="email"
              required
              fullWidth
              value={email}
              onChange={handleEmailChange}
            />
            <TextField
              data-testid="password"
              label="Password"
              type="password"
              required
              fullWidth
              value={password}
              onChange={handlePasswordChange}
            />
            <Button
              data-testid="login-button"
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              Login
            </Button>
            {alertBox}
            <Typography variant="body2">
              Don't have an account?
              <Button variant="text" href="/user/register" color="info">
                Register
              </Button>
              <Button
                variant="text"
                href="/user/reset-password-token"
                color="info"
              >
                Forgot password
              </Button>
            </Typography>
          </Grid>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
