import { useAPI } from "@/client/api";
import { BypassId, BypassJSON } from "./serialization";
import { EntityId, useModelAPI } from "@/client/models-api";

const bypassBaseURL = "bypass/";

export const useBypassListAPI = () => {
  const { data, mutate } = useAPI<BypassJSON[]>(bypassBaseURL);
  return {
    bypasses: data ?? [],
    mutate,
  };
};

export const useBypassAPI = ({
  bypassId,
  options,
}: {
  bypassId?: BypassId | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
}) => {
  const { json, saveEntity, patchEntity, deleteEntity } =
    useModelAPI<BypassJSON>({
      entityId: bypassId ?? EntityId.new(),
      endpointURL: bypassBaseURL,
    });

  const setActive = async (value: boolean) => {
    await patchEntity({ is_active: value }, options);
  };

  return {
    bypass: json,
    setBypassActive: setActive,
    saveBypass: saveEntity,
    patchBypass: patchEntity,
    deleteBypass: deleteEntity,
  };
};
