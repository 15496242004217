import React from "react";

import SettingsPanel from "@/pages/settings/SettingsPanel";
import Slack from "./integrations/Slack";
import Salesforce from "./integrations/Salesforce";
import HubSpot from "./integrations/HubSpot";
import Snowflake from "./integrations/Snowflake";

const Integrations = () => (
  <SettingsPanel keyPrefix="workspace/integrations">
    <ul className="flex flex-col gap-2">
      <li>
        <Salesforce />
      </li>
      <li>
        <Slack />
      </li>
      <li>
        <HubSpot />
      </li>
      <li>
        <Snowflake />
      </li>
    </ul>
  </SettingsPanel>
);

export default Integrations;
