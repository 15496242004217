import React from "react";
import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { colors, darkTheme } from "../themes/theme";

interface MacaButtonProps {
  text?: string;
  variant?: "text" | "outlined" | "contained";
  size?: "small" | "medium" | "large";
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  bgColor?: "primary" | "surface";
}

const MacaButton: React.FC<MacaButtonProps> = ({
  text,
  variant = "text",
  bgColor = "primary",
  size,
  onClick,
  disabled,
}) => {
  // Change border color and color based on variant
  const borderColor =
    variant === "outlined"
      ? "unstable_sxConfig.borderColor"
      : variant === "contained"
      ? darkTheme.palette.primary.main
      : "transparent";
  const color =
    variant === "text"
      ? darkTheme.palette.text.disabled
      : variant === "contained"
      ? "#FFFFFF"
      : darkTheme.palette.primary.main;

  return (
    <ThemeProvider theme={darkTheme}>
      <Button
        variant={variant}
        size={size}
        sx={{
          borderColor,
          fontSize: "1rem",
          borderRadius: "6px",
          color,
          height: "fit-content",
          backgroundColor:
            bgColor === "surface" ? colors.secondary.bgLighter : undefined,
        }}
        style={{ textTransform: "none" }}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </Button>
    </ThemeProvider>
  );
};

export default MacaButton;
