import { last } from "lodash";

export type ToCEntry = {
  id: string;
  text: string;
  level: number;
};

export type ToCTreeNode = {
  id: string;
  text: string;
  level: number;
  children: ToCTreeNode[];
};

export const toToCTree = (entries: ToCEntry[]) => {
  const tree: ToCTreeNode[] = [];
  const stack: ToCTreeNode[] = [];

  entries.forEach((entry) => {
    const node: ToCTreeNode = { ...entry, children: [] };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    while (stack.length && last(stack)!.level >= node.level) {
      stack.pop();
    }

    if (stack.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      last(stack)!.children.push(node);
    } else {
      tree.push(node);
    }

    stack.push(node);
  });

  return tree;
};
