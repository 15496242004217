import React, { useCallback, useMemo } from "react";
import { type BarSeriesOption } from "echarts/charts";
import { type EChartsOption } from "echarts/types/dist/shared";

import { type TFunction } from "i18next";

import { isNotNil } from "@/lib/functional";

import useText from "@/components/hooks/useText";

import { metricFormatter, type Formatter } from "@/components/insights/format";

import { type MetricDatum, type ChartProps } from "@/components/insights/types";

import { type BarChartPresentationProps } from "@/components/insights/BarChart2";

import { useKeys } from "@/components/insights/XYChart";
import { themeColor } from "@/components/colors";

import { Chart } from "./Chart";
import { useAccuracyData, type MetricAccuracyDatum } from "./accuracy";
import { useLegend } from "./legend";
import { useTooltip } from "./tooltip";

export type BarChartProps = ChartProps<
  BarChartPresentationProps,
  MetricDatum[],
  MetricAccuracyDatum[]
>;

type ChartOptions = Omit<BarSeriesOption, "id"> & EChartsOption;

const emphasisProps = {
  blur: {
    itemStyle: {
      opacity: 0.4,
    },
  },
};

const useAxes = ({
  verticalLayout,
  valueFormat,
  showLabels,
  maxValue,
  t,
}: {
  verticalLayout: boolean;
  valueFormat?: Formatter;
  showLabels: boolean;
  maxValue?: number;
  t: TFunction;
}) =>
  useMemo(() => {
    const autoRotate = verticalLayout ? [0, 20, 35, 45, 70, 90] : undefined;
    const keyOrValueLabel = t(valueFormat ? "valueLabel" : "keyLabel");
    const indexLabel = t("indexLabel");

    const categoryAxis = {
      type: "category",
      ...(showLabels ? { name: indexLabel } : { nameGap: 0 }),
      axisLabel: {
        margin: 12,
        showMinLabel: true,
        showMaxLabel: true,
        autoRotate,
      },
    };

    const valueAxis = {
      type: "value",
      ...(showLabels
        ? { name: keyOrValueLabel, nameGap: verticalLayout ? 8 : 14 }
        : { nameGap: 0 }),
      splitNumber: 10,
      max:
        maxValue ??
        ((value: { min: number; max: number }) =>
          value.max + (value.max - value.min) * 0.05),

      axisLabel: {
        margin: 10,
        formatter: valueFormat,
        showMinLabel: true,
        showMaxLabel: isNotNil(maxValue),
      },
    };

    return verticalLayout
      ? { xAxis: categoryAxis, yAxis: valueAxis }
      : { xAxis: valueAxis, yAxis: categoryAxis };
  }, [verticalLayout, valueFormat, showLabels, maxValue, t]);

const useBarChartProps = ({
  id,
  presentation,
  data,
  accuracy,
}: BarChartProps): ChartOptions => {
  const {
    orientation,
    metricType,
    indexBy,
    groupMode,
    showAxesLabels,
    colors,
  }: BarChartPresentationProps = {
    metricType: "currency",
    orientation: "vertical",
    groupMode: "stacked",
    showAxesLabels: true,
    ...presentation,
  };

  const keys = useKeys(data, { indexBy, comprehensive: true });
  const t = useText({ keyPrefix: `charts.${id}` });

  const valueFormat = metricType
    ? metricFormatter(metricType, true)
    : undefined;

  const verticalLayout = orientation === "vertical";
  const stack = groupMode === "stacked" ? indexBy : undefined;

  const axesProps = useAxes({
    verticalLayout,
    valueFormat,
    showLabels: showAxesLabels,
    ...(metricType === "percentage" ? { maxValue: 1 } : {}),
    t,
  });

  const legendProps = useLegend(keys);
  const tooltipProps = useTooltip(metricType);
  const accuracyProps = useAccuracyData({
    accuracyData: accuracy,
    indexBy,
    keys,
    verticalLayout,
  });

  const seriesStyle = useCallback(
    (id: string) => {
      const colorName = colors?.[id];
      const color = colorName ? themeColor(colorName) : undefined;
      return color ? { itemStyle: { color } } : {};
    },
    [colors]
  );

  return useMemo(
    () =>
      ({
        ...axesProps,
        ...legendProps,
        ...tooltipProps,
        dataset: [
          {
            source: data,
            dimensions: [indexBy, ...keys],
          },
          ...accuracyProps.dataset,
        ],
        series: [
          ...keys.map((key) => ({
            type: "bar",
            datasetIndex: 0,
            stack,
            ...emphasisProps,
            ...seriesStyle(key),
          })),
          ...accuracyProps.series.map((s, i) => ({
            ...s,
            datasetIndex: 1 + i,
          })),
        ],
        animationDuration: 300,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any as ChartOptions),
    [
      data,
      indexBy,
      keys,
      stack,
      axesProps,
      legendProps,
      tooltipProps,
      accuracyProps,
      seriesStyle,
    ]
  );
};

const BarChart = (props: BarChartProps) => {
  const chartProps = useBarChartProps(props);
  return <Chart id={props.id} className="w-full h-full" {...chartProps} />;
};

export default BarChart;
