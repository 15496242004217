import { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";

const renderToElement = (children: ReactNode, tagName?: string) => {
  const element = document.createElement(tagName ?? "div");
  const root = createRoot(element);
  flushSync(() => root.render(children));
  return element;
};

export default renderToElement;
