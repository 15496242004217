import { deepmerge } from "deepmerge-ts";
import Cookies from "js-cookie";

const defaultFetchOptions: RequestInit = { credentials: "include" };

export async function baseClient(
  path: string,
  options: RequestInit = {}
): Promise<Response> {
  const mergedOptions = deepmerge({}, defaultFetchOptions, options, {
    // Cookies cannot be part of defaultFetchOptions as the value can potentially be stale.
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  });

  return fetch(
    process.env["REACT_APP_API_HOST"] + path,
    mergedOptions as RequestInit
  );
}
