import React from "react";
import { RouteObject, Navigate } from "react-router-dom";

import SettingsLayout from "./SettingsLayout";
import Integrations from "./panels/Integrations";
import Members from "./panels/Members";
import Profile from "./panels/Profile";

const routes = [
  // Comment out until we flesh these out
  // { path: "workspace", element: <Workspace /> },
  { path: "workspace/integrations", element: <Integrations /> },
  { path: "workspace/members", element: <Members /> },
  { path: "user/profile", element: <Profile /> },
];

const settingsRoutes = ({ path }: { path: string }) => [
  {
    element: (
      <SettingsLayout routes={routes} sections={["workspace", "user"]} />
    ),
    path,
    children: [
      {
        index: true,
        element: <Navigate to={routes[0]?.path || ""} />,
      } as RouteObject,
    ].concat(routes),
  },
];

export default settingsRoutes;
