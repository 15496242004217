import React, { PropsWithChildren, useEffect } from "react";
import { groupBy, pick } from "lodash";
import cx from "clsx";
import { Trans } from "react-i18next";

import useEasyForm, { useSubforms } from "@/components/easy-form";
import useText, { Text } from "@/components/hooks/useText";
import Tooltip from "@/components/Tooltip";

// @ts-ignore
import { ReactComponent as PrivacyShieldIcon } from "@/assets/icons/privacy-shield.svg";
// @ts-ignore
import stripeInvoicesInstructions from "../assets/stripe-invoice-instructions.gif";

import { useUploadsListAPI } from "../../api";
import { BillingUploadsValues, billingUploadsSchema } from "../schema";
import FileUpload from "../components/FileUpload";
import { useStepContext } from "../StepsLayout";

const ImageTooltip = ({
  src,
  className,
  children,
}: PropsWithChildren<{
  src: string;
  className?: string;
}>) => (
  <Tooltip
    content={
      <div className="p-2">
        <img src={src} className={cx("rounded-md", className)} alt="" />
      </div>
    }
    delayDuration={200}
    ignoreClick={true}
    side="top"
    className="z-50 bg-base-white fill-base-white rounded-lg drop-shadow-lg"
  >
    <span className="decoration-text-muted/60 hover:text-text-bright cursor-pointer">
      {children}
    </span>
  </Tooltip>
);

const InstallationInstructions = () => {
  const t = useText();
  return (
    <div className={cx("text-sm cursor-default border-l border-transparent")}>
      <Trans
        i18nKey="instructions"
        t={t}
        components={[
          <ImageTooltip src={stripeInvoicesInstructions} className="w-96" />,
        ]}
      />
    </div>
  );
};

const BillingExport = () => {
  const uploads = useUploadsListAPI();
  const { Panel } = useStepContext();

  const { register, handleSubmit } = useEasyForm<BillingUploadsValues>({
    values: pick(groupBy(uploads, "category"), [
      "invoices",
    ]) as BillingUploadsValues,
    schema: billingUploadsSchema,
  });

  const { handleSubmitAll, registerNoopSubmitHandler } =
    useSubforms(handleSubmit);

  useEffect(
    () => registerNoopSubmitHandler(handleSubmit),
    [handleSubmit, registerNoopSubmitHandler]
  );

  return (
    <Panel
      handleSubmit={(event, { next }) =>
        handleSubmitAll(async () => {
          next();
        })(event)
      }
    >
      <div className="flex flex-col gap-4">
        <div className="text-text-bright font-medium leading-tight">
          <Text i18nKey="other.label" />
        </div>
        <InstallationInstructions />
        <FileUpload {...register("invoices")} mode="multiple" />
        <div className="flex items-center gap-2">
          <PrivacyShieldIcon className="w-8 aspect-square text-text-muted/60 px-1" />
          <div className="text-text-muted text-sm leading-tight">
            <Text i18nKey="other.footer" />
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default BillingExport;
