import React, { PropsWithChildren } from "react";

import { Text } from "@/components/hooks/useText";

const Label = ({
  i18nKey,
  children,
}: PropsWithChildren<{ i18nKey?: string }>) => (
  <div className="text-text-default pl-1">
    {i18nKey && <Text {...{ i18nKey }} />}
    {children}
  </div>
);

export default Label;
