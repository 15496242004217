// import & register only the components we use, see
// https://echarts.apache.org/handbook/en/basics/import/#shrinking-bundle-size

import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { LabelLayout, UniversalTransition } from "echarts/features";

import {
  LegendComponent,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  TitleComponent,
  DatasetComponent,
  VisualMapComponent,
} from "echarts/components";

import { BarChart, HeatmapChart, CustomChart } from "echarts/charts";

export const register = () =>
  echarts.use([
    // renderers
    SVGRenderer,
    // features
    LabelLayout,
    UniversalTransition,
    // components
    DatasetComponent,
    GridComponent,
    LegendComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
    // charts
    BarChart,
    HeatmapChart,
    CustomChart,
  ]);
