import React, { useEffect } from "react";

import { isEmpty } from "lodash";

// @ts-ignore
import { ReactComponent as SnowflakeLogo } from "@/assets/logos/snowflake.svg";

import {
  HelpBlock,
  OpenSnowflakeLink,
  useSnowflakeSettingsForm,
} from "@/pages/settings/panels/integrations/Snowflake";
import DomainInput from "@/pages/settings/panels/integrations/components/DomainInput";
import ConnectButton from "@/pages/settings/panels/integrations/components/ConnectButton";
import UndoButton from "@/components/UndoButton";

import { Text, TranslationScope } from "@/components/hooks/useText";
import Form from "@/components/input/Form";
import ErrorMessage from "@/components/input/ErrorMessage";
import { oauthInstallationSchema } from "../schema";
import { Infer } from "myzod";
import useEasyForm from "@/components/easy-form";
import { type IntegrationComponent } from "../types";

type SnowFlakeInstallationStatus = Infer<typeof oauthInstallationSchema>;

const SnowflakeSetup: IntegrationComponent = ({
  registerNoopSubmitHandler,
}) => {
  const {
    showHelp,
    unsavedChanges,
    getFormProps,
    getUsernameInputProps,
    getPasswordInputProps,
    getAccountInputProps,
    getConnectButtonProps,
    getUndoButtonProps,
    getOpenSFLinkProps,
    error,
    connected,
  } = useSnowflakeSettingsForm({ keyPrefix: "snowflake" });

  const { handleSubmit, errors } = useEasyForm<SnowFlakeInstallationStatus>({
    values: { connected },
    schema: oauthInstallationSchema,
  });

  useEffect(
    () => registerNoopSubmitHandler(handleSubmit),
    [handleSubmit, registerNoopSubmitHandler]
  );

  return (
    <TranslationScope keyPrefix="snowflake">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <SnowflakeLogo className="w-14 self-start shrink-0" />
          <div className="text-text-bright text-lg font-bold">
            <Text i18nKey="title" />
          </div>
        </div>
        {showHelp && <HelpBlock />}
        <Form {...getFormProps()} className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <DomainInput {...getAccountInputProps()}>
                <OpenSnowflakeLink {...getOpenSFLinkProps()} />
              </DomainInput>
              {unsavedChanges && <UndoButton {...getUndoButtonProps()} />}
            </div>
            <div className="flex justify-between gap-2">
              <input
                className="input-base min-w-[12rem]"
                {...getUsernameInputProps()}
              />
              <input
                className="input-base min-w-[12rem]"
                {...getPasswordInputProps()}
              />
              <ConnectButton
                {...getConnectButtonProps()}
                className="button-base button-primary px-4 py-1.5"
              />
            </div>
            <div />
          </div>
          <div className="text-sm text-text-muted mx-1 cursor-default">
            <Text i18nKey="footer.setting_up" />
          </div>
          {error && <ErrorMessage message={error} />}
        </Form>
        {!isEmpty(errors) && (
          <ErrorMessage>
            <Text i18nKey="error.not_installed" />
          </ErrorMessage>
        )}
      </div>
    </TranslationScope>
  );
};

export default SnowflakeSetup;
