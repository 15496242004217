import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";

import { colors } from "./colors";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  gridGap: "1em",
  "& .MuiToggleButtonGroup-grouped": {
    "&:not(:first-of-type)": {
      borderRadius: 5,
      borderColor: colors.secondary.lightGray,
    },
    "&:first-of-type": {
      borderRadius: 5,
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 5,
      borderColor: colors.primary.purple,
      backgroundColor: colors.secondary.darkGray,
      color: colors.secondary.ghostWhite,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  "&.MuiToggleButton-root": {
    textTransform: "none",
    justifyContent: "left",
    color: colors.primary.textMonoBase,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    borderColor: colors.secondary.lightGray,
    padding: 14,
    fontSize: "0.9em",
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    borderRadius: 5,
    border: "1px solid !important",
    borderColor: colors.primary.purple + " !important",
  },
}));

export { StyledToggleButtonGroup, StyledToggleButton };
