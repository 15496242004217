import React, { type PropsWithChildren } from "react";
import cx from "clsx";

//@ts-ignore
import { ReactComponent as WarningIcon } from "@/assets/icons/triangle-warning-small.svg";

const ErrorMessage = ({
  children,
  message,
  className,
}: PropsWithChildren<{
  message?: string;
  className?: string;
}>) => (
  <div
    className={cx(
      "flex gap-2.5 pl-[1px] items-center leading-tight text-text-error text-sm",
      className
    )}
  >
    <WarningIcon className="w-3 aspect-square shrink-0" />
    <span role="alert">{children ?? message}</span>
  </div>
);

export default ErrorMessage;
