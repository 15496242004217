import React, { useMemo } from "react";
import { type CallbackDataParams } from "echarts/types/dist/shared";
import { keyBy } from "lodash";
import cx from "clsx";

import renderToElement from "@/components/render-to-element";

import { type MetricValueType } from "@/components/insights/types";
import { metricFormatter, type Formatter } from "@/components/insights/format";

type SeriesValue =
  | {
      seriesType: "bar";
      value: Record<string, string | number>;
    }
  | {
      seriesType: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any;
    };

export type TooltipCallbackDataParams = Omit<
  CallbackDataParams,
  "seriesType" | "value"
> &
  SeriesValue & {
    seriesName: string;
  } & {
    // based on https://github.com/apache/echarts/blob/master/src/component/tooltip/TooltipView.ts#L127
    axisDim: string;
    axisIndex: number;
    axisType: string;
    axisId: string;
    axisValue: string | number;
    axisValueLabel: string;
    marker: string;
  };

const formatAccuracy = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accuracyValue: any,
  value: string | number | undefined,
  valueFormatter: Formatter
) => {
  if (accuracyValue.length < 3) {
    return "";
  }

  const normalizedValue = Number(value);
  const minValue = Number(accuracyValue[1]);
  const maxValue = Number(accuracyValue[2]);

  const positiveDelta = maxValue - normalizedValue;
  const negativeDelta = normalizedValue - minValue;
  return positiveDelta === negativeDelta
    ? `±${valueFormatter(positiveDelta)}`
    : `+${valueFormatter(positiveDelta)} / -${valueFormatter(negativeDelta)}`;
};

const SeriesEntry = ({
  seriesByName,
  hasAccuracyData,
  valueFormatter,
  marker,
  seriesName,
  value,
}: {
  seriesByName: Record<string, TooltipCallbackDataParams>;
  hasAccuracyData: boolean;
  valueFormatter: Formatter;
  marker: string;
  seriesName: string;
  value: Record<string, string | number>;
}) => {
  const accuracy = seriesByName[`${seriesName}.accuracy`];
  return (
    <>
      <span className="flex items-center gap-0.5">
        <span dangerouslySetInnerHTML={{ __html: marker }} />
        <span className="mr-4 whitespace-nowrap">{seriesName}</span>
      </span>
      <span className="font-extrabold text-right">
        {valueFormatter(value?.[seriesName])}
      </span>
      {hasAccuracyData && (
        <span className="text-2xs font-medium text-text-default whitespace-nowrap">
          {accuracy
            ? formatAccuracy(
                accuracy.value,
                value?.[seriesName],
                valueFormatter
              )
            : ""}
        </span>
      )}
    </>
  );
};

export const TooltipBody = ({
  metricType,
  series,
}: {
  metricType: MetricValueType;
  series: TooltipCallbackDataParams[];
}) => {
  const barSeries = series.filter((s) => s.seriesType === "bar");
  if (!barSeries.length) {
    return <span>n/a</span>;
  }

  const valueFormatter = metricType
    ? metricFormatter(metricType, false)
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (v?: any) => v;

  const seriesByName = keyBy(series, "seriesName");
  const hasAccuracyData = series.length > Object.keys(barSeries).length;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { axisValueLabel } = barSeries[0]!;
  return (
    <div className="flex flex-col gap-2 leading-tight">
      <span>{axisValueLabel}</span>
      <div
        className={cx(
          "grid gap-1.5 items-center",
          hasAccuracyData
            ? "grid-cols-[minmax(0,min-content),minmax(0,min-content),minmax(0,min-content)]"
            : "grid-cols-[minmax(0,min-content),minmax(0,min-content)]"
        )}
      >
        {barSeries.map(({ seriesName, ...props }) => (
          <SeriesEntry
            key={seriesName}
            {...{
              seriesName,
              hasAccuracyData,
              valueFormatter,
              seriesByName,
              ...props,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const useTooltip = (metricType: MetricValueType) =>
  useMemo(
    () => ({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (series: TooltipCallbackDataParams[]) =>
          series.length
            ? renderToElement(<TooltipBody {...{ metricType, series }} />)
            : undefined,
      },
    }),
    [metricType]
  );
