import React, {
  useMemo,
  useRef,
  useState,
  type CSSProperties,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
} from "react";

import { type AxisProps } from "@nivo/axes";
import { type Margin, type CompleteTheme, useTheme } from "@nivo/core";
import { scaleLinear } from "d3-scale";

import { max, omit, first, last } from "lodash";
import memoize from "micro-memoize";
import cx from "clsx";

import { isNotNil } from "@/lib/functional";

import useText from "@/components/hooks/useText";
import useSize, { type Size } from "@/components/hooks/useSize";

import type {
  ValueType,
  MetricValueType,
  MetricDatum,
  ChartPresentationProps,
  ChartProps,
  ChartOrientation,
} from "./types";

import { metricFormatter, valueFormatter } from "./format";
import { useChartAnimationContext } from "./animation";
import { chartColors } from "./colorPicker";
import Legend from "./Legend";

export type XYChartProps = ChartProps<ChartPresentationProps, MetricDatum[]>;

const assignStyles = (
  target: ElementCSSInlineStyle,
  styles: Partial<CSSProperties>
) =>
  Object.entries(styles).forEach(
    ([key, value]) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((target.style as any)[key] =
        typeof value === "string" ? value : `${value}px`)
  );

const svgTextSandbox = memoize((styles: Partial<CSSProperties>) => {
  const sandbox = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  assignStyles(sandbox, {
    position: "absolute",
    visibility: "hidden",
    left: "-10000px",
    top: "-10000px",
  });

  document.body.appendChild(sandbox);

  const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
  assignStyles(text, styles);
  sandbox.appendChild(text);
  return text;
});

const sgvTextSize = memoize(
  (text: string | undefined, styles: Partial<CSSProperties>) => {
    const textSandbox = svgTextSandbox(styles);
    textSandbox.textContent = text ?? null;
    const { width, height } = textSandbox.getBoundingClientRect(); // `getBBox()` is about 2x faster, but is not always accurate
    return { width, height };
  },
  { maxSize: 1024 }
);

const maxTickLabelSize = (labels: string[], theme: CompleteTheme) => {
  const styles = theme.axis?.ticks?.text;
  const sizes = labels.map((l) => sgvTextSize(l, styles));
  return {
    width: Math.ceil(max(sizes.map((s) => s.width)) ?? 0),
    height: Math.ceil(max(sizes.map((s) => s.height)) ?? 0),
  };
};

const useKeyOrValueLabels = (
  data: MetricDatum[],
  indexBy: string,
  valueFormat?: (value: number) => string,
  tickCount?: number
) => {
  const keys = useKeys(data, { indexBy, comprehensive: !valueFormat });
  if (!valueFormat) return keys;

  const values = data.flatMap((row) => keys.map((k) => Number(row[k])));
  return scaleLinear()
    .domain([0, max(values) ?? 0])
    .ticks(tickCount)
    .map((v) => valueFormat(v));
};

const useIndexLabels = (
  data: MetricDatum[],
  indexBy: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatter: (x: any) => string
) =>
  useMemo(
    () => data.map((d) => formatter(d[indexBy])),
    [data, indexBy, formatter]
  );

const useXAxisLabels = (labels: string[], perLabelWidth: number) => {
  const labelFrequency = perLabelWidth < 8 ? 3 : perLabelWidth < 28 ? 2 : 1;
  const xs = perLabelWidth < 5;
  return useMemo(
    () =>
      new Set(
        xs
          ? [first(labels), last(labels)].filter(isNotNil)
          : labels.filter((_, i) => !(i % labelFrequency))
      ),
    [labels, xs, labelFrequency]
  );
};

const toRadians = (degrees: number) => degrees * (Math.PI / 180);

const indexLabelHeight = (tickRotation: number, labelWidth: number) =>
  Math.ceil(Math.sin(toRadians(Math.abs(tickRotation))) * labelWidth);

const toHTMLTextStyle = (style?: Partial<CSSProperties>) =>
  style
    ? {
        fontSize: `${style.fontSize}px`,
        color: style.fill,
        fontFamily: style.fontFamily,
      }
    : {};

const getAxisLabelStyle = (theme: CompleteTheme) =>
  toHTMLTextStyle(theme.axis?.legend?.text);

export const useAxisTransitions = ({ size }: { size?: Size | null }) => {
  const [enableTransition, setEnableTransition] = useState(false);
  useEffect(() => {
    if (size) {
      setTimeout(() => setEnableTransition(true), 50);
    }
  }, [size]);

  return cx(
    enableTransition && "[transition-property:margin] duration-300 ease-in-out",
    size ? "" : "opacity-0"
  );
};

export const YAxisLabel = ({
  theme,
  children,
  marginTop,
  marginBottom,
  className,
}: PropsWithChildren<{
  theme: CompleteTheme;
  marginTop?: number;
  marginBottom?: number;
  className?: string;
}>) => {
  const axisLabelStyle = getAxisLabelStyle(theme);
  return (
    <div
      className={cx("text-sideways-lr text-center leading-none", className)}
      style={{ ...axisLabelStyle, marginTop, marginBottom }}
    >
      {children}
    </div>
  );
};

export const XAxisLabel = ({
  theme,
  children,
  marginLeft,
  className,
}: PropsWithChildren<{
  theme: CompleteTheme;
  marginLeft: number;
  className?: string;
}>) => {
  const axisLabelStyle = getAxisLabelStyle(theme);

  return (
    <div
      className={cx("text-center leading-none", className)}
      style={{ ...axisLabelStyle, marginLeft }}
    >
      {children}
    </div>
  );
};

export const useKeys = (
  data: MetricDatum[],
  { indexBy, comprehensive }: { indexBy: string; comprehensive?: boolean }
) =>
  useMemo(
    () =>
      comprehensive
        ? [...new Set(data.flatMap(Object.keys)).values()].filter(
            (k) => k !== indexBy
          )
        : Object.keys(omit(data[0], [indexBy])),
    [data, indexBy, comprehensive]
  );

const useChartSize = () => {
  const target = useRef(null);
  const size = useSize(target);
  return {
    target,
    size,
  };
};

export const useAxes = ({
  id,
  data,
  indexBy,
  metricType,
  indexType,
  orientation,
}: {
  id: string;
  data: MetricDatum[];
  indexBy: string;
  metricType?: MetricValueType;
  indexType?: ValueType;
  orientation: ChartOrientation;
}) => {
  const t = useText({ keyPrefix: `charts.${id}` });
  const theme = useTheme();
  const { target, size } = useChartSize();

  const verticalLayout = orientation === "vertical";
  const valueFormat = metricType
    ? metricFormatter(metricType, true)
    : undefined;

  // "value label" is a formatted numberic amount
  const keyOrValueLabels = useKeyOrValueLabels(
    data,
    indexBy,
    valueFormat,
    verticalLayout ? 7 : 10
  );

  // "index label" is a text description of the corresponding numeric value
  const indexFormat = indexType ? valueFormatter(indexType, false) : String;
  const indexLabels = useIndexLabels(data, indexBy, indexFormat);

  const [xLabels, yLabels] = verticalLayout
    ? [indexLabels, keyOrValueLabels]
    : [keyOrValueLabels, indexLabels];

  const tickSize = 5;
  const tickPadding = 5;
  const marginX = useMemo(
    () => maxTickLabelSize(yLabels, theme).width + tickSize + tickPadding,
    [yLabels, theme]
  );

  const chartWidth = size ? size?.width - marginX : 0;
  const perLabelWidth = Math.round(chartWidth / xLabels.length);
  const xAxisLabels = useXAxisLabels(xLabels, perLabelWidth);

  const maxXAxisLabelSize = useMemo(
    () => maxTickLabelSize([...xAxisLabels], theme),
    [xAxisLabels, theme]
  );

  const xAxisLabelFormat = (l: string | number) => {
    const label = indexFormat(l);
    return xAxisLabels.has(label) ? label : "";
  };

  const tickRotation =
    (maxXAxisLabelSize.width + 4) * xLabels.length >= chartWidth
      ? perLabelWidth < 14
        ? -90
        : -30
      : 0;

  const maxXAxisLabelHeight = Math.max(
    indexLabelHeight(
      tickRotation,
      maxXAxisLabelSize.width +
        maxXAxisLabelSize.height * Math.sin(toRadians(100 + tickRotation))
    ),
    Math.ceil(maxXAxisLabelSize.height + tickPadding)
  );

  const marginY = maxXAxisLabelHeight + tickSize + tickPadding;

  const axisProps: Partial<AxisProps> = {
    tickSize,
    tickPadding,
    tickRotation: 0,
    legendPosition: "middle",
  };

  const keyOrValueLabel = t(valueFormat ? "valueLabel" : "keyLabel");
  const indexLabel = t("indexLabel");

  return {
    target,
    size,
    yAxis: {
      ...axisProps,
      tickValues: yLabels.length,
      format: verticalLayout ? valueFormat : undefined,
    },
    xAxis: {
      ...axisProps,
      tickValues: xAxisLabels.size,
      tickRotation,
      format: verticalLayout ? xAxisLabelFormat : valueFormat,
    },
    yAxisLabel: verticalLayout ? keyOrValueLabel : indexLabel,
    xAxisLabel: verticalLayout ? indexLabel : keyOrValueLabel,
    margin: {
      x: marginX,
      y: marginY,
    },
  };
};

export type XYChartChildrenProps = Omit<ChartPresentationProps, "colors"> & {
  theme: CompleteTheme;
  colors: string[];
  data: MetricDatum[];
  keys: string[];
  valueFormat: (value: number) => string;
  labelFormat?: (value: string | number) => string;
  axisLeft: AxisProps;
  axisBottom: AxisProps;
  margin: Partial<Margin>;
  size: Size;
  animate: boolean;
};

export const XYChart = ({
  id,
  presentation,
  data,
  children,
}: XYChartProps & {
  children: (props: XYChartChildrenProps) => ReactNode;
}) => {
  const {
    indexBy,
    orientation,
    metricType,
    indexType,
    showAxesLabels,
  }: ChartPresentationProps = {
    metricType: "currency",
    orientation: "vertical",
    showAxesLabels: true,
    ...presentation,
  };

  const {
    target,
    size,
    yAxis: axisLeft,
    xAxis: axisBottom,
    yAxisLabel,
    xAxisLabel,
    margin,
  } = useAxes({
    id,
    data,
    indexBy,
    metricType,
    indexType,
    orientation,
  });

  const keys = useKeys(data, { indexBy });
  const valueFormat = metricFormatter(metricType, false);
  const labelFormat = indexType ? valueFormatter(indexType, false) : undefined;
  const colors = useMemo(() => chartColors(id), [id]);
  const chartMargin = useMemo(
    () => ({ left: margin.x, bottom: margin.y }),
    [margin.x, margin.y]
  );

  const theme = useTheme();
  const axisTransitions = useAxisTransitions({ size });
  const { animate } = useChartAnimationContext();
  const hasLegend = keys.length > 1;

  return (
    <div
      className={cx(
        "@container/chart relative grid",
        hasLegend
          ? "[grid-template-columns:auto_1fr_auto]"
          : "[grid-template-columns:auto_1fr]",
        "[grid-template-rows:1fr_auto] pt-2 gap-x-3 gap-y-2 h-full w-full"
      )}
    >
      {showAxesLabels ? (
        <YAxisLabel
          {...{ theme, marginBottom: margin.y, className: axisTransitions }}
        >
          {yAxisLabel}
        </YAxisLabel>
      ) : (
        <div />
      )}
      <div ref={target} className="relative w-full h-full">
        <div className="absolute svg-child-overflow-visible">
          {size
            ? children({
                theme,
                colors,
                data,
                keys,
                indexBy,
                valueFormat,
                labelFormat,
                orientation,
                axisLeft,
                axisBottom,
                margin: chartMargin,
                size,
                animate,
              })
            : null}
        </div>
      </div>
      {hasLegend && <Legend {...{ keys, colors, theme }} />}
      <div />
      {showAxesLabels ? (
        <XAxisLabel
          {...{ theme, marginLeft: margin.x, className: axisTransitions }}
        >
          {xAxisLabel}
        </XAxisLabel>
      ) : (
        <div />
      )}
      {hasLegend && <div />}
    </div>
  );
};

export default XYChart;
