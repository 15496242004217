import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Button, Alert, TextField, Typography } from "@mui/material";
import Cookies from "js-cookie";

import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../../themes/theme";

import { useAuth } from "@/components/Auth";

export default function OrganizationNew() {
  const { refreshSession } = useAuth();

  const [orgName, setOrgName] = useState("");
  const [csrf] = useState(Cookies.get("csrftoken"));
  const [alertBox, setAlertBox] = useState(<></>);

  const navigate = useNavigate();

  function showError(message: string) {
    setAlertBox(
      <Alert data-testid="alert-box" severity="error">
        {message}
      </Alert>
    );
  }

  async function submitForm(event: React.SyntheticEvent) {
    event.preventDefault();

    const payload: { [key: string]: string } = { name: orgName };

    fetch(process.env["REACT_APP_API_HOST"] + "/organization/", {
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRFToken": csrf,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          await refreshSession();
          navigate("/", { replace: true });
        } else {
          response.text().then((data) => {
            console.log(data);
            showError("Unable to create organization. Please try again.");
          });
        }
      })
      .catch((err) => showError(err.toString()));
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        m="auto"
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Typography variant="h4">New Organization</Typography>
        <Typography paragraph={true} style={{ paddingBottom: "20px" }}>
          An organization has one-to-one mapping with a Salesforce domain.
        </Typography>
        <form onSubmit={submitForm}>
          <Box
            alignItems="center"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1em",
            }}
          >
            <TextField
              data-testid="name"
              label="Name"
              required
              value={orgName}
              placeholder="Acme Inc."
              onChange={(e) => setOrgName(e.target.value)}
              fullWidth
            />
            <Box
              alignItems="center"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.5em",
              }}
            >
              <Button
                data-testid="submit-button"
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Create
              </Button>
              <Button
                data-testid="select-button"
                component={RouterLink}
                to="/organization/select"
                variant="outlined"
                fullWidth
              >
                Select an existing organization
              </Button>
            </Box>
            {alertBox}
          </Box>
        </form>
      </Box>
    </ThemeProvider>
  );
}
