import { useAPI } from "@/client/api";

import { splitFieldId } from "@/components/dataflow/common";

export type TableMetadata = {
  id: string;
  name: string;
  row_deps: string[];
  column_deps: Record<string, string[]>;
  data_quality: Record<string, FieldDataQuality>;
};

export type FieldDataQuality = {
  type:
    | "Int64"
    | "int64"
    | "float64"
    | "category"
    | "object"
    | "datetime64[ns]"
    | "period[M]"
    | "bool"
    | "boolean";

  total: number;
  accuracy: number;
  complete_ratio: number;
  unexpected: number;
  nas: number;
  outliers: Record<string, number>;
  errors: Record<string, number>[] | null;
};

export type FieldMetadata = FieldDataQuality & {
  id: string;
  name: string;
};

export const useTableMetadata = ({ tableName }: { tableName: string }) => {
  const { data } = useAPI<TableMetadata>(
    `insight/table/${encodeURIComponent(tableName)}/metadata/`
  );

  return data;
};

export const useFieldMetadata = ({ fieldId }: { fieldId: string }) => {
  const { tableName, fieldName } = splitFieldId(fieldId);
  const tableMetadata = useTableMetadata({ tableName });

  return tableMetadata?.data_quality[fieldName]
    ? {
        id: fieldId,
        name: `${tableMetadata.name}.${fieldName}`,
        ...(tableMetadata.data_quality[fieldName] as FieldDataQuality),
      }
    : undefined;
};

export type TableErrorsData = {
  columns: string[];
  data: Record<string, string>[];
  urls?: Record<"opportunity:", string>;
};

export const useTableErrorsData = ({
  tableName,
  fieldName,
}: {
  tableName: string;
  fieldName: string;
}) => {
  const { data } = useAPI<TableErrorsData>(
    `insight/table/${encodeURIComponent(tableName)}/errors/${encodeURIComponent(
      fieldName
    )}/`
  );

  return data;
};
