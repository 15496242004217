import { useCallback, useEffect, useLayoutEffect } from "react";
import { autoUpdate } from "@floating-ui/react";

import { boundingRect, Rectangle } from "@/lib/geometry";
import { isNotNil } from "@/lib/functional";

export const getClientRect = (element: Element): Rectangle => {
  // `DOMRect` is not spreadable, convert it to a plain `Rectangle` object
  const { left, top, width, height } = element.getBoundingClientRect();
  return { left, top, width, height };
};

export const elementsBoundingRect = (elements: (Element | null)[]) =>
  boundingRect(
    elements
      .filter(isNotNil)
      .filter((el) => document.body.contains(el))
      .map((el) => getClientRect(el))
  );

export const getAbsoluteClientRect = (element: Element) => {
  const clientRect = element.getBoundingClientRect();
  return {
    left: clientRect.left + window.scrollX,
    top: clientRect.top + window.scrollY,
    width: clientRect.width,
    height: clientRect.height,
  };
};

export const getDataset = (element: Element) =>
  element instanceof HTMLElement ? { ...element.dataset } : {};

// use Floating UI's `autoUpdate` middleware function to implement element's position/size tracking
export const useVizObserver = (element: Element, callback: () => void) =>
  useLayoutEffect(
    () => autoUpdate(element, window.document.body, callback),
    [element, callback]
  );

export const isIdSelector = (selector: string) =>
  selector.startsWith("#") && !selector.match(/[ [\]]/g);

export const queryForElement = (selector: string) =>
  // `getElementById` is about 2x faster than `querySelector`, prefer it if possible
  isIdSelector(selector)
    ? document.getElementById(selector.slice(1))
    : document.querySelector(selector);

export const useMutationObserver = (
  target: Node,
  options: MutationObserverInit,
  callback?: (_: MutationRecord[], observer: MutationObserver) => boolean
) =>
  useEffect(() => {
    if (!callback) return;

    const observer = new MutationObserver(callback);
    if (callback([], observer)) return;

    observer.observe(target, options);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, JSON.stringify(options), callback]);

export const useWaitForElement = <T,>(
  selector: string | undefined | null | (() => T | undefined | null),
  callback: (value: T) => void
) => {
  const waitForElement = useCallback(
    (_: MutationRecord[], observer: MutationObserver) => {
      const element =
        typeof selector === "string" ? queryForElement(selector) : selector?.();

      if (element) {
        observer.disconnect();
        callback(element as T);
        return true;
      }

      return false;
    },
    [selector, callback]
  );

  return useMutationObserver(
    document.body,
    { subtree: true, childList: true },
    selector ? waitForElement : undefined
  );
};
