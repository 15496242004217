import React, { forwardRef, PropsWithChildren, ReactNode } from "react";

import * as Portal from "@radix-ui/react-portal";

import { Rectangle } from "@/lib/geometry";

import { StepPopoverProps, useStepPopover } from "./Popover";
import { ProductTourStep } from "@/components/product-tour/store";

const clipOutPolygon = ({ left, top, width, height }: Rectangle) =>
  `polygon(0% 0%, 0% 100%, ${left}px 100%, ${left}px ${top}px, ${
    left + width
  }px ${top}px, ${left + width}px ${top + height}px, ${left}px ${
    top + height
  }px, ${left}px 100%, 100% 100%, 100% 0%)`;

const SpotlightClipOutOverlay = ({
  spotlightRect,
  onClick,
  children,
}: PropsWithChildren<{ spotlightRect: Rectangle; onClick: () => void }>) => (
  <div
    className="fixed inset-0 pointer-events-auto"
    style={{ clipPath: clipOutPolygon(spotlightRect) }}
    onClick={onClick}
  >
    {children}
  </div>
);

const Spotlight = forwardRef<HTMLDivElement, { rect: Rectangle }>(
  ({ rect }, ref) => (
    <div
      ref={ref}
      className="fixed bg-[rgb(128,128,128)] rounded-lg pointer-events-none"
      style={{ ...rect }}
    />
  )
);

export const ProductTourOverlay = ({
  step,
  spotlightRect,
  onClick,
  children,
}: {
  step: ProductTourStep;
  spotlightRect: Rectangle;
  onClick: () => void;
  children: ({ popoverProps }: { popoverProps: StepPopoverProps }) => ReactNode;
}) => {
  const placement = step.beacon ? step.beacon?.placement : undefined;
  const { anchorRef, popoverProps } = useStepPopover({ placement });
  return (
    <Portal.Root>
      <div
        className={
          "fixed z-[10000] overflow-hidden inset-0 mix-blend-hard-light bg-base-black/50 pointer-events-none"
        }
      >
        <SpotlightClipOutOverlay {...{ spotlightRect, onClick }} />
        <Spotlight ref={anchorRef} rect={spotlightRect} />
      </div>
      {children({ popoverProps })}
    </Portal.Root>
  );
};
