import React from "react";
import { Outlet, RouteObject } from "react-router-dom";

import { isNotNil } from "@/lib/functional";

import SettingsSidebar from "./SettingsSidebar";

interface SettingsLayoutProps {
  sections: string[];
  routes: RouteObject[];
}

const SettingsLayout = ({ routes, sections }: SettingsLayoutProps) => (
  <div className="grid max-w-6xl mx-auto w-full p-6 grid-cols-sidebar gap-11">
    <SettingsSidebar
      sections={sections}
      paths={routes.map((r) => r.path).filter(isNotNil)}
    />
    <div className="pt-24">
      <Outlet />
    </div>
  </div>
);

export default SettingsLayout;
