import { type CSSProperties } from "react";

export const toHTMLTextStyle = (style?: Partial<CSSProperties>) =>
  style
    ? {
        fontSize: `${style.fontSize}px`,
        color: style.fill,
        fontFamily: style.fontFamily,
      }
    : {};
