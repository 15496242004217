import React, { PropsWithChildren } from "react";
import { useText } from "@/components/hooks/useText";

const SettingsCard = ({
  unsavedChanges,
  children,
}: PropsWithChildren<{ unsavedChanges: boolean }>) => {
  const t = useText();

  return (
    <div className="flex flex-col grow gap-2">
      <div className="flex items-center justify-between h-9">
        <div className="font-medium text-text-bright text-lg leading-none select-none">
          {t("title")}
        </div>
        {unsavedChanges && (
          <div className="text-text-muted text-sm border border-border-default rounded-md px-2 py-1 select-none">
            {t("status.unsaved_changes")}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default SettingsCard;
