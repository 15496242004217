import React, { useMemo } from "react";
import * as Accordion from "@radix-ui/react-accordion";

import { NavLink, useNavigate } from "react-router-dom";
import { find } from "lodash";

import cx from "clsx";

// @ts-ignore
import { ReactComponent as CheckIcon } from "@/assets/icons/circle-check-filled.svg";

import HelpFooter from "@/components/HelpFooter";
import { useTranslationScope, useText } from "@/components/hooks/useText";

import {
  useOnboardingStatusAPI,
  OnboardingSteps,
} from "@/pages/onboarding/api";

import { linkSlug, calcCurrentLink, completionStatus } from "./links";

interface SectionProps {
  index: number;
  id: string;
  links: string[];
  active: boolean;
  steps?: OnboardingSteps;
}

const NumberBadge = ({ value, active }: { value: number; active: boolean }) => (
  <div
    className={cx(
      "flex items-center justify-center w-6 aspect-square shrink-0 rounded-full group-focus-ring-rounded-full",
      "font-medium text-sm text-text-bright select-none",
      active ? "bg-brand-purple" : "bg-bg-l4"
    )}
  >
    {value}
  </div>
);

const CheckmarkBadge = ({ active }: { active: boolean }) => (
  <div className="w-6 aspect-square shrink-0 rounded-full group-focus-ring-rounded-full">
    <CheckIcon
      className={cx(
        "text-text-bright scale-[1.25]",
        active ? "fill-brand-purple" : "fill-bg-l4"
      )}
    />
  </div>
);

const Dot = ({ className }: { className?: string }) => (
  <div className="flex items-center justify-center w-6 shrink-0">
    <div className={cx("rounded-full w-2 aspect-square", className)} />
  </div>
);

const Section = ({ index, id, links, active, steps }: SectionProps) => {
  const navigate = useNavigate();
  const t = useText();

  const { completed, linkStatus } = useMemo(
    () => completionStatus(links, steps),
    [links, steps]
  );

  const currentLink = useMemo(
    () => calcCurrentLink(links, steps),
    [links, steps]
  );

  console.assert(currentLink || !links.length);

  return (
    <Accordion.Item value={id} asChild>
      <li>
        <Accordion.Header asChild>
          <h2 className="group flex items-center gap-4">
            {completed ? (
              <CheckmarkBadge active={active} />
            ) : (
              <NumberBadge value={index} active={active} />
            )}
            <Accordion.Trigger
              onClick={() => currentLink && navigate(currentLink)}
              className={cx(
                "text-text-bright hover:text-text-link font-medium text-left py-1 cursor-pointer",
                "outline-none focus:text-text-link",
                completed && !active && "line-through"
              )}
            >
              {t(`nav_sections.${id}`)}
            </Accordion.Trigger>
          </h2>
        </Accordion.Header>
        <Accordion.Content>
          <ul className="flex flex-col py-1">
            {links.map((link) => (
              <li key={link} className="group flex">
                <NavLink
                  to={link}
                  className={({ isActive }) =>
                    cx(
                      "text-left hover:text-text-link select-none outline-none",
                      isActive
                        ? "text-text-link font-medium"
                        : "text-text-default",
                      linkStatus[link] && "line-through"
                    )
                  }
                  tabIndex={0}
                  end
                >
                  {({ isActive }) => (
                    <div className="flex items-center py-1">
                      <Dot
                        className={cx(
                          "group-focus-ring-rounded-full",
                          isActive
                            ? "bg-brand-purple"
                            : "bg-text-muted bg-opacity-60"
                        )}
                      />
                      <div className="pl-7 group-focus-within:text-text-link leading-tight">
                        {t(`${linkSlug(link)}.nav`)}
                      </div>
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </Accordion.Content>
      </li>
    </Accordion.Item>
  );
};

interface StepsSidebarProps {
  sections: string[];
  paths: string[];
  stepIndex: number;
  slug: string;
}

const StepsSidebar = ({ sections, paths, stepIndex }: StepsSidebarProps) => {
  const { TranslationScope } = useTranslationScope("onboarding");
  const { steps } = useOnboardingStatusAPI() ?? {};
  const currentSection = find(sections, (s) =>
    paths[stepIndex]?.startsWith(s)
  ) as string | undefined;

  return (
    <TranslationScope>
      <div className="flex flex-col justify-between gap-1 bg-bg-l3">
        <Accordion.Root
          type="single"
          value={currentSection ?? sections[0]}
          collapsible
          asChild
        >
          <nav className="p-7">
            <ul>
              {sections.map((s, i) => (
                <Section
                  key={s}
                  index={i + 1}
                  id={s}
                  active={s === currentSection}
                  links={paths.filter((p) => p.startsWith(s))}
                  steps={steps}
                />
              ))}
            </ul>
          </nav>
        </Accordion.Root>
        <HelpFooter
          className="px-6 pb-6"
          i18nKey="step.help"
          contactEmail="support@maca.io"
        />
      </div>
    </TranslationScope>
  );
};

export default StepsSidebar;
