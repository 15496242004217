import { last, every, fromPairs, values } from "lodash";

import { slug } from "@/lib/string";

import { OnboardingSteps } from "@/pages/onboarding/api";

export const linkSlug = (link: string) => slug(last(link.split("/")) ?? "");

export const calcCurrentLink = (links: string[], steps?: OnboardingSteps) => {
  const firstIncomplete = steps
    ? links.findIndex((link) => !steps[linkSlug(link) as keyof OnboardingSteps])
    : null;

  return links[firstIncomplete ?? 0] ?? last(links);
};

export const completionStatus = (links: string[], steps?: OnboardingSteps) => {
  if (!steps) return { completed: false, linkStatus: {} };

  const linkStatus = fromPairs(
    links.map((link) => [link, steps[linkSlug(link) as keyof OnboardingSteps]])
  );

  return {
    completed: every(values(linkStatus)),
    linkStatus,
  };
};
