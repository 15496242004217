import React from "react";

// @ts-ignore
import { ReactComponent as SeedlingIcon } from "@/assets/icons/seedling.svg";

const ComingSoon = () => (
  <div className="flex flex-col grow items-center justify-center">
    <div className="flex flex-col items-center gap-4 text-text-muted select-none mt-5 mb-10">
      <SeedlingIcon className="w-20 aspect-square" />
      <div className="text-2xl font-semibold">Coming soon</div>
    </div>
  </div>
);

export default ComingSoon;
