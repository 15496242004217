import { omit } from "lodash";

import { isNotNil } from "@/lib/functional";

import { MetricDatum } from "./types";

export const toHeatmapData = (data: MetricDatum[], indexBy: string) =>
  data
    .filter((row) => isNotNil(row[indexBy]))
    .map((row) => ({
      id: `${row[indexBy]}`,
      data: Object.keys(omit(row, [indexBy]))
        .map((key) => ({ x: key, y: Number(row[key]) }))
        .filter(({ y }) => !isNaN(y)),
    }));
