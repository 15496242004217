import React, { useState, useEffect } from "react";
import { Grid, Box, Button, Typography, Alert, TextField } from "@mui/material";
import Cookies from "js-cookie";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../../themes/theme";

export default function ResetPasswordToken() {
  const [email, setEmail] = useState("");
  const [alertBox, setAlertBox] = useState(<></>);

  useEffect(() => {
    if (Cookies.get("csrftoken") === undefined) {
      fetch(process.env["REACT_APP_API_HOST"] + "/user/session/", {
        credentials: "include",
      });
    }
  }, []);

  const sendEmail = async () => {
    try {
      const response = await fetch(
        process.env["REACT_APP_API_HOST"] + "/user/generate-token/",
        {
          method: "POST",
          body: JSON.stringify({ email }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        setAlertBox(
          <Alert severity="success" sx={{ backgroundColor: "transparent" }}>
            Check your inbox for a password reset link.
          </Alert>
        );
      } else {
        console.log("unexpected return code: " + response.status);
        setAlertBox(
          <Alert severity="error" sx={{ backgroundColor: "transparent" }}>
            Unable to send reset link to provided email address.
          </Alert>
        );
      }
    } catch (err) {
      console.log(err);
      setAlertBox(
        <Alert severity="error">
          Unable to send reset link to provided email address.
        </Alert>
      );
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        m="auto"
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Typography variant="h4" style={{ paddingBottom: "20px" }}>
          Reset password
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }} color="text.secondary">
          If you forgot your password, you can request a link to reset your
          password here.
        </Typography>
        <Grid
          alignItems="center"
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          <TextField
            id="email-form"
            label="Email"
            type="email"
            fullWidth
            data-testid="email-form"
            variant="outlined"
            value={email}
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement;
              setEmail(target.value);
            }}
          />
          <Button
            data-testid="send-button"
            variant="contained"
            color="primary"
            fullWidth
            onClick={sendEmail}
          >
            Send reset link
          </Button>
          <br />
          {alertBox}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
