import { useState, RefObject } from "react";
import useResizeObserver from "@react-hook/resize-observer";

export interface Size {
  width: number;
  height: number;
}

const asSize = (rect?: DOMRectReadOnly): Size | null =>
  rect
    ? {
        width: rect.width,
        height: rect.height,
      }
    : null;

const useSize = <T extends HTMLElement>(target: RefObject<T>) => {
  const [size, setSize] = useState<Size | null>();
  useResizeObserver(target, (entry) => setSize(asSize(entry.contentRect)));
  return size;
};

export default useSize;
