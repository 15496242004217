import React, {
  createContext,
  useContext,
  ReactNode,
  PropsWithChildren,
} from "react";

import { useAPI } from "@/client/api";

import {
  type MetricValueType,
  type MetricSentiment,
} from "@/components/insights/types";

interface ChartDataContextType {
  readonly baseUrl: string;
}

const ChartDataContext = createContext<ChartDataContextType>({
  baseUrl: "",
});

export const ChartDataProvider = ({
  routeKey,
  children,
}: PropsWithChildren<{ routeKey: string }>) => (
  <ChartDataContext.Provider value={{ baseUrl: `insight/chart/${routeKey}` }}>
    {children}
  </ChartDataContext.Provider>
);

export const useChartDataContext = () => useContext(ChartDataContext);

export type UseChartDataProps = {
  dataKey: string;
  inView: boolean;
};

export const useChartData = ({ dataKey, inView }: UseChartDataProps) => {
  const { baseUrl } = useChartDataContext();
  // don't fetch/revalidate if the chart is not visible
  const { data } = useAPI(baseUrl && inView ? `${baseUrl}/${dataKey}` : null, {
    keepPreviousData: true,
  });

  return data;
};

export interface KpiDatum {
  value: number;
  time_period: string;
  metric_type?: MetricValueType;
  sentiment?: MetricSentiment;
}

type KpiData = Record<string, KpiDatum | [KpiDatum, KpiDatum]>;

export const useKpiData = () => {
  const { baseUrl } = useChartDataContext();
  const { data } = useAPI<KpiData>(baseUrl ? `${baseUrl}/__kpis__` : null);
  return data;
};

export interface HighlightDatum {
  targetId: string;
  icon: string;
  text: string;
}

type HighlightsData = HighlightDatum[];

export const useHighlightsData = () => {
  const { baseUrl } = useChartDataContext();
  const { data } = useAPI<HighlightsData>(
    baseUrl ? `${baseUrl}/__highlights__` : null
  );
  return data;
};

export interface ChartDataFetcherProps extends UseChartDataProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (data: any) => ReactNode;
}

export const ChartDataFetcher = ({
  dataKey,
  inView,
  children,
}: ChartDataFetcherProps) => {
  const data = useChartData({ dataKey, inView });
  return <>{children(data || [])}</>;
};
