import React, {
  PropsWithChildren,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  useCallback,
  useState,
} from "react";

import cx from "clsx";

import Spinner from "@/components/Spinner";

type HTMLButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export interface CoreButtonProps extends HTMLButtonProps {
  className?: string;
}

export const CoreButton = ({
  className,
  children,
  ...props
}: PropsWithChildren<CoreButtonProps>) => (
  <button {...props} className={cx("button-base py-2 px-4", className)}>
    {children}
  </button>
);

export const CancelButton = ({
  children,
  className,
  ...props
}: HTMLButtonProps) => (
  <CoreButton
    {...props}
    type="button"
    className={cx(
      "text-text-default hover:text-text-link focus:text-text-link active:text-brand-purple-l2",
      "focus:bg-bg-l3 hover:bg-bg-l3",
      className
    )}
  >
    {children ?? "Cancel"}
  </CoreButton>
);

export const PrimaryButton = ({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLButtonProps> & { className?: string }) => (
  <CoreButton {...props} className={cx("button-primary", className)}>
    {children}
  </CoreButton>
);

export const ActionButton = ({
  children,
  className,
  disabled,
  onClick,
  ...props
}: PropsWithChildren<HTMLButtonProps> & {
  className?: string;
  onClick: () => Promise<void>;
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const handleOnClick = useCallback(async () => {
    setShowSpinner(true);
    try {
      await onClick();
    } finally {
      setShowSpinner(false);
    }
  }, [onClick]);

  return (
    <PrimaryButton
      {...props}
      {...{
        className,
        disabled: disabled || showSpinner,
        onClick: handleOnClick,
      }}
    >
      <div className="flex items-center gap-2">
        {showSpinner && (
          <div className="w-6 aspect-square -ml-1">
            <Spinner />
          </div>
        )}
        <span>{children}</span>
      </div>
    </PrimaryButton>
  );
};
