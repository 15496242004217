import React from "react";

import ReportsIndex from "./reports/ReportsIndex";
import QueriesIndex from "./queries/QueriesIndex";

const AnalysisHome = () => (
  <div className="flex flex-col w-full max-w-3xl my-8 mx-auto px-8 gap-8">
    <ReportsIndex />
    <QueriesIndex />
  </div>
);

export default AnalysisHome;
