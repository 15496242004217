import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useEffect,
  useCallback,
} from "react";

import { useLocation, Navigate, Outlet } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

import apiClient from "@/client";
import { useAPI, useAPIConfig } from "@/client/api";

import { identify } from "@/lib/analytics";

import type { UserSession, UserCredentials } from "@/client/user";

interface AuthContextType {
  readonly session: UserSession;
  readonly isSignedIn: boolean;
  signIn: (credentials: UserCredentials) => Promise<UserSession | null>;
  signOut: () => Promise<void>;
  refreshSession: () => Promise<void>;
}

const anonymousSession: UserSession = { authenticated: false };

export const AuthContext = createContext<AuthContextType>({
  session: anonymousSession,
  isSignedIn: false,
  signIn: async () => {
    console.error("Missing <AuthProvider>");
    return null;
  },
  signOut: async () => console.error("Missing <AuthProvider>"),
  refreshSession: async () => console.error("Missing <AuthProvider>"),
});

const userSessionURL = "user/session/";

const useAuthContextValue = (): AuthContextType => {
  const { resetCache, mutate: mutateKey } = useAPIConfig();
  const { data: serverSession } = useAPI<UserSession>(userSessionURL);

  const [storedSession, setStoredSession, { removeItem: removeStoredSession }] =
    useLocalStorageState<UserSession | null>("userSession", {
      defaultValue: null,
    });

  useEffect(() => {
    if (serverSession) {
      setStoredSession(serverSession);
    }
  }, [serverSession, setStoredSession, resetCache]);

  useEffect(() => {
    resetCache({ excludeKeys: [userSessionURL] });
  }, [storedSession, resetCache]);

  const signIn = useCallback(
    async (credentials: UserCredentials) => {
      const session = await apiClient.signIn(credentials);
      setStoredSession(session);
      return session;
    },
    [setStoredSession]
  );

  const signOut = useCallback(async () => {
    await apiClient.signOut();
    removeStoredSession();
  }, [removeStoredSession]);

  const refreshSession = useCallback(async () => {
    await mutateKey(userSessionURL, null, { revalidate: true });
  }, [mutateKey]);

  const session = storedSession || anonymousSession;
  return {
    session,
    isSignedIn: session.authenticated,
    signIn,
    signOut,
    refreshSession,
  };
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const value = useAuthContextValue();
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const RequireAuth = ({ children }: PropsWithChildren) => {
  const { session, isSignedIn } = useAuth();
  const location = useLocation();

  useEffect(() => session.user && identify(session.user), [session]);

  if (!isSignedIn) {
    // Redirect to the login page, but save the current location so we can
    // redirect back once authenticated
    return <Navigate to="/user/login" state={{ from: location }} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
