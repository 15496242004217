import { baseClient } from "../base";

export interface User {
  readonly id: string;
  readonly email: string;
}

export interface Organization {
  readonly id: string;
  readonly name: string;
  readonly product_offering: string;
}

export interface UserSession {
  readonly authenticated: boolean;
  readonly user?: User;
  readonly organization?: Organization;
}

export interface UserCredentials {
  email: string;
  password: string;
}

async function userSession(): Promise<UserSession | null> {
  const response = await baseClient("/user/session/");
  return response?.ok ? await response.json() : null;
}

async function signIn({
  email,
  password,
}: UserCredentials): Promise<UserSession | null> {
  await userSession(); // make sure we have csrftoken cookie

  const response = await baseClient("/user/session/", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  });

  return response?.ok ? await userSession() : null;
}

async function signOut(): Promise<void> {
  const response = await baseClient("/user/session/", { method: "DELETE" });
  if (!response?.ok) {
    console.error("Error signing out current user:", await response.text());
  }
}

export { userSession, signIn, signOut };
