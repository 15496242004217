import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  memo,
} from "react";

import { range, minBy } from "lodash";

import cx from "clsx";

import tw from "@/styles/tailwind";
import { toToCTree, type ToCEntry, type ToCTreeNode } from "./tocTree";

export { type ToCEntry } from "./tocTree";

const toTocEntries = (headings: HTMLElement[]) => {
  const entries: ToCEntry[] = headings.map(({ id, innerText, nodeName }) => ({
    id,
    text: innerText,
    level: parseInt(nodeName.slice(1)),
  }));

  const minLevel = minBy(entries, "level")?.level ?? 0;
  return entries.map(({ level, ...entry }) => ({
    ...entry,
    level: level - minLevel,
  }));
};

const queryHeadings = (node: HTMLDivElement, depth: number) =>
  Array.from(
    node.querySelectorAll(
      range(1, depth + 1)
        .map((n) => `h${n}`)
        .join(", ")
    )
  ) as HTMLElement[];

export const useToC = ({ depth }: { depth: number }) => {
  const [contentNode, setContentNode] = useState<HTMLDivElement>();
  const [tocEntries, setTocEntries] = useState<ToCEntry[]>([]);

  useEffect(() => {
    if (contentNode) {
      setTocEntries(toTocEntries(queryHeadings(contentNode, depth)));
    }
  }, [contentNode, depth]);

  return {
    contentRef: (ref: HTMLDivElement) => setContentNode(ref),
    tocEntries,
  };
};

const useHeadingsObserver = (tocEntries: ToCEntry[]) => {
  const [activeEntryId, setActiveEntryId] = useState<string>();
  const headingItems = useRef<Record<string, IntersectionObserverEntry>>({});
  const tocEntryIndexes = useMemo<Record<string, number>>(
    () => tocEntries.reduce((acc, { id }, i) => ({ ...acc, [id]: i }), {}),
    [tocEntries]
  );

  const handleIntersectionEvent = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        headingItems.current[entry.target.id] = entry;
      });

      const visibleHeadings = Object.entries(headingItems.current)
        .filter(([_, { isIntersecting }]) => Boolean(isIntersecting))
        .map(([id]) => id)
        .sort((a, b) => (tocEntryIndexes[a] ?? 0) - (tocEntryIndexes[b] ?? 0));

      if (visibleHeadings.length) {
        const entryId = visibleHeadings[0] ?? "";
        setActiveEntryId(entryId);
      } else {
        if (activeEntryId) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const entry = headingItems.current[activeEntryId]!;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const rootBounds = entry.rootBounds!;
          if (entry.boundingClientRect.top > rootBounds.y + rootBounds.height) {
            setActiveEntryId(
              tocEntries[(tocEntryIndexes[activeEntryId] ?? 0) - 1]?.id ?? ""
            );
          }
        }
      }
    },
    [tocEntryIndexes, activeEntryId, tocEntries]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersectionEvent, {
      rootMargin: `-${
        tw.theme.variables.DEFAULT.navbar["height-px"] + 4
      }px 0% 0% 0%`,
    });

    tocEntries.forEach(({ id }) => {
      const element = document.getElementById(id);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, [tocEntries, handleIntersectionEvent]);

  return { activeEntryId };
};

const ToCTree = memo(
  ({
    tocTree,
    activeEntryId,
  }: {
    tocTree: ToCTreeNode[];
    activeEntryId?: string;
  }) => (
    <ul className="leading-[1.6rem] text-sm">
      {tocTree.map(({ id, text, level, children }) => (
        <li
          key={id}
          className={cx(level > 0 && "ml-4", "state-transition-colors")}
        >
          <div className="grid self-start">
            <a
              href={`#${id}`}
              className={cx(
                id === activeEntryId
                  ? "font-medium text-text-link"
                  : "text-text-default",
                "col-start-1 row-start-1 leading-tight hover:text-text-link"
              )}
            >
              {text}
            </a>
            <div
              className="invisible font-medium col-start-1 row-start-1"
              aria-hidden="true"
            >
              {text}
            </div>
          </div>
          {children.length ? (
            <ToCTree {...{ tocTree: children, activeEntryId }} />
          ) : null}
        </li>
      ))}
    </ul>
  )
);

export const ToC = ({ tocEntries }: { tocEntries: ToCEntry[] }) => {
  const { activeEntryId } = useHeadingsObserver(tocEntries);
  const tocTree = useMemo(() => toToCTree(tocEntries), [tocEntries]);
  return <ToCTree {...{ tocTree, activeEntryId }} />;
};
