import React, { ForwardedRef, forwardRef } from "react";
import { FieldValues, FieldPath } from "react-hook-form";
import cx from "clsx";

import InputField, { InputFieldProps } from "./InputField";

const Input = forwardRef(
  <Values extends FieldValues, Name extends FieldPath<Values>>(
    {
      inputClassName,
      ...props
    }: InputFieldProps<Values, Name> & { inputClassName?: string },
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <InputField ref={ref} {...props}>
      {({ error, control: _, ...props }) => (
        <input
          className={cx(
            "input-border input-text bg-transparent px-3 py-2",
            error && "!border-border-error",
            inputClassName
          )}
          {...props}
        />
      )}
    </InputField>
  )
);

export default Input;
