import { useMemo } from "react";
import { keyBy } from "lodash";

import { isNotNil } from "@/lib/functional";

import { useProductPlansListAPI } from "@/pages/plans/models/api";
import type {
  ProductPlanComponentJSON,
  ProductPlanSetJSON,
} from "@/pages/plans/models/serialization";

import { IPriceRule } from "./price-rules";
import { WithId } from "@/client/types";

export interface PriceRuleRefs {
  plansById: Record<string, ProductPlanSetJSON>;
  planComponentsByProductComponentId: Record<string, ProductPlanComponentJSON>;
}

const hydrateRef = <T extends WithId>(
  entity: T | undefined,
  ref: Record<string, T>,
  fallbackName: string
): T | undefined =>
  ref[entity?.id ?? ""] ??
  (isNotNil(entity?.id)
    ? ({ ...entity, name: fallbackName } as unknown as T)
    : entity);

export const hydrateRule = (
  { plan, planComponent, ...props }: IPriceRule,
  { plansById, planComponentsByProductComponentId }: PriceRuleRefs
): IPriceRule => ({
  ...props,
  plan: hydrateRef(plan, plansById, "[Unknown plan]"),
  planComponent: hydrateRef(
    planComponent,
    planComponentsByProductComponentId,
    "[Unknown component]"
  ),
});

export const usePriceRuleRefs = (): PriceRuleRefs => {
  const plans = useProductPlansListAPI();
  const planComponents = useMemo(
    () => plans?.map(({ components }) => [...components]).flat(),
    [plans]
  );
  const plansById = useMemo(() => keyBy(plans, "id"), [plans]);
  const planComponentsByProductComponentId = useMemo(
    () => keyBy(planComponents || [], "product_component_id"),
    [planComponents]
  );

  return { plansById, planComponentsByProductComponentId };
};
