import React, { useState, PropsWithChildren, HTMLAttributes } from "react";
import { Avatar, Menu, MenuItem, IconButton } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as Logo } from "@/assets/maca-logo.svg";

import { useAuth } from "@/components/Auth";

import { type User, Organization } from "@/client/user";

const LoggedInUser = ({
  user,
  organization,
  signOut,
}: {
  user: User;
  organization?: Organization;
  signOut: () => Promise<void>;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(undefined);
  const handleSignOut = () => {
    handleClose();
    signOut();
  };

  return (
    <>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        sx={{ ml: 2 }}
      >
        <Avatar sx={{ width: 28, height: 28 }}>
          {user.email.slice(0, 1).toUpperCase()}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem disabled={true}>
          <span className="flex flex-col">
            <span className="font-semibold">
              {organization?.name ?? "No organization found"}
            </span>
            <span>{user?.email}</span>
          </span>
        </MenuItem>
        <MenuItem component={Link} to="/organization/select">
          Manage organizations
        </MenuItem>
        <MenuItem component={Link} to="/settings">
          Settings
        </MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

const navBarDefaultLinkClassName = ({ isActive }: { isActive: boolean }) =>
  cx(
    isActive ? "text-text-bright bg-bg-l3" : "text-text-default",
    "py-2 px-3 rounded-md w-full font-semibold whitespace-nowrap hover:text-text-link"
  );

const NavBar = ({
  links,
  keyPrefix = "navigation.links",
  className = "flex items-center gap-4",
  linkClassName = navBarDefaultLinkClassName,
  ...props
}: {
  links: string[];
  keyPrefix?: string;
  className?: string;
  linkClassName?: (params: { isActive: boolean }) => string;
} & HTMLAttributes<Element>) => {
  const { t } = useTranslation("common", { keyPrefix });
  return (
    <ul className={className} {...props}>
      {links.map((link) => (
        <li key={link} className="flex select-none">
          <NavLink to={link} className={linkClassName}>
            {t(link)}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export const PageTabs = ({
  pageName,
  links,
  ...props
}: {
  pageName: string;
  links: string[];
} & HTMLAttributes<Element>) => (
  <NavBar
    links={links}
    keyPrefix={`navigation.links.${pageName}`}
    className="flex gap-8"
    linkClassName={({ isActive }) =>
      cx(
        isActive ? "text-text-bright border-b-[2px]" : "text-text-default",
        "pb-2 text-lg font-semibold whitespace-nowrap hover:text-text-link"
      )
    }
    {...props}
  />
);

const topLevelLinks = ["/products", "/insights", "/price-rules", "/bypasses"];

const insightsOnlyTopLevelLinks = [
  "/insights",
  "/analysis",
  "/experiments",
  "/data-quality",
];

const orgNavLinks = ({ product_offering }: Organization) =>
  product_offering === "insights" ? insightsOnlyTopLevelLinks : topLevelLinks;

const Navigation = ({ children }: PropsWithChildren) => {
  const {
    session: { user, organization },
    signOut,
  } = useAuth();

  return (
    <nav
      className={cx(
        "flex sticky top-0 z-10 justify-between items-center h-navbar-height",
        "px-4 pl-6 bg-bg-l2 border-b border-border-default"
      )}
      data-testid="appbar"
    >
      <div className="flex items-center gap-8">
        <Link to="/">
          <Logo className="h-8 fill-base-white" data-testid="logo" />
        </Link>
        {organization && <NavBar links={orgNavLinks(organization)} />}
      </div>
      <div className="flex items-center justify-end gap-4">
        {children}
        {user && <LoggedInUser {...{ user, organization, signOut }} />}
      </div>
    </nav>
  );
};

export { NavBar, Navigation };
