import React from "react";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as ChatIcon } from "@/assets/icons/chat.svg";
import mailToUrl from "@/lib/mailto";

import { Text } from "@/components/hooks/useText";

const HelpFooter = ({
  i18nKey,
  contactEmail,
  className,
}: {
  i18nKey: string | string[];
  contactEmail: string;
  className?: string;
}) => (
  <div className={cx("flex items-center gap-3.5", className)}>
    <ChatIcon className="w-6 aspect-square shrink-0 text-text-muted/60" />
    <div className="text-sm text-text-muted leading-tight">
      <Text
        i18nKey={i18nKey}
        values={{ contactEmail }}
        components={[
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            href={mailToUrl({ to: contactEmail, subject: "Onboarding help" })}
            className="underline hover:text-text-link outline-none focus:text-text-link"
            onClick={(e) => e.stopPropagation()} // prevent accidental opening of the upload dialog
          />,
        ]}
      />
    </div>
  </div>
);

export default HelpFooter;
