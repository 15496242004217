import React, { type PropsWithChildren } from "react";
import cx from "clsx";

import { isNotNil } from "@/lib/functional";
import { useAPI } from "@/client/api";

//@ts-ignore
import { ReactComponent as DataflowIcon } from "@/assets/icons/dataflow.svg";

import { Text, TranslationScope } from "@/components/hooks/useText";

import { useChartDataContext } from "@/components/insights/ChartData";
import DataflowDiagram, {
  DataflowProvider,
  useSelectedNodes,
} from "@/components/dataflow/DataflowDiagram";

import { Modal, CloseButton, type ModalProps } from "@/components/Modal";

import { type DataflowIndex } from "@/pages/data-quality/api";

import { FieldDetailsPanel } from "./FieldDetailsPanel";

type ChartDataflowProps = {
  chartId: string;
  onClose: () => void;
  dataflowIndex?: DataflowIndex;
};

const Header = ({ chartId }: Pick<ChartDataflowProps, "chartId">) => (
  <div className="relative flex justify-between items-center px-5 pt-4 pb-3">
    <div className="flex items-center gap-2 select-none">
      <DataflowIcon className="h-5 aspect-square text-text-muted" />
      <div className="font-semibold text-text-default leading-tight">
        <Text
          i18nKey="chart_dataflow.title"
          components={[<Text i18nKey={`insights:charts.${chartId}.title`} />]}
        />
      </div>
    </div>
  </div>
);

export const useChartDataflowData = ({
  chartId,
}: Pick<ChartDataflowProps, "chartId">) => {
  const { baseUrl } = useChartDataContext();
  const { data } = useAPI<DataflowIndex>(
    baseUrl ? `${baseUrl}/${chartId}/dataflow/` : null,
    {
      keepPreviousData: true,
    }
  );

  return data;
};

const DataflowPane = ({
  dataflowIndex,
}: Pick<ChartDataflowProps, "dataflowIndex">) => (
  <div className="grow overflow-auto">
    <div className="m-2">
      {dataflowIndex && <DataflowDiagram {...dataflowIndex} />}
    </div>
  </div>
);

const ChartDataflowPane = ({
  chartId,
}: Pick<ChartDataflowProps, "chartId">) => {
  const dataflowIndex = useChartDataflowData({ chartId });
  return <DataflowPane {...{ dataflowIndex }} />;
};

const Sidebar = () => {
  const nodes = useSelectedNodes();
  return (
    <div
      className={cx(
        "absolute px-5 py-4 top-0 bottom-0  bg-bg-default border-l border-bg-l3",
        "w-[480px] transition-all ease-in-out duration-200",
        nodes.length > 0
          ? "right-0 opacity-1 shadow-l-lg"
          : "-right-[480px] opacity-0"
      )}
    >
      {nodes.length && isNotNil(nodes[0]) ? (
        <FieldDetailsPanel fieldId={nodes[0]} />
      ) : null}
    </div>
  );
};

const ChartDataflowContainer = ({ children }: PropsWithChildren) => {
  const nodes = useSelectedNodes();
  return (
    <div
      className={cx(
        "relative w-[calc(100vw-32px)] h-[calc(95vh-16px)] flex flex-col",
        "border border-bg-l3 rounded-[11px] bg-bg-default",
        "transition-[padding-right] ease-in-out duration-200",
        nodes.length > 0 ? "pr-[480px]" : "pr-0"
      )}
    >
      {children}
    </div>
  );
};

export const ChartDataflow = ({
  chartId,
  onClose,
  dataflowIndex,
}: ChartDataflowProps) => {
  return (
    <TranslationScope ns="data-quality">
      <ChartDataflowContainer>
        <Header {...{ chartId, onClose }} />
        {dataflowIndex ? (
          <DataflowPane {...{ dataflowIndex }} />
        ) : (
          <ChartDataflowPane {...{ chartId }} />
        )}
        <Sidebar />
        <CloseButton
          onClick={onClose}
          size="md"
          className="absolute top-2 right-2"
        />
      </ChartDataflowContainer>
    </TranslationScope>
  );
};

export const ChartDataflowModal = ({
  chartId,
  open,
  onClose,
}: ModalProps & ChartDataflowProps) => (
  <Modal {...{ open, onClose }}>
    <DataflowProvider>
      <ChartDataflow {...{ chartId, onClose }} />
    </DataflowProvider>
  </Modal>
);
