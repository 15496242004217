import React, {
  type PropsWithChildren,
  useState,
  useCallback,
  useMemo,
  type ReactNode,
} from "react";

import { sortBy } from "lodash";
import cx from "clsx";

import { interpolate } from "@/lib/string";

import { Text, TranslationScope } from "@/components/hooks/useText";

import { Modal, CloseButton, type ModalProps } from "@/components/Modal";

// @ts-ignore
import { ReactComponent as TableIcon } from "@/components/dataflow/icons/table.svg";

import Table, { type TableProps } from "@/components/insights/Table";
import { valueFormatter } from "@/components/insights/format";
import { type ValueType } from "@/components/insights/types";

import { type DataflowIndex } from "@/pages/data-quality/api";

import { splitFieldId } from "@/components/dataflow/common";

import { FieldStatsNote } from "./common";

import {
  useTableMetadata,
  useTableErrorsData,
  type FieldMetadata,
  type FieldDataQuality,
  type TableErrorsData,
} from "./api";

type TableViewProps = {
  fieldId: string;
  onClose: () => void;
  dataflowIndex?: DataflowIndex;
};

const Header = ({
  tableName,
  fieldName,
  stats,
}: {
  tableName: string;
  fieldName: string;
  stats?: Record<string, number>;
}) => (
  <div className="relative flex items-center px-5 pt-4 pb-3 gap-4">
    <div className="flex items-center gap-2 select-none">
      <TableIcon className="h-5 aspect-square text-text-muted" />
      <div className="font-semibold text-text-default leading-tight">
        <Text i18nKey="title" values={{ tableName, fieldName }} />
      </div>
    </div>
    {stats && (
      <div className="text-sm text-base-orange-200">
        <FieldStatsNote
          keyPrefix="data-quality:chart_accuracy.note"
          {...{ stats }}
        />
      </div>
    )}
  </div>
);

const translateType = (pandasType?: FieldMetadata["type"]): ValueType => {
  switch (pandasType) {
    case "Int64":
    case "int64":
      return "count";

    case "float64":
      return "currency";

    case "datetime64[ns]":
      return "string";

    case "bool":
    case "boolean":
      return "string";

    case "category":
    case "object":
    case "period[M]":
      return "string";

    default:
      return "string";
  }
};

const columnOrder: Record<string, string[]> = {
  "Opportunity Id": [
    "Opportunity Id",
    "Opportunity Name",
    "Create Date",
    "CloseDate",
    "Stage Name",
  ],
};

const sortIndex = (sortOrder: string[], item: string) => {
  const i = sortOrder.indexOf(item);
  return i === -1 ? Infinity : i;
};

const orderedColumns = (columns: string[], fieldName: string) => {
  const order = columnOrder[columns[0] ?? ""];
  if (!order) return columns;

  const sortOrder = [...order, fieldName];
  return sortBy(columns, (col) => sortIndex(sortOrder, col));
};

const columnDefs = (
  columns: string[],
  columnDefs: Record<string, FieldDataQuality>,
  fieldName: string
) =>
  Object.fromEntries(
    orderedColumns(columns, fieldName).map((column) => [
      column,
      { type: translateType(columnDefs[column]?.type) },
    ])
  );

const useTableViewData = ({ fieldId }: { fieldId: string }) => {
  const { tableName, fieldName } = splitFieldId(fieldId);
  const tableMetadata = useTableMetadata({ tableName });
  const errorsData = useTableErrorsData({ tableName, fieldName });
  const fieldStats = tableMetadata?.data_quality[fieldName];

  return useMemo(
    () =>
      tableMetadata && errorsData
        ? {
            tableName: tableMetadata.name,
            fieldName,
            stats: fieldStats
              ? {
                  missing_values_count: fieldStats.nas,
                  unexpected_values_count: fieldStats.unexpected,
                }
              : undefined,
            presentation: {
              columns: columnDefs(
                errorsData.columns,
                tableMetadata.data_quality,
                fieldName
              ),
              highlightedColumns: [fieldName],
              stickyHeader: "bg-bg-default/[0.9]",
            },
            data: errorsData.data,
            urls: errorsData.urls,
          }
        : undefined,
    [fieldName, tableMetadata, errorsData, fieldStats]
  );
};

const makeformatter =
  (urls: TableErrorsData["urls"]): TableProps["formatter"] =>
  ({ columnType, columnName }) =>
    columnName === "Opportunity Id"
      ? (id: string) => (
          <a
            href={interpolate(urls?.["opportunity:"] ?? "#{{id}}", { id })}
            className="text-text-link font-medium"
            target="_blank"
            rel="noreferrer"
          >
            {id}
          </a>
        )
      : valueFormatter(columnType, false);

const TableViewPane = ({ fieldId }: { fieldId: string }) => {
  const data = useTableViewData({ fieldId });
  const formatter = useMemo(
    () => (data ? makeformatter(data.urls) : undefined),
    [data]
  );

  return data ? (
    <>
      <Header {...data} />
      <div className="overflow-auto">
        <Table
          {...{ id: `${fieldId}.errors`, formatter, ...data }}
          highlightBgClassName="bg-bg-l1"
          hoverBgClassName="hover:bg-bg-l250 group-hover/row:bg-bg-l250"
        />
      </div>
    </>
  ) : null;
};

const TableViewContainer = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={cx(
        "relative w-[calc(100vw-32px)] h-[calc(95vh-16px)] flex flex-col",
        "border border-bg-l3 rounded-[11px] bg-bg-default"
      )}
    >
      {children}
    </div>
  );
};

export const TableViewModal = ({
  fieldId,
  open,
  onClose,
}: ModalProps & TableViewProps) => (
  <Modal {...{ open, onClose }}>
    <TranslationScope ns="data-quality" keyPrefix="table_view">
      <TableViewContainer>
        <TableViewPane {...{ fieldId }} />
        <CloseButton
          onClick={onClose}
          size="md"
          className="absolute top-2 right-2"
        />
      </TableViewContainer>
    </TranslationScope>
  </Modal>
);

export const TableViewLink = ({
  fieldId,
  children,
}: {
  fieldId: string;
  children: (props: { onClick: () => void }) => ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const onClick = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      {children({ onClick })}
      <TableViewModal {...{ open, onClose, fieldId }} />
    </>
  );
};
