import React, {
  useState,
  useRef,
  useEffect,
  memo,
  type PropsWithChildren,
} from "react";

import cx from "clsx";

const lineClampMap: Record<string, string> = {
  "1": "line-clamp-1",
  "2": "line-clamp-2",
  "3": "line-clamp-3",
  "4": "line-clamp-4",
  "5": "line-clamp-5",
  "6": "line-clamp-6",
  "7": "line-clamp-7",
  "8": "line-clamp-8",
  "9": "line-clamp-9",
  "10": "line-clamp-10",
};

const AutoSizeText = memo(
  ({
    children,
    maxFontSize,
    minFontSize,
    lineHeight = 1.25,
    className,
  }: PropsWithChildren<{
    maxFontSize: number;
    minFontSize: number;
    className?: string;
    lineHeight?: number;
  }>) => {
    const ref = useRef<HTMLDivElement>(null);
    const step = useRef((maxFontSize - minFontSize) / 2);
    const [fontSize, setFontSize] = useState(maxFontSize);
    const [layoutComplete, setLayoutComplete] = useState(false);
    const [lineClamp, setLineClamp] = useState<number>();

    useEffect(() => {
      const element = ref.current;
      const parent = element?.parentElement;
      if (!element || !parent) return;

      const { height } = element.getBoundingClientRect();
      const { height: parentHeight } = parent.getBoundingClientRect();

      if (height > parentHeight && fontSize > minFontSize) {
        setFontSize((prev) => prev - step.current);
      } else if (
        height < parentHeight &&
        fontSize < maxFontSize &&
        step.current > 0.25
      ) {
        step.current /= 2;
        setFontSize((prev) => prev + step.current);
      } else {
        setLayoutComplete(true);
        if (height > parentHeight) {
          setLineClamp(Math.round(parentHeight / (fontSize * lineHeight)));
        }
      }

      console.log({ height, parentHeight, fontSize, step: step.current });
    }, [fontSize, minFontSize, maxFontSize, lineHeight]);

    return (
      <div
        className={cx(
          "relative",
          lineClamp &&
            "after:absolute after:inset-0 after:top-[50%] after:bg-gradient-to-t after:from-bg-l2",
          className
        )}
      >
        <div
          className={cx(
            "absolute left-0 top-0 w-full text-ellipsis",
            layoutComplete && lineClamp && lineClampMap[`${lineClamp}`],
            layoutComplete ? "h-full overflow-hidden" : "invisible"
          )}
          style={{ fontSize }}
          ref={ref}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default AutoSizeText;
