import React, { PropsWithChildren, useEffect } from "react";
import { groupBy, pick } from "lodash";

// @ts-ignore
import { ReactComponent as PrivacyShieldIcon } from "@/assets/icons/privacy-shield.svg";

import useText from "@/components/hooks/useText";

import Choice from "@/components/easy-form/Choice";
import useEasyForm, {
  RegisterNoopSubmitHander,
  useSubforms,
} from "@/components/easy-form";

import FileUpload from "./components/FileUpload";

import { useStepContext } from "./StepsLayout";
import {
  FileUploadsValues,
  PricingJSON,
  pricingSchema,
  uploadsSchema,
} from "./schema";

import {
  useOnboardingStepAPI,
  useUploadsListAPI,
} from "@/pages/onboarding/api";

const UploadSection = ({ children }: PropsWithChildren) => {
  const t = useText();
  return (
    <div className="flex flex-col gap-4">
      <div className="text-text-bright font-medium leading-tight">
        {t("upload_section.label")}
      </div>
      <div className="flex flex-col gap-3">{children}</div>
      <div className="flex items-center gap-2">
        <PrivacyShieldIcon className="w-8 aspect-square text-text-muted/60 px-1" />
        <div className="text-text-muted text-sm leading-tight">
          {t("upload_section.footer")}
        </div>
      </div>
    </div>
  );
};

const FileUploads = ({
  registerSubmit,
}: {
  registerSubmit: RegisterNoopSubmitHander;
}) => {
  const uploads = useUploadsListAPI();

  const { register, handleSubmit } = useEasyForm<FileUploadsValues>({
    values: pick(groupBy(uploads, "category"), [
      "pricing_matrix",
    ]) as FileUploadsValues,
    schema: uploadsSchema,
  });

  useEffect(() => registerSubmit(handleSubmit), [handleSubmit, registerSubmit]);

  return (
    <UploadSection>
      <FileUpload {...register("pricing_matrix")} />
    </UploadSection>
  );
};

const PricingStructure = () => {
  const { Panel } = useStepContext();
  const { values, createOrUpdate } =
    useOnboardingStepAPI<PricingJSON>("pricing-structure/");

  const { register, handleSubmit } = useEasyForm<PricingJSON>({
    values,
    schema: pricingSchema,
  });

  const { handleSubmitAll, registerNoopSubmitHandler } =
    useSubforms(handleSubmit);

  return (
    <Panel
      handleSubmit={(event, { next }) =>
        handleSubmitAll(async (values) => {
          await createOrUpdate(values);
          next();
        })(event)
      }
    >
      <div className="flex flex-col gap-9">
        <Choice
          {...register("pricing_structure")}
          options={["SET", "BESPOKE", "UNKNOWN"]}
        />
        <FileUploads registerSubmit={registerNoopSubmitHandler} />
      </div>
    </Panel>
  );
};

export default PricingStructure;
