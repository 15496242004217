import React, { useRef, forwardRef, RefObject, PropsWithChildren } from "react";

import {
  useFloating,
  autoUpdate,
  FloatingArrow,
  FloatingContext,
  Placement,
  offset,
  flip,
  shift,
  arrow,
} from "@floating-ui/react";

export interface StepPopoverProps extends PropsWithChildren {
  style: React.CSSProperties;
  arrowRef: RefObject<SVGSVGElement>;
  context: FloatingContext;
}

const tooltipArrowHeight = 12;
const tooltipArrowGap = 2;
const tooltipMargin = 16;

export const StepPopover = forwardRef<HTMLDivElement, StepPopoverProps>(
  ({ children, style, arrowRef, context }, ref) => (
    <div
      ref={ref}
      style={style}
      className={
        "z-[10010] flex flex-col gap-2 bg-bg-l4 text-text-bright rounded-lg max-w-[30rem] px-5 py-4 shadow-xl"
      }
    >
      <FloatingArrow
        ref={arrowRef}
        context={context}
        className="fill-bg-l4"
        height={tooltipArrowHeight}
        width={20}
      />
      {children}
    </div>
  )
);

export const useStepPopover = ({
  placement,
}: { placement?: Placement } = {}) => {
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(tooltipArrowHeight + tooltipArrowGap),
      flip({ padding: tooltipMargin }),
      shift({ padding: tooltipMargin }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  return {
    anchorRef: refs.setReference,
    popoverProps: {
      ref: refs.setFloating,
      style: floatingStyles,
      arrowRef,
      context,
    },
  };
};
