import React from "react";
import {
  RouteObject,
  Outlet,
  useOutletContext,
  useParams,
} from "react-router-dom";

import { PageTabs } from "@/components/Navigation";
import { isNotNil } from "@/lib/functional";

import ProductAvatar from "./components/ProductAvatar";
import { ProductJSON } from "./serialization";
import { useProductsAPI } from "./api";

const ProductHeader = ({ product }: { product: ProductJSON }) => (
  <div className="flex items-center gap-6">
    <ProductAvatar name={product.name} />
    <div className="flex flex-col gap-1 min-w-0 break-words">
      <h1 className="text-3xl font-semibold text-text-bright">
        {product.name}
      </h1>
      <div className="text-text-default">{product.description}</div>
    </div>
  </div>
);

export const ProductPageLayout = ({ routes }: { routes: RouteObject[] }) => {
  const productId = useParams()["productId"];
  const { product: data } = useProductsAPI({ productId });

  if (!data) return null;

  const { product } = data;
  return (
    <div className="flex flex-col w-full mb-8 mx-auto gap-8">
      <div className="flex flex-col bg-bg-l1 border-b border-border-default pt-8 px-8 gap-8">
        <ProductHeader product={product} />
        <div className="flex">
          <PageTabs
            id="product-tabs"
            pageName="product"
            links={routes.map((r) => r.path).filter(isNotNil)}
          />
        </div>
      </div>
      <div className="px-8">
        <Outlet context={{ product }} />
      </div>
    </div>
  );
};

export const useProductContext = () =>
  useOutletContext<{ product: ProductJSON }>();

export default ProductPageLayout;
