import { baseClient } from "../base";

export async function salesforceSetupGet() {
  const response = await baseClient("/oauth2/salesforce/", { method: "GET" });

  if (response.ok) {
    const { tenant_domain }: { tenant_domain: string } = await response.json();
    return { tenant_domain };
  }

  response
    .text()
    .then((body) => console.log("Unable to retrieve existing config: " + body));

  throw new Error("Unable to retrieve existing config.");
}

export async function salesforceSetupStart(
  salesforce_host: string,
  post_callback_host: string,
  post_callback_path = "/onboarding/salesforce-setup?success=true"
) {
  const post_callback = post_callback_host + post_callback_path;
  const response = await baseClient("/oauth2/provider/salesforce/", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ salesforce_host, post_callback }),
  });

  if (response.ok) {
    const { oauth2_url }: { oauth2_url: string } = await response.json();
    return { oauth2_url };
  }

  response
    .text()
    .then((body) =>
      console.log("Unable to retrieve OAuth2 initialization URL: " + body)
    );
  throw new Error("Unable to retrieve OAuth2 initialization URL.");
}
