import React, { memo } from "react";
import { Handle, Position, type NodeProps } from "@xyflow/react";

import cx from "clsx";

import { AccuracyMeter } from "./accuracy";

export const FIELD_SLOT_HEIGHT = 32;

export type FieldSlotProps = {
  id: string;
  name: string;
  accuracy?: number;
  inPort?: boolean;
  outPort?: boolean;
  highlighted?: boolean;
};

export const FieldName = ({
  name,
  inactive,
  highlighted,
  selected,
}: {
  name: string;
  inactive?: boolean;
  highlighted?: boolean;
  selected?: boolean;
}) => (
  <div
    className={cx(
      "px-1.5 py-1 border rounded-[4px]",
      "text-xs font-medium leading-none",
      inactive
        ? highlighted
          ? "text-text-default"
          : "text-text-muted"
        : selected
        ? "border-text-link bg-text-link text-bg-l2"
        : highlighted
        ? "border-text-bright bg-text-bright text-bg-l2"
        : "border-text-muted text-text-bright"
    )}
  >
    {name}
  </div>
);

const Port = ({
  type,
  highlighted,
}: {
  type: "in" | "out";
  highlighted?: boolean;
}) => (
  <Handle
    {...(type === "in"
      ? { type: "target", position: Position.Left }
      : { type: "source", position: Position.Right })}
    className={cx(
      "w-2.5 aspect-square rounded-full",
      highlighted ? "!bg-text-bright" : "!bg-text-muted"
    )}
  />
);

const FieldSlot = memo(
  ({
    data: { name, accuracy, inPort, outPort, highlighted },
    selected,
  }: NodeProps<FieldSlotProps>) => {
    const inactive = accuracy === undefined;
    return (
      <div
        className={cx(
          "group flex gap-1.5 items-center pl-4 py-[5px]",
          !inactive && "hover:bg-bg-l250 cursor-pointer",
          highlighted && "bg-bg-l250",
          inPort && "pl-8",
          outPort && "pr-4"
        )}
      >
        {inPort && <Port {...{ type: "in", highlighted }} />}
        <FieldName {...{ name, inactive, highlighted, selected }} />
        {!inactive && <AccuracyMeter value={accuracy} />}
        {outPort && !inactive && <Port {...{ type: "out", highlighted }} />}
      </div>
    );
  }
);

export default FieldSlot;
