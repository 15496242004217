import { baseClient } from "../base";

interface PricingMatrixFile {
  id: string;
  file: string;
  created_at: string;
}

export async function pricingMatrix(file: File | undefined) {
  if (!file) {
    throw new Error("File is required, but not found or invalid.");
    return;
  }

  const formData = new FormData();
  formData.append("file", file, file.name);

  const response = await baseClient("/onboarding/pricing-matrix/", {
    method: "POST",
    body: formData,
  });

  if (response.ok) {
    response
      .json()
      .then((data: PricingMatrixFile) =>
        console.log("Uploaded file: " + data.id)
      );
    return;
  }

  response
    .text()
    .then((body) =>
      console.log("Unable to upload file. API response body: " + body)
    );
  throw new Error("Server did not confirm receipt.");
}
