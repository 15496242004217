import React, {
  useState,
  useDeferredValue,
  Children,
  ReactElement,
  useCallback,
} from "react";

//@ts-ignore
import { ReactComponent as SparksIcon } from "../icons/sparks.svg";

import { Text, TranslationScope } from "@/components/hooks/useText";

import { Modal, type ModalProps } from "@/components/Modal";

import QueryInput from "./QueryInput";
import { useQuickSearch } from "./QuickSearch";
import SuggestedQuestions from "./SuggestedQuestions";
import { useAskMaca } from "./QueryAnswer";

const QueryResults = ({
  children,
}: {
  children: ReactElement | (ReactElement | null)[];
}) => (
  <>
    <div aria-live="polite" role="region" aria-atomic="true">
      <div className="sr-only"></div>
    </div>
    <ul className="flex flex-col px-5 py-3 gap-4">
      {Children.map(
        children,
        (c) =>
          c && (
            <li className="w-full">
              <TranslationScope keyPrefix={`${c.key}`}>{c}</TranslationScope>
            </li>
          )
      )}
    </ul>
  </>
);

const AskMacaButton = ({
  onClick,
  enabled,
}: {
  onClick: () => void;
  enabled: boolean;
}) => (
  <button
    className="flex button-base button-primary mr-3 py-1.5 px-3 gap-2.5"
    onClick={onClick}
    disabled={!enabled}
  >
    <Text i18nKey="query_input.ask_maca" />
    <SparksIcon className="w-5 aspect-square shrink-0" />
  </button>
);

const SearchPanel = () => {
  const [query, setQuery] = useState("");
  const deferredQuery = useDeferredValue(query);
  const handleSelectedQuestion = useCallback((query: string) => {
    setQuery(query);
  }, []);

  const { quickSearch, isQuestion } = useQuickSearch(query);
  const { askMaca, answers } = useAskMaca(query);
  const handleAskMaca = useCallback(async () => {
    await askMaca(deferredQuery);
    setQuery("");
  }, [askMaca, deferredQuery]);

  return (
    <TranslationScope ns="insights" keyPrefix="search">
      <div
        className="w-[96rem] max-w-6xl min-h-[48rem] bg-bg-l250"
        role="search"
      >
        <QueryInput value={query} onChange={setQuery}>
          <AskMacaButton onClick={handleAskMaca} enabled={isQuestion} />
        </QueryInput>
        <QueryResults>
          {answers}
          {quickSearch}
          <SuggestedQuestions
            query={deferredQuery}
            onSelected={handleSelectedQuestion}
            key="suggested_questions"
          />
        </QueryResults>
      </div>
    </TranslationScope>
  );
};

const SearchModal = ({ open, onClose }: ModalProps) => (
  <Modal {...{ open, onClose }}>
    <SearchPanel />
  </Modal>
);

export default SearchModal;
