import {
  useForm,
  UseFormProps,
  RegisterOptions,
  FieldValues,
  FieldError,
  FieldPath,
  Resolver,
  ResolverOptions,
  UseFormHandleSubmit,
} from "react-hook-form";

import { get, mapValues, every, pull } from "lodash";
import { Type, ObjectType, ValidationError } from "myzod";
import { SyntheticEvent, useCallback, useMemo } from "react";

const translateMyzodError = (error: ValidationError) =>
  error
    ? {
        type: error.message.endsWith("but got undefined")
          ? "required"
          : "validation_error",
        message: error.message,
      }
    : undefined;

const myzodResolver =
  <Values extends FieldValues>(schema: ObjectType<Values>): Resolver<Values> =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: Values, _context: any, options: ResolverOptions<Values>) => {
    const result = (
      options.criteriaMode === "firstError" ? schema : schema.collectErrors()
    ).try(data);

    return {
      values: data,
      errors:
        result && result instanceof ValidationError
          ? mapValues(
              result.collectedErrors ?? { rootPredicate: result },
              translateMyzodError
            )
          : {},
    };
  };

const useEasyForm = <Values extends FieldValues>({
  schema,
  ...props
}: UseFormProps<Values> & { schema?: Type<Values> }) => {
  const {
    register: _register,
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<Values>({
    ...props,
    resolver: schema
      ? myzodResolver(schema as unknown as ObjectType<Values>)
      : undefined,
  });

  const register = <FieldName extends FieldPath<Values>>(
    name: FieldName,
    options?: RegisterOptions<Values, FieldName>
  ) => {
    const opts = { required: true, ...options };
    const error = get(errors, name) as FieldError | undefined;
    return {
      ..._register(name, opts),
      control,
      required: Boolean(opts?.required),
      error: error?.root ?? error,
    };
  };

  return { register, control, handleSubmit, reset, errors, setError };
};

export type SubmitHandler = (event: SyntheticEvent) => Promise<boolean>;
export type RegisterSubmitHandler = (handler: SubmitHandler) => () => void;
export type RegisterNoopSubmitHander = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: UseFormHandleSubmit<any>
) => void;

export const useSubforms = <Values extends FieldValues>(
  handleSubmit: UseFormHandleSubmit<Values>
) => {
  const submitHandlers = useMemo<SubmitHandler[]>(() => [], []);
  const registerSubmitHandler: RegisterSubmitHandler = useCallback(
    (handler: SubmitHandler) => {
      submitHandlers.push(handler);
      return () => pull(submitHandlers, handler);
    },
    [submitHandlers]
  );

  const validateSubforms = useCallback(
    async (event: SyntheticEvent) =>
      await Promise.all(submitHandlers.map((handler) => handler(event))),
    [submitHandlers]
  );

  const handleSubmitAll = useCallback(
    (callback: (values: Values) => Promise<void>) =>
      async (event: SyntheticEvent) =>
        handleSubmit(
          async (values) => {
            if (every(await validateSubforms(event))) {
              await callback(values);
            }
          },
          async () => {
            validateSubforms(event);
          }
        )(event),
    [handleSubmit, validateSubforms]
  );

  const registerNoopSubmitHandler: RegisterNoopSubmitHander = useCallback(
    (handleSubmit) =>
      registerSubmitHandler(
        (event: SyntheticEvent) =>
          new Promise<boolean>((resolve) =>
            handleSubmit(
              async () => resolve(true),
              async () => resolve(false)
            )(event)
          )
      ),
    [registerSubmitHandler]
  );

  return {
    handleSubmitAll,
    registerSubmitHandler,
    registerNoopSubmitHandler,
  };
};

export default useEasyForm;
