import React from "react";

import * as Portal from "@radix-ui/react-portal";

import { Placement, Alignment, Side, Axis, Length } from "@floating-ui/react";

import cx from "clsx";

import { Point, Rectangle, inflateRect, rectCenter } from "@/lib/geometry";

const getAlignment = (placement: Placement): Alignment =>
  placement.split("-")[1] as Alignment;

const getSide = (placement: Placement): Side => placement.split("-")[0] as Side;

const getMainAxisFromPlacement = (placement: Placement): Axis =>
  ["top", "bottom"].includes(getSide(placement)) ? "x" : "y";

const getLengthFromAxis = (axis: Axis): Length =>
  axis === "y" ? "height" : "width";

const rectSidePoint = (rect: Rectangle, side: Side) => {
  const center = rectCenter(rect);
  switch (side) {
    case "left":
      return { x: rect.left, y: center.y };

    case "top":
      return { x: center.x, y: rect.top };

    case "right":
      return { x: rect.left + rect.width, y: center.y };

    case "bottom":
      return { x: center.x, y: rect.top + rect.height };

    default:
      return { x: rect.left, y: rect.top };
  }
};

const beaconLocation = (
  elementRect: Rectangle,
  placement: Placement,
  rtl?: boolean
) => {
  const sidePoint = rectSidePoint(elementRect, getSide(placement));

  const mainAxis = getMainAxisFromPlacement(placement);
  const halfLength = elementRect[getLengthFromAxis(mainAxis)] / 2;
  const inverseDirection = rtl && mainAxis === "x" ? -1 : 1;

  switch (getAlignment(placement)) {
    case "start":
      sidePoint[mainAxis] -= halfLength * inverseDirection;
      break;

    case "end":
      sidePoint[mainAxis] += halfLength * inverseDirection;
      break;

    default:
      break;
  }

  return sidePoint;
};

export const StepBeacon = ({
  elementRect,
  placement,
  margin,
  onClick,
  zIndex,
}: {
  elementRect: Rectangle;
  placement?: Placement;
  margin?: number | Point;
  zIndex?: number;
  onClick: () => void;
}) => {
  const adjustedRect = margin ? inflateRect(elementRect, margin) : elementRect;
  const location = beaconLocation(adjustedRect, placement ?? "bottom");
  return (
    <Portal.Root>
      <div
        className="absolute z-40 pointer-events-none"
        style={{ left: location.x, top: location.y, zIndex }}
      >
        <button
          className="relative w-8 aspect-square -translate-x-1/2 -translate-y-1/2 pointer-events-auto"
          onClick={onClick}
        >
          <div
            className={cx(
              "absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2",
              "rounded-full bg-brand-purple-l2 opacity-70 animate-beacon-inner"
            )}
          />
          <div
            className={cx(
              "absolute inset-0 border-[2px] rounded-full border-brand-purple-l2",
              "bg-brand-purple-l2/20 opacity-90 animate-beacon-outer"
            )}
          />
        </button>
      </div>
    </Portal.Root>
  );
};
