import { useMemo } from "react";
import { type EChartsType } from "echarts/core";

const legendselectchanged = (
  {
    name,
    selected,
  }: {
    name: string;
    selected: Record<string, boolean>;
  },
  chart: EChartsType
) => {
  if (name in selected) {
    chart.dispatchAction({
      type: selected[name] ? "legendSelect" : "legendUnSelect",
      name: `${name}.accuracy`,
    });
  }
};

export const useLegend = (keys: string[]) =>
  useMemo(
    () =>
      keys.length > 1
        ? {
            legend: {
              data: keys.map((key) => ({ name: key })),
              icon: "circle",
              orient: "vertical",
              top: 10,
              right: 0,
              emphasis: { areaStyle: { opacity: 0.4 } },
            },
            grid: { right: 150 },
            on: {
              legendselectchanged,
            },
          }
        : undefined,
    [keys]
  );
