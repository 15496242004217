import React, { PropsWithChildren } from "react";

import cx from "clsx";

// @ts-ignore
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";

import { useText } from "@/components/hooks/useText";

const QueryInput = ({
  value,
  onChange,
  children,
}: PropsWithChildren<{
  value: string;
  onChange: (v: string) => void;
}>) => {
  const t = useText();
  return (
    <div className="group relative flex gap-2 items-center border-b border-bg-l4">
      <div className="absolute flex left-4 h-full items-center pointer-events-none">
        <SearchIcon
          className={cx(
            "w-6 text-text-muted group-hover:text-text-default",
            "group-focus-within:text-text-default state-transition-colors"
          )}
        />
      </div>
      <input
        type="search"
        className="input-text text-lg grow py-3 pl-12 outline-none bg-transparent"
        placeholder={t("query_input.placeholder")}
        {...{ value, onChange: (e) => onChange(e.target.value) }}
      />
      {children}
    </div>
  );
};

export default QueryInput;
