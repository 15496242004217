import React from "react";
import * as Av from "@radix-ui/react-avatar";
import initials from "name-initials";
import cx from "clsx";

export type AvatarSize = "xs" | "sm" | "base" | "lg" | "xl";

export interface AvatarProps {
  name: string;
  src?: string;
  size?: AvatarSize;
}

const sizeToHeight: Record<AvatarSize, string> = {
  xs: "h-6",
  sm: "h-8",
  base: "h-10",
  lg: "h-12",
  xl: "h-16",
};

// Use SVG text to render initials that auto-scale with the size of the container
const Initials = ({ initials }: { initials: string }) => (
  <svg viewBox="0 0 256 256" className="w-full">
    <g transform="translate(128, 128)">
      <text
        x="0"
        y="12"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={initials.length > 1 ? 92 : 128}
      >
        {initials}
      </text>
    </g>
  </svg>
);

const Avatar = ({ name, src, size = "base" }: AvatarProps) => {
  return (
    <Av.Root
      className={cx(
        "inline-flex overflow-hidden select-none aspect-square",
        "rounded-full border border-border-default transition ease-in-out duration-1000",
        sizeToHeight[size]
      )}
    >
      <Av.Image className="w-full" src={src} alt={name} />
      <Av.Fallback
        className={cx(
          "flex items-center justify-center bg-bg-l4 text-text-bright leading-none aspect-square",
          "fill-text-default font-medium"
        )}
      >
        <Initials initials={initials(name)} />
      </Av.Fallback>
    </Av.Root>
  );
};

export default Avatar;
