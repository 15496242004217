import React, {
  Fragment,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
} from "react";

import { FieldValues, FieldPath, useController } from "react-hook-form";

import { RadioGroup } from "@headlessui/react";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as CheckIcon } from "@/assets/icons/circle-check-filled.svg";

import { CustomInputProps } from "@/components/input/props";

import useText from "@/components/hooks/useText";

import InputField, { InputFieldProps } from "./InputField";

export const OptionItem = forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement> & {
    option: string;
    name: string;
    checked: boolean;
  }
>(({ option, checked, ...props }, ref) => {
  const t = useText({ keyPrefix: `options.${option}` });
  const description = t("description", { defaultValue: "" });
  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <button
      ref={ref}
      type="button"
      className={cx(
        checked ? "bg-brand-purple" : "bg-bg-l4 bg-opacity-60",
        "group grow leading-tight whitespace-nowrap pl-5 pr-3 py-3 min-h-[52px] rounded-lg",
        "hover:bg-opacity-100 focus-ring-rounded-lg",
        "group-disabled/fieldset:text-text-muted"
      )}
      aria-checked={checked}
      {...props}
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-col items-start gap-0.5">
          <div className="text-text-bright font-medium group-hover:text-text-link">
            {t("title")}
          </div>
          {description && (
            <div className="text-text-default text-sm">{description}</div>
          )}
        </div>
        {checked && (
          <CheckIcon className="w-6 aspect-square shrink-0 text-text-bright fill-brand-purple-l3/40" />
        )}
      </div>
    </button>
  );
});

const OptionsGroup = <
  Values extends FieldValues,
  Name extends FieldPath<Values>
>({
  options,
  id,
  name,
  control,
}: CustomInputProps<Values, Name> & {
  options: string[];
  id: string;
}) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return (
    <RadioGroup
      className="flex flex-col gap-2"
      value={value ?? null}
      onChange={(value) => onChange(value ?? null)}
      id={id}
    >
      {options.map((op) => (
        <RadioGroup.Option key={op} value={op} as={Fragment}>
          {({ checked }) => <OptionItem option={op} {...{ name, checked }} />}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

interface OptionsInputProps<
  Values extends FieldValues,
  Name extends FieldPath<Values>
> extends InputFieldProps<Values, Name> {
  options: string[];
}

const Choice = forwardRef(
  <Values extends FieldValues, Name extends FieldPath<Values>>(
    { options, ...props }: OptionsInputProps<Values, Name>,
    _: ForwardedRef<HTMLInputElement>
  ) => (
    <InputField {...props}>
      {(props) => <OptionsGroup options={options} {...props} />}
    </InputField>
  )
);

export default Choice;
