import { useMemo } from "react";
import {
  type CustomSeriesRenderItem,
  type CustomSeriesRenderItemAPI,
} from "echarts/types/dist/shared";

export interface MetricAccuracyDatum {
  [key: string]: string | number | [string | number, string | number];
}

const lineShape = (from: [number, number], to: [number, number]) => ({
  x1: from[0],
  y1: from[1],
  x2: to[0],
  y2: to[1],
});

const animationProps = {
  transition: ["style"],
  enterFrom: {
    style: {
      opacity: 0,
    },
  },
  leaveTo: {
    style: {
      opacity: 0,
    },
  },
  enterAnimation: {
    duration: 200,
    delay: 200,
    easing: "cubicOut",
  },
};

type Dimesion = 0 | 1;

const makeErrorBarRenderer =
  (verticalLayout: boolean): CustomSeriesRenderItem =>
  (_, api) => {
    const index = api.value(0);
    const minValue = api.value(1);
    const maxValue = api.value(2);

    const [indexDimension, valueDimension]: [Dimesion, Dimesion] =
      verticalLayout ? [0, 1] : [1, 0];

    const makePoint = <T,>(index: T, value: T): [T, T] => {
      const r: [T, T] = [index, value];
      return [r[indexDimension], r[valueDimension]];
    };

    const columnWidth = (api: CustomSeriesRenderItemAPI) =>
      api.size ? (api.size([1, 1]) as [number, number])[indexDimension] : 0;

    const highPoint = api.coord(makePoint(index, minValue)) as [number, number];
    const lowPoint = api.coord(makePoint(index, maxValue)) as [number, number];
    const halfWidth = columnWidth(api) * 0.1;

    const style = {
      stroke: api.visual("color"),
      opacity: 1,
      lineWidth: 1.5,
      fill: undefined,
    };

    return {
      type: "group",
      children: [
        {
          type: "line",
          shape: lineShape(
            makePoint(
              highPoint[indexDimension] - halfWidth,
              highPoint[valueDimension]
            ),
            makePoint(
              highPoint[indexDimension] + halfWidth,
              highPoint[valueDimension]
            )
          ),
          style,
          ...animationProps,
        },
        {
          type: "line",
          shape: lineShape(
            makePoint(highPoint[indexDimension], highPoint[valueDimension]),
            makePoint(lowPoint[indexDimension], lowPoint[valueDimension])
          ),
          style,
          ...animationProps,
        },
        {
          type: "line",
          shape: lineShape(
            makePoint(
              lowPoint[indexDimension] - halfWidth,
              lowPoint[valueDimension]
            ),
            makePoint(
              lowPoint[indexDimension] + halfWidth,
              lowPoint[valueDimension]
            )
          ),
          style,
          ...animationProps,
        },
      ],
    } as ReturnType<CustomSeriesRenderItem>;
  };

export const useAccuracyData = ({
  accuracyData,
  indexBy,
  keys,
  verticalLayout,
}: {
  accuracyData?: MetricAccuracyDatum[];
  indexBy: string;
  keys: string[];
  verticalLayout: boolean;
}) =>
  useMemo(
    () =>
      accuracyData
        ? {
            dataset: keys.map((key) => ({
              source: [
                [indexBy, "min", "max"],
                ...accuracyData.map((item) => [
                  item[indexBy],
                  ...((item[key] as Array<string>) ?? []),
                ]),
              ],
            })),
            series: keys.map((key) => ({
              type: "custom",
              name: `${key}.accuracy`,
              renderItem: makeErrorBarRenderer(verticalLayout),
              encode: verticalLayout
                ? { x: indexBy, y: ["min", "max"] }
                : { x: ["min", "max"], y: indexBy },
              z: 100,
            })),
          }
        : { dataset: [], series: [] },
    [accuracyData, indexBy, keys, verticalLayout]
  );
