import React, {
  PropsWithChildren,
  useEffect,
  useState,
  useCallback,
} from "react";

import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../../themes/theme";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box, Grid, Link, Typography } from "@mui/material";
import api from "@/client/api";
import { MarkEmailReadOutlined, Block } from "@mui/icons-material";
import { useTranslation, Trans } from "react-i18next";

interface AcceptInvitationReponse {
  organization_name: string;
  new_user: boolean;
}

const InvitationLayout = ({ children }: PropsWithChildren) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        textAlign="center"
        sx={{
          flexGrow: 1,
        }}
      >
        <Grid>{children}</Grid>
      </Box>
    </ThemeProvider>
  );
};

const InvitationNotFound = () => {
  const { t } = useTranslation("invitation", { keyPrefix: "not_found" });

  return (
    <InvitationLayout>
      <Block sx={{ fontSize: 80 }} color="primary" />
      <Typography variant="h3">{t("title")}</Typography>
      <Typography
        variant="h6"
        color="text.secondary"
        style={{ marginTop: "1em", whiteSpace: "pre-line" }}
      >
        {t("message")}
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }} color="text.secondary">
        {t("details")}
      </Typography>
    </InvitationLayout>
  );
};

const InvitationAccepted = ({
  organization_name,
  new_user,
}: AcceptInvitationReponse) => {
  const navigate = useNavigate();
  const routeToSelectOrganization = () => {
    navigate("/organization/select");
  };
  const { t } = useTranslation("invitation", { keyPrefix: "accept" });

  return (
    <InvitationLayout>
      <MarkEmailReadOutlined sx={{ fontSize: 80 }} color="primary" />
      <Typography variant="h3">{t("title")}</Typography>
      <Typography
        variant="h6"
        color="text.secondary"
        style={{ marginTop: "1em", whiteSpace: "pre-line" }}
      >
        {t("message", { organization_name })}
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }} color="text.secondary">
        {new_user ? (
          t("details", { context: "new" })
        ) : (
          <Trans i18nKey="details" context="existing">
            Please change your{" "}
            <Link
              sx={{ cursor: "pointer" }}
              onClick={routeToSelectOrganization}
            >
              active
            </Link>{" "}
            organization.
          </Trans>
        )}
      </Typography>
    </InvitationLayout>
  );
};

const AcceptInvitation = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<string>("");
  const [orgName, setOrgName] = useState<string>("");
  const [newUser, setNewUser] = useState<boolean>(true);

  const acceptInvite = useCallback(
    async (code: string) => {
      try {
        const response: AcceptInvitationReponse = await api
          .post("organization/accept-invite/", {
            json: { code },
          })
          .json();
        setStatus("accepted");
        setOrgName(response.organization_name);
        setNewUser(response.new_user);
      } catch (err) {
        setStatus("not_found");
        console.error(err);
      }
    },
    [setStatus]
  );

  const code = searchParams.get("code");

  useEffect(() => {
    if (code) {
      acceptInvite(code);
    } else {
      setStatus("not_found");
    }
  }, [acceptInvite, setStatus, code]);

  if (status === "not_found") {
    return <InvitationNotFound />;
  }

  if (status === "accepted") {
    return (
      <InvitationAccepted organization_name={orgName} new_user={newUser} />
    );
  }
  return <>Loading...</>;
};

export default AcceptInvitation;
