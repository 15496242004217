import React from "react";
import cx from "clsx";
import { Button } from "@mui/material";
import { SyncOutlined } from "@mui/icons-material";
import Avatar from "@/components/Avatar";
import { InvitationData, useInvitationListAPI, useInviteUserAPI } from "./api";

interface InvitationListItemProps {
  invitation: InvitationData;
}

const InvitationListItem = ({ invitation }: InvitationListItemProps) => {
  const { saveInvitation } = useInviteUserAPI({
    inviteId: invitation.id.toString(),
  });

  return (
    <li
      className={cx(
        "grid grid-cols-8 py-4 content-center items-center justify-items-stretch",
        "border-t border-border-default"
      )}
    >
      <div>
        <Avatar name={invitation.email} size="sm" />
      </div>
      <div className="col-span-3">{invitation.email}</div>
      <div>{invitation.expired ? "Expired" : invitation.status}</div>
      <div className="justify-self-end col-span-3">
        {invitation.status !== "Accepted" && (
          <Button
            variant="outlined"
            startIcon={<SyncOutlined />}
            onClick={() => saveInvitation(invitation)}
          >
            Resend
          </Button>
        )}
      </div>
    </li>
  );
};

const InvitationList = () => {
  const { invitations } = useInvitationListAPI();

  return (
    <ul>
      {(invitations || []).map((invitation) => (
        <InvitationListItem invitation={invitation} key={invitation.id} />
      ))}
    </ul>
  );
};

export default InvitationList;
