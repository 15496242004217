import { type Theme } from "@nivo/core";
import tw from "@/styles/tailwind";

const colors = tw.theme?.colors;
const fontFamily = "var(--ui-font-family)";

const insightsTheme: Theme = {
  background: colors?.transparent,
  textColor: colors?.text.bright,
  fontSize: 12,
  axis: {
    domain: {
      line: {
        stroke: colors?.border.bright,
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: colors?.text.default,
        fontFamily,
      },
    },
    ticks: {
      line: {
        stroke: colors?.text.default,
        strokeWidth: 1,
      },
      text: {
        fontSize: 11,
        fill: colors?.text.bright,
        fontFamily,
      },
    },
  },
  grid: {
    line: {
      stroke: colors?.border.default,
      strokeWidth: 1,
    },
  },
  markers: {
    lineColor: colors?.border.bright,
    lineStrokeWidth: 2,
  },
  legends: {
    title: {
      text: {
        fontSize: 14,
        fill: colors?.text.bright,
        fontFamily,
      },
    },
    text: {
      fontSize: 11,
      fill: colors?.text.default,
      fontFamily,
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: colors?.text.bright,
        fontFamily,
      },
    },
  },
  annotations: {
    text: {
      fontSize: 12,
      fill: colors?.text.bright,
      outlineWidth: 2,
      outlineColor: colors?.border.default,
      outlineOpacity: 1,
      fontFamily,
    },
    link: {
      stroke: colors?.brand.purple.DEFAULT,
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: colors?.border.default,
      outlineOpacity: 1,
    },
    outline: {
      stroke: colors?.brand.purple.DEFAULT,
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: colors?.border.default,
      outlineOpacity: 1,
    },
    symbol: {
      fill: colors?.border.default,
      outlineWidth: 2,
      outlineColor: colors?.border.default,
      outlineOpacity: 1,
    },
  },
  tooltip: {
    container: {
      background: colors?.bg.d1,
      color: colors?.text.bright,
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

export default insightsTheme;
