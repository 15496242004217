import React, {
  useMemo,
  ReactNode,
  ComponentType,
  PropsWithChildren,
  useCallback,
} from "react";

import {
  useFieldArray,
  useWatch,
  Control,
  FieldPathByValue,
  FieldArrayWithId,
} from "react-hook-form";

import {
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggableRubric,
} from "@hello-pangea/dnd";

import { Trans } from "react-i18next";
import { useTimeout } from "usehooks-ts";

import { map, findIndex, isNil, keyBy, uniqBy, omit } from "lodash";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as CheckIcon } from "@/assets/icons/check.svg";
// @ts-ignore
import { ReactComponent as XIcon } from "@/assets/icons/x.svg";
// @ts-ignore
import { ReactComponent as ComponentsLarge } from "@/assets/icons/components-large.svg";
// @ts-ignore
import { ReactComponent as WarningIcon } from "@/assets/icons/circle-warning.svg";
// @ts-ignore
import { ReactComponent as InfoIcon } from "@/assets/icons/circle-info.svg";

import useText from "@/components/hooks/useText";
import { useDragEndResponder } from "@/components/hooks/useDndResponder";

import Tooltip from "@/components/Tooltip";

import NumericInput from "@/components/input/NumericInput";
import { useProductComponentsListAPI } from "@/pages/products/api";

import PlanComponentsSelect, {
  filterComponents,
  ProductComponentOption,
  ComponentMenuItem,
} from "./PlanComponentsSelect";

import PlanSelect from "./PlanSelect";

import {
  ProductPlanComponentJSON,
  ProductPlanDetailsJSON,
  UnfurledProductPlanComponentJSON,
  pluckPlanComponents,
  toPlanComponent,
} from "../models/serialization";

import { EditablePlan } from "../models/planset";

const IconContainer = ({
  onClick,
  children,
}: PropsWithChildren<{
  onClick?: VoidFunction;
}>) => (
  <span
    className={cx(
      "group/icon grid w-9 p-2 shrink-0 place-items-center",
      onClick && "cursor-pointer"
    )}
    onClick={onClick}
  >
    {children}
  </span>
);

const PlanComponentIcon = ({
  editing,
  onClick,
}: {
  editing: boolean;
  onClick?: VoidFunction;
}) => (
  <IconContainer onClick={editing ? onClick : undefined}>
    <CheckIcon
      className={cx(
        "col-start-1 row-start-1",
        editing
          ? "text-text-default shrink-0 group-hover:opacity-0 pointer-events-none"
          : "text-text-link"
      )}
    />
    {editing && (
      <XIcon
        className={cx(
          "col-start-1 row-start-1 shrink-0 text-text-muted opacity-0 group-hover:opacity-100",
          "group-hover/icon:text-text-link"
        )}
      />
    )}
  </IconContainer>
);

const ComponentDeleteIcon = () => (
  <IconContainer>
    <XIcon className="col-start-1 row-start-1 shrink-0 text-text-error" />
  </IconContainer>
);

const ComponentQuantityEditor = ({
  control,
  name,
  unit,
  placeholder,
  dragging,
}: {
  control: Control<EditablePlan>;
  name: FieldPathByValue<EditablePlan, number | null | undefined>;
  unit?: string;
  placeholder: string;
  dragging: boolean;
}) => (
  <NumericInput
    name={name}
    control={control}
    options={unit ? { valueType: "unit", unit } : { valueType: "decimal" }}
    padding="snug"
    placeholder={placeholder}
    className={
      dragging
        ? "!border-bg-l4"
        : "group-hover:border-bg-l4 group-hover:focus-within:border-border-focus"
    }
    inputClassName={dragging ? "!text-text-bright" : ""}
    adornmentClassName={dragging ? "!text-text-default" : ""}
    disabled={dragging}
    autoSize={true}
    hidePlaceholderWhenFocused={true}
  />
);

const ComponentName = ({
  component,
  editing,
  dragging,
}: {
  component: ProductPlanComponentJSON;
  editing: boolean;
  dragging: boolean;
}) => {
  const t = useText();
  const { is_countable, quantity_unit_of_measure, ...parts } = component;
  const formatKey = is_countable
    ? editing
      ? "countable_editing"
      : isNil(component.quantity)
      ? "countable_unlimited"
      : "countable_quantity"
    : "non_countable";

  return (
    <span
      className={cx(
        "leading-snug min-w-0 pr-1.5 grow select-none",
        dragging ? "text-text-bright" : "text-text-default",
        editing && "group-hover:text-text-bright",
        editing && is_countable ? "py-1" : "py-1.5",
        !component.name.includes(" ") && "break-words"
      )}
    >
      {t(`components.${formatKey}`, {
        ...parts,
        formatParams: {
          quantity: {
            unit: quantity_unit_of_measure ?? "",
          },
        },
      })}
    </span>
  );
};

interface ListItemProps {
  id: string;
  index: number;
  editing: boolean;
  draggable?: DraggableProvided;
  dragPlaceholder?: boolean;
  className: string;
  tooltip: ReactNode;
  children: ({ dragging }: { dragging: boolean }) => ReactNode;
}

const SortableListItem = ({
  id,
  index,
  editing,
  dragPlaceholder,
  className,
  tooltip,
  children,
}: ListItemProps) => {
  const tooltipProps = {
    content: tooltip,
    delayDuration: 0,
    disableHoverableContent: true,
  };

  return editing ? (
    <>
      <Draggable draggableId={id} index={index}>
        {(provided, { isDragging }) => (
          <Tooltip {...tooltipProps}>
            <li
              ref={provided.innerRef}
              className={cx(
                isDragging && "z-50 bg-bg-l3 shadow-2xl cursor-grabbing",
                className
              )}
              {...provided.draggableProps}
              // disable tabbing through plan components as we've disabled keyboard dragging for now
              {...omit(provided.dragHandleProps, "tabIndex")}
            >
              {children({ dragging: isDragging })}
            </li>
          </Tooltip>
        )}
      </Draggable>
      {dragPlaceholder && (
        <li className={cx(className, "invisible pointer-events-none")}>
          {children({ dragging: true })}
        </li>
      )}
    </>
  ) : (
    <Tooltip {...tooltipProps}>
      <li className={className}>{children({ dragging: false })}</li>
    </Tooltip>
  );
};

interface ComponentParts {
  planId: string;
  productComponentId: string;
  quantity?: number | null;
}

export const serializeToId = ({
  planId,
  productComponentId,
  quantity,
}: ComponentParts) =>
  new URLSearchParams({
    planId,
    productComponentId,
    ...(quantity === undefined ? {} : { quantity: `${quantity}` }),
  }).toString();

export const deserializeFromId = (id: string): ComponentParts => {
  const parts = new URLSearchParams(id);
  return {
    planId: parts.get("planId") ?? "",
    productComponentId: parts.get("productComponentId") ?? "",
    quantity: parts.get("quantity")
      ? parts.get("quantity") === "null"
        ? null
        : Number(parts.get("quantity"))
      : undefined,
  };
};

type BasePlanComponent = UnfurledProductPlanComponentJSON;

const BasePlanComponentTooltip = ({
  tooltipKey,
  basePlanComponent: { is_countable, quantity_unit_of_measure, ...values },
}: {
  tooltipKey: string;
  basePlanComponent: BasePlanComponent;
}) => {
  const t = useText();
  const fullName = is_countable
    ? t(
        isNil(values.quantity)
          ? "components.countable_unlimited"
          : "components.countable_quantity",
        {
          ...values,
          formatParams: {
            quantity: {
              unit: quantity_unit_of_measure ?? "",
            },
          },
        }
      )
    : values.name;

  return (
    <div className="flex text-text-bright text-sm max-w-[16rem] pointer-events-none gap-4">
      <Trans
        i18nKey={`components.base_component_tooltip.${tooltipKey}`}
        values={{
          ...values,
          fullName,
        }}
        t={t}
        components={{
          p: <span className="break-words min-w-0" />,
          em: <span className="font-medium" />,
          info: (
            <InfoIcon className="w-5 aspect-square shrink-0 text-base-indigo-200" />
          ),
          warning: (
            <WarningIcon className="w-5 aspect-square shrink-0 text-base-orange-200" />
          ),
        }}
      />
    </div>
  );
};

const basePlanComponentTooltipKey = ({
  editing,
  componentQuantity,
  is_countable,
  quantity,
}: BasePlanComponent & {
  editing: boolean;
  componentQuantity?: number | null;
}) => {
  if (
    !is_countable ||
    (!editing && (componentQuantity ?? Infinity) === (quantity ?? Infinity))
  ) {
    return "already_included";
  }

  if (!editing && (componentQuantity ?? Infinity) < (quantity ?? Infinity)) {
    return "lesser_quantity";
  }

  return editing ? "countable_override" : null;
};

const PlanComponent = ({
  ListItem,
  itemId,
  control,
  index,
  component,
  basePlanComponent,
  editing,
  draggable,
  dragPlaceholder,
  highlightAsDuplicate,
  onRemove,
}: {
  ListItem: ComponentType<ListItemProps>;
  itemId: string;
  control: Control<EditablePlan>;
  index: number;
  component: ProductPlanComponentJSON;
  basePlanComponent?: BasePlanComponent;
  editing: boolean;
  highlightAsDuplicate: boolean;
  draggable?: DraggableProvided;
  dragPlaceholder?: boolean;
  onRemove?: VoidFunction;
}) => {
  const t = useText();
  const tooltipKey = basePlanComponent
    ? basePlanComponentTooltipKey({
        editing,
        componentQuantity: component.quantity,
        ...basePlanComponent,
      })
    : null;

  return (
    <ListItem
      id={itemId}
      index={index}
      editing={editing}
      draggable={draggable}
      dragPlaceholder={dragPlaceholder}
      className={cx(
        "flex items-center gap-x-2 rounded-md group",
        editing && "hover:bg-bg-l3 px-1 cursor-grab active:cursor-grabbing"
      )}
      tooltip={
        basePlanComponent &&
        tooltipKey && (
          <BasePlanComponentTooltip {...{ tooltipKey, basePlanComponent }} />
        )
      }
    >
      {({ dragging }) => (
        <>
          {highlightAsDuplicate ? (
            <ComponentDeleteIcon />
          ) : (
            <PlanComponentIcon editing={editing} onClick={onRemove} />
          )}
          <span
            className={cx(
              "inline-flex flex-wrap items-center min-w-0 gap-x-2 py-0.5 pr-0.5",
              highlightAsDuplicate ||
                (tooltipKey &&
                  (!editing || !component.is_countable) &&
                  "opacity-50")
            )}
          >
            {component.is_countable && editing && (
              <ComponentQuantityEditor
                control={control}
                name={`components.${index}.quantity`}
                unit={component.quantity_unit_of_measure}
                placeholder={t("components.quantity_placeholder")}
                dragging={dragging}
              />
            )}
            <ComponentName
              component={component}
              editing={editing}
              dragging={dragging}
            />
          </span>
        </>
      )}
    </ListItem>
  );
};

const SortablePlanComponent = ({
  control,
  index,
  planId,
  component,
  basePlanComponent,
  editing,
  highlightAsDuplicate,
  dragPlaceholder,
  onRemove,
}: {
  control: Control<EditablePlan>;
  index: number;
  planId: string;
  component: ProductPlanComponentJSON;
  basePlanComponent?: BasePlanComponent;
  editing: boolean;
  highlightAsDuplicate: boolean;
  dragPlaceholder: boolean;
  onRemove: VoidFunction;
}) => {
  const quantity = useWatch({
    control,
    name: `components.${index}.quantity`,
  });

  return (
    <PlanComponent
      ListItem={SortableListItem}
      itemId={serializeToId({
        planId,
        productComponentId: component.product_component_id,
        quantity,
      })}
      {...{
        control,
        index,
        component,
        basePlanComponent,
        editing,
        highlightAsDuplicate,
        dragPlaceholder,
        onRemove,
      }}
    />
  );
};

const DragListItem = ({ draggable, className, children }: ListItemProps) => (
  <li
    ref={draggable?.innerRef}
    className={cx("z-50 bg-bg-l3 shadow-2xl cursor-grabbing", className)}
    {...draggable?.draggableProps}
    {...draggable?.dragHandleProps}
  >
    {children({ dragging: true })}
  </li>
);

const excludeSelected = (
  productComponents: ProductComponentOption[],
  selectedComponents: ProductPlanComponentJSON[]
) => {
  const selectedComponentIds = new Set(
    map(selectedComponents, "product_component_id")
  );

  return productComponents.filter(({ id }) => !selectedComponentIds.has(id));
};

const ComponentsList = ({
  control,
  planId,
  components,
  basePlanComponentLookup,
  editing,
  remove,
  onAcceptDrag,
  isDraggingOver,
  draggingFromThisWith,
  draggingOverWith,
  children,
}: PropsWithChildren<{
  control: Control<EditablePlan>;
  planId: string;
  components: FieldArrayWithId<EditablePlan, "components", "key">[];
  basePlanComponentLookup: Record<string, BasePlanComponent>;
  editing: boolean;
  remove: (index: number) => void;
  onAcceptDrag: VoidFunction;
  isDraggingOver: boolean;
  draggingFromThisWith: string | null;
  draggingOverWith: string | null;
}>) => {
  const draggedOverComponent = draggingOverWith
    ? deserializeFromId(draggingOverWith)
    : null;

  const acceptDragThrottle = isDraggingOver && !editing;
  useTimeout(onAcceptDrag, acceptDragThrottle ? 1250 : null);

  const draggedProductComponentId = draggingFromThisWith
    ? deserializeFromId(draggingFromThisWith).productComponentId
    : null;

  return (
    <ul
      className={cx(
        "flex flex-col",
        acceptDragThrottle && "animate-flash-on-drag"
      )}
    >
      {components.map((component, index) => (
        <SortablePlanComponent
          key={component.key}
          control={control}
          index={index}
          planId={planId}
          component={component}
          basePlanComponent={
            basePlanComponentLookup[component.product_component_id]
          }
          editing={editing}
          highlightAsDuplicate={
            editing &&
            planId !== draggedOverComponent?.planId &&
            component.product_component_id ===
              draggedOverComponent?.productComponentId
          }
          dragPlaceholder={
            component.product_component_id === draggedProductComponentId
          }
          onRemove={() => remove(index)}
        />
      ))}
      {children}
    </ul>
  );
};

const BasePlan = ({
  control,
  planId,
  allPlans,
  editing,
  componentsCount,
}: {
  control: Control<EditablePlan>;
  planId: string;
  allPlans: ProductPlanDetailsJSON[];
  editing: boolean;
  componentsCount: number;
}) => {
  const t = useText();
  const basePlanId = useWatch({ control, name: "base_plan_id" });
  const plansById = useMemo(() => keyBy(allPlans, "id"), [allPlans]);
  const planOptions = useMemo(
    () => allPlans.filter(({ id }) => id !== planId),
    [allPlans, planId]
  );

  return editing ? (
    <div className="flex items-center gap-x-2 -mx-1 -mt-0.5">
      <PlanSelect
        plans={planOptions}
        control={control}
        name="base_plan_id"
        placeholder="Select base plan"
      />
      {basePlanId && (
        <span className="whitespace-nowrap text-text-bright">
          {t("components.base_plan.suffix")}
        </span>
      )}
    </div>
  ) : basePlanId ? (
    <span className="text-text-bright py-1.5 pl-1.5 break-words min-w-0 select-none">
      <Trans
        i18nKey="components.base_plan.description"
        values={{
          planName: plansById[basePlanId ?? ""]?.name,
          count: componentsCount,
        }}
        t={t}
        components={{ em: <span className="font-semibold" /> }}
      />
    </span>
  ) : null;
};

const EmptyListPlaceholder = () => {
  const t = useText();
  return (
    <div className="flex flex-col gap-4 py-4 select-none text-text-muted">
      <ComponentsLarge className="px-8 opacity-30" />
      <div className="font-medium text-center opacity-60">
        {t("components.empty_list_placeholder")}
      </div>
    </div>
  );
};

const PlanComponents = ({
  control,
  productId,
  planId,
  allPlans,
  editing,
  onAcceptDrag,
}: {
  control: Control<EditablePlan>;
  productId: string;
  planId: string;
  allPlans: ProductPlanDetailsJSON[];
  editing: boolean;
  onAcceptDrag?: VoidFunction;
}) => {
  const t = useText();
  const { components: options } = useProductComponentsListAPI({
    productId,
  });

  const productComponentsById = useMemo(
    () => keyBy(options ?? [], "id"),
    [options]
  );

  const {
    fields: components,
    append,
    remove,
    move,
    insert,
  } = useFieldArray({ control, name: "components", keyName: "key" });

  const allPlansById = useMemo(() => keyBy(allPlans, "id"), [allPlans]);

  const basePlanId = useWatch({ control, name: "base_plan_id" });
  const basePlanIds = useMemo(() => {
    const data: string[] = [];
    let planIdCursor = basePlanId;
    while (planIdCursor) {
      if (planIdCursor in data) {
        console.log(
          `Found recursive base plan traversal: ${planIdCursor} is already in ${basePlanIds}`
        );
        break;
      }
      data.push(planIdCursor);
      planIdCursor = allPlansById[planIdCursor]?.base_plan_id;
    }
    return data;
  }, [allPlansById, basePlanId]);

  // Recursively collect plan components from nested base plans.
  // Uniquely filter the duplicates based on the closest-distance base plan, which
  // is conveniently the order of basePlanIds.
  const unfurledBasePlanComponents = useMemo(
    () =>
      uniqBy(
        basePlanIds.map((id) => pluckPlanComponents(allPlansById[id])).flat(),
        "product_component_id"
      ),
    [allPlansById, basePlanIds]
  );

  const basePlanComponentLookup = useMemo(
    () => keyBy(unfurledBasePlanComponents, "product_component_id"),
    [unfurledBasePlanComponents]
  );

  const nonCountableBasePlanComponents = useMemo(
    () => unfurledBasePlanComponents.filter((c) => !c.is_countable),
    [unfurledBasePlanComponents]
  );

  const countableBasePlanComponents = useMemo(
    () =>
      keyBy(
        unfurledBasePlanComponents.filter((c) => c.is_countable),
        "id"
      ),
    [unfurledBasePlanComponents]
  );

  const componentOptions = useMemo(
    () =>
      excludeSelected(options ?? [], [
        ...components,
        ...nonCountableBasePlanComponents,
      ]).map((c) => ({
        ...c,
        override: Boolean(countableBasePlanComponents[c.id]),
      })),
    [
      options,
      components,
      countableBasePlanComponents,
      nonCountableBasePlanComponents,
    ]
  );

  useDragEndResponder(
    ({ reason, source, destination, draggableId }) => {
      if (reason !== "DROP" || !destination) return;

      switch (
        `${source.droppableId === planId}/${
          destination?.droppableId === planId
        }`
      ) {
        case "true/true":
          move(
            source.index,
            Math.min(destination.index, components.length - 1)
          );
          break;

        case "true/false":
          remove(source.index);
          break;

        case "false/true": {
          const { productComponentId, quantity } =
            deserializeFromId(draggableId);

          const existingIndex = findIndex(
            components,
            ({ product_component_id }) =>
              product_component_id === productComponentId
          );

          const protoComponent = productComponentsById[productComponentId];
          if (protoComponent) {
            const destIndex = Math.min(destination.index, components.length);
            insert(
              destIndex,
              toPlanComponent(protoComponent, quantity ?? null)
            );

            if (existingIndex !== -1) {
              remove(
                existingIndex >= destIndex ? existingIndex + 1 : existingIndex
              );
            }
          }
          break;
        }
      }
    },
    [move, remove, insert, planId, components, productComponentsById]
  );

  const renderClone = useCallback(
    (
      draggable: DraggableProvided,
      _: DraggableStateSnapshot,
      rubric: DraggableRubric
    ) => {
      const { index } = rubric.source;
      return (
        <PlanComponent
          ListItem={DragListItem}
          itemId={rubric.draggableId}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          component={components[index]!}
          {...{
            control,
            index,
            editing,
            highlightAsDuplicate: false,
            draggable,
          }}
        />
      );
    },
    [components, control, editing]
  );

  const renderNoMatches = useCallback(
    (input: string) => {
      const selected = uniqBy(
        [
          ...components.map((c) => omit(c, "description")),
          ...nonCountableBasePlanComponents.map(({ plan, ...c }) => ({
            ...c,
            description: `(part of the ${plan.name} plan)`,
          })),
        ],
        "product_component_id"
      );

      const matchingSelected = filterComponents(selected, input);

      return matchingSelected.length ? (
        <span className="flex flex-col gap-2">
          {matchingSelected.length && (
            <>
              <span className="text-sm">
                {t("components.already_included")}
              </span>
              {matchingSelected.map((c) => (
                <span key={c.id}>
                  <ComponentMenuItem item={c} />
                </span>
              ))}
            </>
          )}
        </span>
      ) : null;
    },
    [components, nonCountableBasePlanComponents, t]
  );

  return (
    <div
      className="flex flex-col flex-grow gap-y-1"
      data-has-plan-components={components.length > 0}
    >
      <BasePlan
        {...{
          control,
          planId,
          allPlans,
          editing,
          componentsCount: components.length,
        }}
      />
      <Droppable droppableId={planId} mode="virtual" renderClone={renderClone}>
        {(droppable, snapshot) => (
          <div
            className={cx("flex-grow flex flex-col", editing && "-mx-1")}
            ref={droppable.innerRef}
            {...droppable.droppableProps}
          >
            <ComponentsList
              {...{
                control,
                planId,
                components,
                basePlanComponentLookup,
                editing,
                remove,
                droppable,
              }}
              onAcceptDrag={onAcceptDrag ?? (() => {})}
              {...snapshot}
            >
              {editing ? (
                <Draggable
                  draggableId={`${planId}-component-select`}
                  index={components.length}
                  isDragDisabled={true}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={components.length ? "mt-4" : "mt-2"}
                    >
                      <PlanComponentsSelect
                        options={componentOptions}
                        onAdd={append}
                        placeholder={t("components.add_placeholder")}
                        renderNoMatches={renderNoMatches}
                      />
                    </div>
                  )}
                </Draggable>
              ) : (
                !components.length && <EmptyListPlaceholder />
              )}
            </ComponentsList>
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default PlanComponents;
