import React, { useRef, useMemo, memo } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import useResizeObserver from "@react-hook/resize-observer";

import * as echarts from "echarts/core";
import {
  type EChartsOption,
  type EChartsInitOpts,
} from "echarts/types/dist/shared";
import { type EChartsType } from "echarts/core";
import { forEach } from "lodash";

import { chartColors } from "@/components/insights/colorPicker";

import { THEME_NAME } from "./theme";
import { register } from "./components";

register();

type ChartEventHandler = (params: unknown, self: EChartsType) => void;

type CommonChartProps = {
  id: string;
  className?: string;
  renderer?: EChartsInitOpts["renderer"];
  on?: Record<string, ChartEventHandler>;
};

export const Chart = memo(
  <Options,>({
    id,
    className,
    renderer,
    on,
    ...props
  }: CommonChartProps & Options & EChartsOption) => {
    const chart = useRef<EChartsType>();
    const chartRef = useRef<HTMLDivElement>(null);
    useResizeObserver(chartRef, (entry) =>
      chart.current?.resize(entry.contentRect)
    );

    const colors = useMemo(() => chartColors(id), [id]);

    useDeepCompareEffect(() => {
      if (chartRef.current !== null) {
        const newChart = echarts.init(chartRef.current, THEME_NAME, {
          renderer: renderer ?? "svg",
        });
        chart.current = newChart;

        newChart.setOption({ ...props, color: colors });
        forEach(on, (handler, event) => {
          newChart.on(event, (params) => handler(params, newChart));
        });
      }

      return () => {
        chart.current?.dispose();
        chart.current = undefined;
      };
    }, [props, renderer, on, colors]);

    return <div ref={chartRef} {...{ className }} />;
  }
);
