import React, { PropsWithChildren } from "react";
import { Outlet, Link } from "react-router-dom";

// @ts-ignore
import { ReactComponent as Logo } from "@/assets/maca-logo.svg";

import { Navigation } from "@/components/Navigation";
import { useAuth } from "@/components/Auth";
import ProductTour from "@/components/product-tour/ProductTour";

import { useOnboardingLocalState } from "@/pages/onboarding/v2/OnboardingCheck";
import Search from "@/pages/insights/components/search";

const PageContainer = ({ children }: PropsWithChildren) => (
  <div className="flex flex-col min-h-screen w-screen selection:bg-brand-purple selection:text-text-bright">
    {children}
  </div>
);

export const NavPageLayout = ({ children }: PropsWithChildren) => {
  const [onboardingStatus] = useOnboardingLocalState();
  return (
    <PageContainer>
      <Navigation>
        <Search />
      </Navigation>
      <div className="flex flex-grow">{children || <Outlet />}</div>
      {onboardingStatus?.completedRequired && <ProductTour />}
    </PageContainer>
  );
};

export const ExternalPageLayout = ({ children }: PropsWithChildren) => (
  <PageContainer>
    <div className="container p-2 px-6">
      <Link to="/">
        <Logo className="h-12 fill-base-white" data-testid="logo" />
      </Link>
    </div>
    <div className="flex flex-grow p-12">{children || <Outlet />}</div>
  </PageContainer>
);

export const PageLayout = ({ children }: PropsWithChildren) => {
  const { isSignedIn } = useAuth();
  const Layout = isSignedIn ? NavPageLayout : ExternalPageLayout;
  return <Layout>{children}</Layout>;
};
