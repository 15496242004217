import React, {
  useRef,
  ReactNode,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";

import {
  MediaPlayer,
  MediaPlayerProps,
  MediaPlayButton,
  useMediaStore,
  MediaOutlet,
} from "@vidstack/react";

import {
  isHLSProvider,
  MediaProviderChangeEvent,
  MediaProviderSetupEvent,
} from "vidstack";

import { useTimeout } from "usehooks-ts";

import mux from "mux-embed";

import HLS from "hls.js";

import { format } from "date-fns";

import cx from "clsx";

import "vidstack/styles/base.css";

//@ts-ignore
import { ReactComponent as PlayButton } from "@/assets/icons/button-play.svg";
//@ts-ignore
import { ReactComponent as PauseIcon } from "@/assets/icons/pause-small.svg";

import { useAuth } from "@/components/Auth";

const muxDataEnvKey = process.env["REACT_APP_MUX_ENV_KEY"];

const TimeContainer = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div
    className={cx(
      "bg-bg-default rounded-md text-xs text-text-bright font-medium px-2 py-1.5",
      className
    )}
  >
    {children}
  </div>
);

const CurrentTime = ({ time, playing }: { playing: boolean; time: number }) => (
  <div className="flex items-center tabular-nums">
    <PauseIcon
      className={cx(
        "text-text-link/80 group-hover:text-text-bright",
        "state-transition transition-all",
        playing ? "opacity-100 w-4 aspect-square mr-1" : "opacity-0 w-0"
      )}
    />
    {format(time * 1000, "mm:ss")}
  </div>
);

const usePlayback = ({ prologuePause }: { prologuePause?: number }) => {
  const { buffered, ...props } = useMediaStore();
  const [prologue, setPrologue] = useState(Boolean(prologuePause));
  useTimeout(() => setPrologue(false), prologuePause ?? null);

  const loaded = buffered.length > 0 && !prologue;
  return { loaded, buffered, ...props };
};

const VideoPlayerControls = ({
  children,
  prologuePause,
}: {
  children?: ReactNode;
  prologuePause?: number;
}) => {
  const { loaded, started, ended, playing, duration, currentTime } =
    usePlayback({ prologuePause });

  return (
    <MediaPlayButton
      className={cx(
        "absolute inset-0 flex items-center justify-center bg-opacity-0 bg-bg-default overflow-hidden",
        "transition-colors duration-300 group-hover:group-data-[paused='true']:bg-opacity-20"
      )}
    >
      <PlayButton
        className={cx(
          "w-12 aspect-square opacity-0 state-transition transition-all drop-shadow-2xl",
          "group-data-[playing='true']:scale-[2] group-hover:scale-[1.2]",
          loaded &&
            "group-data-[paused='true']:opacity-60 group-hover:group-data-[paused='true']:opacity-90"
        )}
      />
      <div
        className={cx(
          "absolute inset-0 px-8 py-3 flex flex-col justify-end pointer-events-none select-none",
          "group-data-[playing='true']:opacity-0 state-transition transition-all",
          loaded
            ? "group-data-[playing='true']:translate-y-6"
            : "-translate-y-[37%]"
        )}
      >
        <div
          className={cx(
            "state-transition transition-scale origin-left",
            !loaded && "scale-[1.5]"
          )}
        >
          {children}
        </div>
      </div>
      {loaded && (duration > 0 || currentTime) && (
        <TimeContainer
          className={cx(
            "absolute right-3 bottom-3 transition-[transform, color, opacity] duration-300",
            "group-hover:-translate-y-1 group-hover:text-base-white",
            loaded ? "opacity-80" : "opacity-0"
          )}
        >
          {!started || ended ? (
            `${Math.round(duration)} sec`
          ) : (
            <CurrentTime time={currentTime} playing={playing} />
          )}
        </TimeContainer>
      )}
    </MediaPlayButton>
  );
};

const Video = ({ prologuePause }: { prologuePause?: number }) => {
  const { loaded } = usePlayback({ prologuePause });
  return (
    <MediaOutlet
      className={cx(
        "state-transition transition-opacity duration-500",
        loaded ? "opacity-100" : "opacity-0"
      )}
    />
  );
};

interface VideoMetadata {
  video_id: string;
  video_title: string;
}

type VideoPlayerProps = MediaPlayerProps & {
  playbackId?: string;
  metadata?: VideoMetadata;
  className?: string;
  prologuePause?: number;
  children?: ReactNode;
};

const srcUrl = (playbackId: string) =>
  `https://stream.mux.com/${playbackId}.m3u8`;

const VideoPlayer = ({
  playbackId,
  metadata,
  src,
  className,
  prologuePause,
  children,
  ...props
}: VideoPlayerProps) => {
  const { session } = useAuth();
  const initTime = useRef(null);

  const onProviderChange = useCallback((event: MediaProviderChangeEvent) => {
    const provider = event.detail;
    if (isHLSProvider(provider)) {
      provider.library = HLS;
      initTime.current = mux.utils.now();
    }
  }, []);

  const onProviderSetup = useCallback(
    (event: MediaProviderSetupEvent) => {
      const provider = event.detail;
      if (!isHLSProvider(provider)) return;

      mux.monitor(provider.video, {
        debug: process.env["NODE_ENV"] !== "production",
        hlsjs: provider.instance,
        Hls: provider.ctor,
        data: {
          env_key: muxDataEnvKey,
          player_init_time: initTime.current,
          viewer_user_id: session?.user?.id,
          video_id: playbackId ?? src,
          ...metadata,
        },
      });
    },
    [session?.user?.id, playbackId, src, metadata]
  );

  return (
    <MediaPlayer
      src={playbackId ? srcUrl(playbackId) : src}
      className={cx("relative group overflow-hidden", className)}
      {...{ onProviderChange, onProviderSetup, ...props }}
    >
      <Video {...{ prologuePause }} />
      <VideoPlayerControls {...{ prologuePause }}>
        {children}
      </VideoPlayerControls>
    </MediaPlayer>
  );
};

export default VideoPlayer;
