import React from "react";

import FormattedList from "@/components/FormattedList";
import { Text } from "@/components/hooks/useText";

export const ValueStats = ({
  stats,
  keyPrefix,
}: {
  stats: Record<string, number>;
  keyPrefix: string;
}) => (
  <FormattedList listStyle="narrow">
    {Object.entries(stats)
      .filter(([_, count]) => Boolean(count))
      .map(([key, count]) => (
        <span {...{ key }}>
          <Text i18nKey={`${keyPrefix}.${key}`} values={{ count }} />
        </span>
      ))}
  </FormattedList>
);

export const FieldStatsNote = ({
  stats,
  keyPrefix = "note",
}: {
  stats: Record<string, number>;
  keyPrefix?: string;
}) => (
  <Text
    i18nKey={`${keyPrefix}.field_stats`}
    values={{
      count: Math.max(...Object.values(stats)),
    }}
    components={[<ValueStats {...{ stats, keyPrefix }} />]}
  />
);
