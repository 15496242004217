import { useAPI } from "@/client/api";
import { useModelAPI } from "@/client/models-api";

export interface InvitationData {
  id: string;
  email: string;
  organization_id: string;
  sent_at: Date;
  status: string;
  expired: boolean;
}

const endpointBaseURL = "organization/invitations/";

export const useInvitationListAPI = () => {
  const { data, mutate } = useAPI<InvitationData[]>(endpointBaseURL);
  return { invitations: data, mutate };
};

export const useInviteUserAPI = ({ inviteId }: { inviteId?: string }) => {
  const { saveEntity } = useModelAPI<InvitationData>({
    entityId: inviteId,
    endpointURL: endpointBaseURL,
  });

  return {
    saveInvitation: saveEntity,
  };
};
