import React, { useEffect, useRef } from "react";
import { getBezierPath, type EdgeProps } from "@xyflow/react";
import cx from "clsx";

import { useAccuracyColor } from "./accuracy";

const setZIndex = (group: SVGGeometryElement | null, zIndex: number) => {
  const grandParent = group?.parentElement?.parentElement;
  if (grandParent) grandParent.style.zIndex = `${zIndex}`;
};

export type FieldConnectorProps = {
  sourceAccuracy?: number;
  highlighted?: boolean;
};

const FieldConnector = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  interactionWidth = 20,
  pathOptions,
  data,
}: EdgeProps<FieldConnectorProps>) => {
  const ref = useRef<SVGGeometryElement>(null);

  const [path] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    curvature: pathOptions?.curvature,
  });

  const { highlighted, sourceAccuracy } = data ?? {};
  const color = useAccuracyColor(
    sourceAccuracy ?? 0,
    highlighted ? "all" : "warning"
  );

  useEffect(
    () => setZIndex(ref.current, highlighted ? 1000 : 0),
    [highlighted]
  );

  return (
    <g ref={ref}>
      {interactionWidth && (
        <path
          d={path}
          fill="none"
          stroke="currentColor"
          strokeWidth={interactionWidth}
          className={cx(
            "react-flow__edge-interaction text-bg-l1",
            highlighted && "highlighted"
          )}
        />
      )}
      <path
        id={id}
        style={{ ...style, stroke: color?.rgb }}
        d={path}
        fill="none"
        className={cx("react-flow__edge-path", highlighted && "highlighted")}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
    </g>
  );
};

export default FieldConnector;
