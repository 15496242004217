import React, {
  useState,
  PropsWithChildren,
  useCallback,
  useMemo,
} from "react";

import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
  useMouseSensor,
  useTouchSensor,
} from "@hello-pangea/dnd";

import { keyBy } from "lodash";
import cx from "clsx";

import { createdAtSuffix } from "@/lib/date";

import { useTranslationScope } from "@/components/hooks/useText";
import {
  DndRespondersContext,
  DndRespondersContextType,
  useDragEndResponder,
} from "@/components/hooks/useDndResponder";

import {
  EditableCard,
  EditingButtons,
  CardMenu,
} from "@/components/EditableCard";

import TextInput from "@/components/input/TextInput";

// @ts-ignore
import { ReactComponent as PlusSign } from "@/assets/icons/big-plus.svg";

import { EntityId } from "@/client/models-api";

import { useProductContext } from "@/pages/products/ProductPageLayout";
import { useProductComponentsListAPI } from "@/pages/products/api";

import PlanComponents, { deserializeFromId } from "./components/PlanComponents";
import PlanPricing from "./components/PlanPricing";

import { useProductPlansetListAPI, useProductPlansetAPI } from "./models/api";
import {
  ProductPlanSetJSON,
  ProductPlanComponentJSON,
  toPlanComponent,
} from "./models/serialization";

import {
  EditablePlan,
  normalizeComponents,
  normalizePlanPricing,
  normalizeBeforeSave,
  productPlansSortOrder,
} from "./models/planset";

const PlansGrid = ({ children, ...props }: PropsWithChildren) => {
  const [isDragging, setIsDragging] = useState(false);
  const [responders] = useState<DndRespondersContextType>({
    onDragEnd: [],
  });

  const handleDragStart = useCallback(() => setIsDragging(true), []);
  const handleDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      setIsDragging(false);
      responders.onDragEnd.forEach((r) => r(result, provided));
    },
    [responders.onDragEnd]
  );

  return (
    <div className={cx("flex flex-row", isDragging && "pointer-events-none")}>
      <div
        className="grid grid-flow-col gap-4 auto-cols-[1fr] overflow-x-auto"
        {...props}
      >
        <DndRespondersContext.Provider value={responders}>
          <DragDropContext
            enableDefaultSensors={false}
            sensors={[useMouseSensor, useTouchSensor]}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            {children}
          </DragDropContext>
        </DndRespondersContext.Provider>
      </div>
    </div>
  );
};

//@ts-ignore
const PlanHeader = ({ plan, editing, register, isSubmitting }) => (
  <div className="flex flex-col gap-1">
    <div className="flex flex-col text-2xl font-medium leading-tight text-text-bright">
      {editing ? (
        <TextInput
          {...register("name")}
          maxLength={128}
          placeholder="Plan name"
          className="py-2 px-2 -mx-1 text-center"
          disabled={isSubmitting}
          autoSelect
        />
      ) : (
        <h2 className="py-2 text-center select-none border border-transparent break-words input-like-tracking">
          {plan.name}
        </h2>
      )}
    </div>
    <div className="px-1 flex flex-col items-stretch">
      {editing ? (
        <TextInput
          {...register("description")}
          maxLength={512}
          minRows={plan.description ? undefined : 2}
          placeholder="Plan description"
          className="px-2 py-1 -mx-2 text-center"
          disabled={isSubmitting}
        />
      ) : (
        <div
          className={cx(
            "text-text-default text-center whitespace-pre-line break-words",
            "border border-transparent py-1 input-like-tracking"
          )}
        >
          {plan.description}
        </div>
      )}
    </div>
  </div>
);

//@ts-ignore
const PlanMenu = ({ onEdit, onDelete }) => (
  <CardMenu
    className="absolute right-2 top-2"
    items={[
      {
        item: "Edit plan",
        action: () => onEdit(),
      },
      {
        item: "Delete plan",
        action: onDelete && (() => onDelete()),
        danger: true,
      },
    ]}
  />
);

const PlanColumn = ({
  elementId,
  productId,
  allPlans,
  productPlanset,
  discardDraft,
}: {
  elementId?: string;
  productId: string;
  allPlans: ProductPlanSetJSON[];
  productPlanset: ProductPlanSetJSON;
  discardDraft?: (id: string) => void;
}) => {
  let { components, prices, ...plan } = productPlanset;

  const { t, TranslationScope } = useTranslationScope("common", {
    keyPrefix: "plans",
  });

  const isDraft = Boolean(discardDraft);
  const { planset, createOrPatchPlanset, deletePlanset } = useProductPlansetAPI(
    {
      productId,
      plansetId: isDraft ? undefined : plan.id,
    }
  );

  plan = planset || plan;
  components = normalizeComponents(planset?.components || components || []);
  prices = normalizePlanPricing(plan, planset?.prices || prices);

  const onDelete = !components?.length ? deletePlanset : undefined;

  return (
    <EditableCard
      className={(editing) =>
        cx(
          "relative flex flex-col justify-between bg-bg-l2 rounded-md px-4 py-10 max-w-[19rem] min-w-[16rem] min-h-[36rem]",
          "border-2 gap-8 overflow-y-clip",
          editing ? "border-transparent pb-24" : "border-transparent"
        )
      }
      entity={{ ...plan, components, prices } as EditablePlan}
      onSave={async (entity) => {
        await createOrPatchPlanset(normalizeBeforeSave(entity), {
          forceSync: true,
        });
      }}
      {...{ discardDraft, elementId }}
    >
      {({
        editing,
        setEditing,
        state,
        register,
        registerController,
        control,
        setValue,
        cancelEditing,
        isSubmitting,
      }) => (
        <TranslationScope>
          {!editing && (
            <PlanMenu onEdit={() => setEditing(true)} onDelete={onDelete} />
          )}
          <div className="flex flex-col gap-6">
            <PlanHeader {...{ plan, editing, register, isSubmitting }} />
            <PlanPricing
              {...registerController("prices")}
              {...{ editing, register, setValue, t }}
              pricingUnit={state.pricing_unit}
              pricing={state.prices}
            />
          </div>
          <PlanComponents
            {...registerController("components")}
            {...registerController("base_plan_id")}
            {...{ productId, allPlans, editing }}
            planId={plan.id}
            onAcceptDrag={() => setEditing(true)}
          />
          {editing && (
            <div className="absolute bottom-0 right-0 left-0 pr-4 pb-4 pt-6 bg-gradient-to-t from-bg-l2/90 from-70%">
              <EditingButtons
                {...{ control, cancelEditing, isSubmitting, isDraft, t }}
                required={["name"]}
              />
            </div>
          )}
        </TranslationScope>
      )}
    </EditableCard>
  );
};

const newPlan = ({
  components,
}: {
  components?: ProductPlanComponentJSON[];
} = {}): ProductPlanSetJSON => ({
  id: EntityId.new(),
  name: "Untitled plan",
  description: "",
  is_active: true,
  components: components || [],
  prices: [],
});

const NewPlanPlaceholder = ({
  draftsCount,
  productId,
  addPlan,
}: {
  draftsCount: number;
  productId: string;
  addPlan: (plan: ProductPlanSetJSON) => void;
}) => {
  const droppableId = "new-plan-placeholder";
  const { components: productComponents } = useProductComponentsListAPI({
    productId,
  });

  const productComponentsById = useMemo(
    () => keyBy(productComponents ?? [], "id"),
    [productComponents]
  );

  useDragEndResponder(
    ({ reason, destination, draggableId }) => {
      if (reason !== "DROP" || destination?.droppableId !== droppableId) return;

      const { productComponentId, quantity } = deserializeFromId(draggableId);
      const protoComponent = productComponentsById[productComponentId];
      if (protoComponent) {
        addPlan(
          newPlan({
            components: [toPlanComponent(protoComponent, quantity ?? null)],
          })
        );
      }
    },
    [addPlan, productComponentsById]
  );

  return (
    <Droppable droppableId={droppableId}>
      {(droppable, { isDraggingOver }) => {
        return (
          <div
            id={
              draftsCount
                ? `new-plan-placeholder-${draftsCount}`
                : "new-plan-placeholder"
            }
            className={cx(
              "relative flex flex-col items-center justify-between rounded-md p-4 w-[19rem] min-h-[23rem]",
              "border-2 group hover:border-border-bright hover:shadow-lg cursor-pointer",
              isDraggingOver ? "border-border-bright" : "border-border-default"
            )}
            onClick={() => addPlan(newPlan())}
            ref={droppable.innerRef}
            {...droppable.droppableProps}
          >
            {droppable.placeholder}
            <div className="flex flex-col flex-grow items-center justify-center">
              <PlusSign
                className={cx(
                  "w-24 group-hover:stroke-text-link",
                  isDraggingOver ? "stroke-text-link" : "stroke-text-muted"
                )}
              />
            </div>
            <span
              className={cx(
                "font-medium pb-2 group-hover:text-text-link",
                isDraggingOver ? "text-text-link" : "text-text-muted"
              )}
            >
              Create a new plan
            </span>
          </div>
        );
      }}
    </Droppable>
  );
};

const DraftPlans = ({
  productId,
  allPlans,
  recentlySaved,
}: {
  productId: string;
  allPlans: ProductPlanSetJSON[];
  recentlySaved: string[];
}) => {
  const [drafts, setDrafts] = useState<ProductPlanSetJSON[]>([]);
  const discardDraft = (planId: string) =>
    setDrafts(drafts.filter((plan) => plan.id !== planId));

  return (
    <>
      {drafts
        .filter((plan) => !recentlySaved.includes(plan.id))
        .map((plan, i) => (
          <PlanColumn
            key={plan.id}
            elementId={`draft-plan-${i + 1}`}
            {...{
              productId,
              allPlans,
              productPlanset: plan,
              discardDraft,
            }}
          />
        ))}

      <NewPlanPlaceholder
        draftsCount={drafts.length}
        productId={productId}
        addPlan={(plan) => setDrafts([...drafts, plan])}
      />
    </>
  );
};

const planElementId = ({ created_at }: ProductPlanSetJSON, last: boolean) =>
  last ? `latest-plan${createdAtSuffix(created_at)}` : undefined;

const ProductPlans = () => {
  const { product } = useProductContext();
  const plansJSON = useProductPlansetListAPI({
    productId: product.id,
  });

  const { components } = useProductComponentsListAPI({
    productId: product.id,
  });

  if (!plansJSON) return null;

  const plansets = [...plansJSON].sort(productPlansSortOrder);
  return (
    <PlansGrid
      data-plans-count={plansets.length}
      data-components-count={components?.length ?? 0}
    >
      {plansets.map((plan, i) => (
        <PlanColumn
          key={plan.id}
          elementId={planElementId(plan, i === plansets.length - 1)}
          productId={product.id}
          allPlans={plansets}
          productPlanset={plan}
        />
      ))}
      <DraftPlans
        productId={product.id}
        allPlans={plansets}
        recentlySaved={(plansets as any[]) // eslint-disable-line @typescript-eslint/no-explicit-any
          .map(({ _previousId }) => _previousId)
          .filter(Boolean)}
      />
    </PlansGrid>
  );
};

export default ProductPlans;
