import { type Node, type Edge } from "@xyflow/react";

import { flatMap, keyBy } from "lodash";

import { type FieldGroupDef, type Dependency } from "./types";

import { type FieldSlotProps } from "./FieldSlot";
import { type FieldGroupProps } from "./FieldGroup";
import { type FieldConnectorProps } from "./FieldConnector";

import nodeLayout, { type NodeShape } from "./nodeLayout";

import { FIELD_GROUP_MARGIN, FIELD_GROUP_WIDTH } from "./FieldGroup";
import { FIELD_SLOT_HEIGHT } from "./FieldSlot";

const groupLayout = (id: string, nodesLayout: Record<string, NodeShape>) => {
  const layout = nodesLayout[id];
  return {
    position: { x: layout?.x ?? 0, y: layout?.y ?? 0 },
    style: {
      height: layout?.height ?? 100,
      width: layout?.width ?? 100,
    },
  };
};

export type DataflowNode =
  | Node<FieldGroupProps, "fieldgroup">
  | Node<FieldSlotProps, "fieldslot">;

export type DataflowEdge = Edge<FieldConnectorProps>;

const dataflowNodes = (
  nodes: FieldGroupDef[],
  deps: Dependency[],
  nodeLayout: Record<string, NodeShape>
): DataflowNode[] => {
  const sources = keyBy(deps, "source");
  const targets = keyBy(deps, "target");
  return flatMap(nodes, ({ id: groupId, fields, ...props }) => [
    {
      type: "fieldgroup",
      id: groupId,
      data: props,
      ...groupLayout(groupId, nodeLayout),
    },
    ...fields.map(
      ({ id, name, accuracy }, i) =>
        ({
          type: "fieldslot",
          id,
          data: {
            id,
            name,
            accuracy,
            outPort: id in sources,
            inPort: id in targets,
          },
          parentNode: groupId,
          position: {
            x: FIELD_GROUP_MARGIN.left,
            y: FIELD_GROUP_MARGIN.top + i * FIELD_SLOT_HEIGHT,
          },
          style: {
            width:
              FIELD_GROUP_WIDTH -
              (FIELD_GROUP_MARGIN.left + FIELD_GROUP_MARGIN.right),
          },
        } as DataflowNode)
    ),
  ]);
};

const dataFlowEdges = (
  deps: Dependency[],
  nodes: FieldGroupDef[]
): DataflowEdge[] => {
  const fieldset = keyBy(
    flatMap(nodes, ({ fields }) => fields),
    "id"
  );

  return deps.map(({ source, target }) => ({
    id: `${source}-${target}`,
    type: "fielddependency",
    source,
    target,
    data: {
      sourceAccuracy: fieldset[source]?.accuracy,
    },
  }));
};

const maxSize = (layout: Record<string, NodeShape>, padding: number) => {
  const shapes = Object.values(layout);
  return {
    width:
      Math.max(...shapes.map(({ x, width }) => (x ?? 0) + (width ?? 0))) +
      padding,
    height:
      Math.max(...shapes.map(({ y, height }) => (y ?? 0) + (height ?? 0))) +
      padding,
  };
};

const dataflowLayout = async (nodes: FieldGroupDef[], deps: Dependency[]) => {
  const layout = await nodeLayout(nodes, deps);
  if (!layout) return { nodes: [], edges: [], size: { width: 0, height: 0 } };

  return {
    nodes: dataflowNodes(nodes, deps, layout),
    edges: dataFlowEdges(deps, nodes),
    size: maxSize(layout, 32),
  };
};

export default dataflowLayout;
