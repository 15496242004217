import React, { useCallback, useState } from "react";
import * as Tt from "@radix-ui/react-tooltip";

import cx from "clsx";

//@ts-ignore
import { ReactComponent as QCIcon } from "@/assets/icons/quality-control.svg";

import ChartAccuracy from "./ChartAccuracy";

const QCBadge = ({
  chartId,
  className,
}: {
  chartId: string;
  className?: string;
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((o) => !o), []);
  return (
    <Tt.Root {...{ open, delayDuration: 250 }}>
      <Tt.Portal>
        <Tt.Content
          className="bg-bg-l250/[0.98] rounded-md p-4 z-10 drop-shadow-lg shadow-bg-default"
          align="end"
          alignOffset={8}
        >
          <ChartAccuracy {...{ chartId }} />
        </Tt.Content>
      </Tt.Portal>
      <Tt.Trigger asChild>
        <div
          onClick={toggleOpen}
          className={cx(
            "absolute group right-1 bottom-1 text-text-muted hover:text-text-default p-2 cursor-pointer",
            className
          )}
        >
          <QCIcon className="h-4" />
        </div>
      </Tt.Trigger>
    </Tt.Root>
  );
};

export default QCBadge;
