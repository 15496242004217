import React from "react";
import { useTranslation } from "react-i18next";
import { useHotkeys } from "react-hotkeys-hook";
import { dropRight, last } from "lodash";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";

import { isMacOS } from "@/lib/user-agent";
import { Text } from "@/components/hooks/useText";

type ModifierKey = "mod" | "alt" | "shift";

const modifierMap: Record<ModifierKey, string> = {
  mod: isMacOS() ? "⌘" : "⌃",
  alt: "⌥",
  shift: "⇧",
};

type KeyboardShortcut<S extends string> = S extends `${ModifierKey}+${string}`
  ? S
  : "invalid shortcut";

const parseKbdShorcut = <S extends string>(
  shortcut: KeyboardShortcut<S>
): { modifiers: ModifierKey[]; key: string } => {
  const parts = (shortcut as string).split("+");
  return {
    modifiers: dropRight(parts, 1) as ModifierKey[],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    key: last(parts)!,
  };
};

const KbdShortcut = <S extends string>({
  shortcut,
}: {
  shortcut: KeyboardShortcut<S>;
}) => {
  const { t } = useTranslation("common", { keyPrefix: "keyboard_shortcuts" });
  const { modifiers, key } = parseKbdShorcut(shortcut);
  const platform = isMacOS() ? "macos" : "other";
  return (
    <kbd className="font-ui text-text-muted font-medium tracking-[-0.1em] select-none">
      <abbr
        title={[
          ...modifiers.map((m) =>
            t([`modifiers.${m}.${platform}`, `modifiers.${m}`])
          ),
          key,
        ].join("+")}
        className="no-underline"
      >
        {modifiers.map((m) => modifierMap[m])}
        <span className="pl-0.5">{key}</span>
      </abbr>
    </kbd>
  );
};

const SearchButton = <S extends string>({
  onClick,
  shortcut,
}: {
  onClick: () => void;
  shortcut: KeyboardShortcut<S>;
}) => {
  useHotkeys(shortcut, (event) => {
    onClick();
    event.preventDefault();
  });

  return (
    <button
      className={cx(
        "group button-base flex items-center justify-stretch bg-bg-l3 border border-transparent rounded-md px-3 py-2 gap-x-3",
        "hover:bg-bg-l4 md:w-[13rem] lg:w-[24rem]"
      )}
      onClick={onClick}
    >
      <SearchIcon className="w-5 grow-0 shrink-0 text-text-muted group-hover:text-text-default" />
      <span className="flex justify-between grow gap-x-4">
        <span className="text-text-default hidden md:inline-block lg:hidden">
          <Text i18nKey="search.placeholder.short" />
        </span>
        <span className="text-text-default hidden lg:inline-block">
          <Text i18nKey="search.placeholder.long" />
        </span>
        <span className="self-end">
          <KbdShortcut {...{ shortcut }} />
        </span>
      </span>
    </button>
  );
};

export default SearchButton;
