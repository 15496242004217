import React from "react";

import { useAPI } from "@/client/api";
import useText, { TranslationScope } from "@/components/hooks/useText";

// @ts-ignore
import { ReactComponent as HubSpotLogo } from "@/assets/logos/hubspot.svg";
// @ts-ignore
import { ReactComponent as CircleCheck } from "@/assets/icons/circle-check.svg";
import SettingsCard from "./SettingsCard";

interface HubSpotInstallationJSON {
  url: string;
  state: string;
  updated_at: string;
  hub_domain: string;
}

export interface HubSpotConfig {
  url: string;
  state: string;
  updatedAt?: Date;
  hubDomain: string;
}

export const useHubSpotInstallAPI = () => {
  const endpoint = "hubspot/install/";
  const { data } = useAPI<HubSpotInstallationJSON>(endpoint);
  if (!data) return { url: "", state: "", connected: false, hubDomain: "" };
  const { url, state, updated_at, hub_domain } = data;
  return {
    url,
    state,
    hubDomain: hub_domain,
    updatedAt: new Date(updated_at),
  };
};

export const HubSpotInstallation = ({
  hubDomain,
  updatedAt,
}: {
  hubDomain: string;
  updatedAt?: Date;
}) => {
  const t = useText();
  return (
    <div className="grow">
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="text-md mx-1 grow cursor-default">{hubDomain}</div>
        <div className="flex-none">
          <button
            disabled={true}
            className="button-base button-primary px-4 py-1.5"
          >
            <span className="flex text-text-success gap-2">
              <CircleCheck className="w-5" />
              {t("status.connected")}
            </span>
          </button>
        </div>
      </div>
      <div className="text-sm text-text-muted mx-1 cursor-default">
        {t("footer.connected", {
          updatedAt,
          formatParams: {
            updatedAt: { dateStyle: "medium", timeStyle: "long" },
          },
        })}
      </div>
    </div>
  );
};

export const HubSpotInstallLink = ({
  url,
  className,
}: {
  url: string;
  className: string;
}) => {
  const t = useText();
  return (
    <a className={className} href={url} target="_blank" rel="noreferrer">
      {t("action.connect")}
    </a>
  );
};

const HubSpotSettings = () => {
  const config: HubSpotConfig = useHubSpotInstallAPI();
  const connected = !!config.hubDomain;
  return (
    <SettingsCard unsavedChanges={false}>
      {connected ? (
        <div className="flex flex-row gap-2 items-center">
          <HubSpotInstallation
            hubDomain={config.hubDomain}
            updatedAt={config.updatedAt}
          />
        </div>
      ) : (
        <div className="flex flex-row gap-2 items-center grow">
          <HubSpotInstallLink
            url={config.url}
            className="button-base py-1.5 button-primary px-4"
          />
        </div>
      )}
    </SettingsCard>
  );
};

const HubSpot = () => (
  <TranslationScope keyPrefix="hubspot">
    <div className="flex bg-bg-l2 rounded-md p-4 gap-4">
      <HubSpotLogo className="w-14 p-1 self-start" />
      <HubSpotSettings />
    </div>
  </TranslationScope>
);

export default HubSpot;
