import React, { PropsWithChildren } from "react";
import { Trans, useTranslation } from "react-i18next";

import FormattedList from "@/components/FormattedList";

import {
  PricingType,
  PricingFormula,
  ProductPlanPriceTierJSON,
  ProductPlanPricingJSON,
} from "../models/serialization";

import { isTiered, isNilPrice, isFullRangeTier } from "../models/pricing-plan";

import { TFunction } from "i18next";

const Em = ({ children }: PropsWithChildren) => (
  <span className="font-semibold">{children}</span>
);

const formatPrice = (price: number | null | undefined, t: TFunction) =>
  isNilPrice(price) ? t("custom_price") : t("price_amount", { price });

const TieredPriceClause = ({
  price,
  unit,
  perUnitPricing,
  formatKey,
  t,
}: {
  price: ProductPlanPriceTierJSON;
  unit?: string;
  perUnitPricing: boolean;
  formatKey?: string;
  t: TFunction;
}) => {
  const amount = formatPrice(price.tier_price, t);
  return (
    <Trans
      i18nKey="tiered"
      t={t}
      values={{
        price: perUnitPricing
          ? t("unit_price", { amount, unit })
          : t("flat_price", { amount }),
        amount: t(
          formatKey ?? (isFullRangeTier(price) ? "count_interval" : "count_up"),
          price
        ),
        unit,
      }}
      components={{ em: <Em /> }}
    />
  );
};

interface PricingProps {
  pricing: ProductPlanPricingJSON;
  pricingUnit: string;
  t: TFunction;
}

const TieredPricing = ({ pricing, pricingUnit, t }: PricingProps) => (
  <FormattedList listType="conjunction">
    {(pricing.tiers ?? []).map((tieredPrice, i) => (
      <TieredPriceClause
        key={i}
        formatKey={
          i === 0 && !isNilPrice(tieredPrice.upper_bound)
            ? `first_tier_${
                pricing.pricing_formula === PricingFormula.TIERED
                  ? "tiered"
                  : "volume"
              }`
            : undefined
        }
        price={tieredPrice}
        unit={
          pricing.pricing_type === PricingType.USAGE
            ? pricing.usage_metric_name
            : pricingUnit
        }
        perUnitPricing={pricing.pricing_formula !== PricingFormula.STAIRSTEP}
        t={t}
      />
    ))}
  </FormattedList>
);

const singleRatePrice = (
  pricing: ProductPlanPricingJSON,
  pricingUnit: string,
  t: TFunction
) => {
  if (
    isNilPrice(pricing.default_amount) &&
    isNilPrice(pricing.default_percent_rate)
  )
    return t("custom_price");

  const amount = formatPrice(pricing.default_amount, t);

  if (pricing.pricing_type === PricingType.UNIT)
    return t("unit_price", { amount, unit: pricingUnit });

  if (pricing.pricing_type === PricingType.FLAT_RATE)
    return t("flat_price", { amount });

  if (pricing.pricing_type === PricingType.USAGE) {
    const percent_rate = t("percent_rate", {
      percent: pricing.default_percent_rate,
    });
    return t("usage_price", {
      percent_rate,
      metric: pricing.usage_metric_name,
    });
  }

  return t("custom_price");
};

const NonTieredPricing = ({ pricing, pricingUnit, t }: PricingProps) => (
  <Trans
    i18nKey="non_tiered"
    t={t}
    values={{ price: singleRatePrice(pricing, pricingUnit, t) }}
    components={{ em: <Em /> }}
  />
);

const PricingPlanSummary = ({
  planName,
  pricingUnit,
  pricing,
}: {
  planName: string;
  pricingUnit: string;
  pricing: ProductPlanPricingJSON;
}) => {
  const { t } = useTranslation("common", {
    keyPrefix: "plans.pricing.summary",
  });

  const Pricing = isTiered(pricing) ? TieredPricing : NonTieredPricing;

  return (
    <Trans
      i18nKey="template"
      t={t}
      values={{ planName, billingPeriod: pricing.billing_period }}
      components={{
        em: <Em />,
        pricing: <Pricing {...{ pricing, pricingUnit, t }} />,
      }}
    />
  );
};

export default PricingPlanSummary;
