import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardActions, CardContent, Stack } from "@mui/material";

import MacaButton from "@/components/Button";

import { PriceRuleType, IPriceRuleBase } from "./models/price-rules";

export type PriceRuleTemplateCardProps = {
  ruleTemplate: IPriceRuleBase;
  onClick: (type: PriceRuleType) => void;
};

export const PriceRuleTemplateCard: React.FC<PriceRuleTemplateCardProps> = ({
  ruleTemplate,
  onClick,
}) => {
  const { t } = useTranslation("price-rules", { keyPrefix: "rule" });
  const { type } = ruleTemplate;
  const disabled = false;

  return (
    <Card
      sx={{
        minHeight: "250px",
        padding: "16px",
        borderRadius: "8px",
        display: "flex",
      }}
    >
      <Stack
        display={"flex"}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
      >
        <CardContent
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0px",
            paddingBottom: "16px",
          }}
        >
          <Stack
            display={"flex"}
            direction={"column"}
            width={"100%"}
            height={"100%"}
            gap={2}
          >
            <h2 className="text-lg text-text-bright font-semibold leading-tight">
              {t(`type.${type}`)}
            </h2>
            <p className="text-sm">
              <span className="text-text-bright font-semibold">Example: </span>
              <span className="text-text-default">{t(`example.${type}`)}</span>
            </p>
          </Stack>
        </CardContent>
        <CardActions sx={{ padding: "0px" }}>
          <div className="w-full flex flex-row justify-end">
            <MacaButton
              variant="contained"
              text={disabled ? "Coming soon" : "Create rule"}
              onClick={() => onClick(ruleTemplate.type)}
              bgColor={"surface"}
              disabled={disabled}
            />
          </div>
        </CardActions>
      </Stack>
    </Card>
  );
};

export default PriceRuleTemplateCard;
