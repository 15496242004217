import React from "react";

import { ProductTourStepDef } from "./types";

const steps: ProductTourStepDef[] = [
  {
    route: "/products",
    target: "[data-product-count='0'] #new-product-placeholder",
    advanceOn: { selector: "#draft-product-1" },
    content: (
      <>
        👋 Welcome to Maca! Now that you're onboarded, let's get you started in
        making your first pricing plan. First, you'll need to define the product
        you're selling.
      </>
    ),
  },
  {
    route: "/products",
    target: "[data-product-count='0'] #draft-product-1",
    advanceOn: { selector: "#latest-product-just-now" },
    content: (
      <>
        Give your product a name and an optional description, and click "Create
        product" when you're ready.
      </>
    ),
  },
  {
    route: "/products",
    target: (context) =>
      context && "entityid" in context
        ? `[data-entityid="${context["entityid"]}"]`
        : null,
    content: (
      <>
        🎉 Nice job! Let's add some features and a pricing plan for this
        product. Click on the product card to go to product details.
      </>
    ),
  },
  {
    route: "/products",
    target: `[data-entity-type="product"][data-editing='false'][data-plans-count="0"][data-components-count="0"]`,
    content: (
      <>
        Let's add some features and a pricing plan for this product. Click on
        the product card to go to product details.
      </>
    ),
  },
  {
    route: "/products",
    target: `[data-entity-type="product"][data-editing='false'][data-components-count="0"]:not([data-plans-count="0"])`,
    content: (
      <>
        Let's populate this product with a few components. Click on the product
        card to go to product details.
      </>
    ),
  },
  {
    route: "/products",
    target: `[data-entity-type="product"][data-editing='false'][data-plans-count="0"]:not([data-components-count="0"])`,
    content: (
      <>
        Let's define a pricing plan for this product. Click on the product card
        to go to product details.
      </>
    ),
  },
  {
    route: "/products/:productId/plans",
    target: "#product-tabs",
    preconditions: [
      "[data-components-count='0']",
      { not: "[data-editing='true']" },
    ],
    beacon: { placement: "right-start", margin: { x: 32, y: 0 } },
    advanceOn: { route: "/products/:productId/components" },
    content: (
      <>
        Click on the "Components" tab to start building this product's feature
        catalog.
      </>
    ),
  },
  {
    route: "/products/:productId/components",
    target: `#new-component-placeholder`,
    preconditions: ["[data-components-count='0']"],
    beacon: { placement: "bottom" },
    advanceOn: { selector: "#draft-component-1" },
    content: (
      <>
        Components are the building blocks of pricing plans. List all product
        features here as they exist on your company's pricing page.
      </>
    ),
  },
  {
    route: "/products/:productId/components",
    target: "#draft-component-1",
    preconditions: ["[data-components-count='0']"],
    content: (
      <>
        Give your feature a name and an optional description. If the feature
        describes a countable subject, such as the number of collaborators or
        the file upload limit, click the "Countable" toggle. Click "Create
        component" when you're ready.
      </>
    ),
  },
  {
    route: "/products/:productId/components",
    target: "#product-component-list",
    beacon: { placement: "top" },
    spotlight: { elements: ["#product-tabs", "#product-component-list"] },
    preconditions: [
      { not: "[data-components-count='0']" },
      "[data-plans-count='0']",
    ],
    content: (
      <>
        Continue adding product features as they exist on your company's pricing
        page. When done, click on the "Plans" tab to move on to building a
        pricing plan.
      </>
    ),
  },
  {
    route: "/products/:productId/plans",
    target: `#new-plan-placeholder`,
    preconditions: [
      "[data-plans-count='0']",
      { not: "[data-components-count='0']" },
    ],
    advanceOn: { selector: "#draft-plan-1" },
    content: (
      <>
        Click on the plan placeholder to start defining a pricing plan for this
        product.
      </>
    ),
  },
  {
    route: "/products/:productId/plans",
    target: "[data-entityid][data-editing='true']",
    preconditions: [{ not: "[data-entityid] [data-price-amount]" }],
    advanceOn: { selector: "#plan-price-drawer" },
    content: (
      <>
        Give your plan a name, then click on one of the dollar signs to define
        the plan's annual or monthly pricing.
      </>
    ),
  },
  {
    route: "/products/:productId/plans",
    target: "#plan-price-drawer",
    beacon: { placement: "left", zIndex: 10000 },
    spotlight: { padding: 0 },
    advanceOn: { selector: "#plan-price-drawer-closed" },
    content: (
      <>
        Choose the pricing option that best fit your needs and set your plan's
        price. When you're done, click anywhere outside the sidebar to return to
        the plan.
      </>
    ),
  },
  {
    route: "/products/:productId/plans",
    target: "#draft-plan-1",
    preconditions: [
      "#draft-plan-1 [data-price-amount]",
      "[data-plans-count='0']",
      "[data-components-count='0']",
      { not: "#plan-price-drawer" },
    ],
    advanceOn: { selector: "#latest-plan-just-now" },
    content: <>🚀 Nice work! Click "Create plan" to save your changes.</>,
  },
  {
    route: "/products/:productId/plans",
    target: `[data-entityid]`,
    preconditions: [
      "[data-plans-count='1']",
      { not: "[data-components-count='0']" },
      "[data-entityid] [data-has-plan-components='false']",
    ],
    advanceOn: { selector: "[data-entityid][data-editing='true']" },
    content: <>Double-click on the plan to start adding plan components.</>,
  },

  {
    route: "/products/:productId/plans",
    target: "[data-entityid][data-editing='true']",
    preconditions: [
      "[data-entityid] [data-price-amount]",
      { not: "[data-components-count='0']" },
      { not: "#plan-price-drawer" },
    ],
    advanceOn: { selector: "#latest-plan-just-now" },
    content: (
      <>
        🚀 Nice work! Now use the "Add plan component" field to populate your
        plan with features you've added on the "Components" tab. You can use
        drag & drop to reorder the components. When you're done, click "Create
        plan" to save your changes.
      </>
    ),
  },
  {
    route: "/products/:productId/plans",
    target: (context) =>
      context && "entityid" in context
        ? `[data-plans-count='1'] [data-entityid="${context["entityid"]}"][data-editing='false']`
        : null,
    preconditions: [
      "[data-entityid] [data-has-plan-components='true']",
      "[data-entityid] [data-price-amount]",
    ],
    beacon: false,
    content: (
      <>
        <h1 className="text-xl font-medium">
          🎉 Congrats on defining your first plan!
        </h1>
        <p>
          Well done! You are well on your way to becoming a Maca expert. If
          there was anything along the way that you found confusing or
          difficult, please{" "}
          <a
            href="mailto:help+onboarding@maca.io?subject=Product%20tour%20feedback"
            className="text-text-link underline"
          >
            let us know
          </a>{" "}
          — we love user feedback!
        </p>
      </>
    ),
  },
];

export default steps;
