import React from "react";

import { useParams } from "react-router-dom";

import GradientHero from "@/components/GradientHero";

import { Text, TranslationScope } from "@/components/hooks/useText";

import { ToC, useToC, type ToCEntry } from "../components/ToC";
import { AnalysisBody } from "../components/AnalysisDoc";

import { useReportData } from "./api";

const ReportHeader = ({
  name,
  publish_date,
}: {
  name: string;
  publish_date: Date;
}) => (
  <div className="@container relative flex flex-col gap-4 px-10 pt-6 pb-10 overflow-hidden">
    <div className="absolute z-0 left-0 -bottom-10 @lg:-bottom-10 right-0 @lg:right-20">
      <GradientHero className="w-[200%] @sm:w-full" />
    </div>
    <div className="z-[1] text-text-default">
      <Text i18nKey="publish_date" values={{ publish_date }} />
    </div>
    <h1 className="z-[1] text-base-white text-5xl font-bold">{name}</h1>
  </div>
);

const ReportToC = ({ tocEntries }: { tocEntries: ToCEntry[] }) =>
  tocEntries.length ? (
    <div className="pr-6">
      <nav className="sticky top-navbar-height flex flex-col text-text-bright px-4 py-6 gap-5">
        <div className="font-semibold leading-tight">In this report</div>
        <ToC {...{ tocEntries }} />
      </nav>
    </div>
  ) : null;

export const Report = () => {
  const reportId = useParams()["reportId"];
  const reportData = useReportData(reportId);
  const { contentRef, tocEntries } = useToC({ depth: 3 });
  return reportData ? (
    <TranslationScope ns="insights" keyPrefix="reports.report">
      <div className="flex gap-4 my-8 mx-auto opacity-0 animate-fade-in">
        <main className="flex flex-col w-full max-w-3xl bg-bg-l2 rounded-lg">
          <ReportHeader {...reportData} />
          <hr className="border-border-default" />
          <AnalysisBody
            className="py-8"
            ref={contentRef}
            {...reportData}
            embedsDataDir={`__reports__/${reportId}`}
          />
        </main>
        <ReportToC {...{ tocEntries }} />
      </div>
    </TranslationScope>
  ) : null;
};

export default Report;
