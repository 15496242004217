import React, {
  InputHTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
  forwardRef,
  useRef,
  ClipboardEvent,
} from "react";

import cx from "clsx";
import { isString, trimEnd } from "lodash";

import { stripSuffix } from "@/lib/string";
import { parseUrl } from "@/lib/url";

import Tooltip from "@/components/Tooltip";

import { useInnerRef } from "@/components/hooks";
import { useText } from "@/components/hooks/useText";

import { pasteText } from "@/components/input/TextInput";

type DomainInputProps = PropsWithChildren<
  InputHTMLAttributes<HTMLInputElement>
> & {
  domainSuffix: string | string[];
  placeholder: string;
};

export const stripDomainSuffix = (domain: string, suffixes: string[]) =>
  suffixes.reduce((domain, suffix) => stripSuffix(domain, suffix), domain);

const domainSuffixProps = (domainSuffix: string | string[]) =>
  isString(domainSuffix)
    ? {
        primaryDomainSuffix: domainSuffix,
        domainSuffixes: [domainSuffix],
      }
    : {
        primaryDomainSuffix: domainSuffix[0],
        domainSuffixes: domainSuffix,
      };

const DomainInput = forwardRef<HTMLInputElement, DomainInputProps>(
  ({ children, domainSuffix, placeholder, ...props }, ref) => {
    const t = useText();
    const parentRef = useRef<HTMLDivElement>(null);
    const [inputRef, setInputRef] = useInnerRef(ref);

    const { primaryDomainSuffix, domainSuffixes } =
      domainSuffixProps(domainSuffix);

    const focusInput: MouseEventHandler<HTMLSpanElement> = (e) => {
      if (e.target === parentRef.current) {
        e.preventDefault();
        inputRef.current?.focus();
      }
    };

    const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");
      const domain = parseUrl(text)?.hostname || trimEnd(text, "/");
      pasteText(trimEnd(stripDomainSuffix(domain, domainSuffixes), "."));
    };

    return (
      <Tooltip content={t("tooltip.input")}>
        <div
          className="flex items-center grow input-border gap-1 cursor-text"
          onClick={focusInput}
          ref={parentRef}
        >
          <span className="pl-3 py-2 text-text-default select-none pointer-events-none">
            https://
          </span>
          <input
            {...props}
            ref={setInputRef}
            onPaste={onPaste}
            className="py-2 grow bg-transparent outline-none text-right input-text min-w-0 w-24"
            placeholder={placeholder}
            maxLength={128}
            autoComplete="off"
          />
          <span
            className={cx(
              "py-2 text-text-default select-none pointer-events-none",
              children ? "pr-1" : "pr-3"
            )}
          >
            {primaryDomainSuffix}
          </span>
          {children}
        </div>
      </Tooltip>
    );
  }
);

export default DomainInput;
