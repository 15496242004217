import { baseClient } from "../base";

export async function productPlanList(productId: string) {
  const response = await baseClient(`/dashboard/product/${productId}/`);
  if (response.ok) {
    return await response.json();
  }

  throw new Error(
    `Unable to retrieve dashboard products for '${productId}'. API response body: ` +
      (await response.text())
  );
}
