import { EntityId } from "@/client/models-api";
import { ProductComponentJSON } from "@/pages/products/serialization";

export interface ProductPlanComponentJSON extends ProductComponentJSON {
  id: string; // Plan Component ID
  product_component_id: string; // Product Component ID
  quantity?: number | null;
  order?: number;
  plan_id?: string;
}

export interface UnfurledProductPlanComponentJSON
  extends ProductPlanComponentJSON {
  plan: { id: string; name: string };
}

export const pluckPlanComponents = (
  plan?: ProductPlanDetailsJSON
): UnfurledProductPlanComponentJSON[] =>
  plan
    ? (plan.components ?? []).map((c) => ({
        ...c,
        plan: { id: plan.id, name: plan.name },
      }))
    : [];

export const toPlanComponent = (
  { id, is_countable, ...productComponent }: ProductComponentJSON,
  quantity: number | null
): ProductPlanComponentJSON => ({
  id: EntityId.new(),
  product_component_id: id,
  ...productComponent,
  is_countable,
  ...(is_countable ? { quantity } : {}),
});

export interface ProductPlanPriceTierJSON {
  id?: string;
  lower_bound: number;
  upper_bound?: number;
  tier_price?: number;
  price_id?: string;
  tier_percent_rate?: number;
}

export enum PricingType {
  UNIT = "UNIT",
  USAGE = "USAGE",
  FLAT_RATE = "FLAT-RATE",
}

export enum PricingFormula {
  SINGLE_RATE = "SINGLE-RATE",
  TIERED = "TIERED",
  VOLUME = "VOLUME",
  STAIRSTEP = "STAIRSTEP",
  PERCENTAGE = "PERCENTAGE",
}

export enum BillingPeriod {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}

export interface ProductPlanPricingJSON {
  id?: string;
  name?: string;
  pricing_type: PricingType;
  pricing_formula?: PricingFormula;
  billing_period: BillingPeriod;
  default_amount?: number;
  usage_metric_name?: string;
  default_percent_rate?: number;
  tiers?: ProductPlanPriceTierJSON[];
}

export interface ProductPlanDetailsJSON {
  id: string;
  name: string;
  description?: string;
  free_plan?: boolean;
  pricing_unit?: string;
  is_active: boolean;
  product_id?: string;
  product_name?: string;
  base_plan_id?: string;
  components?: ProductPlanComponentJSON[];
  created_at?: string;
  updated_at?: string;
}

export interface ProductPlanSetJSON extends ProductPlanDetailsJSON {
  components: ProductPlanComponentJSON[];
  prices: ProductPlanPricingJSON[];
}
