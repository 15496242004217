import { useState, useEffect, useCallback, type ForwardedRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRefCallback } from "@/components/hooks";

export const useScrollToState = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { scrollTo } = location.state ?? {};
  useEffect(() => {
    if (scrollTo) {
      // clear location state so that we don't scroll again on refresh
      setTimeout(() => navigate(location.pathname, {}), 1000);
    }
  }, [scrollTo, location.pathname, navigate]);

  return scrollTo;
};

const useScrollIntoView = <T extends HTMLElement>(
  ref: ForwardedRef<T>,
  scrollIntoView?: boolean,
  options?: { highlightDurationMs?: number; scrollClass?: string }
) => {
  const [scrollClass, setScrollClass] = useState("");
  const setScrollRef = useCallback(
    (element: T) => {
      if (!element || !scrollIntoView) return;

      setScrollClass(options?.scrollClass ?? "!border-text-link");
      setTimeout(() => setScrollClass(""), options?.highlightDurationMs ?? 750);
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    },
    [scrollIntoView, options?.scrollClass, options?.highlightDurationMs]
  );

  const [_, scrollRef] = useRefCallback(ref, setScrollRef);
  return { scrollClass, scrollRef };
};

export default useScrollIntoView;
