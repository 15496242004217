import React from "react";
import { Link } from "react-router-dom";
import cx from "clsx";

// @ts-ignore
import { ReactComponent as ArrowIcon } from "@/assets/icons/arrow-right.svg";

import { Text, TranslationScope } from "@/components/hooks/useText";

import KeyStats from "../components/KeyStats";
import KeyCharts from "../components/KeyCharts";

import { ReportMetadata, useReportIndex } from "./api";
import { reportLink } from "./routes";

const ReportCard = ({
  id,
  name,
  publish_date,
  stats,
  charts,
}: ReportMetadata) => (
  <TranslationScope keyPrefix="summary">
    <div className="group bg-bg-l2 rounded-lg py-6 px-10">
      <Link to={reportLink(id)} className="flex flex-col gap-4">
        <div className="text-text-default">
          <Text i18nKey="publish_date" values={{ publish_date }} />
        </div>
        <h2
          className={cx(
            "text-base-white text-5xl font-bold group-hover:text-text-link",
            "state-transition-colors"
          )}
        >
          {name}
        </h2>
        {charts && <KeyCharts {...{ charts }} />}
        <div className="flex items-center justify-between">
          <KeyStats {...{ stats }} />
          <ArrowIcon
            className={cx(
              "h-6 text-text-muted translate-x-3 group-hover:text-text-link",
              "state-transition transition-all origin-left group-hover:scale-[1.1]"
            )}
          />
        </div>
      </Link>
    </div>
  </TranslationScope>
);

const SmallReportCard = ({ id, name, publish_date, stats }: ReportMetadata) => (
  <TranslationScope keyPrefix="summary">
    <div className="group bg-bg-l2 rounded-lg py-4 px-6 max-w-[20rem]">
      <Link to={reportLink(id)} className="flex flex-col gap-3">
        <div className="text-text-default text-sm">
          <Text i18nKey="publish_date" values={{ publish_date }} />
        </div>
        <h2
          className={cx(
            "text-text-bright text-xl font-bold group-hover:text-text-link",
            "state-transition-colors leading-tight"
          )}
        >
          {name}
        </h2>
        <div className="flex items-center justify-between">
          <KeyStats {...{ stats }} className="text-sm" />
        </div>
      </Link>
    </div>
  </TranslationScope>
);

const ReportsIndex = () => {
  const reports = useReportIndex();
  const [latestReport, ...pastReports] = reports ?? [];
  return (
    <TranslationScope ns="insights" keyPrefix="reports">
      <div className="flex flex-col gap-4">
        <h2 className="text-text-bright text-2xl font-semibold">
          <Text i18nKey="title" />
        </h2>
        {latestReport && <ReportCard {...latestReport} />}
        <div className="overlow-y-auto">
          <ul className="flex gap-4">
            {pastReports?.map((r) => (
              <li key={r.id}>
                <SmallReportCard {...r} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </TranslationScope>
  );
};

export default ReportsIndex;
