import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { initAnalytics } from "@/lib/analytics";
import { initI18n } from "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "inter-ui/inter.css";
import "./index.css";

initAnalytics();
initI18n();

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
