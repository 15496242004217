import React from "react";

import { useAPI } from "@/client/api";
import useText, { TranslationScope } from "@/components/hooks/useText";

// @ts-ignore
import { ReactComponent as SlackLogo } from "@/assets/logos/slack.svg";
// @ts-ignore
import { ReactComponent as CircleCheck } from "@/assets/icons/circle-check.svg";
import SettingsCard from "./SettingsCard";

interface SlackInstallJSON {
  url: string;
  state: string;
  installed_at: string;
  workspace: string;
}

export interface SlackConfig {
  url: string;
  state: string;
  installedAt?: Date;
  workspace: string;
}

export const useSlackInstallAPI = () => {
  const endpoint = "slack/install/";
  const { data } = useAPI<SlackInstallJSON>(endpoint);
  if (!data) return { url: "", state: "", workspace: "" };
  const { url, state, installed_at, workspace } = data;
  return {
    url,
    state,
    installedAt: new Date(installed_at),
    workspace: workspace,
  };
};

export const SlackInstallation = ({
  workspace,
  installedAt,
}: {
  workspace: string;
  installedAt?: Date;
}) => {
  const t = useText();
  return (
    <div className="grow">
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="text-lg text-text-bright font-bold mx-1 grow cursor-default">
          {workspace}
        </div>
        <div className="flex-none">
          <button
            disabled={true}
            className="button-base button-primary px-4 py-1.5"
          >
            <span className="flex text-text-success gap-2">
              <CircleCheck className="w-5" />
              {t("status.connected")}
            </span>
          </button>
        </div>
      </div>
      <div className="text-sm text-text-muted mx-1 cursor-default">
        {t("footer.connected", {
          installedAt,
          formatParams: {
            installedAt: { dateStyle: "medium", timeStyle: "long" },
          },
        })}
      </div>
    </div>
  );
};

export const SlackInstallLink = ({
  url,
  className,
}: {
  url: string;
  className: string;
}) => {
  const t = useText();
  return (
    <a className={className} href={url} target="_blank" rel="noreferrer">
      {t("action.connect")}
    </a>
  );
};

const SlackSettings = () => {
  const config: SlackConfig = useSlackInstallAPI();
  const connected = !!config.workspace;
  return (
    <SettingsCard unsavedChanges={false}>
      {connected ? (
        <div className="flex flex-row gap-2 items-center">
          <SlackInstallation
            workspace={config.workspace}
            installedAt={config.installedAt}
          />
        </div>
      ) : (
        <div className="flex flex-row gap-2 items-center grow">
          <div className="flex-initial">
            <SlackInstallLink
              url={config.url}
              className="button-base py-2 button-primary px-4"
            />
          </div>
        </div>
      )}
    </SettingsCard>
  );
};

const Slack = () => (
  <TranslationScope keyPrefix="slack">
    <div className="flex bg-bg-l2 rounded-md p-4 gap-4">
      <SlackLogo className="w-14 p-1 self-start" />
      <SlackSettings />
    </div>
  </TranslationScope>
);

export default Slack;
