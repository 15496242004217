import React, {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
  ReactElement,
} from "react";

import { useTranslation, Trans } from "react-i18next";

interface TranslationScopeContextType {
  ns: string;
  keyPrefix?: string;
}

const TranslationScopeContext = createContext<TranslationScopeContextType>({
  ns: "common",
});

const NestedTranslationScope = ({
  keyPrefix: scopedPrefix,
  children,
}: PropsWithChildren<{
  keyPrefix?: string;
}>) => {
  const { ns, keyPrefix } = useContext(TranslationScopeContext);
  return (
    <TranslationScopeContext.Provider
      value={{
        ns,
        keyPrefix: [keyPrefix, scopedPrefix].filter(Boolean).join("."),
      }}
    >
      {children}
    </TranslationScopeContext.Provider>
  );
};

export const TranslationScope = ({
  ns,
  keyPrefix,
  children,
}: PropsWithChildren<{
  ns?: string;
  keyPrefix?: string;
}>) =>
  ns ? (
    <TranslationScopeContext.Provider value={{ ns, keyPrefix }}>
      {children}
    </TranslationScopeContext.Provider>
  ) : (
    <NestedTranslationScope keyPrefix={keyPrefix}>
      {children}
    </NestedTranslationScope>
  );

export const useTranslationScope = (
  ns: string,
  { keyPrefix }: { keyPrefix?: string } = {}
) => {
  const { t } = useTranslation(ns, { keyPrefix });
  const BoundTranslationScope = useMemo(
    () =>
      ({ children }: PropsWithChildren) =>
        (
          <TranslationScope ns={ns} keyPrefix={keyPrefix}>
            {children}
          </TranslationScope>
        ),
    [ns, keyPrefix]
  );

  return { t, TranslationScope: BoundTranslationScope };
};

export const useText = ({
  keyPrefix: scopedPrefix,
}: { keyPrefix?: string } = {}) => {
  const { ns, keyPrefix } = useContext(TranslationScopeContext);
  const { t } = useTranslation(ns, {
    keyPrefix: [keyPrefix, scopedPrefix].filter(Boolean).join("."),
  });

  return t;
};

export const mapKey = (key: string, ns: string, keyPrefix?: string) =>
  key.includes(":")
    ? key
    : [ns, [keyPrefix, key].filter(Boolean).join(".")].join(":");

export const mapKeyOrKeys = (
  key: string | string[],
  ns: string,
  keyPrefix?: string
) =>
  key instanceof Array
    ? key.map((key) => mapKey(key, ns, keyPrefix))
    : mapKey(key, ns, keyPrefix);

export const Text = ({
  i18nKey,
  ...props
}: {
  i18nKey: string | string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: Record<string, any>;
  defaults?: string;
  components?:
    | readonly ReactElement[]
    | { readonly [tagName: string]: ReactElement };
}) => {
  const { ns, keyPrefix } = useContext(TranslationScopeContext);
  return (
    <Trans {...{ i18nKey: mapKeyOrKeys(i18nKey, ns, keyPrefix), ...props }} />
  );
};

export default useText;
