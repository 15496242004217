import { useCallback } from "react";
import ky, { KyResponse } from "ky";
import useSWR, { Key, useSWRConfig, SWRConfig, preload as preload_ } from "swr";
import Cookies from "js-cookie";
import { isNil } from "lodash";

import { WithId } from "@/client/types";

const api = ky.create({
  prefixUrl: process.env["REACT_APP_API_HOST"],
  credentials: "include",
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set("X-CSRFToken", Cookies.get("csrftoken"));
      },
    ],
  },
});

interface CreateOrUpdateOptions {
  isNew: boolean;
}

const createOrUpdateExt = async <Entity, R = Entity>(
  url: string,
  entity: Entity,
  entityId?: string,
  options?: CreateOrUpdateOptions
) =>
  (await (options?.isNew || isNil(entityId)
    ? api.post(url, { json: entity })
    : api.put(`${url}${entityId}/`, { json: entity })
  ).json()) as R;

const createOrUpdate = async <Entity extends WithId, R = Entity>(
  url: string,
  entity: Entity,
  options?: CreateOrUpdateOptions
) => createOrUpdateExt<Entity, R>(url, entity, entity.id, options);

const partialUpdateExt = async <Entity, R = Entity>(
  url: string,
  entity: Partial<Entity>,
  entityId: string
) => (await api.patch(`${url}${entityId}/`, { json: entity }).json()) as R;

const fetcher = <Data>(url: string) => api.get(url).json() as Data;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAPI = <Data>(key: Key, options?: any) =>
  useSWR<Data>(key, fetcher, options);

export const APIConfig = SWRConfig;

export const useAPIConfig = () => {
  const { cache, mutate, ...config } = useSWRConfig();
  const resetCache = useCallback(
    async ({ excludeKeys }: { excludeKeys?: [string] } = {}) =>
      Promise.all(
        [...cache.keys()]
          .filter((key) => !excludeKeys?.includes(key))
          .map((key) => mutate(key, null))
      ),
    [cache, mutate]
  );

  return {
    cache,
    resetCache,
    mutate,
    ...config,
  };
};

export interface APIErrorJSON<Field extends string = string> {
  field?: Field;
  error?: string;
}

export const extractError = async <Field extends string = string>(
  res: KyResponse
) => {
  if (res.ok) return null;

  try {
    return await res.json<APIErrorJSON<Field>>();
  } catch (x) {
    console.error(x);
    return { error: "internal error" };
  }
};

export const preload = (url: string) => preload_(url, fetcher);

export default { ...api, createOrUpdate, createOrUpdateExt, partialUpdateExt };
