import {
  format as formatDate,
  differenceInCalendarDays,
  differenceInCalendarYears,
} from "date-fns";
import memoize from "micro-memoize";
import { NumericInputOptions } from "@/components/input/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const currencyFormatter = (lang?: string, options: any = {}) =>
  new Intl.NumberFormat(lang, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 6,
    ...options,
  });

const currencyNoFractionsFormatter = (lang?: string) =>
  currencyFormatter(lang, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const currencyLarge = (value: number, lang?: string) =>
  currencyNoFractionsFormatter(lang).format(Math.round(value));

interface PercentageOptions {
  fractional?: boolean;
  maxFractionDigits?: number;
}

const percentage = (
  value: number,
  lang?: string,
  options?: PercentageOptions
) =>
  new Intl.NumberFormat(
    lang,
    options?.fractional
      ? {
          style: "percent",
          maximumFractionDigits: options?.maxFractionDigits ?? 2,
        }
      : { style: "unit", unit: "percent" }
  ).format(value);

const decimal = (
  value: number,
  lang?: string,
  options?: Intl.NumberFormatOptions
) =>
  new Intl.NumberFormat(lang, { style: "decimal", ...options }).format(value);

const fileSize = (value: number, lang?: string) =>
  new Intl.NumberFormat(lang, {
    notation: "compact",
    style: "unit",
    unit: "byte",
    unitDisplay: "narrow",
  }).format(value);

export const unitFormatter = memoize((unit: string, lang?: string) => {
  try {
    return new Intl.NumberFormat(lang, { style: "unit", unit });
  } catch (x) {
    return null;
  }
});

const unit = (value: number, lang?: string, options?: { unit: string }) =>
  options?.unit
    ? unitFormatter(options?.unit, lang)?.format(value) ??
      `${decimal(value, lang)} ${options.unit}`
    : decimal(value, lang);

const timestamp = (value: Date) => {
  const today = new Date();
  const formatStr =
    differenceInCalendarDays(today, value) === 0
      ? "h:mm aaa"
      : differenceInCalendarYears(today, value) === 0
      ? "MMM d, h:mm aaa"
      : "MMM d, yyyy, h:mm aaa";

  return formatDate(value, formatStr);
};

const date = (value: Date, lang?: string) =>
  new Intl.DateTimeFormat(lang).format(value);

const formalDate = (value: Date) => formatDate(value, "MMM d, yyyy");
const shortDate = (value: Date) =>
  formatDate(
    value,
    differenceInCalendarYears(new Date(), value) === 0 ? "MMM d" : "MMM d, yyyy"
  );

export default {
  currencyPrice: (value: number, lang?: string) =>
    (value % 1 === 0
      ? currencyNoFractionsFormatter(lang)
      : currencyFormatter(lang)
    ).format(value),

  currencyLargeCompact: (value: number, lang?: string) =>
    currencyFormatter(lang, {
      notation: "compact",
      minimumFractionDigits: 0,
      maximumFractionDigits: value > 1000 ? 1 : 0,
    }).format(Math.round(value)),

  currencyLarge,
  percentage,
  unit,
  decimal,
  fileSize,

  numericLarge: (
    value: number,
    lang?: string,
    options?: NumericInputOptions
  ) => {
    const opt = options ?? { valueType: "decimal" };

    switch (opt.valueType) {
      case "currency":
        return currencyLarge(value, lang);

      case "percent":
        return percentage(value, lang);

      case "decimal":
        return decimal(value, lang);

      case "unit":
        return unit(value, lang, { unit: opt.unit });
    }
  },

  lowercase: (value: string) => value.toLowerCase(),

  timestamp,
  date,
  formalDate,
  shortDate,
};
