import React, {
  useState,
  useMemo,
  useRef,
  forwardRef,
  Ref,
  PropsWithChildren,
  ForwardedRef,
  FocusEvent,
} from "react";

import { useController, FieldValues, FieldPathByValue } from "react-hook-form";
import { Combobox } from "@headlessui/react";
import { matchSorter, MatchSorterOptions } from "match-sorter";
import cx from "clsx";

import { isNotNil } from "@/lib/functional";

// @ts-ignore
import { ReactComponent as DropdownIcon } from "@/assets/icons/ctrl-down.svg";
// @ts-ignore
import { ReactComponent as CheckIcon } from "@/assets/icons/check.svg";

import { InputPadding } from "@/components/input/types";
import { CustomInputProps } from "@/components/input/props";
import { useInnerRef } from "@/components/hooks";

interface EntitySelectRenderProps<ValueType> {
  optionKey: (value: ValueType) => string;
  displayValue: (value: ValueType | null) => string;
}

export const filterOptions = <ValueType,>(
  fields: ValueType[],
  input: string,
  keys: MatchSorterOptions<ValueType>["keys"]
) => {
  if (!input.trim()) return fields;

  const terms = input.trim().toLowerCase().split(/\s+/);

  return terms.reduceRight(
    (results, term) =>
      matchSorter(results, term, {
        keys,
        threshold: matchSorter.rankings.WORD_STARTS_WITH,
      }),
    fields
  );
};

const OptionItem = <ValueType,>({
  value,
  children,
}: PropsWithChildren<{ value: ValueType }>) => (
  <Combobox.Option
    value={value}
    className={({ active }) =>
      cx(
        "flex justify-between cursor-pointer select-none py-2 pl-3 pr-4 leading-tight",
        active ? "bg-bg-l3 text-text-bright" : "text-text-default"
      )
    }
  >
    {({ selected }) => (
      <>
        <span
          className={cx(
            "break-words min-w-0",
            selected ? "font-medium text-text-link" : "ui-selected:font-medium"
          )}
        >
          {children}
        </span>
        {selected && (
          <CheckIcon className="w-5 aspect-square shrink-0 text-text-link" />
        )}
      </>
    )}
  </Combobox.Option>
);

const OptionsDropdown = <ValueType,>({
  options,
  hasOptions,
  input,
  optionKey,
  displayValue,
}: EntitySelectRenderProps<ValueType> & {
  options: ValueType[];
  hasOptions: boolean;
  input: string;
}) => (
  <Combobox.Options
    className={cx(
      "absolute mt-1 py-1 max-h-60 w-full overflow-auto p-0",
      "z-50 shadow-lg rounded-md bg-bg-l2 border border-border-default"
    )}
  >
    {options.length ? (
      options.map((value) => (
        <OptionItem key={optionKey(value)} value={value}>
          {displayValue(value)}
        </OptionItem>
      ))
    ) : (
      <li className="text-text-muted py-2 px-3 select-none">
        {hasOptions && input.trim() ? (
          <span>
            No matches for "
            <span className="font-medium break-all text-text-default">
              {input}
            </span>
            " 🤔
          </span>
        ) : (
          "Nothing to select 🤷"
        )}
      </li>
    )}
  </Combobox.Options>
);

const ComboboxInput = forwardRef(
  <ValueType,>(
    {
      displayValue,
      onChange,
      onFocus,
      placeholder,
      className,
      id,
    }: {
      displayValue: (value: ValueType | null) => string;
      onChange: (value: string) => void;
      onFocus: VoidFunction;
      placeholder?: string;
      className?: string;
      id?: string;
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <Combobox.Button as="div">
      {/* ^^ automatically open the dropdown when user clicks on the input, see 
          https://github.com/tailwindlabs/headlessui/discussions/1236#discussioncomment-2970969;
          opening the dropdown when user *tabs* into the field is handled by the "focus traps" 
          machinery below. */}
      <Combobox.Input
        id={id}
        className={className}
        maxLength={128}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
        displayValue={displayValue}
        ref={ref}
        onKeyDown={(event) => event.key === "Escape" && onChange("")}
        onFocus={onFocus}
      />
    </Combobox.Button>
  )
);

const KeyboardFocusTrap = forwardRef(
  (
    { onFocus, disabled }: { onFocus: VoidFunction; disabled?: boolean },
    ref: Ref<HTMLInputElement>
  ) => {
    const [innerRef, setInnerRef] = useInnerRef(ref);

    return (
      <input
        className="absolute w-0 h-0 pointer-events-none outline-none grow-0"
        aria-hidden="true"
        ref={setInnerRef}
        tabIndex={0}
        onFocus={(e) => {
          if (
            e.target === innerRef.current &&
            !e.target.contains(e.relatedTarget)
          ) {
            onFocus();
          }
        }}
        disabled={disabled}
      />
    );
  }
);

const useFocusTraps = () => {
  const tabForwardTrapRef = useRef<HTMLInputElement>(null);
  const tabBackwardTrapRef = useRef<HTMLInputElement>(null);
  const setTabIndex = (tabIndex: number) => {
    if (tabForwardTrapRef.current)
      tabForwardTrapRef.current.tabIndex = tabIndex;

    if (tabBackwardTrapRef.current)
      tabBackwardTrapRef.current.tabIndex = tabIndex;
  };

  return {
    tabForwardTrapRef,
    tabBackwardTrapRef,
    arm: () => setTabIndex(0),
    disarm: () => setTabIndex(-1),
  };
};

const DropdownToggle = forwardRef(
  ({ className }: { className: string }, ref: Ref<HTMLButtonElement>) => (
    <Combobox.Button
      ref={ref}
      className={cx(
        "absolute inset-y-0 right-0 flex items-center text-text-muted hover:text-text-link",
        "group-disabled/fieldset:text-border-default ui-disabled:text-border-default",
        className
      )}
    >
      <DropdownIcon className="h-4 w-4 outline-none" aria-hidden="true" />
    </Combobox.Button>
  )
);

export const Select = forwardRef(
  <ValueType,>(
    {
      options,
      searchKeys,
      optionKey,
      displayValue,
      value,
      onChange,
      onBlur,
      invalid,
      disabled,
      placeholder,
      padding = "normal",
      className,
      id,
    }: EntitySelectRenderProps<ValueType> & {
      options: ValueType[];
      searchKeys: MatchSorterOptions<ValueType>["keys"];
      value?: ValueType;
      onChange?: (value: ValueType | null) => void;
      onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
      invalid?: boolean;
      disabled?: boolean;
      placeholder?: string;
      padding?: InputPadding;
      className?: string;
      id?: string;
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { tabForwardTrapRef, tabBackwardTrapRef, ...focusTraps } =
      useFocusTraps();

    const toggleButtonRef = useRef<HTMLButtonElement>(null);
    const openDropdown = () => toggleButtonRef.current?.click();

    const [input, setInput] = useState("");
    const filteredOptions = useMemo(
      () => filterOptions(options, input, searchKeys),
      [options, input, searchKeys]
    );

    const nullableOptionKey = (value?: ValueType | null) =>
      isNotNil(value) ? optionKey(value) : value;

    const snugPadding = padding === "snug";

    return (
      <Combobox
        as="div"
        className={cx(
          "relative input-border",
          invalid && "!border-border-error",
          className
        )}
        value={value ?? null}
        onChange={(value) => onChange?.(value ?? null)}
        onBlur={(event: FocusEvent<HTMLDivElement>) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            focusTraps.arm();
            onBlur?.(event);
          }
        }}
        by={(lhs, rhs) => nullableOptionKey(lhs) === nullableOptionKey(rhs)}
        disabled={disabled}
        nullable
      >
        <div className="relative">
          <KeyboardFocusTrap
            ref={tabForwardTrapRef}
            onFocus={openDropdown}
            disabled={disabled}
          />
          <ComboboxInput
            ref={ref}
            id={id}
            displayValue={displayValue}
            onChange={setInput}
            placeholder={placeholder}
            onFocus={() => focusTraps.disarm()}
            className={cx(
              "bg-transparent input-text rounded-md outline-none w-full",
              snugPadding ? "pl-2.5 pr-8 py-1.5" : "pl-3 pr-9 py-2"
            )}
          />
          <KeyboardFocusTrap
            ref={tabBackwardTrapRef}
            onFocus={openDropdown}
            disabled={disabled}
          />
          <DropdownToggle
            ref={toggleButtonRef}
            className={snugPadding ? "px-2" : "px-2.5"}
          />
        </div>
        <OptionsDropdown
          options={filteredOptions}
          hasOptions={options.length > 0}
          input={input}
          optionKey={optionKey}
          displayValue={displayValue}
        />
      </Combobox>
    );
  }
);

const EntitySelect = <
  ValueType,
  Entity extends FieldValues,
  Name extends FieldPathByValue<Entity, ValueType | undefined>
>({
  name,
  control,
  required,
  ...props
}: CustomInputProps<Entity, Name> &
  EntitySelectRenderProps<ValueType> & {
    options: ValueType[];
    searchKeys: MatchSorterOptions<ValueType>["keys"];
    placeholder?: string;
    className?: string;
  }) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid },
  } = useController({ control, name, rules: { required } });

  return (
    <Select
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
      invalid={invalid}
      {...props}
    />
  );
};

export default EntitySelect;
