import React from "react";
import { NavLink } from "react-router-dom";

import clsx from "clsx";

import { useText, useTranslationScope } from "@/components/hooks/useText";
import HelpFooter from "@/components/HelpFooter";

interface SectionProps {
  id: string;
  links: string[];
}

const Section = ({ id, links }: SectionProps) => {
  const t = useText();
  return (
    <>
      <h2 className="uppercase text-text-bright mt-2">{t(`sections.${id}`)}</h2>
      <ul className="flex flex-col items-stretch mt-2 -ml-3">
        {links.map((link) => (
          <li key={link} className="flex">
            <NavLink
              to={link}
              className={({ isActive }) =>
                clsx(
                  isActive
                    ? "text-text-bright font-semibold bg-bg-l3"
                    : "text-text-default",
                  "py-2 px-3 rounded-md w-full hover:text-text-link"
                )
              }
              end
            >
              {t(`panels.${link}.link`)}
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
};

interface SettingsSidebarProps {
  sections: string[];
  paths: string[];
}

const SettingsSidebar = ({ sections, paths }: SettingsSidebarProps) => {
  const { t, TranslationScope } = useTranslationScope("settings");
  return (
    <TranslationScope>
      <nav className="flex flex-col gap-6">
        <h1 className="text-3xl font-semibold mt-4 mb-4 ml-5 text-text-bright">
          {t("title")}
        </h1>
        <div className="bg-bg-l2 border border-border-default rounded-md py-2 pl-5 pr-2">
          {sections.map((s) => (
            <Section
              key={s}
              id={s}
              links={paths.filter((p) => p.startsWith(s))}
            />
          ))}
        </div>
        <HelpFooter i18nKey="help" contactEmail="help@maca.io" />
      </nav>
    </TranslationScope>
  );
};

export default SettingsSidebar;
