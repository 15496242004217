import React, { useCallback, useState } from "react";

import { useWatch, type Control } from "react-hook-form";
import z, { Infer } from "myzod";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import cx from "clsx";
import { isEqual } from "lodash";

// @ts-ignore
import { ReactComponent as CircleCheck } from "@/assets/icons/circle-check.svg";

import { slug } from "@/lib/string";
import api, { useAPI, type APIErrorJSON } from "@/client/api";
import { useAuth } from "@/components/Auth";

import { useText, Text } from "@/components/hooks/useText";

import ErrorMessage from "@/components/input/ErrorMessage";
import Form from "@/components/input/Form";
import { CoreButton, PrimaryButton } from "@/components/CoreButtons";
import UndoButton from "@/components/UndoButton";

import Input from "@/components/easy-form/Input";
import InputField from "@/components/easy-form/InputField";
import useEasyForm from "@/components/easy-form";

import SettingsPanel from "@/pages/settings/SettingsPanel";

const profileSchema = z.object({
  name: z.string(),
  phone: z
    .string()
    .withPredicate((s) => !s || isMobilePhone(s), "invalid phone")
    .optional(),
  email: z.string().withPredicate(isEmail, "invalid email"),
  pending_email: z.string().optional().nullable(),
  email_change_password: z.string().optional().nullable(),
});

type UserProfile = Infer<typeof profileSchema>;
type APIError = APIErrorJSON<keyof UserProfile>;

const useUserProfileAPI = () => {
  const endpointUrl = "user/";
  const { data, mutate } = useAPI<UserProfile>(endpointUrl);

  const updateProfile = async (
    json: UserProfile
  ): Promise<UserProfile | APIError> => {
    try {
      const res = await api.put(endpointUrl, { json, throwHttpErrors: false });
      const data = await res.json<UserProfile | APIError>();

      if (res.ok) {
        await mutate(data as UserProfile, {
          revalidate: false,
        });
      }

      return data;
    } catch (x) {
      console.error(x);
      return { error: "internal error" };
    }
  };

  return {
    data,
    updateProfile,
  };
};

const SaveSettingsButtons = ({
  control,
  currentValues,
  revertChanges,
}: {
  control: Control<UserProfile>;
  currentValues: UserProfile;
  revertChanges: () => void;
}) => {
  const values = useWatch({ control });
  const hasChanges = !isEqual(values, currentValues);
  return (
    <div className="flex justify-end gap-2">
      <UndoButton
        onClick={revertChanges}
        className={cx(
          "transition-opacity duration-300",
          hasChanges ? "opacity-100" : "opacity-0"
        )}
        disabled={!hasChanges}
      />
      <PrimaryButton disabled={!hasChanges}>
        <Text i18nKey="buttons.save" />
      </PrimaryButton>
    </div>
  );
};

const EmailChangePassword = ({
  register,
}: {
  register: ReturnType<typeof useEasyForm<UserProfile>>["register"];
}) => (
  <InputField {...register("email_change_password")}>
    {({ error, control: _, ...props }) => (
      <input
        type="password"
        autoComplete="current-password"
        className={cx(
          "text-text-default text-sm input-border bg-transparent px-3 py-2",
          error && "!border-border-error"
        )}
        {...props}
      />
    )}
  </InputField>
);

const PendingEmailBanner = ({ pendingEmail }: { pendingEmail: string }) => (
  <div className="flex pl-0.5 select-none">
    <div className="text-sm bg-base-orange-300 text-bg-l1 rounded-md px-2 pt-1 pb-1.5 leading-tight">
      <Text
        i18nKey="fields.pending_email"
        values={{ pendingEmail }}
        components={[<span className="font-medium" />]}
      />
    </div>
  </div>
);

const EmailInput = ({
  register,
  control,
  currentValue,
  pendingEmail,
}: {
  register: ReturnType<typeof useEasyForm<UserProfile>>["register"];
  control: Control<UserProfile>;
  currentValue?: string;
  pendingEmail?: string | null;
}) => {
  const value = useWatch({ control, name: "email" });
  return (
    <div className="flex flex-col gap-2">
      <Input {...register("email")} />
      {value && currentValue?.toLocaleLowerCase() !== value.toLowerCase() && (
        <EmailChangePassword {...{ register }} />
      )}
      {pendingEmail && <PendingEmailBanner {...{ pendingEmail }} />}
    </div>
  );
};

const ProfileSettings = () => {
  const { data, updateProfile } = useUserProfileAPI();
  const t = useText();
  const { register, control, reset, handleSubmit, errors, setError } =
    useEasyForm<UserProfile>({
      values: data ?? {},
      schema: profileSchema,
    });

  const revertChanges = useCallback(() => reset(data), [reset, data]);
  const saveChanges = useCallback(
    async (data: UserProfile) => {
      const res = await updateProfile(data);
      if ("error" in res) {
        setError(res.field ?? "root", { type: "server", message: res.error });
      } else {
        reset(res as UserProfile);
      }
    },
    [setError, updateProfile, reset]
  );

  return (
    <div className="flex bg-bg-l2 rounded-md p-6 gap-4">
      <Form
        className="flex flex-col grow gap-6"
        onSubmit={handleSubmit(saveChanges)}
      >
        <Input {...register("name", { required: false })} />
        <Input {...register("phone", { required: false })} />
        <EmailInput
          {...{
            register,
            control,
            currentValue: data?.email,
            pendingEmail: data?.pending_email,
          }}
        />
        {errors.root && (
          <ErrorMessage
            message={t(`error.${slug(errors.root.message ?? "unknown")}`)}
          />
        )}
        <SaveSettingsButtons
          {...{ control, currentValues: data, revertChanges }}
        />
      </Form>
    </div>
  );
};

interface PasswordResetStatus {
  shown: boolean;
  success?: boolean;
}

const PasswordSettings = () => {
  const { session } = useAuth();
  const email = session.user?.email;

  const [status, setStatus] = useState<PasswordResetStatus>({
    shown: false,
  });

  const passwordReset = async () => {
    try {
      await api.post("user/generate-token/", { json: { email } }).json();

      setStatus({ shown: true, success: true });
    } catch (x) {
      console.error(x);
      setStatus({ shown: true, success: false });
    }

    setTimeout(
      () => setStatus((status) => ({ ...status, shown: false })),
      5000
    );
  };

  return (
    <div className="flex gap-4">
      <CoreButton
        className="bg-bg-l3 text-text-default hover:text-text-link focus:text-text-link active:bg-bg-l250"
        onClick={passwordReset}
      >
        <Text i18nKey="buttons.password_change" />
      </CoreButton>
      <div
        className={cx(
          "text-text-default text-sm leading-tight flex items-center gap-1.5 transition-all duration-300",
          status.shown ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        )}
        aria-hidden={!status.shown}
      >
        {status.success ? (
          <>
            <CircleCheck className="w-5 text-text-success shrink-0" />
            <Text
              i18nKey="password_reset.success"
              values={{ email }}
              components={[<span className="font-medium" />]}
            />
          </>
        ) : (
          <span className="text-text-error">
            <Text i18nKey="password_reset.failure" />
          </span>
        )}
      </div>
    </div>
  );
};

const Profile = () => (
  <SettingsPanel keyPrefix="user/profile">
    <ProfileSettings />
    <PasswordSettings />
  </SettingsPanel>
);

export default Profile;
